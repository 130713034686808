import React from 'react';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { IExplainerFocusPanelProps } from './explainer-focus-panel.models';
import { Button } from '@keplerco/core';

export function ExplainerFocusPanelLayout(props: IExplainerFocusPanelProps): JSX.Element {
  return (
    <React.Fragment>
      <div className="dialogGraphicLayout" style={{ overflow: 'hidden' }}>
        <object data={themedAssetUrl('graphics/horizontal-pattern.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/horizontal-pattern.graphic.svg')} alt="" />
        </object>
      </div>

      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ textAlign: 'left' }}>
          <h2 className="heading2">{props.title}</h2>
        </header>

        <div className="dialogBodyLayout">
          <h6 className="subtitle">{props.description}</h6>
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout">
          <Button type="button" filled arrow={false} onClick={props.onClose}>
            GOT IT, THANKS
          </Button>
        </footer>
      </div>
    </React.Fragment>
  );
}

