import React from 'react';

export function ReflectIcon(): JSX.Element {
  return (
    <svg fill="none" viewBox="0 0 41 51">
      <path
        fill="var(--primary)"
        d="M7.16533 5.79774c-.63777 0-1.15433.51858-1.15433 1.15635V47.6063c0 .638.51656 1.1575 1.15433 1.1575H37.9456c.6378 0 1.1573-.5195 1.1573-1.1575V6.95409c0-.63777-.5195-1.15635-1.1573-1.15635H7.16533ZM37.9456 50.4165H7.16533c-1.5468 0-2.80791-1.2599-2.80791-2.8102V6.95409c0-1.5494 1.26111-2.80994 2.80791-2.80994H37.9456c1.5497 0 2.8109 1.26054 2.8109 2.80994V47.6063c0 1.5503-1.2612 2.8102-2.8109 2.8102Z"
      />
      <path
        fill="var(--primary)"
        d="M5.18214 46.6899h-.12873c-2.6433 0-4.791691-2.1506-4.791691-4.7948V27.496c0-.4563.369054-.8265.823911-.8265.45771 0 .82672.3702.82672.8265v14.3991c0 1.4924 1.04704 2.7451 2.44306 3.0627V6.95439c0-1.54936 1.26157-2.81007 2.81207-2.81007H34.8162c-.4376-1.21409-1.5991-2.07459-2.9493-2.07459H5.05341c-1.73073 0-3.14106 1.40919-3.14106 3.14107v16.883c0 .4572-.36901.8274-.82672.8274-.454857 0-.823911-.3702-.823911-.8274V5.2108c0-2.64359 2.148391-4.794277 4.791691-4.794277H31.8669c2.5288 0 4.6286 1.978757 4.7802 4.505047.0143.22742-.0687.45-.2231.61592-.1574.16592-.3748.26032-.6008.26032H7.16748c-.63793 0-1.15573.51864-1.15573 1.15658V45.8626c0 .4572-.3719.8273-.82961.8273Z"
      />
      <path
        fill="var(--primary)"
        d="M13.8578 10.6038c-1.838 0-3.332 1.4943-3.332 3.3314s1.494 3.3314 3.332 3.3314c1.8379 0 3.3319-1.4943 3.3319-3.3314s-1.494-3.3314-3.3319-3.3314Zm0 8.3166c-2.7487 0-4.9828-2.2366-4.9828-4.9852 0-2.7484 2.2341-4.98502 4.9828-4.98502 2.7486 0 4.9855 2.23662 4.9855 4.98502 0 2.7486-2.2369 4.9852-4.9855 4.9852Z"
      />
      <path
        fill="var(--secondary)"
        d="M13.8578 32.2651c-.4568 0-.8255-.3692-.8255-.8262 0-.4562.3687-.8265.8255-.8265 1.8379 0 3.3319-1.4943 3.3319-3.3314 0-1.8379-1.494-3.3314-3.3319-3.3314-1.838 0-3.332 1.4935-3.332 3.3314 0 .7429.2394 1.4458.6934 2.033.2779.361.2118.8799-.1486 1.1592-.3632.2792-.8804.2126-1.16109-.1494-.67684-.878-1.03451-1.9304-1.03451-3.0428 0-2.7487 2.2341-4.985 4.9828-4.985 2.7486 0 4.9855 2.2363 4.9855 4.985 0 2.7486-2.2369 4.9841-4.9855 4.9841Z"
      />
      <path
        fill="var(--primary)"
        d="M13.8578 37.2944c-1.838 0-3.332 1.4943-3.332 3.3323 0 1.8371 1.494 3.3316 3.332 3.3316 1.8379 0 3.3319-1.4945 3.3319-3.3316 0-1.838-1.494-3.3323-3.3319-3.3323Zm0 8.3167c-2.7487 0-4.9828-2.2358-4.9828-4.9844 0-2.7484 2.2341-4.985 4.9828-4.985 2.7486 0 4.9855 2.2366 4.9855 4.985 0 2.7486-2.2369 4.9844-4.9855 4.9844ZM35.4096 18.9204h-3.8327c-.4573 0-.8289-.3704-.8289-.8265 0-.4568.3716-.8272.8289-.8272h3.8327c.4544 0 .826.3704.826.8272 0 .4561-.3716.8265-.826.8265ZM27.2011 18.9199h-5.944c-.4576 0-.8255-.3703-.8255-.8263 0-.4571.3679-.8271.8255-.8271h5.944c.4548 0 .8255.37.8255.8271 0 .456-.3707.8263-.8255.8263ZM35.4082 14.7615H24.4217c-.4573 0-.826-.3693-.826-.8263 0-.4561.3687-.8266.826-.8266h10.9865c.4544 0 .826.3705.826.8266 0 .457-.3716.8263-.826.8263ZM35.4089 32.2654H21.2585c-.4573 0-.8288-.3696-.8288-.8266 0-.4561.3715-.8263.8288-.8263h14.1504c.4545 0 .826.3702.826.8263 0 .457-.3715.8266-.826.8266ZM35.4082 28.1074H24.4217c-.4573 0-.826-.3701-.826-.8263 0-.457.3687-.8274.826-.8274h10.9865c.4544 0 .826.3704.826.8274 0 .4562-.3716.8263-.826.8263ZM35.4089 45.6111H21.2585c-.4573 0-.8288-.3696-.8288-.8266 0-.4561.3715-.8263.8288-.8263h14.1504c.4545 0 .826.3702.826.8263 0 .457-.3715.8266-.826.8266ZM35.4082 41.4531H24.4217c-.4573 0-.826-.3701-.826-.8262 0-.457.3687-.8275.826-.8275h10.9865c.4544 0 .826.3705.826.8275 0 .4561-.3716.8262-.826.8262Z"
      />
    </svg>
  );
}

