import { Context } from '../..';
import * as base from '../base';
import { TeamMemberReviewResource } from '../../../models/team-member-review-resource';

export async function getTeamReviews(): Promise<TeamMemberReviewResource[] | undefined> {
  // TODO: remove hardcoded 1
  const request: base.IRequest = { url: base.url(`review`, `get-team-reviews`, '1'), authenticated: true };
  const response: base.IResponse<TeamMemberReviewResource[]> = await base.request(request);

  return response.data;
}

export async function submitTeamReviews(): Promise<void> {
  // TODO: remove hardcoded 1
  const request: base.IRequest = { url: base.url(`review`, `submit-team-reviews`, '1'), authenticated: true, method: 'POST' };
  await base.request(request);
}

export async function submitTeamMemberReview(context: Context, reviewMember: TeamMemberReviewResource): Promise<TeamMemberReviewResource | undefined> {
  // TODO: remove hardcoded 1
  const request: base.IRequest = { url: base.url(`review`, `submit-team-member-review`, '1'), authenticated: true, method: 'POST', body: JSON.stringify([reviewMember]) };
  const response: base.IResponse<TeamMemberReviewResource> = await base.request(request);

  return response.data;
}
