import React, { useEffect, useReducer, useRef, useState } from 'react';
import { AvatarIcon, GridItemLayout, GridLayout, Modal, Pager, Searchfield } from '@keplerco/core';
import { BaseSearchRequest } from '../../../../../models';
import { ConfirmationModalLayout } from '../../../../../widgets/layouts';
import { FetchType, OrganizationLevelType } from '../../../../../enums';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { IViewRoleAssignedEntitiesProps } from '../view-role.models';
import * as stateSetup from './view-role-assigned-people.state';
import styles from './view-role-assigned-people.module.css';
import { ViewRoleAssigneesSkeleton } from './view-role-assignees.skeleton';
import { EmptyState } from '../../../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import { EntityAssigneeListItemResponse } from '../../../../../models/overmind/entities';
import { useLocation } from 'react-router-dom';

export default function ViewRoleAssignedPeople({ role }: IViewRoleAssignedEntitiesProps) {
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(stateSetup.reducer, stateSetup.initialState);
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onInputHandler(value: string) {
    setIsLoading(true);
    dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  async function updateData(request?: BaseSearchRequest) {
    const data = pathname !== PagePath.myCareer
      ? await actions.getRoleAssignees({ companySlug: companyVariables.slug!, roleSlug: role.slug, page: request?.page ?? 1, pageSize: request?.pageSize ?? 9, search: request?.search })
      : await actions.getUserRoleAssignees({ roleSlug: role.slug, page: request?.page ?? 1, pageSize: request?.pageSize ?? 9, search: request?.search });
    dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetData, payload: data });
    setIsLoading(false);
  }

  function closeConfirmationModal() {
    dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetPersonToRemove, payload: { openConfirmationModal: false, person: undefined } });
  }

  useEffect(() => {
    async function fetchData() {
      actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });
      const result = pathname !== PagePath.myCareer
        ? await actions.getRoleAssignees({ companySlug: companyVariables.slug!, roleSlug: role.slug, page: state.request.page, pageSize: state.request.pageSize, search: state.request.search })
        : await actions.getUserRoleAssignees({ roleSlug: role.slug, page: state.request.page, pageSize: state.request.pageSize, search: state.request.search });

      if (result) dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetData, payload: result });

      actions.stopLoader(PagePath.administrationSelectedRole);
    }

    fetchData();
  }, [role]);

  useEffect(() => {
    updateData(state.request);
  }, [state.request]);

  async function removePersonFromRole() {
    const result = await actions.removePersonFromRole({
      companySlug: companyVariables.slug!,
      assignees: [
        {
          entitySlug: state.personToRemove!.slug!,
          organizationLevel: OrganizationLevelType.Learner,
        },
      ],
      entitySlug: role.slug,
    });

    dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetPersonToRemove, payload: { openConfirmationModal: false, person: undefined } });
    updateData();

    return result;
  }

  async function onPageChangeHandler(page: number) {
    if (page === state.request?.page) return;

    setIsLoading(true);
    dispatch({ type: stateSetup.ViewRoleAssignedPeopleActionTypes.SetRequest, payload: { ...state.request, page: page } });
  }

  return (
    <div ref={containerRef} className="card">
      <div className={styles.personCardsHeader}>
        <p className="body1">People</p>
        <span className="caption1">People assigned to {role?.name} role</span>
      </div>

      <div className={styles.personCardsSearch}>
        <Searchfield responsive label="Search People" loading={isLoading} onInput={onInputHandler} />
      </div>

      {(!!state.pageData?.assignees?.length || isLoading) && (
        <div className={styles.personCardsContainer}>
          {isLoading ? (
            <ViewRoleAssigneesSkeleton />
          ) : (
            <GridLayout columnCount={3}>
              {state.pageData?.assignees?.map((person: EntityAssigneeListItemResponse) => {
                return (
                  <GridItemLayout key={person.slug}>
                    <div className={styles.personCardWrapper}>
                      <div className={styles.personCardIcon}>
                        <AvatarIcon name={{ firstName: person.name!.split(' ')[0], lastName: person.name!.split(' ')[1] }} />
                      </div>

                      <div className={styles.personCardContent}>
                        <p className="body1">{person.name}</p>

                        <p className="caption2">{person.department}</p>
                      </div>

                      <div className={styles.personCardAction}>{/* <KebabMenu data={person} items={generateKebabMenuItems(person)} /> */}</div>
                    </div>
                  </GridItemLayout>
                );
              })}
            </GridLayout>
          )}
        </div>
      )}

      {!state.pageData?.assignees?.length && !isLoading && <EmptyState centerText={true} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} badgeIconWidth={'full-width'} subtitle="No people assigned to this role" />}

      {!!state.pageData?.assignees?.length && state.pageData?.totalPages > 1 && !isLoading && (
        <div>
          <Pager
            onPageChange={(pageNumber: number) => {
              if (pageNumber !== state.request?.page && typeof pageNumber !== 'undefined' && !isNaN(pageNumber)) {
                onPageChangeHandler(pageNumber);
                containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }}
            activePageNumber={state.request.page ?? 1}
            pageCount={state.pageData?.totalPages ?? 1}
          />
        </div>
      )}

      <Modal type="small" open={!!state.openConfirmationModal} onClose={closeConfirmationModal}>
        {!!state.openConfirmationModal && (
          <ConfirmationModalLayout
            title="Remove person"
            titleSize="standard"
            submitButtonText="Yes, remove"
            onClickSubmit={() => {
              actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });
              removePersonFromRole();
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
            textAlign="left"
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove <strong>{state.personToRemove?.name ? state.personToRemove?.name : state.personToRemove?.email ? state.personToRemove.slug : state.personToRemove?.slug}</strong> from this role?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </div>
  );
}

