import React, { useEffect, useRef, useState } from 'react';
import { AuthenticationStatus } from './enums/authentication-status';
import { Dots } from './theme/dots.background';
import { FetchType } from './enums';
import { Loader } from './components/loading-handling/loaders/loader/loader';
import { LoaderContainer } from './components/loading-handling/loaders/loader/loader.styles';
import { KeplerState } from './models/kepler-state';
import { Route, Routes } from 'react-router-dom';
import { page, track } from './lib/track';
import { AppLoaderLayer } from './components/loading-handling/loader-layers/app-loader-layer/app-loader-layer';
import { useAppActions, useAppState } from './overmind';
import { useWatchLocation } from './lib/use-watch-location';
import { useDataSyncTimeout } from './lib/data-sync.hooks';
import { useRefocusHook } from './lib/refocus-hook';
import { NavigationOutlet } from './navigation/navigation.outlet';
import { Mode, PagePath } from './navigation/navigation.enums';
import { AdministrationRouter } from './navigation/routers/administration.router';
import { AccountRouter } from './navigation/routers/account.router';
import { AnalysisRouter } from './navigation/routers/analysis.router';
import { LearningJourneyRouter } from './navigation/routers/learning-journey.router';
import { AnalyticsRouter } from './navigation/routers/analytics.router';
import { AssessmentsRouter } from './navigation/routers/assessments.router';
import { LearningManagementRouter } from './navigation/routers/learning-management.router';
import { SkillsRouter } from './navigation/routers/skills.router';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { CompaniesPage } from './pages/companies/companies.page';
import { PermissionType } from './enums/permission-type';
import { RouterGuard } from './navigation/guards/router.guard';
import { ProfilePage } from './pages/profile/profile.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { VersionPage } from './pages/version/version';
import { ErrorRedirect, ModeRedirect } from './navigation/routers/routers.helpers';
import { useMediaQuery } from '@keplerco/core';
import { YourSkillsRouter } from './navigation/routers/your-skills.router';
import { ErrorPage } from './pages/error/error.page';
import CompletedAssessmentsPage from './pages/assessments/completed-assessments/completed-assessments.page';
import { ReportManagementCMSPage } from './pages/report-management/report-management.cms.page';
import ViewRolePage from './pages/administration/roles/view-role/view-role.page';

export function App(): JSX.Element {
  const isTablet = useMediaQuery('screen and (max-width: 930px)');
  const isMobile = useMediaQuery('screen and (max-width: 580px)');

  const actions = useAppActions();
  const { user, authenticationStatus, isInitialising, mayViewSideNav, sideNavVisible, sideNavCollapsed, companyVariables, mode } = useAppState<KeplerState>();

  const hasInitialised = useRef<boolean>(false);

  const [mainPaddingLeft, setMainPaddingLeft] = useState<number>(0);

  useWatchLocation(() => {
    page();
  });

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.root, type: FetchType.PageFetching });
      await actions.initialiseApplication();
      actions.stopLoader(PagePath.root);
    }

    if (!hasInitialised.current) {
      getData();
      hasInitialised.current = true;
    }

    function watchClicks(event: MouseEvent) {
      const target = event.target as HTMLElement;

      const analyticsProperties: IAnalyticsProperties = {
        userId: user?.learnerSlug,
        companyId: user?.companySlug,

        elementTag: target.tagName.toLowerCase(),
        elementId: target.id,
        elementContent: target.textContent,
      };

      track('KPLR-CLICK-EVENT', analyticsProperties);
    }

    function watchSubmit(event: SubmitEvent) {
      const target = event.target as HTMLFormElement;

      const analyticsProperties: IAnalyticsProperties = { formId: target.id };
      [...new FormData(target).entries()].forEach(entry => {
        if (!entry[0].toLowerCase().includes('password')) analyticsProperties[entry[0]] = entry[1];
      });

      track('KPLR-FORM-EVENT', analyticsProperties);
    }

    window.addEventListener('click', watchClicks);
    window.addEventListener('submit', watchSubmit);

    return () => {
      window.removeEventListener('click', watchClicks);
      window.removeEventListener('submit', watchSubmit);
    };
  });

  useEffect(() => {
    if (isMobile || isTablet) return void setMainPaddingLeft(0);
    if (!mayViewSideNav) return void setMainPaddingLeft(0);
    if (!sideNavVisible) return void setMainPaddingLeft(0);
    if (sideNavCollapsed) return void setMainPaddingLeft(90);
    setMainPaddingLeft(245);
  }, [mayViewSideNav, sideNavVisible, sideNavCollapsed, isTablet, isMobile]);

  useEffect(() => {
    if (!companyVariables.slug) return;
    mode === Mode.PlatformManagement ? actions.getCompanyVariables(companyVariables.slug) : actions.getUserCompanyVariables();
  }, [companyVariables.slug]);

  useDataSyncTimeout();

  useRefocusHook(() => {
    actions.syncCourseContent().then(() => {
      actions.getTotalLearnerKeplerPoints();
    });
  });

  if (isInitialising || authenticationStatus === AuthenticationStatus.Unknown)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <main style={{ paddingLeft: mainPaddingLeft }}>
      <NavigationOutlet isMobile={isMobile} isTablet={isTablet} />

      {!!user && <Dots show={authenticationStatus === AuthenticationStatus.Authenticated} />}

      <Routes>
        {/* routers */}
        <Route
          path={PagePath.accountConfig}
          element={
            <RouterGuard authStatusRequired="Unauthenticated">
              <AccountRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.analysisConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Incomplete">
              <AnalysisRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.yourSkillsConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <YourSkillsRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.learningJourneyConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <LearningJourneyRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.administrationConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Administration}>
              <AdministrationRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.analyticsConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Analytics}>
              <AnalyticsRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.assessmentsConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Assessments}>
              <AssessmentsRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.learningManagementConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.LearningManagement}>
              <LearningManagementRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.reportManagement}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.PlatformManagement}>
              <ReportManagementCMSPage />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.skillsConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Courses}>
              <SkillsRouter />
            </RouterGuard>
          }
        />

        {/* pages */}
        <Route
          path={PagePath.onboarding}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Incomplete">
              <OnboardingPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.profile}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete">
              <ProfilePage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.version}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete">
              <VersionPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.companies}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.PlatformManagement}>
              <CompaniesPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.dashboard}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <DashboardPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.learningCompletedAssessments}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <CompletedAssessmentsPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.myCareer}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <ViewRolePage />
            </RouterGuard>
          }
        />

        <Route path={PagePath.error} element={<ErrorPage />} />

        {/* redirects */}
        <Route
          path={PagePath.root}
          element={
            <RouterGuard authStatusRequired="Authenticated">
              <ModeRedirect />
            </RouterGuard>
          }
        />
        <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
      </Routes>

      <AppLoaderLayer />
    </main>
  );
}

