import { Employee, UniqueEntity } from '../../../../../models';
import { Permission, Permissions } from '../../../../../models/overmind/permissions';
import { TagList } from '../../../../../models/tag';

export enum ManagePersonCascadingPanelsEnum {
  Sections = 'sections',
  Permissions = 'permissions',
  Tags = 'tags',
}

export interface IManagePersonCascadingPanelsProps {
  personId: string | undefined;
  onCancel: () => void;
}

export interface IManagePersonFocusPanelProps {
  person: Employee | undefined;
  permissions: Permissions | undefined;
  tags: TagList[] | undefined;
  onClickSection: (section: ManagePersonCascadingPanelsEnum) => void;
  onCancel: () => void;
  onSave: (personSlug: string) => void;
}

export interface IManagePersonDetailsLayoutProps {
  defaultValues: Employee | undefined;
  onSave: (personSlug: string) => void;
}

// permissions
export enum ManagePersonPermissionsCascadingPanelsEnum {
  Sections = 'sections',
  Analytics = 'analytics',
  Administration = 'administration',
  Assessments = 'assessments',
  Tags = 'tags',
  LearningManagement = 'learningManagement',
  Skills = 'skills',
  Roles = 'roleManagement',
}

export interface IManagePersonPermissionsCascadingPanelsProps {
  person: Employee;
  permissions: Permissions;
  onSave: () => void;
  onCancel: () => void;
}

export interface IManagePersonPermissionsFocusPanelProps {
  person: Employee;
  permissions: Permissions;
  onClickSection: (section: ManagePersonPermissionsCascadingPanelsEnum) => void;
  onCancel: () => void;
}

export interface IManagePersonPermissionFocusPanelProps {
  name: string;
  company: UniqueEntity | undefined;
  person: Employee;
  permission: Permission;
  onCancel: () => void;
  onSave: () => void;
}

// tags
export interface IManagePersonTagsFocusPanelProps {
  personSlug: string;
  onCancel: () => void;
}

