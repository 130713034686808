import React, { useEffect, useState } from 'react';
import styles from './assign-skill.module.css';
import { DropdownItem, DropdownSearch, DropdownSelect, FocusPanel, KebabMenu, ListItemLayout, ListLayout, MenuItem, Modal } from '@keplerco/core';
import classNames from 'classnames';
import { SkeletonLoader } from '../../../../../components';
import { useAppActions, useAppState } from '../../../../../overmind';
import { IAssignSkillPanelProps } from './assign-skill.model';
import { ManageSkillFocusPanelCMSLayout } from '../../../../skills/manage-skill-cascading-focus-panels/manage-skill-focus-panel.layout';
import { ConfirmationModalLayout } from '../../../../../widgets/layouts';
import { EmptyState } from '../../../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import { EntityType, OrganizationLevelType, SortField } from '../../../../../enums';
import { EntityListItemResponse } from '../../../../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../lib/permissions.helpers';
import { createLevelsArray } from '../../../../../lib/create-levels-array';
import { useSearchParams } from 'react-router-dom';

export function AssignSkillPanel(props: IAssignSkillPanelProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const isImport = searchParams.get('isImport');

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    // TODO https://keplerandco.atlassian.net/browse/KPRDEV-1873
    pageSize: 100,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: !!isImport, // TODO: check if this should be true or false
  };

  const [currentSkills, setCurrentSkills] = useState<EntityListItemResponse[]>([]);
  const [skillPoolEditItem, setSkillPoolEditItem] = useState<EntityListItemResponse>();
  const [loadingSlugs, setLoadingSlugs] = useState<string[]>([]);
  const [skillToRemove, setSkillToRemove] = useState<EntityListItemResponse>();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function getData() {
      if (props.roleSlug) {
        await fetchSkills(props.roleSlug);
      }

      fetchSkillsForDropdown();
    }

    getData();
  }, [props.roleSlug]);

  async function fetchSkills(slug: string) {
    const request: CompanyEntitySearchParams = {
      ...defaultRequest,
      relatedSlug: slug,
      relatedType: EntityType.Role,
    };
    const skills = await actions.getSkills(request);
    setCurrentSkills(skills?.entities ?? []);
    syncDropdownItemsWithSkills(skills?.entities ?? []);
  }

  async function fetchSkillsForDropdown() {
    const skillSearch = await actions.getSkills(defaultRequest);

    if (skillSearch) {
      const items: DropdownItem[] = skillSearch.entities.map(skill => ({
        value: skill.slug,
        label: skill.name,
        onClick: () => onClickMultiSelectItemHandler(skill),
        selected: false,
      }));
      setDropdownItems(items);
      syncDropdownItemsWithSkills(currentSkills);
    }
  }

  function syncDropdownItemsWithSkills(skills: EntityListItemResponse[]) {
    setDropdownItems(currentItems =>
      currentItems.map(item => ({
        ...item,
        selected: skills.some(skill => skill.slug === item.value),
      }))
    );
  }

  function onClickMultiSelectItemHandler(skill: EntityListItemResponse) {
    setDropdownItems(currentState => {
      const nextState = currentState.map(item => ({ ...item }));
      const item = nextState.find(temp => temp.value === skill.slug);
      if (!!item) {
        item.selected ? handleRemoveSkill(skill) : handleAddSkill(skill);
        item.selected = !item.selected;
      }
      return nextState;
    });
  }

  async function handleAddSkill(skill: EntityListItemResponse) {
    setLoadingSlugs(current => [...current, skill.slug]);

    const result = await actions.relateEntityToRole({
      slug: props.roleSlug!,
      companySlug: companyVariables.slug!,
      targetSlug: skill.slug,
      targetEntityType: EntityType.Skill,
    });

    if (!result) {
      setDropdownItems(currentState =>
        currentState.map(item => ({
          ...item,
          selected: false,
        }))
      );
      setLoadingSlugs(current => current.filter(a => a !== skill.slug));
      return;
    }

    setCurrentSkills(currentState => {
      if (!currentState.some(item => item.slug === skill.slug)) {
        return [...currentState, skill];
      }
      return currentState;
    });
    setLoadingSlugs(current => current.filter(a => a !== skill.slug));
  }

  async function handleRemoveSkill(skill: EntityListItemResponse) {
    setLoadingSlugs(current => [...current, skill.slug]);

    const result = await actions.unrelateEntityFromRole({
      slug: props.roleSlug!,
      companySlug: companyVariables.slug!,
      targetSlug: skill.slug,
      targetEntityType: EntityType.Skill,
    });

    if (!result) {
      setDropdownItems(currentState =>
        currentState.map(item => ({
          ...item,
          selected: true,
        }))
      );
      setLoadingSlugs(current => current.filter(a => a !== skill.slug));
      return;
    }

    setCurrentSkills(currentState => currentState.filter(item => item.slug !== skill.slug));
    setLoadingSlugs(current => current.filter(a => a !== skill.slug));
  }

  function generateKebabMenuItems(skill: EntityListItemResponse): MenuItem<any>[] {
    const kebabMenuItems: MenuItem<any>[] = [
      {
        text: 'Edit skill description',
        onClick: () => setSkillPoolEditItem(skill),
      },
      {
        text: 'Remove skill from role',
        onClick: () => setSkillToRemove(skill),
      },
    ];

    return kebabMenuItems;
  }

  function closeConfirmationModal() {
    setSkillToRemove(undefined);
  }

  return (
    <React.Fragment>
      <div className={classNames(styles.assignSkillsSearch)}>
        <div className={styles.dropDownSearch}>
          <DropdownSearch responsive multiple items={dropdownItems} label="Search and assign skills" />
        </div>

        <div className={styles.scrollContainer}>
          <ListLayout>
            {currentSkills.map(skill => {
              const levelItems: DropdownItem[] = createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
                value: `Level ${level}`,
                onClick: async () => {
                  setLoadingSlugs(current => [...current, skill.slug]);
                  const result = await actions.setSkillLevel({ skillSlug: skill.slug, jobSlug: props.roleSlug!, expectedLevel: level });
                  if (result.isSuccess) await fetchSkills(props.roleSlug!);
                  setLoadingSlugs(current => current.filter(slug => slug !== skill.slug));
                },
                selected: skill.level === level,
              }));

              return (
                <ListItemLayout key={skill.slug}>
                  <div className={classNames('card', styles.addedCardWrapper, styles.cardWrapper)}>
                    <div className={styles.cardContent}>
                      <span className="caption1">{skill.name}</span>
                    </div>

                    <div className={styles.cardSelect}>{loadingSlugs.includes(skill.slug) ? <SkeletonLoader borderRadius="8px" width="250px" height="45px" /> : <DropdownSelect responsive label={'Expected skill level'} items={levelItems} />}</div>

                    <div className={styles.cardAction}>
                      <KebabMenu items={generateKebabMenuItems(skill)} />
                    </div>
                  </div>
                </ListItemLayout>
              );
            })}
          </ListLayout>

          {currentSkills.length === 0 && <EmptyState centerText={true} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} badgeIconWidth={'full-width'} subtitle="No skills assigned to this role" />}

        </div>
      </div>

      <FocusPanel open={!!skillPoolEditItem} onClose={() => setSkillPoolEditItem(undefined)}>
        {!!skillPoolEditItem && (
          <ManageSkillFocusPanelCMSLayout
            type={'update'}
            skillPoolListItem={skillPoolEditItem}
            onClickCancel={() => setSkillPoolEditItem(undefined)}
            hideAssignedDetails
            onClickSave={() => {
              fetchSkills(props.roleSlug!);
              setSkillPoolEditItem(undefined);
            }}
          ></ManageSkillFocusPanelCMSLayout>
        )}
      </FocusPanel>

      <Modal type="small" open={!!skillToRemove} onClose={closeConfirmationModal}>
        {!!skillToRemove && (
          <ConfirmationModalLayout
            title={`Remove skill`}
            subtitleSize="large"
            submitButtonText="Yes, remove"
            onClickSubmit={() => {
              handleRemoveSkill(skillToRemove);
              closeConfirmationModal();
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
            textAlign="left"
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove <strong>{skillToRemove?.name}</strong> from this role?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </React.Fragment>
  );
}
