import React, { useState } from 'react';
import { Daterange } from '../../../components';
import { useAppActions, useAppState } from '../../../overmind';
import { SkillSkeleton } from './skill.skeleton';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { SkillOverviewWidget } from '../../../widgets/analytics/overview/skill-overview.widget';
import { ISkillLayoutProps } from './skill.models';
import { PageHeader } from '@keplerco/core';

export function SkillLayout({ path, skillSlug, skillSubTypeSlug }: ISkillLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [skillName, setSkillName] = useState<string>();

  return (
    <PageLoaderLayer path={path} skeletonLoader={<SkillSkeleton />}>
      <div className="pageWrapper wrapper">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: PagePath.analyticsBase,
            },
            {
              name: 'Skills',
              url: `${PagePath.analyticsBase}${PagePath.analyticsSkills.replace(':companySlug', companyVariables.slug!)}`,
            },
            {
              name: `${skillName ?? 'Skill'}`,
              url: window.location.href,
            },
          ]}
          title={skillName ?? 'Skill'}
          type="actions"
        >
          <div className="headerActions" style={{ flexWrap: 'wrap' }}>
            <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

            <Daterange
              defaultValue={dateRangeOutput}
              onDaterangeChange={result => {
                actions.setGlobalDateRange(result);
              }}
            />
          </div>
        </PageHeader>

        <br />

        <SkillOverviewWidget entity={entity} skillSlug={skillSlug} skillSubTypeSlug={skillSubTypeSlug} setSkillName={setSkillName} />
      </div>
    </PageLoaderLayer>
  );
}

