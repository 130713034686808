import React from 'react';
import { TagType } from '../../../../enums/tag';
import { TagAssigneeResponse } from '../../../../models/tag';
import { IArchiveTagModalProps } from './archive-tag-modal.models';
import { tagTypeToString } from '../tag-management.cms.helpers';
import { Button, ListItemLayout, ListLayout } from '@keplerco/core';

export default function TagArchiveModal({ tagName, assignees, onArchive }: IArchiveTagModalProps) {
  function generateTagTypeSummaries(assignees: TagAssigneeResponse[]) {
    const counts = {
      [TagType.Department]: 0,
      [TagType.User]: 0,
      [TagType.Team]: 0,
      [TagType.Assessment]: 0,
      [TagType.Lesson]: 0,
    };

    assignees.forEach(assignee => {
      counts[assignee.tagType] += 1;
    });

    return Object.entries(counts)
      .filter(([, count]) => count > 0)
      .map(([tagType, count]) => `${count} ${tagTypeToString(parseInt(tagType))}`);
  }

  return (
    <div className="dialogContentLayout modalContentLayout">
      <div className="dialogHeaderLayout modalHeaderLayout" style={{ justifyContent: 'flex-start', textAlign: 'start' }}>
        <h2 className="heading2">Are you sure you want to archive "{tagName}"?</h2>
        <h6 className="heading6">This tag is assigned to: </h6>
      </div>

      <div className="dialogBodyLayout">
        <ListLayout>
          {generateTagTypeSummaries(assignees).map((tagTypeSummary, index) => (
            <ListItemLayout key={index}>
              <div className="card">
                <span className="caption1">{tagTypeSummary}</span>
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>

      <footer className="dialogFooterLayout.modalFooterLayout">
        <Button type="button" filled onClick={onArchive}>
          Yes, archive
        </Button>
      </footer>
    </div>
  );
}

