import React, { useEffect, useState } from 'react';
import { SideNavPanel } from './side-nav-panel/side-nav-panel';
import { SideNavDialog } from './side-nav-dialog/side-nav-dialog';
import { useAppActions, useAppState } from '../../overmind';
import { ISideNavRoute } from './side-nav.models';
import { generateCompanyRoutes, generateLearnerRoutes } from '../navigation.helpers';
import { Company } from '../../models';
import { PagePath } from '../navigation.enums';
import { FetchType } from '../../enums';
import { FocusPanel } from '@keplerco/core';
import { ManageCompanyFocusPanelLayout } from '../../pages/companies/manage-company-focus-panel/manage-company-focus-panel.layout';

export function SideNav(props: { isMobile: boolean; isTablet: boolean; showSideNavDialog: boolean; onCloseSideNavDialog: () => void }): JSX.Element {
  const { isMobile, isTablet, showSideNavDialog, onCloseSideNavDialog } = props;

  const actions = useAppActions();
  const { user, skillAssessmentConfiguration, assessmentYearCourses, assignedCourses, companyVariables, mode, permissions } = useAppState();

  const [learnerRoutes, setLearnerSideNavRoutes] = useState<ISideNavRoute[]>([]);
  const [companyRoutes, setCompanySideNavRoutes] = useState<ISideNavRoute[]>([]);
  const [showCompanySettingsFocusPanel, setShowCompanySettingsFocusPanel] = useState<boolean>(false);
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    if (!user) return;
    setLearnerSideNavRoutes(generateLearnerRoutes(user, skillAssessmentConfiguration, assessmentYearCourses, assignedCourses));
    setCompanySideNavRoutes(generateCompanyRoutes(user, skillAssessmentConfiguration, companyVariables.slug, mode, permissions));
  }, [user, skillAssessmentConfiguration, assessmentYearCourses, assignedCourses, user, companyVariables.slug, mode, permissions]);

  useEffect(() => {
    if (!!showSideNavDialog) {
      setLearnerSideNavRoutes(currentRoutes => {
        const newRoutes = structuredClone(currentRoutes);
        newRoutes.forEach((route: ISideNavRoute) => (route.children = route.children?.filter(child => child.title !== 'Core Skills Map')));
        return newRoutes;
      });
    }
  }, [showSideNavDialog]);

  async function getCompany() {
    if (!companyVariables.slug) return setShowCompanySettingsFocusPanel(false);

    actions.startLoader({ path: PagePath.root, type: FetchType.DialogFetching });
    const data = await actions.getCompany(companyVariables.slug);
    setCompany(data);
    actions.stopLoader(PagePath.root);
  }

  function onOpenSettings() {
    if (!companyVariables.slug) return;
    setShowCompanySettingsFocusPanel(true);
    getCompany();
  }

  function onCloseSettings() {
    setShowCompanySettingsFocusPanel(false);
    setCompany(undefined);
  }
  return (
    <React.Fragment>
      {!isMobile && !isTablet ? (
        <SideNavPanel learnerRoutes={learnerRoutes} companyRoutes={companyRoutes} onOpenSettings={onOpenSettings} />
      ) : (
        <SideNavDialog learnerRoutes={learnerRoutes} companyRoutes={companyRoutes} open={showSideNavDialog} onClose={onCloseSideNavDialog} onOpenSettings={onOpenSettings} />
      )}

      <FocusPanel open={showCompanySettingsFocusPanel} onClose={onCloseSettings}>
        {!!company && <ManageCompanyFocusPanelLayout companyToManage={company} onClickCancel={onCloseSettings} onClickSubmit={onCloseSettings} />}
      </FocusPanel>
    </React.Fragment>
  );
}

