import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../overmind';
import { KeplerState } from '../../models/kepler-state';
import * as settingsLayout from './profile.styles';
import { useForm } from 'react-hook-form';
import { ProfileEmailBlock } from './profile-email-block';
import { OrganizationLevelType } from '../../enums/organization-level';
import { NavLink } from 'react-router-dom';
import { FetchStatus, FetchType } from '../../enums';
import { UserEmail } from '../../models/user';
import applicationPackage from '../../../package.json';
import { SettingsSkeleton } from './profile.skeleton';
import { PagePath } from '../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { getSubdomain } from '../../lib/get-subdomain';
import { SkillScoresRequest, SkillScoresResponse } from '../../models/overmind/analytics';
import { ResponseGroup } from '../../enums/analytics';
import { SkillScoresSearchParams } from '../../models/overmind/search-params';
import { Anchor, AvatarIcon, Button, EmailValidator, FormControl, LettersValidator, Modal, RequiredValidator, SkillLevel, SkillPercentage, Textfield, colourString } from '@keplerco/core';

type EditModeType = 'none' | 'personal' | 'contact';

export function ProfilePage(): JSX.Element {
  const { user, settings, fetchState, companyVariables } = useAppState<KeplerState>();
  const actions = useAppActions();
  const [isAddingEmail, setIsAddingEmail] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<EditModeType>('none');

  const isCPD = getSubdomain().includes('cpd');

  const { control: emailControl, reset: resetEmail, handleSubmit } = useForm<any>();

  const [addEmail, setAddEmail] = useState<string>();

  const [submittedResetPassword, setSubmittedResetPassword] = useState<boolean>(false);

  const {
    control,
    setValue,
    handleSubmit: handleSubmitForm,
  } = useForm<any>({
    mode: 'onBlur',
    defaultValues: {
      firstName: settings?.firstName,
      lastName: settings?.lastName,
      preferredName: settings?.preferredName,
      jobTitle: settings?.jobTitle,
    },
  });

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.profile, type: FetchType.PageFetching });

      const profileResponse = await actions.fetchUserSettingsProfile();
      if (!!profileResponse) {
        setValue('firstName', profileResponse.firstName);
        setValue('lastName', profileResponse.lastName);
        setValue('preferredName', profileResponse.preferredName);
        setValue('jobTitle', profileResponse.jobTitle);
      }

      const skillScoresSearchParams: SkillScoresSearchParams = {
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: user?.learnerSlug,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        skillLevelComparison: companyVariables.skillScoreComparison,
      };
      const skillScoresRequest: SkillScoresRequest = {
        responseGroup: ResponseGroup.Overall,
        searchParams: skillScoresSearchParams,
      };
      const skillScoresResponse = await actions.analyticsGetSkillScores(skillScoresRequest);
      setSkillScoresResponse(!!skillScoresResponse ? skillScoresResponse[0] : undefined);

      actions.stopLoader(PagePath.profile);
    }

    if (!settings || !skillScoresResponse) getData();
  }, []);

  const [skillScoresResponse, setSkillScoresResponse] = useState<SkillScoresResponse>();

  function toggleEditMode(mode: EditModeType): void {
    if (editMode === 'none') {
      setEditMode(mode);
    } else setEditMode('none');
  }

  async function resendVerification() {
    if (addEmail === undefined) return;

    actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
    await actions.resendVerification(addEmail);
    actions.stopLoader(PagePath.profile);
  }

  return (
    <PageLoaderLayer path={PagePath.profile} skeletonLoader={<SettingsSkeleton />}>
      <settingsLayout.SettingsPageWrapper className="wrapper pageWrapper">
        <settingsLayout.SettingsHeader>
          <settingsLayout.SettingsHeaderProfile>
            <AvatarIcon name={{ firstName: user?.firstName, lastName: user?.lastName }} />
          </settingsLayout.SettingsHeaderProfile>

          <settingsLayout.SettingsHeaderTitle>
            <h1 className="heading1">
              {settings?.firstName} {settings?.lastName}
            </h1>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
              <h6 className="subtitle job-title">{settings?.jobTitle}</h6>

              <span>|</span>

              {companyVariables.useLevels ? (
                <div style={{ marginTop: 5 }}>
                  <SkillLevel
                    level={skillScoresResponse?.score?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!skillScoresResponse?.score}
                    title="Skill average"
                  />
                </div>
              ) : (
                <SkillPercentage
                  percentage={skillScoresResponse?.score?.percentage ?? 0}
                  notAssessed={!skillScoresResponse?.score}
                  title="Skill average"
                />
              )}
            </div>
          </settingsLayout.SettingsHeaderTitle>
        </settingsLayout.SettingsHeader>

        <settingsLayout.SettingsPageLayout>
          <div className="card about">
            <settingsLayout.SettingsCardHeader>
              <h6 className="subtitle">About</h6>

              {editMode !== 'personal' && (
                <div className="button-wrapper">
                  <button onClick={() => toggleEditMode('personal')}>Edit Details</button>
                </div>
              )}
            </settingsLayout.SettingsCardHeader>

            <form
              id="saveUserProfile"
              onSubmit={handleSubmitForm(async values => {
                actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                await actions.saveUserProfile(values);
                actions.stopLoader(PagePath.profile);
                setEditMode('none');
              })}
            >
              <settingsLayout.SettingsPageDetailsItem>
                <span>First name</span>
                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="firstName"
                    control={control}
                    rules={new LettersValidator('Enter your name')}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{settings?.firstName}</p>
                )}
              </settingsLayout.SettingsPageDetailsItem>

              <settingsLayout.SettingsPageDetailsItem>
                <span>Last name</span>
                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="lastName"
                    control={control}
                    rules={new LettersValidator('Enter your surname')}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{settings?.lastName}</p>
                )}
              </settingsLayout.SettingsPageDetailsItem>

              <settingsLayout.SettingsPageDetailsItem>
                <span>Preferred name</span>
                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="preferredName"
                    rules={new LettersValidator('Enter your preferred name')}
                    control={control}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{settings?.preferredName}</p>
                )}
              </settingsLayout.SettingsPageDetailsItem>

              <settingsLayout.SettingsPageDetailsItem>
                <span>Job title</span>
                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="jobTitle"
                    rules={new RequiredValidator('Enter your job title')}
                    control={control}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{settings?.jobTitle}</p>
                )}
              </settingsLayout.SettingsPageDetailsItem>

              {editMode === 'personal' && (
                <settingsLayout.SettingsCardHeader>
                  <div className="button-wrapper" style={{ justifyContent: 'flex-end', paddingTop: 15, width: '100%' }}>
                    <button className="editing">Save Details</button>
                  </div>
                </settingsLayout.SettingsCardHeader>
              )}
            </form>
          </div>

          <div className="card security">
            <h6 className="subtitle">Security</h6>

            <settingsLayout.SettingsPageDetailsItem>
              <span>Password</span>
              <p className="body1">********************</p>

              <settingsLayout.ChangePasswordWrapper>
                <Anchor
                  fontColour="link-text"
                  onClick={async () => {
                    if (settings?.email !== undefined && fetchState[PagePath.profile].status === FetchStatus.Inactive) {
                      actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                      await actions.forgotPassword({ email: settings.email });
                      setSubmittedResetPassword(true);
                      actions.stopLoader(PagePath.profile);
                    }
                  }}
                >
                  Change Password
                </Anchor>
              </settingsLayout.ChangePasswordWrapper>
            </settingsLayout.SettingsPageDetailsItem>
          </div>

          {!!settings && (
            <div className="contact">
              <div className="card">
                <settingsLayout.SettingsCardHeader>
                  <h6 className="subtitle">Contact</h6>

                  <div className="button-wrapper" style={{ height: 40 }} />
                </settingsLayout.SettingsCardHeader>

                <settingsLayout.SettingsPageDetailsItem>
                  <ProfileEmailBlock hideChip={isCPD} email={settings.email} isPrimary={true} verificationDate={new Date()} onMakePrimary={email => console.log('Make primary', email)} onDelete={email => console.log('Delete', email)} />

                  {settings.emails?.map((a: UserEmail) => {
                    return (
                      <ProfileEmailBlock
                        key={a.id}
                        email={a.email}
                        isPrimary={false}
                        hideChip={isCPD}
                        verificationDate={a.dateConfirmed}
                        onResendVerification={async () => {
                          actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                          await actions.resendVerification(a.email);
                          actions.stopLoader(PagePath.profile);
                        }}
                        onMakePrimary={async () => {
                          actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                          await actions.changePrimaryEmail(a.email);
                          actions.stopLoader(PagePath.profile);
                        }}
                        onDelete={async () => {
                          actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                          await actions.removeEmail(a);
                          actions.stopLoader(PagePath.profile);
                        }}
                      />
                    );
                  })}
                </settingsLayout.SettingsPageDetailsItem>

                {!isCPD && (
                  <>
                    {isAddingEmail ? (
                      <settingsLayout.AddEmailField
                        id="addEmail"
                        onSubmit={handleSubmit(value => {
                          async function addEmail() {
                            actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                            await actions.addEmail(value.email);
                            actions.stopLoader(PagePath.profile);
                          }
                          setIsAddingEmail(false);
                          addEmail();
                          setAddEmail(value.email);
                          resetEmail();
                        })}
                      >
                        <div className="control">
                          <FormControl
                            paddingBottom={0}
                            name="email"
                            rules={new EmailValidator('Enter your email')}
                            control={emailControl}
                            render={({ field, fieldState }) => {
                              return <Textfield {...field} label="Email" haserror={!!fieldState.error} type="email" responsive />;
                            }}
                          />
                        </div>
                        <button type="submit">Add</button>
                      </settingsLayout.AddEmailField>
                    ) : (
                      <settingsLayout.AddEmailButton
                        onClick={() => {
                          setIsAddingEmail(true);
                        }}
                      >
                        Add Additional Email
                      </settingsLayout.AddEmailButton>
                    )}
                  </>
                )}
              </div>

              <div className="card version">
                <span className="caption1" style={{ color: colourString('text_1') }}>
                  <NavLink style={{ color: 'var(--text)', textDecoration: 'none' }} to={PagePath.version}>
                    Application Version: {applicationPackage.version}
                  </NavLink>
                </span>
              </div>
            </div>
          )}
        </settingsLayout.SettingsPageLayout>
      </settingsLayout.SettingsPageWrapper>

      <Modal open={addEmail !== undefined} onClose={() => setAddEmail(undefined)}>
        <div className="dialogContentLayout modalContentLayout">
          <header className="dialogHeaderLayout modalHeaderLayout">
            <h2 className="heading2">
              We've sent a verification link to <strong>{addEmail}</strong>
            </h2>
          </header>

          <div className="dialogBodyLayout">
            <h6 className="subtitle">Please verify the email address using the link in the email sent.</h6>
          </div>

          <footer className="dialogFooterLayout modalFooterLayout">
            <Anchor onClick={resendVerification}>SEND VERIFICATION EMAIL AGAIN</Anchor>

            <Button filled type="button" onClick={() => setAddEmail(undefined)}>
              GOT IT
            </Button>
          </footer>
        </div>
      </Modal>

      <Modal open={submittedResetPassword} onClose={() => setSubmittedResetPassword(false)}>
        <div className="dialogContentLayout modalContentLayout">
          <header className="dialogHeaderLayout modalHeaderLayout">
            <h2 className="heading2">We've emailed a password reset link to you</h2>
          </header>

          <div className="dialogBodyLayout">
            <h6 className="subtitle">Please follow the link to set a new password.</h6>
          </div>

          <footer className="dialogFooterLayout modalFooterLayout">
            <Button filled type="button" onClick={() => setSubmittedResetPassword(false)}>
              GOT IT
            </Button>
          </footer>
        </div>
      </Modal>
    </PageLoaderLayer>
  );
}

