import React, { useEffect, useRef, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import classNames from 'classnames';
import { FetchType } from '../../../enums';
import { TopNavMenuTrigger, TopNavMenuBurgerIconContainer, TopNavMenuListContainer } from './top-nav-menu.styles';
import { Mode, PagePath } from '../../navigation.enums';
import { useNavigate } from 'react-router';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import WeglotLanguageSwitcher from '../../../components/weglot/weglot-switcher';
import { BurgerIcon } from '../burger.icon';
import { AvatarIcon, RadioButton, useWindowResize } from '@keplerco/core';
import navStyles from '../../navigation.module.css';
import topNavMenuStyles from './top-nav-menu.module.css';
import KeplerNavlink from '../../guards/kepler-navlink';

export function TopNavMenu(props: { isMobile: boolean; isTablet: boolean; onOpenSideNavDialog: () => void }): JSX.Element {
  const { isMobile, isTablet, onOpenSideNavDialog } = props;

  const navigate = useNavigate();

  const actions = useAppActions();
  const { user, skillAssessmentConfiguration, mode } = useAppState();

  const [windowSize] = useWindowResize();

  const menuRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  function setMode(mode: Mode) {
    actions.setMode(mode);
    actions.setActiveCompanySlug(user?.companySlug);

    mode === Mode.LearningDashboard ? navigate(PagePath.dashboard) : navigate(PagePath.companies);

    setOpen(false);
  }

  function onClickWindowHandler(event: MouseEvent): void {
    if (!open) return;
    if (!menuRef.current?.contains(event.target as any)) setOpen(false);
  }

  useEffect(() => {
    if (!open) return () => window.removeEventListener('click', onClickWindowHandler);

    window.requestIdleCallback(() => window.addEventListener('click', onClickWindowHandler));

    return () => window.removeEventListener('click', onClickWindowHandler);
  }, [open]);

  useEffect(() => {
    if (open) setOpen(false);
  }, [windowSize]);

  return (
    <React.Fragment>
      {!!user && (
        <React.Fragment>
          <WeglotLanguageSwitcher position="topNavigation" />

          <TopNavMenuTrigger onClick={() => (isMobile || isTablet ? onOpenSideNavDialog() : setOpen(true))}>
            <div className={classNames(navStyles.navAvatarIconContainer, topNavMenuStyles.topNavMenuAvatarIconContainer)}>
              <AvatarIcon name={user} />
            </div>

            <TopNavMenuBurgerIconContainer>
              <BurgerIcon size={40} tone="text" />
            </TopNavMenuBurgerIconContainer>
          </TopNavMenuTrigger>
        </React.Fragment>
      )}

      {open && (
        <TopNavMenuListContainer ref={menuRef}>
          {user?.isSystemAdmin && skillAssessmentConfiguration?.benchmarkResultReady && (
            <React.Fragment>
              <span className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuHeader)}>Switch to:</span>

              <button
                className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuItem, { [topNavMenuStyles.isChecked]: mode === Mode.PlatformManagement })}
                onClick={() => setMode(Mode.PlatformManagement)}
              >
                <RadioButton id="PlatformManagement" checked={mode === Mode.PlatformManagement} onChange={() => setMode(Mode.PlatformManagement)} clickablearea="label" alignitems="center">
                  <span className={navStyles.navCaption}>Platform Management</span>
                </RadioButton>
              </button>

              <button
                className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuItem, { [topNavMenuStyles.isChecked]: mode === Mode.LearningDashboard })}
                onClick={() => setMode(Mode.LearningDashboard)}
              >
                <RadioButton id="LearningDashboard" checked={mode === Mode.LearningDashboard} onChange={() => setMode(Mode.LearningDashboard)} clickablearea="label" alignitems="center">
                  <span className={navStyles.navCaption}>Learning Dashboard</span>
                </RadioButton>
              </button>

              <hr className={navStyles.navDivider} />
            </React.Fragment>
          )}

          {skillAssessmentConfiguration?.benchmarkResultReady && (
            <React.Fragment>
              <span className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuHeader)}>Your account:</span>

              <KeplerNavlink
                className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuItem)}
                to={PagePath.profile}
                onClick={() => {
                  actions.setMode(Mode.LearningDashboard);
                  setOpen(false);
                }}
              >
                <img className={navStyles.navIcon} src={themedAssetUrl('icons/sidebar/profile.icon.svg')} />
                <span className={navStyles.navCaption}>Profile</span>
              </KeplerNavlink>
            </React.Fragment>
          )}

          <a
            className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuItem)}
            href="https://legal.keplerco.io"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setOpen(false)}
          >
            <img className={navStyles.navIcon} src={themedAssetUrl('icons/sidebar/legal.icon.svg')} />
            <span className={navStyles.navCaption}>Legal</span>
          </a>

          <button
            className={classNames(navStyles.navCaption, topNavMenuStyles.topNavMenuItem)}
            onClick={async () => {
              actions.startLoader({ path: PagePath.root, type: FetchType.PageFetching });
              setOpen(false);
              await actions.logout();
              actions.stopLoader(PagePath.root);
            }}
          >
            <img className={navStyles.navIcon} src={themedAssetUrl('icons/sidebar/signout.icon.svg')} />
            <span className={navStyles.navCaption}>Log out</span>
          </button>
        </TopNavMenuListContainer>
      )}
    </React.Fragment>
  );
}

