import React from 'react';
import { RolesCMSSkeleton } from './roles.cms.skeleton';
import { useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { CMSColumn, CMSRow } from '../../../theme/layout.styles';
import { PageHeader, Tab, Tabs } from '@keplerco/core';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { CompanyRolesView } from './company-roles.cms.view';
import { GlobalRolesView } from './global-roles.cms.view';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { OrganizationLevelType } from '../../../enums';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function RolesPage(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split('/roles')[1] === '' ? 'company' : 'global';

  const { companyVariables, permissions, user } = useAppState();

  function handleTabChange(id: string) {
    let url = `${PagePath.administrationBase}${PagePath.administrationRolesGlobal.replace(':companySlug', companyVariables.slug ?? '')}`;

    if (id === 'company') {
      url = `${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companyVariables.slug ?? '')}`;
    }

    keplerNavigate(url);
  }

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  function roleBreadcrumb() {
    const companySlug = companyVariables.slug!;
    const companyName = companyVariables.name;
    return { name: `Roles in ${companyName}`, url: `${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companySlug)}` };
  }

  const breadcrumbs = [{ name: 'Administration', url: PagePath.administrationBase }, roleBreadcrumb()];

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.administrationRoles} skeletonLoader={<RolesCMSSkeleton />}>
        <div className="wrapper stack">
          <CMSRow>
            <CMSColumn>
              <PageHeader breadcrumbs={breadcrumbs} title="Job Roles" subtitle="A library of all the job roles within your company." />
            </CMSColumn>
          </CMSRow>
          {(organizationLevel?.organizationLevel === OrganizationLevelType.Company || user?.isSystemAdmin) && (
            <CMSRow>
              <CMSColumn style={{ width: '100%' }}>
                <Tabs static>
                  <Tab active={activeTab === 'company'} onClick={() => handleTabChange('company')} id="company" name="Company roles" />
                  <Tab active={activeTab === 'global'} onClick={() => handleTabChange('global')} id="global" name="Global roles" />
                </Tabs>
              </CMSColumn>
            </CMSRow>
          )}

          <Routes>
            <Route path="" element={<CompanyRolesView />} />
            <Route path="view/global" element={<GlobalRolesView />} />
            <Route path={PagePath.rootWildcard} element={<Navigate to={PagePath.administrationRoles} />} />
          </Routes>
        </div>
      </PageLoaderLayer>
    </React.Fragment>
  );
}
