import React from 'react';
import { SkeletonLoader } from '../../../../../components';

export function RoleDetailsSkeleton(): JSX.Element {
  return (
    <div style={{ width: '100%', paddingTop: 10 }}>
      <SkeletonLoader borderRadius="8px" height="45px" width="300px" />
      <div style={{ height: 35 }} />
      <SkeletonLoader borderRadius="8px" height="122px" />
      <div style={{ height: 25 }} />
    </div>
  );
}

