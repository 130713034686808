import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FetchStatus, FetchType } from '../../../enums';
import { Team } from '../../../models/view/team';
import { useAppActions, useAppState } from '../../../overmind';
import { KeyValue } from '../../../models/overmind/key-value';
import TagInput from '../../../components/form-elements/tags/tag-input';
import { TagType } from '../../../enums/tag';
import { BaseSearchRequest } from '../../../models';
import { Assignee, TagAssigneeRequest, TagList } from '../../../models/tag';
import { ManageTeamFocusPanelSkeleton } from './manage-team-focus-panel.skeleton';
import { PagePath } from '../../../navigation/navigation.enums';
import { FocusPanelLoaderLayer } from '../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { Anchor, BaseValidator, Button, CopyButton, Dropdown, FormControl, RequiredValidator, Textfield, useMediaQuery } from '@keplerco/core';

interface IFormValues {
  id: string;
  teamName: string;
  departmentId: string;
}

export function ManageTeamFocusPanelCMSLayout(props: { teamSlug?: string; path: PagePath.administrationTeams | PagePath.administrationDepartmentTeams; onClick: () => void }): JSX.Element {
  const params = useParams<any>();
  const [tags, setTags] = useState<TagList[] | undefined>([]);
  const [search, setSearch] = useState('');
  const actions = useAppActions();

  const { handleSubmit, control, setValue } = useForm<any>({ mode: 'onChange' });

  const { fetchState } = useAppState();

  const [departments, setDepartments] = useState<KeyValue[]>();

  const isMobile = useMediaQuery('screen and (max-width: 580px)');

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: props.path, type: FetchType.DialogFetching });

      const departmentsData = await actions.getDepartmentKeyValuesByCompany(params.companySlug!);
      setDepartments(departmentsData?.sort((a, b) => a.name.localeCompare(b.name)) ?? []);

      if (!props.teamSlug) return;

      const teamData = await actions.getTeam({ companySlug: params.companySlug!, teamSlug: props.teamSlug });

      if (!!teamData) {
        setValue('id', teamData.id);
        setValue('teamName', teamData.teamName);
        setValue('departmentId', teamData.departmentId);
      }

      actions.stopLoader(props.path);
    }

    getData();
  }, []);

  useEffect(() => {
    if (!!departments && !props.teamSlug) actions.stopLoader(props.path);
  }, [departments]);

  async function onSubmitHandler(formValues: IFormValues) {
    if (fetchState[props.path].status === FetchStatus.Active) return;
    actions.startLoader({ path: props.path, type: FetchType.Sending });
    const team: Team = {
      id: parseInt(formValues.id),
      teamName: formValues.teamName,
      departmentId: parseInt(formValues.departmentId),
    };

    await actions.saveTeam({ team: team, companySlug: params.companySlug! });

    props.onClick();

    actions.stopLoader(props.path);
  }

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.teamSlug ?? '',
        tagType: TagType.Team,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: params.companySlug! });
  }
  async function fetchData() {
    const result = await actions.getAssigneeTags({ entitySlug: props.teamSlug!, tagType: TagType.Team, companySlug: params.companySlug! });
    if (result) {
      setTags(result);
    } else {
      setTags([]);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function getTagAssignees(): Assignee[] {
    return [
      {
        entitySlug: props.teamSlug ?? '',
        tagType: TagType.Team,
      },
    ];
  }

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;
      assignees = [
        {
          entitySlug: props.teamSlug ?? '',
          tagType: TagType.Team,
        },
      ];
    }

    await actions.removeAssignees({ tagName, assignees, companySlug: params.companySlug! });
  }

  function onTagChange(tags: TagList[]) {
    setTags(tags);
  }

  async function searchUserTags(query: string): Promise<TagList[]> {
    setSearch(query);
    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: params.companySlug!,
    };
    const paginatedResult = await actions.searchTags(searchTagParams);
    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }
    return [];
  }

  return (
    <FocusPanelLoaderLayer path={props.path} skeletonLoader={<ManageTeamFocusPanelSkeleton hasTeamSlug={!!props.teamSlug} />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ justifyContent: isMobile ? 'center' : 'space-between', paddingBottom: 30 }}>
          <h2 className="heading2">{!!props.teamSlug ? 'Update' : 'Create'} team</h2>

          {!!props.teamSlug && <CopyButton label="Team Id" value={props.teamSlug} />}
        </header>

        <form id="saveTeam" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="dialogBodyLayout">
            <FormControl
              control={control}
              rules={
                new BaseValidator('Enter a team name', {
                  nonWhiteSpaceValue: (value: string) => {
                    if (!value?.trim()) return `Enter a team name`;
                    return true;
                  },
                })
              }
              name="teamName"
              render={({ field, fieldState }) => {
                return <Textfield {...field} haserror={!!fieldState.error} label="Team name" type="text" responsive />;
              }}
            />

            {!!departments && departments.length > 0 && (
              <FormControl
                control={control}
                rules={new RequiredValidator('Select a department')}
                name="departmentId"
                render={({ field, fieldState }) => {
                  return (
                    <Dropdown {...field} haserror={!!fieldState.error} label="Department" responsive>
                      {departments?.map(department => {
                        return (
                          <option key={department.id} value={department.id}>
                            {department.name}
                          </option>
                        );
                      })}
                    </Dropdown>
                  );
                }}
              />
            )}

            {!!props.teamSlug && <TagInput getAssignees={getTagAssignees} onRemoveTag={onClickRemoveTagHandler} initialTags={tags} onCreateTag={onClickCreateTagHandler} onTagChange={onTagChange} searchTags={searchUserTags} />}
          </div>

          <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
            <Anchor onClick={props.onClick}>Cancel</Anchor>
            <Button type="button">Submit</Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}

