import React, { useEffect, useState } from 'react';
import { FetchType, SyncStatus } from '../../../../enums';
import { OrganizationLevelType } from '../../../../enums/organization-level';
import { KeplerState } from '../../../../models/kepler-state';
import { useAppActions, useAppState } from '../../../../overmind';
import { AnalyticsGridSection, AnalyticsPageHeaderContainer } from '../../analytics.styles';
import { ContinueLearningWidget } from '../../../../widgets/continue-learning/continue-learning';
import { AdditionalLearningFocusPanelLayout } from '../../../learning-journey/additional-learning/additional-learning-focus-panel/additional-learning-focus-panel.layout';
import { AdditionalLearningViewModel } from '../../../learning-journey/additional-learning/additional-learning.models';
import { QueueItemPriority, QueueItemType } from '../../../../components/notification-hub/notification-hub.models';
import { SkeletonLoader, SyncContentCard, SyncContentCardLayoutWrapper } from '../../../../components';
import { ReflectionFocusPanelLayout } from '../../../learning-journey/reflections/reflection-focus-panel.layout';
import { IPersonLayoutProps } from './person.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PDFReport } from '../../../../components/pdf-report/pdf-report';
import { SkillsBreakdownWidget } from '../../../../widgets/analytics/skills-breakdown/skills-breakdown.widget';
import { PermissionsEntity } from '../../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { CourseEngagementWidget } from '../../../../widgets/analytics/overview/course-engagement.widget';
import { DailyActivityWidget, SkillScoreOverTimeWidget } from '../../../../widgets';
import { EntityAverageWidget } from '../../overview/entity-average/entity-average.widget';
import { PersonDetails } from './person-details/person-details';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { CascadingFocusPanels, FocusPanel, PageHeader, useCascadingPanelConnector } from '@keplerco/core';

export function PersonLayout(props: IPersonLayoutProps): JSX.Element {
  const { loading, path, slug, pageTitle, crumbs, firstName, lastName, keplerPoints, lessons } = props;

  const actions = useAppActions();
  const { user, lastSynced, courseSyncStatus } = useAppState<KeplerState>();

  const { connector, closeById, closeAll, next } = useCascadingPanelConnector();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [selectedReflectionId, setSelectedReflectionId] = useState<number>();

  useEffect(() => {
    if (loading) return;
    setEntity({ organizationLevel: OrganizationLevelType.Learner, entity: { slug: slug, name: `${firstName} ${lastName}` } });
  }, [loading, slug, firstName]);

  // additional learning
  async function onSubmitAdditionalLearningHandler(model: AdditionalLearningViewModel, reflect?: boolean) {
    actions.startLoader({ path: path, type: FetchType.Sending });

    const additionalLearning = await actions.saveAdditionalLearning(model);

    if (!additionalLearning) {
      closeAll();
      actions.stopLoader(path);
      return;
    }

    await actions.getTotalLearnerKeplerPoints();

    actions.addNotification({
      title: 'Additional Learning Captured',
      message: `Your Additional Learning - ${model.name} - has been captured.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Success,
      id: `additional_learning_${Date.now() + Math.random()}`,
      active: true,
    });

    if (reflect) {
      setSelectedReflectionId(additionalLearning.reflectionId);
      next('reflections');
      actions.stopLoader(path);
      return;
    }

    closeAll();
    actions.stopLoader(path);
  }

  // reflection
  function onCloseReflectionHandler() {
    setSelectedReflectionId(undefined);
    closeAll();
  }

  return (
    <React.Fragment>
      <div className="pageWrapper wrapper" id={path === PagePath.dashboard ? 'dashboard-page' : 'person-page'}>
        <AnalyticsPageHeaderContainer>
          {loading ? (
            <SkeletonLoader height={path === PagePath.dashboard ? '80px' : '25px'} width={path === PagePath.dashboard ? '100%' : '50%'} />
          ) : (
            <PageHeader breadcrumbs={crumbs} title={pageTitle} type="actions">
              <div className="headerActions">{path === PagePath.dashboard && <PDFReport path={PagePath.dashboard} />}</div>
            </PageHeader>
          )}
        </AnalyticsPageHeaderContainer>

        {loading ? (
          <SkeletonLoaderRow style={{ marginTop: 20, marginBottom: 20 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="130px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        ) : (
          <PersonDetails firstName={firstName} lastName={lastName} keplerPoints={keplerPoints} />
        )}

        <SyncContentCardLayoutWrapper id="sync-content-section">
          <SyncContentCard
            isSyncing={courseSyncStatus === SyncStatus.Syncing}
            variant="compact-reverse"
            lastSyncDate={lastSynced}
            onClick={() => {
              actions.syncCourseContent().then(() => {
                actions.getTotalLearnerKeplerPoints();
              });
            }}
          />
        </SyncContentCardLayoutWrapper>

        {!!lessons && user?.allowOffPlatformLearning && <ContinueLearningWidget lessons={lessons} onClickCaptureAdditionalLearning={() => next('additional')} />}

        <AnalyticsGridSection>
          <div className="start-1" id="entity-average-widget-section">
            <EntityAverageWidget path={path} entity={entity} />
          </div>

          <div className="end-2" id="skills-breakdown-section">
            <SkillsBreakdownWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection>
          <div className="full-span">
            <DailyActivityWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection id="overview-section">
          <div className="full-span">
            <CourseEngagementWidget entity={entity} isLearner={path === PagePath.dashboard} />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection id="skill-score-over-time-section">
          <div className="full-span">
            <SkillScoreOverTimeWidget path={path} entity={entity} />
          </div>
        </AnalyticsGridSection>
      </div>

      {path !== PagePath.analyticsPerson && (
        <CascadingFocusPanels openIds={connector} onClosePanelById={id => closeById(id)} onCloseAllPanels={closeAll}>
          <FocusPanel id="additional" onClose={closeAll}>
            <AdditionalLearningFocusPanelLayout path={path} onCancel={closeAll} onSubmit={onSubmitAdditionalLearningHandler} />
          </FocusPanel>

          <FocusPanel id="reflections" onClose={onCloseReflectionHandler}>
            <ReflectionFocusPanelLayout reflectionId={selectedReflectionId} path={path} onCancel={onCloseReflectionHandler} onSubmit={onCloseReflectionHandler} />
          </FocusPanel>
        </CascadingFocusPanels>
      )}
    </React.Fragment>
  );
}

