import React, { useState } from 'react';
import { FuzzySearch, FuzzySearchParams } from '../../../../../components';
import { DropdownItem, DropdownMenu, PillButton, Tooltip, XIcon, colourString, useMediaQuery } from '@keplerco/core';
import { ScrollSection } from '../../../../../components/scroll-section/scroll-section';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ICourseMappingCourseSkillConditionProps } from '../course-mapping-course.models';
import { CourseMappingResponseSkill } from '../../../../../models/overmind/learning-management';
import classNames from 'classnames';
import styles from './course-mapping-course-skill-condition.module.css';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../lib/permissions.helpers';
import { OrganizationLevelType, SortField } from '../../../../../enums';
import { createLevelsArray } from '../../../../../lib/create-levels-array';

export function CourseMappingCourseSkillCondition({ skills, onClickAddSkill, onClickRemoveSkill, onClickAddLevel, onClickRemoveLevel }: ICourseMappingCourseSkillConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const isMobile = useMediaQuery('(max-width: 580px)');

  const [loadingFuzzySearchSkills, setLoadingFuzzySearchSkills] = useState<boolean>(false);
  const [fuzzySearchSkills, setFuzzySearchSkills] = useState<CourseMappingResponseSkill[]>([]);

  async function onFuzzySearch(searchParams: FuzzySearchParams) {
    if (!searchParams.query) return setFuzzySearchSkills([]);

    setLoadingFuzzySearchSkills(true);

    const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement?.organizationLevels);
    const request: CompanyEntitySearchParams = {
      search: searchParams.query,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 9, // TODO: remove paging
      page: 1, // TODO: remove paging
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: false,
    };
    const response = await actions.getSkills(request);
    const skills: CourseMappingResponseSkill[] = response?.entities.map(skill => ({ slug: skill.slug, name: skill.name })) ?? [];
    setFuzzySearchSkills(skills);

    setLoadingFuzzySearchSkills(false);
  }

  function onClickLevelItem(selected: boolean | undefined, skillSlug: string, level: number) {
    selected ? onClickRemoveLevel(skillSlug, level) : onClickAddLevel(skillSlug, level);
  }

  return (
    <div className='card'>
      <h5 className="heading5">Skills</h5>
      <p className="body2" style={{ color: colourString('text_1'), marginBottom: 10 }}>
        Courses assigned by skill will only appear once an assessment has been completed.
      </p>

      <div>
        <FuzzySearch
          boxedResults
          disableEmptyState
          label="Search for a skill..."
          loading={loadingFuzzySearchSkills}
          onFuzzySearch={onFuzzySearch}
          fullWidthSearch={true}
        >
          {!!fuzzySearchSkills.length && fuzzySearchSkills.filter(fuzzySearchSkill => !skills?.some(skill => skill.slug === fuzzySearchSkill.slug)).map(skill => (
            <PillButton key={skill.slug} slug={skill.slug} label={skill.name} onClick={() => onClickAddSkill(skill)} />
          ))}
        </FuzzySearch>

        {!!skills?.length && (
          <React.Fragment>
            <h6 className="caption1" style={{ color: colourString('text_1'), marginTop: 10, marginBottom: 10 }}>Assigned:</h6>

            <ScrollSection maxDesktopHeight="395px" visibleItemsIncrement={5} items={skills ?? []}>
              {({ visibleItems }) => (
                <div className={styles.list}>
                  {visibleItems.map(skill => {
                    const levelItems: DropdownItem[] = createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => {
                      const selected = skill.levels?.includes(level);
                      return { value: `Skill level ${level}`, onClick: () => onClickLevelItem(selected, skill.slug!, level), selected };
                    });

                    return (
                      <div key={skill.slug} className={styles.listItem}>
                        <button
                          type="button"
                          className={styles.button}
                          onClick={() => onClickRemoveSkill(skill.slug!)}
                        >
                          <XIcon tone="text" />
                          <span className="caption1">Remove</span>
                        </button>

                        <div className={classNames('card', styles.card)}>
                          <div>
                            <div className="body2" style={{ color: 'var(--accent-2)' }}>{skill.name}</div>

                            {!!skill.description && (
                              <div className="body2" style={{ marginTop: 5 }}>{skill.description}</div>
                            )}
                          </div>

                          {isMobile ? (
                            <DropdownMenu
                              label="Skill level"
                              items={levelItems}
                              dialogContained
                              multiple
                              responsive
                            />
                          ) : (
                            <Tooltip content={levelItems.filter(levelItem => levelItem.selected).length > 0 ? levelItems.filter(levelItem => levelItem.selected).map(levelItem => levelItem.value).join(', ') : `No levels selected`}>
                              <DropdownMenu
                                label="Skill level"
                                items={levelItems}
                                dialogContained
                                multiple
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </ScrollSection>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}