import styled from 'styled-components';

export const SkillPoolWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 581px) and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

