import React, { useState } from 'react';
import { RaterCardHeader, RaterCardModuleContainer, RaterCardWrapper } from './rater-card.styles';
import classNames from 'classnames';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts';
import { RaterRadioButtonList } from '../rater-radio-button/rater-radio-button.styles';
import { RaterRadioButton } from '../rater-radio-button/rater-radio-button';
import { RatingScalePoint } from '../../../models/rating-scale';
import { IRaterCardProps } from '../raters.models';
import { IconButton, HelpIcon, safeCallback, FocusPanel, capitalize } from '@keplerco/core';

const DONT_KNOW_VALUE = 0;

export function RaterCard(props: IRaterCardProps): JSX.Element {
  const { colour = 'primary' } = props;

  const maxWidth = props.scale.length * 70 + (props.scale.length - 1) * 45;

  const [show, setShow] = useState<boolean>();

  return (
    <React.Fragment>
      <RaterCardWrapper className={classNames('card', { fade: props.fadeOnComplete && props.data.rating !== null && props.data.rating !== undefined })}>
        <RaterCardHeader className={classNames({ disabled: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
          <h4 className="heading4">{props.data.label}</h4>

          {!!props.data.description && (
            <IconButton iconType="fill" onClick={() => setShow(true)}>
              <HelpIcon tone="link-text" />
            </IconButton>
          )}
        </RaterCardHeader>

        <RaterCardModuleContainer>
          <RaterRadioButtonList style={{ maxWidth }} className={classNames({ disabled: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
            {props.scale.map((point: RatingScalePoint) => {
              return <RaterRadioButton bgColor={props.bgColor} key={point.label} colour={colour} details={props.data} point={point} onChange={() => safeCallback(props.onChange, point.rating)} />;
            })}
          </RaterRadioButtonList>
        </RaterCardModuleContainer>
      </RaterCardWrapper>

      {show && (
        <FocusPanel open={show} zIndex={110} onClose={() => setShow(false)}>
          {show && <ExplainerFocusPanelLayout title={capitalize(props.data.label)} description={props.data.description ?? ''} onClose={() => setShow(false)} />}
        </FocusPanel>
      )}
    </React.Fragment>
  );
}

