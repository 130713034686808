import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownSearch, capitalize } from '@keplerco/core';
import { MappingType, OrganizationLevelType } from '../../../../enums';
import { UniqueEntity } from '../../../../models';
import { useAppActions, useAppState } from '../../../../overmind';
import { ICourseMappingCourseConditionProps } from './course-mapping-course.models';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../lib/permissions.helpers';

const languages: string[] = [
  'Czech',
  'Dutch',
  'Español',
  'Français',
  'German',
  'Italian',
  'Korean',
  'Portuguese',
  'Vietnamese',
];

function getMappingTypeString(type: MappingType): string {
  switch (type) {
    case MappingType.Language: {
      return 'language';
    }

    case MappingType.Team: {
      return 'team';
    }

    case MappingType.Department: {
      return 'department';
    }

    case MappingType.Role: {
      return 'role';
    }

    default: {
      return 'entity';
    }
  }
}

export function CourseMappingCourseCondition({ type, entities, onClickAddEntity, onClickRemoveEntity }: ICourseMappingCourseConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  function onClickEntityItem(selected: boolean | undefined, entity: UniqueEntity) {
    selected ? onClickRemoveEntity(type, entity.slug!) : onClickAddEntity(type, entity);
  }

  const [entityItems, setEntityItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function fetchEntityItems() {
      switch (type) {
        case MappingType.Role: {
          const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

          const rolesRequest: CompanyEntitySearchParams = {
            organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
            companySlug: companyVariables.slug,
            departmentSlug: undefined,
            teamSlug: undefined,
            learnerSlug: undefined,
            searchGlobally: false,
            page: undefined,
            pageSize: undefined,
            search: undefined,
            sortAscending: undefined,
          };

          const roles = await actions.getRoles(rolesRequest);
          setEntityItems(
            roles?.entities.map(role => {
              const selected = entities?.some(entity => entity.slug === role.slug);
              return {
                value: role.slug,
                label: role.name,
                onClick: () => onClickEntityItem(selected, { slug: role.slug, name: role.name }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }
        case MappingType.Department: {
          const departments = await actions.getLearningManagementDepartments(companyVariables.slug!);
          setEntityItems(departments?.map(department => {
            const selected = entities?.some(entity => entity.slug === department.slug);
            return ({
              value: department.slug,
              label: department.name,
              onClick: () => onClickEntityItem(selected, { slug: department.slug, name: department.name }),
              selected,
            } as DropdownItem);
          }) ?? []);
          break;
        }

        case MappingType.Team: {
          const teams = await actions.getLearningManagementTeams(companyVariables.slug!);
          setEntityItems(
            teams?.map(team => {
              const selected = entities?.some(entity => entity.slug === team.slug);
              return ({
                value: team.slug,
                label: team.teamName,
                onClick: () => onClickEntityItem(selected, { slug: team.slug, name: team.teamName }),
                selected,
              } as DropdownItem);
            }) ?? []);
          break;
        }

        case MappingType.Language: {
          setEntityItems(languages.map(language => {
            const selected = entities?.some(entity => entity.slug === language);
            return { value: language, onClick: () => onClickEntityItem(selected, { slug: language, name: language }), selected, } as DropdownItem;
          }));
          break;
        }

        default: {
          break;
        }
      }
    }

    fetchEntityItems();
  }, [type, entities]);

  return (
    <div className='card'>
      <h5 className="heading5" style={{ marginBottom: 10 }}>
        {capitalize(getMappingTypeString(type))}s
      </h5>

      <DropdownSearch label={`Assign ${getMappingTypeString(type)}s`} items={entityItems} responsive dialogContained multiple />
    </div>
  );
}

