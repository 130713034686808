import React, { useEffect, useRef } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { ISideNavRoute } from '../side-nav.models';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mode, PagePath } from '../../navigation.enums';
import { FetchType } from '../../../enums';
import { SideNavDialogRouteList } from './side-nav-dialog-route-list/side-nav-dialog-route-list';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { DoubleChevronIcon } from '../../../components/assets/chevron.icons';
import { AvatarIcon, CloseButton, RadioButton, colourString } from '@keplerco/core';
import navStyles from '../../navigation.module.css';
import sideNavStyles from '../side-nav.module.css';
import sideNavDialogStyles from './side-nav-dialog.module.css';
import classNames from 'classnames';
import KeplerNavlink from '../../guards/kepler-navlink';

function isInsideClick(element: HTMLDialogElement | null, event: React.MouseEvent<HTMLDialogElement, MouseEvent>): boolean {
  if (!element) return false;

  const content = element.getBoundingClientRect();
  const verticalInside = content.top <= event.clientY && content.bottom >= event.clientY;
  const horizontalInside = content.left <= event.clientX && content.right >= event.clientX;
  return verticalInside && horizontalInside;
}

export function SideNavDialog(props: { learnerRoutes: ISideNavRoute[]; companyRoutes: ISideNavRoute[]; open: boolean; onClose: () => void; onOpenSettings: () => void }): JSX.Element {
  const { learnerRoutes, companyRoutes, open, onClose, onOpenSettings } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const actions = useAppActions();
  const { user, companyVariables, mode, skillAssessmentConfiguration } = useAppState();

  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    function handleTouchStartEvent(touchStartEvent: TouchEvent) {
      if (!dialog) return;

      const startX = touchStartEvent.touches[0].clientX;
      const paneWidth = dialog.getBoundingClientRect().width;
      let activateSwipe = false;
      let totalMovement = 0;
      let totalMovementPercent = 0;

      function handleTouchMove(touchMoveEvent: TouchEvent) {
        if (!dialog) return;

        const moveX = touchMoveEvent.touches[0].clientX;
        totalMovement = moveX - startX;
        totalMovementPercent = totalMovement / paneWidth;

        activateSwipe = totalMovement >= 10;

        if (activateSwipe) {
          if (closeButtonRef.current) {
            closeButtonRef.current.style.transitionDuration = `0ms`;
            closeButtonRef.current.style.transform = `translateX(${Math.min(1, totalMovementPercent) * 200}%)`;
            closeButtonRef.current.style.opacity = `${1 - totalMovementPercent}`;
          }

          dialog.style.transitionDuration = '0ms';
          dialog.style.transform = `translateX(${Math.max(totalMovement, 0)}px)`;
        }
      }

      window.addEventListener('touchmove', handleTouchMove);

      function handleTouchEnd(touchEndEvent: TouchEvent) {
        if (!dialog) return;

        if (activateSwipe) {
          touchEndEvent.preventDefault();
          touchEndEvent.stopPropagation();
        }

        window.removeEventListener('touchmove', handleTouchMove);
        dialog.style.transitionDuration = '';
        if (closeButtonRef.current) closeButtonRef.current.style.transitionDuration = '';

        if (totalMovementPercent >= 0.45) {
          dialog.close();
          onClose();
        }

        dialog.style.transform = ``;
        if (closeButtonRef.current) {
          closeButtonRef.current.style.transform = ``;
          closeButtonRef.current.style.opacity = ``;
        }
      }

      window.addEventListener('touchend', handleTouchEnd, { once: true });
    }

    if (open) {
      dialog.addEventListener('touchstart', handleTouchStartEvent);
      dialog.showModal();
      return;
    }

    return () => dialog.removeEventListener('touchstart', handleTouchStartEvent);
  }, [open]);

  function setMode(mode: Mode) {
    actions.setMode(mode);
    mode === Mode.PlatformManagement ? navigate(PagePath.companies) : navigate(PagePath.dashboard);
    closeDialog();
  }

  function closeDialog() {
    const dialog = dialogRef.current;
    if (!dialog) return;

    // dialog.classList.add('close'); // run animation here
    dialog.classList.add(sideNavDialogStyles.close); // run animation here
    onClose();

    dialog.addEventListener(
      'animationend',
      () => {
        // dialog.classList.remove('close');
        dialog.classList.remove(sideNavDialogStyles.close);
        dialog.close(); // then run the default close method
      },
      { once: true }
    ); // add this to prevent bugs when reopening the modal
  }

  return (
    <dialog
      ref={dialogRef}
      className={sideNavDialogStyles.sideNavDialogContent}
      onClick={event => {
        if (!isInsideClick(dialogRef.current, event)) closeDialog();
      }}
    >
      <CloseButton onClick={closeDialog} />

      <header className={sideNavDialogStyles.sideNavDialogHeader}>
        <div className={classNames(navStyles.navAvatarIconContainer, sideNavDialogStyles.sideNavDialogAvatarIconContainer)}>
          <AvatarIcon name={user} />
        </div>

        <h6 className="subtitle">{user?.preferredName ?? user?.firstName}</h6>

        <p className="body2" style={{ color: colourString(`accent-2`) }}>
          {user?.jobTitle}
        </p>
      </header>

      {user?.isSystemAdmin && skillAssessmentConfiguration?.benchmarkResultReady && (
        <div className={sideNavDialogStyles.sideNavDialogSwitchToContainer}>
          <span className={navStyles.navCaption}>Switch to:</span>

          <RadioButton id="PlatformManagement" checked={mode === Mode.PlatformManagement} onChange={() => setMode(Mode.PlatformManagement)} clickablearea="label" alignitems="center">
            <span className={navStyles.navCaption}>Platform management</span>
          </RadioButton>

          <RadioButton id="LearningDashboard" checked={mode === Mode.LearningDashboard} onChange={() => setMode(Mode.LearningDashboard)} clickablearea="label" alignitems="center">
            <span className={navStyles.navCaption}>Learning Dashboard</span>
          </RadioButton>
        </div>
      )}

      <React.Fragment>
        {mode === Mode.PlatformManagement ? (
          <React.Fragment>
            {window.location.pathname !== PagePath.companies && (
              <div className={sideNavStyles.sideNavAllCompaniesContainer}>
                <KeplerNavlink to={PagePath.companies} className={sideNavStyles.sideNavAllCompanies} onClick={closeDialog}>
                  <DoubleChevronIcon tone="primary" />
                  All Companies
                </KeplerNavlink>
              </div>
            )}
          </React.Fragment>
        ) : (
          <SideNavDialogRouteList sideNavParentRoutes={learnerRoutes} pathname={pathname} onClick={closeDialog} />
        )}
      </React.Fragment>

      {window.location.pathname !== PagePath.companies && companyRoutes.length > 0 && (
        <React.Fragment>
          <span className={navStyles.navCaption}>Company</span>
          <SideNavDialogRouteList sideNavParentRoutes={companyRoutes} pathname={pathname} onClick={closeDialog} />
        </React.Fragment>
      )}

      {skillAssessmentConfiguration?.benchmarkResultReady && mode === Mode.PlatformManagement && window.location.pathname !== PagePath.companies && !!companyVariables.slug && (
        <button
          className={sideNavDialogStyles.sideNavDialogParentRoute}
          onClick={() => {
            onOpenSettings();
            closeDialog();
          }}
        >
          <img className={navStyles.navIcon} src={themedAssetUrl('icons/sidebar/settings.icon.svg')} />
          <div className={sideNavStyles.sideNavParentRouteTitle}>Settings</div>
        </button>
      )}

      <hr className={navStyles.navDivider} />

      {user?.completedOnboarding && skillAssessmentConfiguration?.benchmarkResultReady && (
        <KeplerNavlink
          className={classNames(navStyles.navCaption, sideNavDialogStyles.sideNavDialogYourAccount)}
          to={PagePath.profile} onClick={closeDialog}
        >
          <img className={navStyles.navIcon} src="/icons/ico_profile_tile.svg" alt="Profile Icon" />
          Profile
        </KeplerNavlink>
      )}

      <a
        className={classNames(navStyles.navCaption, sideNavDialogStyles.sideNavDialogYourAccount)}
        href="https://legal.keplerco.io"
        target="_blank"
        rel="noopener noreferrer"
        onClick={closeDialog}
      >
        <img className={navStyles.navIcon} src="/icons/ico_legal_tile.svg" alt="Legal Icon" />
        Legal
      </a>

      <button
        className={classNames(navStyles.navCaption, sideNavDialogStyles.sideNavDialogYourAccount)}
        onClick={async () => {
          actions.startLoader({ path: PagePath.root, type: FetchType.PageFetching });
          closeDialog();
          await actions.logout();
          actions.stopLoader(PagePath.root);
        }}
      >
        <img className={navStyles.navIcon} src="/icons/ico_logout_tile.svg" alt="Sign Out Icon" />
        Log Out
      </button>
    </dialog>
  );
}

