import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../../overmind';
import { ActivationStatus, FetchStatus, FetchType, SkillAssessmentAssignee } from '../../../../enums';
import { useParams } from 'react-router-dom';
import { BaseSearchRequest, Employee, SkillAssessmentAssignees } from '../../../../models';
import * as endorsementSelectionStyles from './endorsement-selection.styles';
import { Assignees } from '../../../../models/view/skill-assessment-assignee';
import { KeplerState } from '../../../../models/kepler-state';
import { WidgetSkeleton } from '../widget.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Autocomplete, AvatarIcon, ListLayout, XIcon, colourString } from '@keplerco/core';

interface EndorsementSelectionWidgetProps {
  onStepComplete: () => void;
  benchmarkSlug: string;
  selectedAssignees: SkillAssessmentAssignees | null;
  onEndorsementSelectionChange: any;
}

export default function EndorsementSelectionWidget({ onEndorsementSelectionChange, selectedAssignees, benchmarkSlug, onStepComplete }: EndorsementSelectionWidgetProps) {
  const actions = useAppActions();
  const params = useParams();

  const [availableEmployeeOptions, setAvailableEmployeeOptions] = useState<Employee[]>();
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<Employee[]>([]);
  const [availableManagerOptions, setAvailableManagerOptions] = useState<Employee[]>();
  const [selectedManager, setSelectedManager] = useState<Employee>();
  const [employees, setEmployees] = useState<Array<Employee>>();
  const [hasManagerError, setHasManagerError] = useState<boolean>(false);
  const [hasLearnerError, setHasLearnerError] = useState<boolean>(false);
  const { fetchState } = useAppState<KeplerState>();

  const isDraft = selectedAssignees !== null;
  const [isSubmitted, setIsSubmitted] = useState(isDraft);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const searchRequest: BaseSearchRequest = {
      companySlug: params.companySlug!,
      pageSize: 999,
    };

    actions.searchPeople(searchRequest).then(employeeSearchResponse => {
      if (!employeeSearchResponse) {
        return;
      }

      const employeeList: Employee[] = employeeSearchResponse.employees.map(person => ({
        id: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
        jobTitle: '',
        departmentId: person.department?.id || 0,
        teamId: person.team?.id || 0,
        countryId: person.country?.id || 0,
        isDepartmentManager: false,
        isTeamChampion: false,
        learningManagerId: '',
        learnerSlug: person.learnerSlug,
        departmentName: person.department?.name || '',
        teamName: person.team?.name || '',
        activationStatus: person.activationStatus,
        departmentSlug: '',
        teamSlug: '',
        dateCreated: new Date(person.dateCreated),
        skills: [],
        country: person.country?.name || '',
      }));

      setEmployees(employeeList.filter(employee => employee.activationStatus !== ActivationStatus.Archived));

      const manager = employeeList?.find(employee => employee.id === selectedAssignees?.assignedTeamChampionId);
      setSelectedManager(manager);

      const selectedEmployees = employeeList?.filter(employee => selectedAssignees?.userIds?.includes(employee.id!) && employee.id !== selectedManager?.id && employee.id !== manager?.id) || [];
      setSelectedEmployeeOptions(selectedEmployees);
    });
  }, [selectedAssignees]);

  async function onSubmitHandler() {
    const managerError = !selectedManager;
    const learnerError = selectedEmployeeOptions.length === 0;

    setHasManagerError(managerError);
    setHasLearnerError(learnerError);

    if (managerError || learnerError) {
      return;
    }

    if (isDraft) {
      void 0;
    } else {
      onStepComplete();
    }

    const userIds = selectedEmployeeOptions.map(i => i.id).filter((id): id is string => id !== undefined);
    if (selectedManager?.id) {
      userIds.unshift(selectedManager.id);
    }

    let selectedManagerAssignee: any[] = [];

    if (!!selectedManager) {
      selectedManagerAssignee = [
        {
          userName: `${selectedManager.firstName} ${selectedManager.lastName}`,
          userId: selectedManager.id!,
          skillCount: 0,
          skills: [],
        },
      ];
    }

    const selectedEmployeeAssignees = selectedEmployeeOptions
      .filter(employee => employee.id !== undefined)
      .map(
        employee =>
          ({
            userName: `${employee.firstName} ${employee.lastName}`,
            userId: employee.id!,
            skillCount: 0,
            skills: [],
          } as Assignees)
      );

    const assignees = [...selectedManagerAssignee, ...selectedEmployeeAssignees];

    const assigneeModel: SkillAssessmentAssignees = {
      assessmentGroup: SkillAssessmentAssignee.Learner,
      benchmarkSlug: params.slug ?? benchmarkSlug ?? '',
      includeLearnersWithNoDepartment: false,
      assignedTeamChampionId: selectedManager?.id,
      userIds: userIds,
      assignees: assignees,
    };

    onEndorsementSelectionChange(assigneeModel);
    actions.startLoader({ path: PagePath.assessmentsCreate, type: FetchType.Custom });

    await actions.saveSkillAssessmentAssignees({ benchmarkAssignee: assigneeModel, companySlug: params.companySlug! });
    actions.stopLoader(PagePath.assessmentsCreate);

    setHasChanges(false);
    setIsSubmitted(true);
  }

  return fetchState[PagePath.assessmentsCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentsCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <React.Fragment>
      <endorsementSelectionStyles.EndorsementSelectionWidgetWrapper>
        <endorsementSelectionStyles.EndorsementWidgetHeader>
          <h5 className="heading5">Select manager:</h5>
        </endorsementSelectionStyles.EndorsementWidgetHeader>
      </endorsementSelectionStyles.EndorsementSelectionWidgetWrapper>

      <endorsementSelectionStyles.AutocompleteWrapper>
        <Autocomplete
          zIndex={999}
          responsive
          name={'managerId'}
          borderColor="borders"
          backgroundColor="background"
          fixDropdownPosition={true}
          haserror={hasManagerError}
          errormessage="Please select a manager for this assessment"
          onSearch={query => {
            if (!!query) {
              const lowercaseQuery = query.toLowerCase();
              setAvailableManagerOptions(
                employees?.filter(opt => {
                  return !selectedEmployeeOptions.find(o => o.id === opt.id) && opt.firstName.toLowerCase().startsWith(lowercaseQuery);
                })
              );
            } else setAvailableManagerOptions(void 0);
          }}
          label="Search for a manager"
        >
          {!!availableManagerOptions &&
            (() => {
              if (!availableManagerOptions.length)
                return (
                  <h4 className="heading4" style={{ padding: '0 15px', color: colourString('default') }}>
                    no results
                  </h4>
                );

              return (
                <endorsementSelectionStyles.CustomStyledDropdownList className="autocompleteDropdownList">
                  {availableManagerOptions.map(option => {
                    const isSelected = selectedManager && selectedManager.id === option.id;

                    return (
                      <endorsementSelectionStyles.CustomStyledDropdownListItem
                        key={option.id}
                        isSelected={isSelected as boolean}
                        className={classNames('autocompleteDropdownListItem', { selected: isSelected })}
                        onClick={event => {
                          event?.stopPropagation();
                          setHasChanges(true);
                          setSelectedManager(option);
                          setHasManagerError(false);
                        }}
                      >
                        <endorsementSelectionStyles.CustomStyledDropdownListItemCheck className="autocompleteDropdownListItemCheck">
                          <span />
                        </endorsementSelectionStyles.CustomStyledDropdownListItemCheck>

                        <endorsementSelectionStyles.EntityAssignmentWidgetListIconAvatar>
                          <AvatarIcon name={option} />
                        </endorsementSelectionStyles.EntityAssignmentWidgetListIconAvatar>
                        <endorsementSelectionStyles.CustomStyledDropdownListItemLabel className="autocompleteDropdownListItemLabel">
                          {option.firstName} {option.lastName} ({option.email})
                        </endorsementSelectionStyles.CustomStyledDropdownListItemLabel>
                      </endorsementSelectionStyles.CustomStyledDropdownListItem>
                    );
                  })}
                </endorsementSelectionStyles.CustomStyledDropdownList>
              );
            })}
        </Autocomplete>

        {selectedManager && (
          <React.Fragment>
            <endorsementSelectionStyles.EndorsementWidgetLabel className="heading5">Selected manager:</endorsementSelectionStyles.EndorsementWidgetLabel>

            <ListLayout>
              <endorsementSelectionStyles.EndorsementWidgetSelectedDropdownCard className="card">
                <endorsementSelectionStyles.EndorsementWidgetCardListItem className="cardListItemBodyLayout">
                  <endorsementSelectionStyles.EndorsementWidgetCardListItemBody>
                    <endorsementSelectionStyles.EndorsementWidgetListIconAvatar>
                      <AvatarIcon name={selectedManager} />
                    </endorsementSelectionStyles.EndorsementWidgetListIconAvatar>

                    <endorsementSelectionStyles.EndorsementSelectionWidgetSelectedDropdownItem>
                      {selectedManager.firstName} {selectedManager.lastName}
                    </endorsementSelectionStyles.EndorsementSelectionWidgetSelectedDropdownItem>
                    <endorsementSelectionStyles.EndorsementWidgetSpacer />
                    <endorsementSelectionStyles.EndorsementWidgetCardListItemIcon
                      onClick={() => {
                        setHasChanges(true);
                        setSelectedManager(undefined);
                      }}
                    >
                      <XIcon />
                    </endorsementSelectionStyles.EndorsementWidgetCardListItemIcon>
                  </endorsementSelectionStyles.EndorsementWidgetCardListItemBody>
                </endorsementSelectionStyles.EndorsementWidgetCardListItem>
              </endorsementSelectionStyles.EndorsementWidgetSelectedDropdownCard>
            </ListLayout>
          </React.Fragment>
        )}
        <endorsementSelectionStyles.EndorsementWidgetLabel className="heading5">Select team members:</endorsementSelectionStyles.EndorsementWidgetLabel>

        <Autocomplete
          responsive
          zIndex={999}
          name={'employeeIds'}
          borderColor="borders"
          backgroundColor="background"
          haserror={hasLearnerError}
          errormessage="Please select at least 1 learner for this assessment"
          onSearch={query => {
            if (!!query) {
              const lowercaseQuery = query.toLowerCase();
              setAvailableEmployeeOptions(
                employees?.filter(opt => {
                  return opt.id !== selectedManager?.id && !selectedEmployeeOptions.find(o => o.id === opt.id) && (opt.firstName.toLowerCase().startsWith(lowercaseQuery) || opt.learnerSlug?.toLowerCase().startsWith(lowercaseQuery));
                })
              );
            } else setAvailableEmployeeOptions(void 0);
          }}
          label="Search for team members"
        >
          {!!availableEmployeeOptions &&
            (() => {
              if (!availableEmployeeOptions.length)
                return (
                  <h4 className="heading4" style={{ padding: '0 15px', color: colourString('default') }}>
                    no results
                  </h4>
                );

              return (
                <endorsementSelectionStyles.CustomStyledDropdownList>
                  {availableEmployeeOptions.map(option => {
                    const isSelected = selectedEmployeeOptions.some(emp => emp.id === option.id);

                    return (
                      <endorsementSelectionStyles.CustomStyledDropdownListItem
                        key={option.id}
                        isSelected={isSelected as boolean}
                        className={classNames('autocompleteDropdownListItem', { selected: isSelected })}
                        onClick={event => {
                          event?.stopPropagation();
                          setHasChanges(true);

                          const isAlreadySelected = selectedEmployeeOptions.some(emp => emp.id === option.id);

                          if (isAlreadySelected) {
                            setSelectedEmployeeOptions(currentSelected => currentSelected.filter(emp => emp.id !== option.id));
                          } else {
                            setSelectedEmployeeOptions(currentSelected => [...currentSelected, option]);
                          }

                          setHasLearnerError(false);
                        }}
                      >
                        <endorsementSelectionStyles.CustomStyledDropdownListItemCheck className="autocompleteDropdownListItemCheck">
                          <span />
                        </endorsementSelectionStyles.CustomStyledDropdownListItemCheck>

                        <endorsementSelectionStyles.EntityAssignmentWidgetListIconAvatar>
                          <AvatarIcon name={option} />
                        </endorsementSelectionStyles.EntityAssignmentWidgetListIconAvatar>
                        <endorsementSelectionStyles.CustomStyledDropdownListItemLabel className="autocompleteDropdownListItemLabel">
                          {option.firstName} {option.lastName} ({option.email})
                        </endorsementSelectionStyles.CustomStyledDropdownListItemLabel>
                      </endorsementSelectionStyles.CustomStyledDropdownListItem>
                    );
                  })}
                </endorsementSelectionStyles.CustomStyledDropdownList>
              );
            })}
        </Autocomplete>

        {!!selectedEmployeeOptions.length && (
          <React.Fragment>
            <endorsementSelectionStyles.EndorsementWidgetLabel className="heading5">Selected team members: ({selectedEmployeeOptions.length})</endorsementSelectionStyles.EndorsementWidgetLabel>

            <ListLayout>
              <endorsementSelectionStyles.EndorsementWidgetSelectedDropdownCard className="card">
                {selectedEmployeeOptions.map(option => {
                  return (
                    <endorsementSelectionStyles.EndorsementWidgetCardListItem className="cardListItemBodyLayout" key={option.id}>
                      <endorsementSelectionStyles.EndorsementWidgetCardListItemBody>
                        <endorsementSelectionStyles.EndorsementWidgetListIconAvatar>
                          <AvatarIcon name={option} />
                        </endorsementSelectionStyles.EndorsementWidgetListIconAvatar>

                        <endorsementSelectionStyles.EndorsementSelectionWidgetSelectedDropdownItem>
                          {option.firstName} {option.lastName}
                        </endorsementSelectionStyles.EndorsementSelectionWidgetSelectedDropdownItem>

                        <endorsementSelectionStyles.EndorsementWidgetSpacer />

                        <endorsementSelectionStyles.EndorsementWidgetCardListItemIcon
                          onClick={() => {
                            if (!!selectedEmployeeOptions.find(o => o.id === option.id)) {
                              setHasChanges(true);
                              setSelectedEmployeeOptions([...selectedEmployeeOptions.filter(i => i.id !== option.id)]);
                            } else setSelectedEmployeeOptions([...selectedEmployeeOptions, option]);
                          }}
                        >
                          <XIcon />
                        </endorsementSelectionStyles.EndorsementWidgetCardListItemIcon>
                      </endorsementSelectionStyles.EndorsementWidgetCardListItemBody>
                    </endorsementSelectionStyles.EndorsementWidgetCardListItem>
                  );
                })}
              </endorsementSelectionStyles.EndorsementWidgetSelectedDropdownCard>
            </ListLayout>
          </React.Fragment>
        )}
      </endorsementSelectionStyles.AutocompleteWrapper>
      <endorsementSelectionStyles.EndorsementWidgetsSubmitButtonWrapper>
        {!isSubmitted && (
          <endorsementSelectionStyles.EndorsementWidgetSubmitButton arrow onClick={onSubmitHandler}>
            Next
          </endorsementSelectionStyles.EndorsementWidgetSubmitButton>
        )}
        {isSubmitted && hasChanges && (
          <endorsementSelectionStyles.EndorsementWidgetSubmitButton arrow onClick={onSubmitHandler}>
            Update
          </endorsementSelectionStyles.EndorsementWidgetSubmitButton>
        )}
      </endorsementSelectionStyles.EndorsementWidgetsSubmitButtonWrapper>
    </React.Fragment>
  );
}
