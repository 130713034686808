import React, { useState } from 'react';
import { Counter } from '../../form-elements';
import { IRaterProps } from '../raters.models';
import { RaterHeader, RaterModuleContainer, RaterModuleExtraRatingOutlet, RaterWrapper, RaterModuleExtraRatingCounterWrapper } from './rater.styles';
import classNames from 'classnames';
import { RaterRadioButtonList } from '../rater-radio-button/rater-radio-button.styles';
import { RaterRadioButton } from '../rater-radio-button/rater-radio-button';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts';
import { Checkbox, FocusPanel, HelpIcon, IconButton, capitalize, safeCallback } from '@keplerco/core';

const DONT_KNOW_VALUE = 0;

export function Rater(props: IRaterProps): JSX.Element {
  const { colour = 'primary' } = props;

  const maxWidth = props.scale.length * 70 + (props.scale.length - 1) * 45;

  const [show, setShow] = useState<boolean>();

  function onChangeHandler(target: EventTarget & HTMLInputElement) {
    const input = target as HTMLInputElement;
    const isChecked = input.checked;
    safeCallback(props.onChange, isChecked ? DONT_KNOW_VALUE : void 0);
  }

  return (
    <React.Fragment>
      <RaterWrapper className={classNames({ fade: props.fadeOnComplete && props.data.rating !== null && props.data.rating !== undefined })}>
        <RaterHeader className={classNames({ disabled: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
          <h4 className="heading4">{props.data.label}</h4>

          {!!props.data.description && (
            <IconButton iconType="fill" onClick={() => setShow(true)}>
              <HelpIcon />
            </IconButton>
          )}
        </RaterHeader>

        <RaterModuleContainer>
          <RaterRadioButtonList style={{ maxWidth }} className={classNames({ disabled: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
            {props.scale.map(point => {
              return <RaterRadioButton key={point.label} colour={colour} details={{ id: props.data.id, label: props.data.label, rating: props.data.rating }} point={point} onChange={() => safeCallback(props.onChange, point.rating)} />;
            })}
          </RaterRadioButtonList>

          {!!props.extraRating && (
            <RaterModuleExtraRatingOutlet>
              {props.extraRating.count !== undefined ? (
                <RaterModuleExtraRatingCounterWrapper>
                  <span className="caption2">{props.extraRating.label}</span>
                  <Counter max={props.extraRating.maxValue} center count={props.extraRating.count ?? 0} onChange={value => safeCallback(props.extraRating?.onChange, value)} />
                </RaterModuleExtraRatingCounterWrapper>
              ) : (
                <Checkbox id={props.data.label} name="" value="" checked={props.data.rating === DONT_KNOW_VALUE} onChange={({ target }) => onChangeHandler(target)}>
                  {props.extraRating.label}
                </Checkbox>
              )}
            </RaterModuleExtraRatingOutlet>
          )}
        </RaterModuleContainer>
      </RaterWrapper>

      {show && (
        <FocusPanel open={show} zIndex={110} onClose={() => setShow(false)}>
          {show && <ExplainerFocusPanelLayout title={capitalize(props.data.label)} description={props.data.description ?? ''} onClose={() => setShow(false)} />}
        </FocusPanel>
      )}
    </React.Fragment>
  );
}

