import React, { useEffect, useReducer, useState } from 'react';
import { ListItemLayout, ListLayout, Modal, Pager, Searchfield, SkillLevel } from '@keplerco/core';
import * as stateSetup from './view-role-assigned-skills.state';
import { ConfirmationModalLayout } from '../../../../../widgets/layouts';
import { IViewRoleAssignedEntitiesProps } from '../view-role.models';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { FetchType, SkillScoreComparison, EntityType, SortField, OrganizationLevelType } from '../../../../../enums';
import styles from './view-role-assigned-skills.module.css';
import classNames from 'classnames';
import { EmptyState } from '../../../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../lib/permissions.helpers';
import { useLocation } from 'react-router-dom';

export default function ViewRoleAssignedSkills({ role }: IViewRoleAssignedEntitiesProps) {
  const isImport = window.location.pathname.includes('import');

  const { pathname } = useLocation();

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const [state, dispatch] = useReducer(stateSetup.reducer, stateSetup.initialState);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 10,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: isImport, // TODO: check if this should be true or false
    relatedSlug: role?.slug,
    relatedType: EntityType.Role,
  };

  async function onInputHandler(value: string) {
    setIsLoading(true);
    dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetRequest, payload: { ...(state.request ?? defaultRequest), search: value } });
  }

  async function updateData(request?: CompanyEntitySearchParams) {
    const data = pathname !== PagePath.myCareer
      ? await actions.getSkills(request ?? defaultRequest)
      : await actions.getUserSkills(request ?? defaultRequest);
    dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetData, payload: data });
    setIsLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });

      const data = pathname !== PagePath.myCareer
        ? await actions.getSkills(state.request ?? defaultRequest)
        : await actions.getUserSkills(state.request ?? defaultRequest);
      if (data) dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetData, payload: data });

      actions.stopLoader(PagePath.administrationSelectedRole);
    }
    fetchData();
  }, [role]);

  useEffect(() => {
    updateData(state.request);
  }, [state.request]);

  async function onPageChangeHandler(page: number) {
    if (page === state.request?.page) return;

    setIsLoading(true);
    dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetRequest, payload: { ...(state.request ?? defaultRequest), page: page } });
  }

  function closeConfirmationModal() {
    dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetSkillToRemove, payload: { open: false, skill: undefined } });
  }

  async function removeSkillFromRole() {
    const result = await actions.unrelateEntityFromRole({
      slug: role.slug,
      companySlug: companyVariables.slug!,
      targetSlug: state.skillToRemove.slug,
      targetEntityType: EntityType.Skill,
    });

    dispatch({ type: stateSetup.ViewRoleAssignedSkillActionTypes.SetSkillToRemove, payload: { open: false, skill: undefined } });
    updateData();

    return result;
  }

  return (
    <div className="card">
      <div className={styles.skillCardsHeader}>
        <p className="body1">Skills</p>
        <span className="caption1">Skill assigned to {role?.name} role</span>
      </div>

      <div className={styles.skillCardsSearch}>
        <Searchfield responsive loading={isLoading} onInput={onInputHandler} />
      </div>

      {!!state.pageData?.entities.length && (
        <div className={styles.skillCardsContainer}>
          <ListLayout>
            {state.pageData?.entities.map(item => {
              return (
                <ListItemLayout key={item.slug}>
                  <div className={classNames('card', styles.skillCard)}>
                    <div className={styles.skillCardContent}>
                      <h6 className="subtitle">{item.name}</h6>
                      <p className="caption2">{item.description}</p>
                    </div>

                    {companyVariables.skillScoreComparison === SkillScoreComparison.ExpectedProficiency && (
                      <div className="average">
                        <SkillLevel
                          level={item.level ?? companyVariables.minLevel}
                          minLevel={companyVariables.minLevel}
                          maxLevel={companyVariables.maxLevel}
                          notAssessed={item.level === null}
                          title="Expected proficiency"
                        />
                      </div>
                    )}
                  </div>
                </ListItemLayout>
              );
            })}
          </ListLayout>
        </div>
      )}

      {!state.pageData?.entities.length && <EmptyState centerText={true} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} badgeIconWidth={'full-width'} subtitle="No skills assigned to this role" />}

      {!!state.pageData?.entities.length && (
        <div>
          <Pager
            onPageChange={(pageNumber: number) => {
              if (pageNumber !== state.request?.page && typeof pageNumber !== 'undefined' && !isNaN(pageNumber)) {
                onPageChangeHandler(pageNumber);
                window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }}
            activePageNumber={state.request?.page ?? 1}
            pageCount={state.pageData?.totalPages ?? 1}
          />
        </div>
      )}

      <Modal type="small" open={!!state.openConfirmationModal} onClose={closeConfirmationModal}>
        {!!state.openConfirmationModal && (
          <ConfirmationModalLayout
            title={`Remove skill`}
            subtitleSize="large"
            submitButtonText="Yes, remove"
            onClickSubmit={() => {
              actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });
              removeSkillFromRole();
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
            textAlign="left"
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove <strong>{state.skillToRemove?.name}</strong> from this role?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </div>
  );
}

