import React from 'react';
import { IRoutingMapProps } from '../../../models/routing-map';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppState } from '../../../overmind';
import { AssessmentType } from '../../../enums/assessment-type';
import { Navigate } from 'react-router-dom';

export function QuestionnairesLandingRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.Questionnaire) return <Navigate to={PagePath.analysisBase} />;

  return <React.Fragment>{props.children({ next: PagePath.analysisBase, previous: PagePath.analysisBase })}</React.Fragment>;
}

export function QuestionnairesRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.Questionnaire) return <Navigate to={PagePath.analysisBase} />;
  
  return <React.Fragment>{props.children({ next: `${PagePath.analysisBase}${PagePath.analysisBehavioural}`, previous: `${PagePath.analysisBase}${PagePath.analysisBehavioural}` })}</React.Fragment>;
}


