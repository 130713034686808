import { SortField } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { Breadcrumb, ReducerAction } from '@keplerco/core';
import { CourseMappingsResponse, CourseMappingsSearchParams } from '../../../models/overmind/learning-management';
import { LearningManagementPageHeader } from '../learning-management.models';

// state
interface CourseMappingState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  loading: boolean;
  syncing: boolean;
  searchParams: CourseMappingsSearchParams;

  data: CourseMappingsResponse | undefined;
}

export const initialState: CourseMappingState = {
  pageTitle: 'Mapping',
  crumbs: [],
  loading: true,
  syncing: false,
  searchParams: {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 10,
    page: 1,

    organizationLevel: undefined,
    companySlug: undefined,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,

    courseMappingStatus: undefined,
    learningPlatformType: undefined,
  },

  data: undefined,
};

// actions
export enum CourseMappingActionTypes {
  SetHeader,
  SetLoading,
  SetSyncing,
  SetRequest,
  SetData,
  SetCourseSlug,
}

type CourseMappingActions =
  | ReducerAction<CourseMappingActionTypes.SetHeader, { payload: LearningManagementPageHeader }>
  | ReducerAction<CourseMappingActionTypes.SetLoading, { payload: boolean }>
  | ReducerAction<CourseMappingActionTypes.SetSyncing, { payload: boolean }>
  | ReducerAction<CourseMappingActionTypes.SetRequest, { payload: CourseMappingsSearchParams }>
  | ReducerAction<CourseMappingActionTypes.SetData, { payload: CourseMappingsResponse | undefined }>

// reducer
export function reducer(state: CourseMappingState, action: CourseMappingActions): CourseMappingState {
  switch (action.type) {
    case CourseMappingActionTypes.SetHeader: {
      return {
        ...state,
        pageTitle: !!action.payload.entityName ? `Course Mapping for ${action.payload.entityName}` : `Course Mapping`,
        crumbs: [
          { name: 'Companies', url: PagePath.companies },
          {
            name: !!action.payload.entityName ? `Course Mapping for ${action.payload.entityName}` : `Course Mapping`,
            url: `${PagePath.learningManagementBase}/${PagePath.learningManagementCourseMapping.replace(':companySlug', action.payload.companySlug)}`,
          },
        ],
      };
    }

    case CourseMappingActionTypes.SetLoading: {
      return { ...state, loading: action.payload };
    }

    case CourseMappingActionTypes.SetSyncing: {
      return { ...state, syncing: action.payload };
    }

    case CourseMappingActionTypes.SetRequest: {
      return { ...state, searchParams: action.payload };
    }

    case CourseMappingActionTypes.SetData: {
      return { ...state, data: action.payload };
    }

    default: {
      return state;
    }
  }
}

