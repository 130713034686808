import styled from 'styled-components';

// timeline
export const TimelineWrapper = styled.div`
  display: block;
  width: 100%;
`;

// collapsible
export const CollapsibleContainer = styled.div`
  padding-top: 30px;
`;

export const CollapsibleCardRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

// reflections
export const ReflectionFocusPanelHeaderLayout = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 20px;
  text-align: left;
  padding-bottom: 15px;

  .heading2 {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 15px;

    @media screen and (max-width: 530px) {
      padding-bottom: 0;
    }
  }

  .body2 {
    display: block;
  }
`;

export const ReflectionFocusPanelHeaderImageLayout = styled.figure`
  display: block;
  padding: 0;
  margin: 0;
  padding-top: 50%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--borders);
  }

  @supports (aspect-ratio: 16 / 9) {
    padding-top: 0;
    aspect-ratio: 16 / 9;
  }
`;

// empty state
export const SkillLayoutEmptyState = styled.div`
  padding: 80px 0;

  @media screen and (max-width: 720px) {
    padding: 50px 0;
  }
`;

