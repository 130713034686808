import React from 'react';
import { useAppState } from '../../../../overmind';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { LandingPageContent, LandingPageContentCard, LandingPageContentCardsWrapper, LandingPageImage, LandingPageWrapper } from '../landing.page.styles';
import classNames from 'classnames';
import { CompletionStatus } from '../../../../enums/completion-status';
import { TechnicalSkillsChips, technicalSkillStatus } from './analysis-landing.page.helpers';
import { PagePath } from '../../../../navigation/navigation.enums';
import { AssessmentType } from '../../../../enums/assessment-type';
import { getSubdomain } from '../../../../lib/get-subdomain';
import { Chip, ChipWrapper } from '@keplerco/core';

export function AnalysisLandingPage(): JSX.Element {
  const { skillAssessmentConfiguration, user } = useAppState();

  const technicalStatus = skillAssessmentConfiguration?.selfReviewCompletionStatus;
  const behavioralStatus = skillAssessmentConfiguration?.behavioralCompletionStatus;
  const peerReviewStatus = skillAssessmentConfiguration?.selfPeerReviewCompletionStatus;
  const careerPathSelectionStatus = skillAssessmentConfiguration?.careerPathSelectionCompletionStatus;
  const careerPathsStatus = skillAssessmentConfiguration?.careerPathCompletionStatus;

  const isCPD = getSubdomain().includes('cpd');

  return (
    <LandingPageWrapper>
      <LandingPageImage>
        <object data={themedAssetUrl('graphics/pie-graph.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/pie-graph.graphic.svg')} alt="graph" />
        </object>
      </LandingPageImage>

      <LandingPageContent>
        <ChipWrapper>
          <Chip label="Skills analysis" backgroundColour="primary" colour="contrast-text" />
        </ChipWrapper>

        <h1 className="heading1">Identify your skills</h1>

        <h6 className="subtitle">Your analysis will give us the insights we need to build your personalised development plan.</h6>

        <LandingPageContentCardsWrapper>
          {skillAssessmentConfiguration?.assessmentType === AssessmentType.PeerEndorsement && (
            <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`} borderColour={'blue'} className={classNames({ done: technicalStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed })}>
              <ChipWrapper>
                <TechnicalSkillsChips hasPeerReview={true} peerReviewStatus={skillAssessmentConfiguration?.selfPeerReviewCompletionStatus} technicalStatus={technicalStatus} />
              </ChipWrapper>

              <h3 className="heading3">Role-based skills analysis</h3>

              <span className="caption2">{technicalSkillStatus(technicalStatus, true, peerReviewStatus)}</span>
            </LandingPageContentCard>
          )}

          {skillAssessmentConfiguration?.assessmentType === AssessmentType.Questionnaire && (
            <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisBehavioural}`} borderColour={'grape'} className={classNames({ done: behavioralStatus === CompletionStatus.Completed })}>
              <ChipWrapper>
                {behavioralStatus === CompletionStatus.NotStarted && (
                  <Chip label="Not Started" variant="tiny" backgroundColour="secondary" colour="background" />
                )}

                {behavioralStatus === CompletionStatus.InProgress && (
                  <Chip label="In Progress" variant="tiny" backgroundColour="borders" colour="text" />
                )}

                {behavioralStatus === CompletionStatus.Completed && (
                  <Chip label="Complete" variant="tiny" backgroundColour="apple" colour="contrast-text" />
                )}
              </ChipWrapper>

              <h3 className="heading3">Behavioural skills analysis</h3>
              <span className="caption2">These skills determine how well you interact with others.</span>
            </LandingPageContentCard>
          )}

          {skillAssessmentConfiguration?.assessmentType === AssessmentType.FieldsOfPractice && (
            <React.Fragment>
              {user?.downloadLearningProgressPdf && isCPD && (
                <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisInterimDownload}`} borderColour="baby-blue">
                  <h3 className="heading3">Download learning reports</h3>
                  <span className="caption2">Download a previous report here without having to complete {skillAssessmentConfiguration.benchmarkName}</span>
                </LandingPageContentCard>
              )}

              <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisCareerPaths}`} borderColour={'lime'} className={classNames({ done: careerPathSelectionStatus === CompletionStatus.Completed })}>
                <ChipWrapper>
                  <Chip
                    label={careerPathSelectionStatus === CompletionStatus.Completed ? 'Completed' : 'Not Started'}
                    variant="tiny"
                    backgroundColour={careerPathSelectionStatus === CompletionStatus.Completed ? 'g' : 'default'}
                    colour={careerPathSelectionStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
                  />
                </ChipWrapper>

                <h3 className="heading3">Choose a career pathway</h3>
                <span className="caption2">Let us know where you are heading with your career</span>
              </LandingPageContentCard>

              {careerPathSelectionStatus === CompletionStatus.Completed && (
                <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`} borderColour={'yellow'} className={classNames({ done: careerPathSelectionStatus === CompletionStatus.Completed && careerPathsStatus === CompletionStatus.Completed })}>
                  <ChipWrapper>
                    <Chip
                      label={careerPathsStatus === CompletionStatus.Completed ? 'Completed' : careerPathsStatus === CompletionStatus.InProgress ? 'In Progress' : 'Not Started'}
                      variant="tiny"
                      backgroundColour={careerPathsStatus === CompletionStatus.Completed ? 'g' : careerPathsStatus === CompletionStatus.InProgress ? 'borders' : 'default'}
                      colour={careerPathsStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
                    />
                  </ChipWrapper>

                  <h3 className="heading3">{skillAssessmentConfiguration.benchmarkName ?? 'Skills assessment'}</h3>
                  <span className="caption2">Rate your self based on your career skills.</span>
                </LandingPageContentCard>
              )}
            </React.Fragment>
          )}
        </LandingPageContentCardsWrapper>
      </LandingPageContent>
    </LandingPageWrapper>
  );
}
