import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function RolesCMSSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="120" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={3} />

        <SkeletonLoaderColumn flex={1} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="500" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

export function RolesCMSPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <header className="dialogHeaderLayout">
        <SkeletonLoader height="100px" />
      </header>

      <div className="dialogBodyLayout">
        <SkeletonLoader height="calc(100vh - 310px)" />
      </div>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="50px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </footer>
    </div>
  );
}

