import React, { useEffect, useState } from 'react';
import { ExportFileIcon } from '../assets/export-file.icon';
import { useAppActions, useAppState } from '../../overmind';
import { PagePath } from '../../navigation/navigation.enums';
import { PDFReportModalLayout } from './pdf-report-modal.layout';
import { Daterange, DaterangeOutput } from '../form-elements';
import { deriveDateRange } from '../../overmind/derived-props/date-range';
import { GlobalDateRangeFilter } from '../../models/kepler-state';
import * as base from '../../overmind/actions/base';
import { FetchType } from '../../enums';
import { KeyValue } from '../../models';
import { getSubdomain } from '../../lib/get-subdomain';
import { Button, Modal } from '@keplerco/core';

function toLocalISOString(date: Date | undefined): string | undefined {
  if (!date) return undefined;
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('Z')[0];
}

export function PDFReport(props: { path: PagePath.dashboard | PagePath.yourSkillsBase | PagePath.analysisInterimDownload }) {
  const actions = useAppActions();
  const { user, settings } = useAppState();

  const isCPD = getSubdomain().includes('cpd');
  const isLPI = getSubdomain().includes('lpi');

  const [pdfReports, setPDFReports] = useState<KeyValue[]>();
  const [dateRange, setDateRange] = useState<GlobalDateRangeFilter>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      const temp = await actions.getPDFReports();
      setPDFReports(temp);
    }

    getData();
  }, []);

  useEffect(() => {
    if (!dateRange) return;
    if (isCPD && !settings?.cpdRegistrationNumber) return setOpen(true);
    downloadReport();
  }, [dateRange]);

  function onDaterangeChangeHandler(dateRangeOutput: DaterangeOutput) {
    setDateRange(deriveDateRange(dateRangeOutput));
  }

  function onClickSubmit() {
    downloadReport();
    setOpen(false);
  }

  function downloadReport() {
    actions.startLoader({ path: props.path, type: FetchType.Sending });
    const url = base.url(`pdfreport`, `${isLPI ? `progress-report` : `download-pdf`}${base.params({ startDate: toLocalISOString(dateRange?.from || undefined), endDate: toLocalISOString(dateRange?.to || undefined) })}`);
    window.location.href = url;
    setTimeout(() => actions.stopLoader(props.path), 1000);
  }

  return (
    <React.Fragment>
      {user?.downloadLearningProgressPdf && (
        <React.Fragment>
          {isCPD || isLPI ? (
            <Daterange title="Select a period:" applyButtonText="Download" onDaterangeChange={onDaterangeChangeHandler}>
              <Button type="button" square theme="dark">
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <ExportFileIcon tone="primary" />
                  {isCPD ? `CPD Report` : `Progress Report`}
                </div>
              </Button>
            </Daterange>
          ) : (
            <React.Fragment>
              {pdfReports?.map(report => (
                <Daterange key={report.slug} title="Select a period:" applyButtonText="Download" onDaterangeChange={onDaterangeChangeHandler}>
                  <Button type="button" square theme="dark">
                    <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                      <ExportFileIcon tone="primary" />
                      {report.name}
                    </div>
                  </Button>
                </Daterange>
              ))}
            </React.Fragment>
          )}

          <Modal open={open} onClose={() => setOpen(false)}>
            {open && !!dateRange?.to && <PDFReportModalLayout path={props.path} from={dateRange.from?.toDateString()} to={dateRange.to?.toDateString()} onClick={onClickSubmit} />}
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

