import { Context } from '../..';
import * as base from '../base';
import { AssessmentsSearchRequest, BaseSearchRequest } from '../../../models/overmind/search-request';
import { SkillAssessmentsSearchResponse } from '../../../models/view/skill-assessments-search-response';
import { SkillAssessment } from '../../../models/view/skill-assessment';
import { SkillAssessmentAssigneeStatusResponse, SkillAssessmentConfiguration, LearnerSkillAssessment } from '../../../models/skill-assessment-configuration';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { UserAssessmentStatusList } from '../../../models/view/user-assessment-status-list';
import { SkillAssessmentAssignees } from '../../../models/view/skill-assessment-assignee';
import { QueueItemPriority, QueueItemType } from '../../../components';

export async function addSkillToFocusArea(context: Context, params: { benchmarkSlug: string; skillId: number; userId?: string; teamSlug?: string }): Promise<base.IResponse<any>> {
  const queryParams = new URLSearchParams();
  if (params.userId) queryParams.append('userId', params.userId);
  if (params.teamSlug) queryParams.append('teamSlug', params.teamSlug);

  const request: base.IRequest = {
    url: base.url(`benchmark`, `add-skill-to-focus-area`, params.benchmarkSlug, params.skillId.toString()) + (queryParams.toString() ? '?' + queryParams.toString() : ''),
    method: 'POST',
    authenticated: true,
  };

  return await base.request(request);
}

export async function completeEndorsementSkillAssignment(context: Context, params: { benchmarkSlug: string }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`benchmark`, `complete-endorsement-skill-assignment`, params.benchmarkSlug),
    method: 'POST',
    authenticated: true,
  };

  await base.request(request);
}

export async function getLearnerSkillAssessmentResultsReady(context: Context): Promise<SkillAssessmentConfiguration | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `get-learner-benchmark-results-ready`), authenticated: true };
  const response: base.IResponse<SkillAssessmentConfiguration> = await base.request(request);

  context.state.skillAssessmentConfiguration = response.data;

  return response.data;
}

export async function getChampionSkillAssessmentResultsReady(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.url(`benchmark`, `get-champion-benchmark-results-ready`), authenticated: true };
  const response: base.IResponse<SkillAssessmentConfiguration> = await base.request(request);

  context.state.skillAssessmentConfiguration = response.data;
}

export async function getSkillAssessmentConfiguration(context: Context): Promise<void> {
  // TODO: remove hardcoded 1
  const request: base.IRequest = { url: base.url(`benchmark`, `get-active-benchmark-configuration/1`), authenticated: true };
  const response: base.IResponse<SkillAssessmentConfiguration> = await base.request(request);

  context.state.skillAssessmentConfiguration = response.data;
}

export async function getActiveSkillAssessment(context: Context, companySlug: string): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `get-active-benchmark`, companySlug), authenticated: true };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function getSkillAssessments(context: Context, SearchRequest: AssessmentsSearchRequest): Promise<SkillAssessmentsSearchResponse | undefined> {
  if (SearchRequest.completionStatus === undefined) delete SearchRequest.completionStatus;
  const queryParams = new URLSearchParams(SearchRequest as any).toString();

  const request: base.IRequest = { url: base.url(`benchmark`, `get-benchmarks`, `${SearchRequest.companySlug}?${queryParams}`), authenticated: true };
  const response: base.IResponse<SkillAssessmentsSearchResponse> = await base.request(request);

  return response.data;
}

export async function getLearnerAssessments(context: Context, searchRequest: AssessmentsSearchRequest): Promise<LearnerSkillAssessment[] | undefined> {
  const queryParams = base.params(searchRequest);

  const requestURL = base.url(`Benchmark`, `get-learner-Assessments${queryParams}`);

  const response: base.IResponse<LearnerSkillAssessment[]> = await base.request({
    url: requestURL,
    method: 'GET',
    authenticated: true,
  });

  return response.data;
}

export async function updateSkillAssessment(context: Context, skillAssessment: SkillAssessment): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `update-benchmark`, skillAssessment.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(skillAssessment) };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function completeSkillAssessment(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `complete-active-benchmark`, params.companySlug, params.skillAssessmentSlug), authenticated: true, method: 'POST' };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function archiveAssessment(context: Context, params: { benchmarkSlug: string }): Promise<void> {
  const request: base.IRequest = { url: base.url(`benchmark`, `archive-assessment`, params.benchmarkSlug), method: 'POST', authenticated: true };
  await base.request(request);
}

export async function activateSkillAssessment(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `activate-benchmark`, params.companySlug, params.skillAssessmentSlug), authenticated: true, method: 'POST' };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function saveSkillAssessmentAssignees(context: Context, params: { benchmarkAssignee: SkillAssessmentAssignees; companySlug: string }): Promise<SkillAssessmentAssignees | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `save-benchmark-assignees`, params.companySlug, params.benchmarkAssignee.benchmarkSlug), authenticated: true, method: 'POST', body: JSON.stringify(params.benchmarkAssignee) };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);

  return response.data;
}

export async function resetBenchmarkAssessment(context: Context, params: { benchmarkSlug: string; userIds: string[] }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`benchmark`, `reset-assessment`, params.benchmarkSlug),
    authenticated: true,
    method: 'POST',
    body: JSON.stringify(params.userIds),
  };
  await base.request(request);
}

export async function getSkillAssessmentAssignees(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessmentAssignees | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `get-benchmark-assignees`, params.companySlug, params.skillAssessmentSlug), authenticated: true };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);

  return response.data;
}

export async function getUserStatusList(context: Context, props: { body: BaseSearchRequest; skillAssessmentSlug: string }): Promise<SkillAssessmentAssigneeStatusResponse | undefined> {
  const params = new URLSearchParams(Object.entries(props.body));

  const request: base.IRequest = { url: base.url(`benchmark`, `get-user-status-list`, `${props.skillAssessmentSlug}?${params.toString()}`), authenticated: true, method: 'GET' };
  const response: base.IResponse<SkillAssessmentAssigneeStatusResponse> = await base.request(request);

  return response.data;
}

export async function getSkillAssessment(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`benchmark`, `get-benchmark`, params.companySlug, params.skillAssessmentSlug), authenticated: true };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

// manager assessments focus panels
export async function getActiveSkillAssessmentList(context: Context, companySlug: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.url('benchmark', 'get-active-benchmark-list', companySlug), authenticated: true };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);

  return response.data;
}

export async function getUserSkillAssessmentStatusList(context: Context, benchmarkSlug: string): Promise<UserAssessmentStatusList | undefined> {
  const request: base.IRequest = { url: base.url('benchmark', 'get-user-status-list', `${benchmarkSlug}?pageSize=1000&page=1`), authenticated: true };
  const response: base.IResponse<UserAssessmentStatusList> = await base.request(request);

  return response.data;
}

export async function markSingleLearnerAsCompleteForSkillAssessment(context: Context, props: { benchmarkSlug: string; userId: string }): Promise<UserAssessmentStatusList | undefined> {
  const request: base.IRequest = { url: base.url('benchmark', 'complete-learner-assessment', props.benchmarkSlug, props.userId), authenticated: true, method: 'POST' };
  const response = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: `Couldn't mark learner as complete`,
      message: `We were unable to mark this learner as complete`,
      active: true,
      id: `${props.userId}_${Date.now()}`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
    });
  }

  return response.data;
}

export async function downloadSkillAssessmentAssigneeReportCSV(_: Context, benchmarkSlug: string): Promise<void> {
  const request: base.IRequest = { url: base.url('benchmark', 'download-benchmark-learners-list', benchmarkSlug), authenticated: true, method: 'POST' };
  const response = await base.request(request);
  const csvRaw = response.data as string;
  const blob = new Blob([csvRaw], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${benchmarkSlug}.csv`;
  setTimeout(() => anchor.click());
}

export async function removeSkillFromFocusArea(context: Context, params: { benchmarkSlug: string; skillId: number; userId?: string; teamSlug?: string }): Promise<void> {
  const queryParams = new URLSearchParams();
  if (params.userId) queryParams.append('userId', params.userId);
  if (params.teamSlug) queryParams.append('teamSlug', params.teamSlug);

  const request: base.IRequest = {
    url: base.url(`benchmark`, `remove-skill-from-focus-area`, params.benchmarkSlug, params.skillId.toString()) + (queryParams.toString() ? '?' + queryParams.toString() : ''),
    method: 'POST',
    authenticated: true,
  };

  await base.request(request);
}
