import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

const REDIRECT_TO = 'redirectTo';

export function useKeplerNavigate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function keplerNavigate(to: string, options?: NavigateOptions) {
    const redirectTo = searchParams.get(REDIRECT_TO);
    navigate(!!redirectTo ? `${to}?${REDIRECT_TO}=${redirectTo}` : to, options);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return keplerNavigate;
}
