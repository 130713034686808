import { Context } from '../..';
import { AssignEntityRequest, EntityResponse, EntityListResponse, CreateEntityRequest } from '../../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import * as base from '../base';

// get-skills START
/**
 * Search for Skills as a User with the User's Company.
 */
export async function getUserSkills(context: Context, payload: CompanyEntitySearchParams): Promise<EntityListResponse | undefined> {
  const url: string = base.url(`companyskill`, `get-skills${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Skills as a User with Permissions or a System Admin.
 */
export async function getSkills(context: Context, payload: CompanyEntitySearchParams): Promise<EntityListResponse | undefined> {
  const url: string = base.url(`companyskill`, `get-skills`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityListResponse> = await base.request(request);
  return response.data;
}
// get-skills END

export async function getCompanySkill(context: Context, payload: { companySlug: string; skillSlug: string }): Promise<EntityResponse | undefined> {
  const request: base.IRequest = { url: base.url(`companyskill`, `get-skill`, payload.companySlug, payload.skillSlug), authenticated: true };
  const response: base.IResponse<EntityResponse> = await base.request(request);
  return response.data;
}

export async function createCompanySkill(context: Context, payload: { companySlug: string; data: CreateEntityRequest }): Promise<string | undefined> {
  const request: base.IRequest = { url: base.url(`companyskill`, `create-skill`, payload.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(payload.data) };
  const response: base.IResponse<string> = await base.request(request);

  return response.data;
}

export async function assignSkill(context: Context, payload: { companySlug: string; data: AssignEntityRequest }): Promise<void> {
  const request: base.IRequest = { url: base.url(`companyskill`, `assign-skill`, payload.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(payload.data) };
  base.request(request);
}

export async function updateCompanySkill(context: Context, payload: { companySlug: string; skillSlug: string; data: CreateEntityRequest }): Promise<string | undefined> {
  const request: base.IRequest = { url: base.url(`companyskill`, `update-skill`, payload.companySlug, payload.skillSlug), authenticated: true, method: 'PUT', body: JSON.stringify(payload.data) };
  const response: base.IResponse<string> = await base.request(request);

  return response.data;
}
