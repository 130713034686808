import React, { useEffect, useState } from 'react';
import { additionalLearningTypeIcons } from '../additional-learning/additional-learning.models';
import { CompletionStatus } from '../../../enums';
import { ExtentAddressed } from '../../../enums/extent-addressed';
import { FetchType } from '../../../enums/fetch';
import { ReflectionFocusPanelHeaderLayout, ReflectionFocusPanelHeaderImageLayout } from '../skill/skill.styles';
import { ReflectionsFocusPanelFooter, ReflectionsForm } from './reflections.styles';
import { Reflection } from '../../../models/view/reflection';
import { useAppActions } from '../../../overmind';
import { useForm } from 'react-hook-form';
import { ReflectionFocusPanelSkeleton } from './reflection-focus-panel.skeleton';
import { PagePath } from '../../../navigation/navigation.enums';
import { FocusPanelLoaderLayer } from '../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { Anchor, Button, FormControl, IPillButton, ListItemLayout, ListLayout, PillButtonGroup, RadioButton, RequiredValidator, Textarea } from '@keplerco/core';

type CompetencyApplied = 'Yes' | 'No';

interface IFormValues {
  extentAddressed: ExtentAddressed | undefined;
  description: string | undefined;
  competencyApplied: CompetencyApplied | undefined;
}

export function ReflectionFocusPanelLayout(props: {
  reflectionId: number | undefined;
  path: PagePath.learningJourneyCourseReflections | PagePath.learningJourneyAdditionalLearning | PagePath.learningJourneySkill | PagePath.dashboard | PagePath.learningJourneyYearSkill | PagePath.learningJourneyAssignedSkill;
  onCancel?: () => void;
  onSubmit: () => void;
}): JSX.Element {
  const actions = useAppActions();

  const [reflection, setReflection] = useState<Reflection>();
  const [formValues, setFormValues] = useState<IFormValues>();

  const [pillButtons, setPillButtons] = useState<IPillButton[]>([
    { id: ExtentAddressed.NotAtAll, label: 'Not At All' },
    { id: ExtentAddressed.Somewhat, label: 'Somewhat' },
    { id: ExtentAddressed.Partially, label: 'Partially' },
    { id: ExtentAddressed.Completely, label: 'Completely' },
  ]);

  const { control, getValues, watch, setValue, handleSubmit, trigger } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    watch(() => {
      const values = getValues() as IFormValues;
      setFormValues(values);
    });

    if (!formValues) setFormValues(getValues() as any);
  }, []);

  useEffect(() => {
    async function getData() {
      if (!props.reflectionId) return;

      actions.startLoader({ path: props.path, type: FetchType.DialogFetching });

      const data = await actions.getReflection(props.reflectionId);
      setReflection(data);

      const hasBeenCompleted = data?.reflectionCompletionStatus === CompletionStatus.Completed;

      if (hasBeenCompleted) {
        const tempFormValues: IFormValues = {
          extentAddressed: data?.extentAddressed,
          description: data?.addressedDescription ?? '',
          competencyApplied: data?.competencyApplied ? 'Yes' : 'No',
        };

        setFormValues(tempFormValues);

        setValue('extentAddressed', tempFormValues.extentAddressed);
        setValue('description', tempFormValues.description);
        setValue('competencyApplied', tempFormValues.competencyApplied);

        setPillButtons(currentState => {
          const newState = structuredClone(currentState);
          newState.forEach((pillButton: IPillButton) => (pillButton.active = pillButton.id === tempFormValues.extentAddressed));
          return newState;
        });
      }

      actions.stopLoader(props.path);
    }

    getData();
  }, [props.reflectionId]);

  async function onSubmitHandler(value: any) {
    actions.startLoader({ path: props.path, type: FetchType.Sending });
    const temp = {
      ...reflection,
      extentAddressed: value.extentAddressed,
      addressedDescription: value.description,
      competencyApplied: value.competencyApplied === 'Yes' ? true : false,
    } as Reflection;

    await actions.saveReflection(temp);

    actions.stopLoader(props.path);
    props.onSubmit();
  }

  return (
    <FocusPanelLoaderLayer path={props.path} skeletonLoader={<ReflectionFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <ReflectionFocusPanelHeaderLayout className="dialogHeaderLayout">
          <h2 className="heading2">Reflect on your learning</h2>

          <ReflectionFocusPanelHeaderImageLayout>
            <img src={reflection?.lessonImage || additionalLearningTypeIcons.find(item => item.type === reflection?.offPlatformLearningType)?.panelUrl} alt="" draggable="false" />
          </ReflectionFocusPanelHeaderImageLayout>

          <p className="body2">{reflection?.lessonName}</p>
        </ReflectionFocusPanelHeaderLayout>

        <ReflectionsForm id="saveReflection" onSubmit={handleSubmit(value => onSubmitHandler(value))}>
          <div className="dialogBodyLayout">
            <h4 className="heading4" style={{ paddingBottom: 15 }}>
              How well did this activity address your learning needs?
            </h4>

            <FormControl
              paddingBottom={0}
              control={control}
              rules={new RequiredValidator('How well this activity addressed your needs')}
              name="extentAddressed"
              defaultValue={formValues?.extentAddressed?.toString()}
              render={() => {
                return (
                  <PillButtonGroup
                    buttons={pillButtons}
                    backgroundColour="link-text"
                    activeTextColour="background"
                    onClick={buttons => {
                      setValue('extentAddressed', buttons[0].id);
                      trigger('extentAddressed');
                    }}
                  />
                );
              }}
            />

            <h4 className="heading4" style={{ paddingTop: 30, paddingBottom: 15 }}>
              Provide a brief explanation for your rating?
            </h4>

            <FormControl
              paddingBottom={0}
              control={control}
              rules={new RequiredValidator('Enter a description')}
              name="description"
              defaultValue={formValues?.description}
              render={({ field, fieldState }) => {
                return <Textarea {...field} haserror={!!fieldState.error} label="Description" value={formValues?.description} responsive />;
              }}
            />

            <h4 className="heading4" style={{ paddingTop: 30, paddingBottom: 15 }}>
              Have you been able to apply what you learned in your work?
            </h4>

            <FormControl
              name="competencyApplied"
              control={control}
              rules={new RequiredValidator('Have you applied this learning?')}
              paddingBottom={0}
              render={({ field }) => {
                return (
                  <ListLayout wrap gap={20}>
                    <ListItemLayout>
                      <RadioButton {...field} id="Yes" value="Yes" clickablearea="label" checked={formValues?.competencyApplied === 'Yes'}>
                        Yes
                      </RadioButton>
                    </ListItemLayout>

                    <ListItemLayout>
                      <RadioButton {...field} id="No" value="No" clickablearea="label" checked={formValues?.competencyApplied === 'No'}>
                        No
                      </RadioButton>
                    </ListItemLayout>
                  </ListLayout>
                );
              }}
            />
          </div>

          <ReflectionsFocusPanelFooter className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
            <Button type="button">Save reflection</Button>

            {!!props.onCancel && <Anchor onClick={props.onCancel}>Cancel</Anchor>}
          </ReflectionsFocusPanelFooter>
        </ReflectionsForm>
      </div>
    </FocusPanelLoaderLayer>
  );
}

