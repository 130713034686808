import { Anchor, AvatarIcon } from '@keplerco/core';
import styled from 'styled-components';

export const EndorsementSelectionWidgetWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const EndorsementSelectionWidgetSelectedDropdownItem = styled.div`
  margin-left: 10px;
  font-size: 16px;
`;

export const EndorsementSelectionDropdownSpacer = styled.div`
  height: 30px;
  width: 5px;
`;

export const EndorsementWidgetHeader = styled.header`
  display: block;
  width: 100%;
  margin-bottom: 15px;

  .heading5 {
    margin-bottom: 15px;
    color: var(--accent-3);
  }

  .subtitle {
    margin-bottom: 15px;
    font-size: 14px;
    color: var(--accent-3);
  }
`;

export const EndorsementWidgetsSubmitButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  flex: 1;
`;

export const StyledAvatarIcon = styled(AvatarIcon)`
  width: 25px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 25px;
  overflow: hidden;
`;

export const EndorsementWidgetListIconAvatar = styled.div`
  width: 25px;
  margin-right: 5px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 30px;
  overflow: hidden;
`;

export const EndorsementWidgetSelectedDropdownCard = styled.div`
  background-color: var(--cards_2);
`;

export const EndorsementWidgetCardListItem = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  justify-content: flex-start;
`;

export const EndorsementWidgetCardListItemBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const EndorsementWidgetListItemLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const EndorsementWidgetCardListItemIcon = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const EndorsementWidgetSpacer = styled.div`
  flex: 1;
`;

export const EndorsementWidgetLabel = styled.h5`
  color: var(--accent-3);
`;

export const AutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export const EndorsementWidgetSubmitButton = styled(Anchor)`
  align-self: flex-end;
`;

export const CustomStyledDropdownList = styled.div`
  border-radius: 10px;
  overflow-y: auto;
  max-height: 200px;
`;

export const CustomStyledDropdownListItem = styled.div<{ isSelected: boolean }>`
  padding: 10px;
  background-color: ${props => (props.isSelected ? 'var(--background)' : 'var(--background)')};
  color: var(--text);
  cursor: pointer;

  &:hover {
    background-color: var(--cards);
  }
`;

export const CustomStyledDropdownListItemCheck = styled.div``;

export const CustomStyledDropdownListItemLabel = styled.div`
  font-size: 16px;
`;

export const EntityAssignmentWidgetListIconAvatar = styled.div`
  width: 25px;
  margin-right: 10px;
  margin-left: 5px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 30px;
  overflow: hidden;
`;

