import { AuthenticationStatus } from '../enums/authentication-status';
import { SetPasswordStatus } from '../enums/set-password-status';
import { KeplerState } from '../models/kepler-state';
import { derived } from 'overmind';
import { DaterangeDateOptionTypes } from '../components';
import { derivedDateRange } from './derived-props/date-range';
import { derivedMayViewSideNav } from './derived-props/may-view-side-nav';
import { FetchStatus, SyncStatus } from '../enums';
import { FetchStateItem } from '../models/fetch-state';
import { PagePath } from '../navigation/navigation.enums';
import { Mode } from '../navigation/navigation.enums';
import { defaultCompanyVariables } from '../models/view/company-variables';
import { LearningJourneyViewType } from '../enums/learning-journey-view-type';

export function generateInitialFetchState() {
  return Object.values(PagePath).reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: { status: FetchStatus.Inactive } };
  }, {} as { [path in PagePath]: FetchStateItem });
}

export const state: KeplerState = {
  // The below three values control the SideNav in the UI:
  // - `mayViewSideNav` determines if the SideNav may be viewed
  // - `sideNavVisible` determines if the SideNav is visible
  // - `sideNavCollapsed` determines if the SideNav is collapsed or expanded
  mayViewSideNav: derived(derivedMayViewSideNav),
  sideNavVisible: true,
  sideNavCollapsed: false,

  // The below three values control the authentication
  // of the application. `authenticationStatus` is an enum
  // that will let the application know what the status
  // of the user is (Unknown, AuthenticationFailed, Authenticated,
  // and UnAuthenticated) respectively. `isRunningAuthentication`
  // is used to ensure that we don't run double authentication,
  // which could cause odd token errors. `setPasswordStatus`
  // is an additional layer on top of the authentication status
  // that determines the users' password status.
  authenticationStatus: AuthenticationStatus.Unknown,
  isRunningAuthentication: false,
  setPasswordStatus: SetPasswordStatus.Unknown,

  // The below indicators keep tracking of operations
  // in the application that require any sort of loader
  // indication
  courseSyncStatus: SyncStatus.NotRun,
  lastSynced: undefined,
  fetchState: generateInitialFetchState(),
  isInitialising: true,

  // The below values are used to keep track of state
  // that is global to the application.
  queue: [],
  keplerPoints: 0,
  assignedCourses: [],
  assessmentYearCourses: [],
  dateRangeOutput: {
    type: DaterangeDateOptionTypes.UpToToday,
  },
  dateRange: derived(derivedDateRange),
  user: void 0,
  settings: void 0,
  userPreferences: { toggleJourneyList: false },

  skillAssessmentConfiguration: void 0,

  allowAccountCreation: false,
  permissions: undefined,
  mode: Mode.LearningDashboard,
  companyVariables: defaultCompanyVariables,
  learningJourneyView: LearningJourneyViewType.List,
};
