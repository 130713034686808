import { IMultiselectCategories, IMultiselectCategory } from '../../../components/multiselect/multiselect.models';

export function filterCategory(initialCategories: IMultiselectCategories, previousCategories: IMultiselectCategories, parentKey: string, childKey: string): IMultiselectCategory {
  if (previousCategories[parentKey].selectAll || previousCategories[parentKey].disabled) {
    const nextChildCategory: IMultiselectCategory = structuredClone({ ...previousCategories[childKey], selectAll: false, query: undefined, disabled: true });
    return nextChildCategory;
  }

  const activeParentItems = previousCategories[parentKey].items.filter(item => item.active);

  if (activeParentItems.length === 0) {
    const nextChildCategory: IMultiselectCategory = structuredClone({ ...previousCategories[childKey], selectAll: false, query: undefined, disabled: true });
    return nextChildCategory;
  }

  const nextChildCategory: IMultiselectCategory = structuredClone({ ...previousCategories[childKey], items: [], disabled: false });

  for (const activeParentItem of activeParentItems) {
    for (const initialChildItem of initialCategories[childKey].items) {
      if (initialChildItem?.parentSlug === activeParentItem.slug) {
        const previousChildItem = previousCategories[childKey].items.find(item => item.slug === initialChildItem.slug);
        nextChildCategory.items.push(previousChildItem ?? initialChildItem);
      }
    }
  }

  return nextChildCategory;
}

