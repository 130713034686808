import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { FetchStatus, FetchType, SyncStatus } from '../../../enums';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { LearnerCertificateSearchResponse } from '../../../models/view/learner-certificate-sesearch-response';
import { useAppActions, useAppState } from '../../../overmind';
import { PageFilterHeader, PageFilterHeaderColumn } from '../../../theme/layout.styles';
import { CertificatesEmptyState, CertificatesPageCardInner, CertificatesPageCards, CertificatesPageLayout } from './certificates.styles';
import { CertificatesSkeleton } from './certificates.skeleton';
import { useDataSyncReaction } from '../../../lib/data-sync.hooks';
import { SyncContentCard, SyncContentCardLayoutWrapper } from '../../../components';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Searchfield, colourString, kebabCase, PageHeader } from '@keplerco/core';

export function CertificatesPage(): JSX.Element {
  const navigate = useNavigate();

  const { fetchState, lastSynced, courseSyncStatus } = useAppState();
  const actions = useAppActions();

  const [response, setResponse] = useState<LearnerCertificateSearchResponse>();

  async function getData(query: string) {
    const result = await actions.getLearnerCertificateList({ page: 1, pageSize: 9999, sortAscending: true, search: query });
    setResponse(result);

    actions.stopLoader(PagePath.learningJourneyCertificates);
  }

  useDataSyncReaction(async () => {
    getData('');
  });

  useEffect(() => {
    actions.startLoader({ path: PagePath.learningJourneyCertificates, type: FetchType.PageFetching });
    getData('');
  }, []);

  return (
    <PageLoaderLayer path={PagePath.learningJourneyCertificates} skeletonLoader={<CertificatesSkeleton />}>
      <CertificatesPageLayout className="wrapper">
        <PageHeader>
          <PageHeader
            breadcrumbs={[
              {
                name: 'Learning Journey',
                url: PagePath.learningJourneyBase,
              },
              {
                name: 'Certificates',
                url: window.location.pathname,
              },
            ]}
            title={'Download Certificates'}
          />

          <SyncContentCardLayoutWrapper>
            <SyncContentCard
              isSyncing={courseSyncStatus === SyncStatus.Syncing}
              variant="large"
              lastSyncDate={lastSynced}
              onClick={() => {
                actions.syncCourseContent().then(() => {
                  actions.getTotalLearnerKeplerPoints();
                });
              }}
            />
          </SyncContentCardLayoutWrapper>
        </PageHeader>

        <React.Fragment>
          <PageFilterHeader>
            <PageFilterHeaderColumn>
              <Searchfield
                loading={fetchState[PagePath.learningJourneyCertificates].status === FetchStatus.Active && fetchState[PagePath.learningJourneyCertificates].type === FetchType.Custom}
                onInput={query => {
                  actions.startLoader({ path: PagePath.learningJourneyCertificates, type: FetchType.Custom });
                  getData(query);
                }}
              />
            </PageFilterHeaderColumn>

            <PageFilterHeaderColumn />
          </PageFilterHeader>

          {!!response?.learnerCertificatesListView?.length ? (
            <CertificatesPageCards>
              {response.learnerCertificatesListView.map(card => {
                return (
                  <div className="card arrow" key={card.name}>
                    <CertificatesPageCardInner>
                      <span className="caption1 title">
                        <b>{card.name}</b>
                      </span>

                      <span className="caption1 date" style={{ color: colourString('accent-2') }}>
                        {new Date(card.dateCompleted).toDateString()}
                      </span>

                      <Anchor href={card.url} block target={'_blank'} rel="noopener noreferrer" download={`${kebabCase(card.name)}.pdf`}>
                        Download
                      </Anchor>
                    </CertificatesPageCardInner>
                  </div>
                );
              })}
            </CertificatesPageCards>
          ) : (
            <CertificatesEmptyState>
              <EmptyState
                badgeIconWidth={450}
                badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')}
                title="You don't have any certificates yet"
                subtitle="Certificates are awarded when you complete lessons in your learning journey"
                buttons={[
                  {
                    text: 'View Learning Journey',
                    onClick: () => navigate(PagePath.learningJourneyBase),
                  },
                ]}
              />
            </CertificatesEmptyState>
          )}
        </React.Fragment>
      </CertificatesPageLayout>
    </PageLoaderLayer>
  );
}

