import { ReducerAction } from '@keplerco/core';
import { BaseSearchRequest } from '../../../../../models';
import { SortField } from '../../../../../enums';
import { EntityAssigneeListItemResponse, EntityAssigneeListResponse } from '../../../../../models/overmind/entities';

export interface ViewRoleAssignedPeopleState {
  request: BaseSearchRequest;
  openConfirmationModal?: boolean;

  pageData?: EntityAssigneeListResponse;
  hasPopulated: boolean;
  showPersonToManage: boolean;
  personToRemove?: EntityAssigneeListItemResponse;
}

export enum ViewRoleAssignedPeopleActionTypes {
  SetData,
  SetPersonToManage,
  SetPersonToRemove,
  SetRequest,
}

type ViewRoleAssignedPeopleActions =
  | ReducerAction<ViewRoleAssignedPeopleActionTypes.SetPersonToManage, { payload: { open: boolean } }>
  | ReducerAction<ViewRoleAssignedPeopleActionTypes.SetRequest, { payload: BaseSearchRequest }>
  | ReducerAction<ViewRoleAssignedPeopleActionTypes.SetPersonToRemove, { payload: { openConfirmationModal: boolean; person?: any } }>
  | ReducerAction<ViewRoleAssignedPeopleActionTypes.SetData, { payload: EntityAssigneeListResponse | undefined }>;

export const initialState: ViewRoleAssignedPeopleState = {
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 9, page: 1, search: '' },
  hasPopulated: false,
  showPersonToManage: false,
};

export function reducer(state: ViewRoleAssignedPeopleState, action: ViewRoleAssignedPeopleActions) {
  if (action.type === ViewRoleAssignedPeopleActionTypes.SetData) {
    return { ...state, pageData: action.payload, hasPopulated: true };
  }

  if (action.type === ViewRoleAssignedPeopleActionTypes.SetPersonToManage) {
    return { ...state, showPersonToManage: action.payload.open };
  }

  if (action.type === ViewRoleAssignedPeopleActionTypes.SetPersonToRemove) {
    return { ...state, openConfirmationModal: action.payload.openConfirmationModal, personToRemove: action.payload.person };
  }

  if (action.type === ViewRoleAssignedPeopleActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  return state;
}
