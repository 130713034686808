import React, { useEffect, useReducer } from 'react';
import { FetchStatus, FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { useParams } from 'react-router-dom';
import TagArchiveModal from './archive-tag-modal/archive-tag-modal.layout';
import * as store from './tag-management.cms.store';
import { BaseSearchRequest } from '../../../models';
import { TagManagementCMSSkeleton } from './tag-management.cms.skeleton';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { ColumnConfiguratorWidget } from '../../../widgets/layouts';
import { ArchiveTagParams, GetAssigneesParams, TagList } from '../../../models/tag';
import { PagePath } from '../../../navigation/navigation.enums';
import { UpdateTagFocusPanelLayout } from './update-tag-focus-panel/update-tag-focus-panel.layout';
import { CreateTagFocusPanelLayout } from './create-tag-focus-panel/create-tag-focus-panel.layout';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { CMSColumn, CMSHeaderRow, CMSRow } from '../../../theme/layout.styles';
import { AddTwoToneIcon, Button, Chip, FocusPanel, KebabMenu, MenuItem, Modal, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow, colourString } from '@keplerco/core';

export function TagManagementCMSPage(): JSX.Element {
  const params = useParams<any>();

  const { fetchState } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  async function updateData(request: BaseSearchRequest) {
    const res = await actions.searchTags(request);
    dispatch({ type: store.TagCMSActionTypes.SetData, payload: res });
    actions.stopLoader(PagePath.administrationTags);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.administrationTags, type: FetchType.PageFetching });

      const company = await actions.getCompany(params.companySlug!);
      dispatch({ type: store.TagCMSActionTypes.SetHeader, payload: { entityName: company?.companyName, entitySlug: params.companySlug! } });

      const request: BaseSearchRequest = { ...state.request, companySlug: params.companySlug };
      dispatch({ type: store.TagCMSActionTypes.SetRequest, payload: request });

      updateData(request);
    }

    initPage();
  }, []);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Custom });
    dispatch({ type: store.TagCMSActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  async function onPageChangeHandler(page: number) {
    if (page === state.request?.page) return;

    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.PageFetching });
    dispatch({ type: store.TagCMSActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  // kebab menu
  const kebabMenuItems: MenuItem<string>[] = [
    {
      text: 'Archive',
      onClick: tagName => onClickArchiveTagHandler(tagName),
    },
    {
      text: 'Manage Tag',
      onClick: tagName => onClickManageTagHandler(tagName),
    },
  ];

  async function onClickArchiveTagHandler(tagName: string | undefined) {
    if (!tagName) return;

    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Sending });

    const getAssigneeParams: GetAssigneesParams = {
      companySlug: params.companySlug!,
      tagName: tagName,
    };
    const assignees = await actions.getAssignees(getAssigneeParams);
    dispatch({ type: store.TagCMSActionTypes.SetTagToArchive, payload: { tagName, assignees } });

    actions.stopLoader(PagePath.administrationTags);
  }

  async function onClickManageTagHandler(tagName: string | undefined) {
    if (!tagName) return;

    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.DialogFetching });

    const getAssigneeParams: GetAssigneesParams = {
      companySlug: params.companySlug!,
      tagName: tagName,
    };
    const assignees = await actions.getAssignees(getAssigneeParams);
    dispatch({ type: store.TagCMSActionTypes.SetTagToUpdate, payload: { tagName, assignees } });

    actions.stopLoader(PagePath.administrationTags);
  }

  // modals
  async function onArchiveHandler() {
    if (!state.tagToArchiveName || !state.tagToArchiveName || !params.companySlug) return;

    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Sending });

    const archiveTagParams: ArchiveTagParams = {
      companySlug: params.companySlug,
      tagName: state.tagToArchiveName,
    };

    dispatch({ type: store.TagCMSActionTypes.SetTagToArchive, payload: { tagName: undefined, assignees: undefined } });

    await actions.archiveTag(archiveTagParams);

    actions.stopLoader(PagePath.administrationTags);
  }

  // focus panels
  function onClickCreateTagHandler() {
    dispatch({ type: store.TagCMSActionTypes.SetOpenCreateTagPanel, payload: true });
  }

  async function onCancelCreateTag() {
    dispatch({ type: store.TagCMSActionTypes.SetOpenCreateTagPanel, payload: false });
  }

  async function onCloseUpdateTag() {
    dispatch({ type: store.TagCMSActionTypes.SetTagToUpdate, payload: { tagName: undefined, assignees: undefined } });
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.TagCMSActionTypes.SetManageColumns, payload: { open: false, reset: false } });
  }

  function onClickResetManagingColumns() {
    dispatch({ type: store.TagCMSActionTypes.SetManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: store.TagCMSActionTypes.SetColumnConfiguration, payload: selection });
    dispatch({ type: store.TagCMSActionTypes.SetManageColumns, payload: { open: false } });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.administrationTags} skeletonLoader={<TagManagementCMSSkeleton />}>
        <div className="wrapper stack">
          <CMSRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <CMSColumn>
              <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle}>
                <h5 className="heading5" style={{ display: 'flex', marginTop: 5, color: colourString('accent-2') }}>
                  {state.data?.totalCount ?? 0} tags in total
                </h5>
              </PageHeader>
            </CMSColumn>
          </CMSRow>

          <CMSHeaderRow>
            <CMSColumn>
              <Searchfield loading={fetchState[PagePath.administrationTags].status === FetchStatus.Active && fetchState[PagePath.administrationTags].type === FetchType.Custom} onInput={onInputHandler} />
            </CMSColumn>

            <CMSColumn style={{ marginLeft: 'auto' }}>
              <Button type="button" square theme="dark" onClick={onClickCreateTagHandler}>
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <AddTwoToneIcon />
                  Create Tag
                </div>
              </Button>
            </CMSColumn>
          </CMSHeaderRow>

          <CMSRow>
            <CMSColumn style={{ width: '100%' }}>
              <Table
                currentSortBy={String(state.request?.sortField)}
                currentSortDirection={state.request?.sortAscending ? 'Ascending' : 'Descending'}
                emptyState={<EmptyState title="There are no tags yet" buttons={[{ text: 'Create One', onClick: onClickCreateTagHandler }]} />}
                onConfigClicked={() => dispatch({ type: store.TagCMSActionTypes.SetManageColumns, payload: { open: true } })}
                footerContent={
                  <PagerNavigationConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.request.page ?? 1} pageCount={state.data?.totalPages ?? 1}>
                    {connector => {
                      return <Pager {...connector} />;
                    }}
                  </PagerNavigationConnector>
                }
              >
                {state.data?.tags.map((tag: TagList, index: number) => (
                  <TableRow
                    configCell={() => {
                      return <KebabMenu data={tag.tagName} items={kebabMenuItems} />;
                    }}
                    key={index}
                    id={index}
                  >
                    <TableColumn id="name" label="Tag Name">
                      {tag.tagName}
                    </TableColumn>

                    <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'Status')?.selected} id="isUsed" label="Status">
                      {tag.isTagUsed ? <Chip label="Used" variant="tiny" backgroundColour="apple" /> : <Chip label="Not Used" variant="tiny" />}
                    </TableColumn>

                    <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'dateCreated')?.selected} id="dateCreated" label="Date Created">
                      {!!tag.dateCreated && new Date(tag.dateCreated).toLocaleString('en-UK', { dateStyle: 'long' })}
                    </TableColumn>
                  </TableRow>
                ))}
              </Table>
            </CMSColumn>
          </CMSRow>
        </div>
      </PageLoaderLayer>

      <FocusPanel open={state.openCreateTagPanel} onClose={onCancelCreateTag}>
        {state.openCreateTagPanel && (
          <CreateTagFocusPanelLayout
            onCancel={onCancelCreateTag}
            onSubmit={async () => {
              actions.startLoader({ path: PagePath.administrationTags, type: FetchType.PageFetching });
              await updateData(state?.request);
              dispatch({ type: store.TagCMSActionTypes.SetOpenCreateTagPanel, payload: false });
            }}
          />
        )}
      </FocusPanel>

      <FocusPanel open={!!state.tagToUpdateName && !!state.assignees} onClose={onCloseUpdateTag}>
        {!!state.tagToUpdateName && !!state.assignees && (
          <UpdateTagFocusPanelLayout
            tagName={state.tagToUpdateName}
            assignees={state.assignees}
            onBack={onCloseUpdateTag}
            onDone={async () => {
              actions.startLoader({ path: PagePath.administrationTags, type: FetchType.PageFetching });
              dispatch({ type: store.TagCMSActionTypes.SetTagToUpdate, payload: { tagName: undefined, assignees: undefined } });
              await updateData(state.request);
              actions.stopLoader(PagePath.administrationTags);
            }}
          />
        )}
      </FocusPanel>

      <FocusPanel open={state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget columns={state.columnConfiguration} onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} />
      </FocusPanel>

      <Modal open={!!state.tagToArchiveName && !!state.assignees} onClose={() => dispatch({ type: store.TagCMSActionTypes.SetTagToArchive, payload: { tagName: undefined, assignees: undefined } })}>
        {!!state.tagToArchiveName && !!state.assignees && <TagArchiveModal tagName={state.tagToArchiveName} assignees={state.assignees} onArchive={onArchiveHandler} />}
      </Modal>
    </React.Fragment>
  );
}

