import styled from 'styled-components';

export const MultiselectTabs = styled.div`
  padding-bottom: 15px;
`;

export const MultiselectActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--borders);
  gap: 10px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const MultiselectContent = styled.div`
  margin-top: 15px;
  padding: 0px 15px;
  height: 200px;
  overflow-y: scroll;
`;
