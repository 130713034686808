import React from 'react';
import { QueueItemPriority, QueueItemType } from './notification-hub.models';
import { useAppState } from '../../overmind';
import { ModalHub } from './modal-hub/modal-hub';
import { TileHub } from './tile-hub/tile-hub';
import { ToastHub } from './toast-hub/toast-hub';
import { NotificationHubContainer } from './notification-hub.styles';

export function NotificationHub(): JSX.Element {
  const { queue } = useAppState();

  const allActive = queue.filter(q => q.active);

  const allowedTileTypes = [QueueItemType.Activity, QueueItemType.LoginStreak];

  const tiles = allActive.filter(q => q.priority === QueueItemPriority.Tile || typeof q.priority === 'undefined').filter(q => allowedTileTypes.includes(q.type));
  const toasts = allActive.filter(q => q.priority === QueueItemPriority.Toast);
  const modal = allActive.find(q => q.priority === QueueItemPriority.Modal);

  return (
    <NotificationHubContainer>
      {!!tiles.length && (
        <TileHub tiles={tiles} />
      )}

      {!!toasts.length && (
        <ToastHub toasts={toasts} />
      )}

      {!!modal && (
        <ModalHub {...modal} />
      )}
    </NotificationHubContainer>
  );
}

