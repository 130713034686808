import React, { useEffect, useState } from 'react';
import { EntityLayout } from './entity.layout';
import { useParams } from 'react-router';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';

export function DepartmentEntityPage(): JSX.Element {
  const { departmentSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [departmentName, setDepartmentName] = useState<string>('Department');

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analyticsDepartment, type: FetchType.PageFetching });

      const temp = await actions.getDepartment({ companySlug: companyVariables.slug!, departmentSlug: departmentSlug! });
      setDepartmentName(temp?.name ?? 'Department');
      actions.stopLoader(PagePath.analyticsDepartment);
    }

    getData();
  }, [departmentSlug]);

  return <EntityLayout path={PagePath.analyticsDepartment} parentPath={PagePath.analyticsDepartments} slug={departmentSlug!} name={departmentName} type="department" />;
}
