import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SelectSkillDualSelectWrapper, SelectSkillErrorMessageWrapper, SelectSkillWidgetSubmitButton, SelectSkillWidgetSubmitButtonWrapper, SelectSkillsWidgetBody, SelectSkillsWidgetWrapper } from './select-skills.styles';
import { useAppActions, useAppState } from '../../../../overmind';
import { FetchStatus, FetchType, OrganizationLevelType, SortField } from '../../../../enums';
import { Assignees, SkillAssessmentAssignees } from '../../../../models/view/skill-assessment-assignee';
import { useParams } from 'react-router-dom';
import { LearnerSkillResource } from '../../../../models/learner-skill-resource';
import { WidgetSkeleton } from '../widget.skeleton';
import { KeplerState } from '../../../../models/kepler-state';
import { PagePath } from '../../../../navigation/navigation.enums';
import { QueueItemPriority, QueueItemType } from '../../../../components';
import { Button, Collapsible, CollapsibleStep, DualSelect } from '@keplerco/core';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../lib/permissions.helpers';
import { EntityListResponse } from '../../../../models/overmind/entities';

interface DualListSelectItem {
  id: number;
  name: string;
}

interface ISelectSkillsWidgetProps {
  assessmentId?: string;
  onStepComplete: () => void;
  selectedAssigneeSkills: SkillAssessmentAssignees | null;
  isDraft: boolean;
  setHasUserWithNoSkills: Dispatch<SetStateAction<boolean>>;
}

export function SelectSkillsWidget({ setHasUserWithNoSkills, isDraft, onStepComplete, assessmentId, selectedAssigneeSkills }: ISelectSkillsWidgetProps): JSX.Element {
  const { fetchState } = useAppState<KeplerState>();

  return fetchState[PagePath.assessmentsCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentsCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <SelectSkillsSwitcher setHasUserWithNoSkills={setHasUserWithNoSkills} isDraft={isDraft} selectedAssigneeSkills={selectedAssigneeSkills} onStepComplete={onStepComplete} assessmentId={assessmentId!} />
  );
}

interface ISelectSkillsSwitcher {
  assessmentId: string;
  onStepComplete: () => void;
  isDraft: boolean;
  selectedAssigneeSkills: SkillAssessmentAssignees | null;
  setHasUserWithNoSkills: Dispatch<SetStateAction<boolean>>;
}

function SelectSkillsSwitcher({ setHasUserWithNoSkills, isDraft, assessmentId, onStepComplete, selectedAssigneeSkills }: ISelectSkillsSwitcher): JSX.Element {
  const actions = useAppActions();
  const params = useParams();
  const { companyVariables } = useAppState();
  const [focusArea, setFocusArea] = useState<SkillAssessmentAssignees | undefined>();
  const [changesMade, setChangesMade] = useState(false);
  const [errorIds, setErrorIds] = useState<string[]>([]);
  const [selectedItemsFromApi, setSelectedItemsFromApi] = useState<Record<string, any[]>>({});

  const convertToDualSelectItems = (skills: LearnerSkillResource[]): DualListSelectItem[] => {
    return skills?.map(skill => ({
      id: skill.companySkillId,
      name: skill.skillName,
    }));
  };

  async function getData(): Promise<Assignees[] | undefined> {
    const areas = await actions.getSkillAssessmentAssignees({ companySlug: companyVariables.slug!, skillAssessmentSlug: params.assessmentSlug ?? assessmentId });
    setFocusArea(areas);

    const newSelectedItems: Record<string, any[]> = {};

    areas?.assignees?.forEach(assignee => {
      newSelectedItems[assignee.userId] = convertToDualSelectItems(assignee.skills);
    });

    setSelectedItemsFromApi(newSelectedItems);

    return areas?.assignees;
  }

  useEffect(() => {
    if (!focusArea?.assignedTeamChampionId) getData();
  }, [focusArea?.assignedTeamChampionId === null]);

  async function runErrorCheck() {
    const assignees = await getData();

    let hasError = false;
    const ids = new Set(errorIds);

    if (!!assignees) {
      for (const assignee of assignees) {
        if (assignee.skills === null || assignee.skills.length < 1) {
          hasError = true;
          ids.add(assignee.userId);
        } else ids.delete(assignee.userId);
      }
    }

    setErrorIds(Array.from(ids));
    return hasError;
  }

  async function handleFinalize() {
    if (!!selectedAssigneeSkills?.assignees) {
      const hasError = await runErrorCheck();

      if (hasError) return void 0;

      if (params.assessmentSlug) {
        return void 0;
      } else {
        onStepComplete();
      }
    }
  }

  return (
    <SelectSkillsWidgetWrapper>
      {!!focusArea && (
        <SelectSkillsWidgetBody>
          <SelectSkillsIndividual
            setHasUserWithNoSkills={setHasUserWithNoSkills}
            selectedItems={selectedItemsFromApi}
            errorIds={errorIds}
            onSave={sections => {
              for (const [id, data] of Object.entries(sections)) {
                if (!!data && errorIds.includes(id)) {
                  setErrorIds([...errorIds.filter(i => i !== id)]);
                }
              }
            }}
            setChangesMade={setChangesMade}
            assessmentId={assessmentId}
            assignees={selectedAssigneeSkills?.assignees ?? focusArea.assignees!}
          />

          <SelectSkillWidgetSubmitButtonWrapper>
            {changesMade && isDraft && (
              <SelectSkillWidgetSubmitButton arrow onClick={handleFinalize}>
                Update
              </SelectSkillWidgetSubmitButton>
            )}

            {!isDraft && (
              <SelectSkillWidgetSubmitButton arrow onClick={handleFinalize}>
                Next
              </SelectSkillWidgetSubmitButton>
            )}
          </SelectSkillWidgetSubmitButtonWrapper>
        </SelectSkillsWidgetBody>
      )}
    </SelectSkillsWidgetWrapper>
  );
}

interface ISelectSkillsIndividualProps {
  assignees: Assignees[];
  assessmentId: string;
  onSave: (selectedItems: Record<string, any[]>) => void;
  errorIds: string[];
  setChangesMade: (changesMade: boolean) => void;
  selectedItems: Record<string, LearnerSkillResource[]>;
  setHasUserWithNoSkills: Dispatch<SetStateAction<boolean>>;
}

function SelectSkillsIndividual(props: ISelectSkillsIndividualProps): JSX.Element {
  const actions = useAppActions();
  const { permissions, companyVariables } = useAppState();
  const [selectedItems, setSelectedItems] = useState<Record<string, any[]>>(props.selectedItems);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  const [skillSearchResponses, setSkillSearchResponses] = useState<Record<string, EntityListResponse | undefined>>({});
  const [editingStates, setEditingStates] = useState<Record<string, boolean>>({});
  const [stagedRemovals, setStagedRemovals] = useState<any[]>([]);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const params = useParams();

  const [initialSkills, setInitialSkills] = useState<Record<string, any[]>>({});

  useEffect(() => {
    const initialEditingState: Record<string, boolean> = {};

    props.assignees.forEach(assignee => {
      if (assignee.userId) {
        initialEditingState[assignee.userId] = false;
      }
    });

    setEditingStates(initialEditingState);
  }, []);

  useEffect(() => {
    async function fetchInitialSkills() {
      for (const assignee of props.assignees) {
        if (assignee.userId) {
          const personData = await actions.getPerson(assignee.userId);

          if (personData) {
            const skills = personData.skills.map(skill => ({
              id: skill.id,
              name: skill.name,
            }));

            setInitialSkills(prev => ({ ...prev, [assignee.userId]: skills }));
          }
        }
      }
    }

    fetchInitialSkills();
  }, [props.assignees, actions]);

  useEffect(() => {
    checkSkillsSelection();
  }, [selectedItems]);

  const onInputHandler = useCallback(async (value: string, userId: string) => {
    // const response = await actions.searchAllSkills({ skillName: value, page: 1 });
    const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
    const request: CompanyEntitySearchParams = {
      search: value,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 99999,
      page: 1,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: false, // TODO: check if this should be true or false
    };
    const response = await actions.getSkills(request);
    setSkillSearchResponses(prev => ({ ...prev, [userId]: response }));
  }, []);

  const getSkillsForUser = (userId: string) => {
    if (!skillSearchResponses[userId]) {
      return (
        initialSkills[userId]?.map(skill => ({
          id: skill.id,
          name: skill.name,
        })) || []
      );
    }

    const currentSearchResponse = skillSearchResponses[userId];

    const searchedSkills: DualListSelectItem[] = currentSearchResponse?.entities.map(entity => ({ id: entity.companySkillId!, name: entity.name, })) || [];

    return [...searchedSkills];
  };

  function toggleEditing(userId: string) {
    setSkillSearchResponses(prev => ({ ...prev, [userId]: undefined }));

    setEditingStates(prevEditingStates => ({
      ...prevEditingStates,
      [userId]: !prevEditingStates[userId],
    }));
  }

  function handleSelectionChange(userId: string, newSelectedItems: any[]) {
    const newSelectedItemsState = {
      ...selectedItems,
      [userId]: newSelectedItems,
    };

    setSelectedItems(newSelectedItemsState);
    props.setChangesMade(true);
    checkSkillsSelection();
  }

  function checkSkillsSelection() {
    const hasUserWithNoSkills = Object.values(selectedItems).some(items => items && items.length === 0);
    props.setHasUserWithNoSkills(hasUserWithNoSkills);
  }

  async function handleSaveSkills(userId: string, selectedSkills: any[]) {
    setLoadingIds([...loadingIds, userId]);

    // FIXME:
    for (const skill of selectedSkills) {
      const response = await actions.addSkillToFocusArea({ benchmarkSlug: params.assessmentSlug ?? props.assessmentId, skillId: skill.id, userId });

      if (!!response.error) {
        actions.addNotification({
          id: ``,
          active: true,
          type: QueueItemType.Error,
          priority: QueueItemPriority.Toast,
          title: `Failed to save some skills`,
          message: `We were unable to save some or all of the skills for this user. Something seems to have gone wrong in the api`,
        });

        return setLoadingIds([...loadingIds.filter(i => i !== userId)]);
      }
    }

    if (stagedRemovals.length > 0) {
      for (const skillToRemove of stagedRemovals) {
        await actions.removeSkillFromFocusArea({
          benchmarkSlug: params.assessmentSlug ?? props.assessmentId,
          skillId: skillToRemove.id,
          userId,
        });
      }
    }

    setStagedRemovals([]);

    setEditingStates(prevEditingStates => ({
      ...prevEditingStates,
      [userId]: false,
    }));

    props.onSave(selectedItems);
    props.setChangesMade(true);

    setLoadingIds([...loadingIds.filter(i => i !== userId)]);
  }

  function setErrorMessageForUser(userId: string, message: string) {
    setErrorMessages(prevMessages => ({
      ...prevMessages,
      [userId]: message,
    }));
  }

  function handleItemChange(userId: string, selectedItemsCount: number) {
    if (selectedItemsCount > 10) {
      setErrorMessageForUser(userId, 'Skill selection limit (10) reached. Review your selected skills to make any changes.');
    } else {
      setErrorMessageForUser(userId, '');
    }
  }

  if (!props.assignees || props.assignees.length === 0) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Collapsible defaultOpen={props.errorIds}>
        {props.assignees.map(assignee => (
          <CollapsibleStep
            key={assignee.userId}
            id={assignee.userId!}
            title={assignee.userName!}
            tagContent={() => <React.Fragment>{selectedItems[assignee.userId!] && selectedItems[assignee.userId!].length > 0 ? <span>{selectedItems[assignee.userId!].length} Skills Selected</span> : <span>No Skills Selected</span>}</React.Fragment>}
          >
            <SelectSkillDualSelectWrapper>
              <DualSelect
                setStagedRemovals={setStagedRemovals}
                onSearchInput={(value: string) => onInputHandler(value, assignee.userId!)}
                searchInputLabel="Search Skills"
                key={assignee.userId}
                isEditing={editingStates[assignee.userId!] || false}
                setIsEditing={() => toggleEditing(assignee.userId!)}
                items={getSkillsForUser(assignee.userId!)}
                onItemChange={selectedItemsCount => handleItemChange(assignee.userId, selectedItemsCount)}
                leftTitle="Available Skills"
                rightTitle="Selected Skills"
                caption="Select up to 10 skills."
                selectedItems={selectedItems[assignee.userId!] || []}
                setSelectedItems={(newSelectedItems: any) => handleSelectionChange(assignee.userId!, newSelectedItems)}
              />
            </SelectSkillDualSelectWrapper>

            {errorMessages[assignee.userId] && (
              <SelectSkillErrorMessageWrapper>
                <div className="formErrorMessage">{errorMessages[assignee.userId]}</div>
              </SelectSkillErrorMessageWrapper>
            )}

            {!getSkillsForUser(assignee.userId).length ||
              (props.errorIds.includes(assignee.userId) && !loadingIds.includes(assignee.userId) && (
                <SelectSkillErrorMessageWrapper>
                  <div className="formErrorMessage">Please add at least one skill for {assignee.userName}</div>
                </SelectSkillErrorMessageWrapper>
              ))}

            {editingStates[assignee.userId] && (
              <Button isLoading={loadingIds.includes(assignee.userId)} onClick={() => handleSaveSkills(assignee.userId!, selectedItems[assignee.userId!] || [])} type="button">
                save
              </Button>
            )}
          </CollapsibleStep>
        ))}
      </Collapsible>
    </React.Fragment>
  );
}

