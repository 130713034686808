import React, { useState } from 'react';
import { Daterange } from '../../../components';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { SkillsPageContainer } from './skills.page.styles';
import { SkillsBreakdownWidget } from '../../../widgets/analytics/skills-breakdown/skills-breakdown.widget';
import { AnalyticsPageHeaderContainer } from '../analytics.styles';
import { PageHeader } from '@keplerco/core';
import { SkillScoreOverTimeWidget } from '../../../widgets';

export function SkillsPage(): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput } = useAppState();

  const [entity, setEntity] = useState<PermissionsEntity>();

  return (
    <SkillsPageContainer className="pageWrapper wrapper">
      <AnalyticsPageHeaderContainer>
        <PageHeader
          breadcrumbs={[
            { name: 'Analytics', url: PagePath.analyticsBase },
            { name: 'Skills', url: window.location.pathname },
          ]}
          title="Skills"
          type="actions"
        >
          <div className="headerActions" style={{ flexWrap: 'wrap' }}>
            <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

            <Daterange defaultValue={dateRangeOutput} onDaterangeChange={actions.setGlobalDateRange} />
          </div>
        </PageHeader>
      </AnalyticsPageHeaderContainer>

      <SkillsBreakdownWidget entity={entity} path={PagePath.analyticsSkills} />

      <SkillScoreOverTimeWidget entity={entity} />
    </SkillsPageContainer>
  );
}

