import React, { createRef, useEffect, useRef, useState } from 'react';
import { QueueItem, QueueItemType } from '../notification-hub.models';
import { useAppActions } from '../../../overmind';
import { Anchor, CloseButton } from '@keplerco/core';
import classNames from 'classnames';
import { InfoIcon } from './info.icon';
import { AlertIcon } from './alert.icon';
import styles from './toast-hub.module.css';

export function ToastHub({ toasts }: { toasts: QueueItem[] }): JSX.Element {
  const actions = useAppActions();
  const refs = useRef<Array<React.RefObject<HTMLLIElement>>>([]);
  const [activeToasts, setActiveToasts] = useState<QueueItem[]>([]);

  function getRef(index: number) {
    if (!refs.current[index]) {
      refs.current[index] = createRef();
    }

    return refs.current[index];
  }

  useEffect(() => {
    if (Array.isArray(toasts)) setActiveToasts(toasts.filter(a => a.active));
  }, [toasts]);

  return (
    <ul className={styles.list}>
      {activeToasts.map((toast, index) => {
        return (
          <li
            key={toast.id}
            ref={getRef(index)}
            className={classNames(styles.listItem, styles[QueueItemType[toast.type]])}
            style={{
              zIndex: 1 - index,
              bottom: `${15 - 5 * index}px`,
              opacity: 1 - 0.075 * index,
              transform: `scale(${1 - 0.05 * index})`,
            }}
          >
            {!!toast.toastIncludeIcon && (
              <figure className={styles.iconContainer}>
                {toast.type === QueueItemType.Info && <InfoIcon tone="text" />}
                {toast.type === QueueItemType.Error && <InfoIcon tone="r" />}
                {toast.type === QueueItemType.Warn && <AlertIcon tone="a" />}
                {toast.type === QueueItemType.Info && <InfoIcon tone="g" />}
              </figure>
            )}

            <div className={classNames(styles.content, { [styles.hasIcon]: !!toast.toastIncludeIcon })}>
              {!!toast.title && <h5 className="heading5">{toast.title}</h5>}

              {!!toast.toastHTML ? <p className={classNames('body2', styles.paragraph)} dangerouslySetInnerHTML={toast.toastHTML}></p> : <p className="body2">{toast.message}</p>}

              {!!toast.toastOnClick && (
                <Anchor style={{ marginLeft: 'auto' }} arrow onClick={toast.toastOnClick}>
                  Continue
                </Anchor>
              )}
            </div>

            <CloseButton
              onClick={() => {
                const listItem = getRef(index).current;
                if (!listItem) return;

                listItem.classList.add(styles.closingToast);
                setActiveToasts([...activeToasts.filter(y => y.id !== toast.id)]);

                // wait for animation to end
                setTimeout(() => actions.dismissNotification(toast.id), 250);
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}

