import { ReducerAction } from '../../models/reducer-state';
import { PagePath } from '../../navigation/navigation.enums';
import { ColumnConfiguration } from '../../models/column-configuration';
import { Breadcrumb } from '@keplerco/core';
import { EntityListItemResponse, EntityListResponse } from '../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../models/overmind/search-params';

interface SkillPoolCMSState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  request: CompanyEntitySearchParams | undefined;

  data?: EntityListResponse;
  skillPoolListItem?: EntityListItemResponse;
  type?: 'create' | 'update' | undefined;

  managingColumns?: boolean;
  columnConfiguration: ColumnConfiguration[];
}

export const initialState: SkillPoolCMSState = {
  pageTitle: 'Skills',
  crumbs: [],
  request: undefined,
  columnConfiguration: [
    { label: 'Skill Name', key: 'skillName', required: true, selected: true },
    { label: 'Description', key: 'skillDescription', required: false, selected: true },
    { label: 'Skill Type', key: 'skillType', required: false, selected: true },
  ],
};

export enum SkillPoolCMSActionTypes {
  SetHeader,
  SetRequest,
  SetData,
  SetSkillPoolListItem,
  SetManageColumns,
  SetColumnConfiguration,
}

type SkillPoolCMSActions =
  | ReducerAction<SkillPoolCMSActionTypes.SetHeader, { payload: { entityName: string | undefined; entitySlug: string } }>
  | ReducerAction<SkillPoolCMSActionTypes.SetRequest, { payload: CompanyEntitySearchParams }>
  | ReducerAction<SkillPoolCMSActionTypes.SetData, { payload: EntityListResponse | undefined }>
  | ReducerAction<SkillPoolCMSActionTypes.SetColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<SkillPoolCMSActionTypes.SetManageColumns, { payload: { open: boolean; reset?: boolean } }>
  | ReducerAction<SkillPoolCMSActionTypes.SetSkillPoolListItem, { payload: { skillPoolListItem: EntityListItemResponse; type: 'create' | 'update' } | undefined }>;

export function reducer(state: SkillPoolCMSState, action: SkillPoolCMSActions): SkillPoolCMSState {
  switch (action.type) {
    case SkillPoolCMSActionTypes.SetHeader: {
      return {
        ...state,
        pageTitle: action.payload.entityName ? `Skill Pool in ${action.payload.entityName}` : 'Skill Pool',
        crumbs: [
          { name: 'Companies', url: PagePath.companies },
          { name: action.payload.entityName ? `Skill pool in ${action.payload.entityName}` : 'Skill pool', url: `${PagePath.administrationBase}${PagePath.administrationPeople.replace(':companySlug', action.payload.entityName!)}` },
        ],
      };
    }

    case SkillPoolCMSActionTypes.SetRequest: {
      return { ...state, request: action.payload };
    }

    case SkillPoolCMSActionTypes.SetData: {
      return { ...state, data: action.payload };
    }

    case SkillPoolCMSActionTypes.SetSkillPoolListItem: {
      return { ...state, skillPoolListItem: action.payload?.skillPoolListItem, type: action.payload?.type };
    }

    case SkillPoolCMSActionTypes.SetManageColumns: {
      if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
      else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
      else return state;
    }

    case SkillPoolCMSActionTypes.SetColumnConfiguration: {
      return { ...state, columnConfiguration: action.payload };
    }

    default: {
      return state;
    }
  }
}
