import { AddTwoToneIcon, FocusPanel, GridIcon, IconButton, KebabMenu, ListIcon, MenuItem, Pager, Searchfield, Table, TableColumn, TableRow, ToggleButtonGroup, Tooltip } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ColumnConfiguratorWidget } from '../../../widgets/layouts';
import { JobPoolWrapper, PagerWrapper, RolesPageFilteringRow, RolesPageJobTitle, RolesPageTableRow } from './roles.cms.styles';
import { CMSColumn } from '../../../theme/layout.styles';
import { HoverCard } from '../../../components/cards/hover-card/hover-card';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { FetchType, FetchStatus, OrganizationLevelType, SortField } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppState, useAppActions } from '../../../overmind';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { EntityListItemResponse, EntityListResponse } from '../../../models/overmind/entities';
import { CompanyEntitySearchParams, PagingSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../models/overmind/search-params';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function CompanyRolesView(): JSX.Element {
  const { fetchState, user, permissions, companyVariables } = useAppState();
  const keplerNavigate = useKeplerNavigate();
  const actions = useAppActions();

  const [pageParams, setPageParams] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 12,
    page: 1,
  });
  const [roles, setRoles] = useState<EntityListResponse>();
  const [managingColumns, setManagingColumns] = useState<boolean>(false);
  const [activeViewType, setActiveViewType] = useState<string>('Grid');

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

  async function initPage(pageLoad?: boolean, overwritePageParams?: SearchingSearchParams & SortingSearchParams & PagingSearchParams) {
    const forcePageLoad = typeof roles === 'undefined' || pageLoad;
    actions.startLoader({ path: PagePath.administrationRoles, type: !forcePageLoad ? FetchType.Custom : FetchType.PageFetching });

    const request: CompanyEntitySearchParams = {
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: false, // TODO: check if this should be true or false
      ...pageParams,
      ...overwritePageParams,
    };
    const response = await actions.getRoles(request);
    setRoles(response);

    actions.stopLoader(PagePath.administrationRoles);
  }

  async function onInputHandler(search: string) {
    search = search.trim();
    setPageParams({ ...pageParams, search });
  }

  useEffect(() => {
    initPage();
  }, [pageParams.search, pageParams.sortAscending, pageParams.page]);

  const pageFetchState = fetchState[PagePath.administrationRoles];
  const isSearching = fetchState[PagePath.administrationRoles].type === FetchType.Custom && pageFetchState.status === FetchStatus.Active;

  function onClickCreateHandler() {
    keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationRolesCreate.replace(':companySlug', companyVariables.slug!)}`);
  }

  const menuItems: MenuItem<EntityListItemResponse>[] = [
    { text: 'View Role', onClick: data => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRole.replace(':roleSlug', data!.slug).replace(':companySlug', companyVariables.slug!)}`) },
    { text: 'Edit Role', onClick: d => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRoleEdit.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', d!.slug)}`) },
  ];

  return (
    <React.Fragment>
      <RolesPageFilteringRow>
        <CMSColumn>
          <Searchfield loading={isSearching} onInput={onInputHandler} />
        </CMSColumn>

        <CMSColumn style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
            {(organizationLevel?.organizationLevel === OrganizationLevelType.Company || user?.isSystemAdmin) && (
              <Tooltip content="Create role">
                <IconButton iconType="fill" borderColour="borders" borderRadius="10px" borderWidth={2} onClick={onClickCreateHandler}>
                  <AddTwoToneIcon />
                </IconButton>
              </Tooltip>
            )}

            <ToggleButtonGroup
              buttons={[
                { label: 'Grid', icon: <GridIcon /> },
                { label: 'List', icon: <ListIcon /> },
              ]}
              defaultButtonLabel={activeViewType}
              onClick={setActiveViewType}
            />
          </div>
        </CMSColumn>
      </RolesPageFilteringRow>

      <RolesPageTableRow>
        <CMSColumn>
          {roles?.entities.length ? (
            activeViewType === 'Grid' ? (
              <React.Fragment>
                <JobPoolWrapper>
                  {roles.entities.map((job, index) => (
                    <HoverCard onClickAction={() => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRole.replace(':roleSlug', job!.slug!).replace(':companySlug', companyVariables.slug!)}`)} key={index} chip="" title={job.name} description={job.description ?? ''} />
                  ))}
                </JobPoolWrapper>

                <PagerWrapper>
                  <PagerNavigationConnector
                    onPageChange={page => {
                      const newParams = { ...pageParams, page };
                      initPage(true, newParams);
                      setPageParams(newParams);
                    }}
                    defaultPageNumber={pageParams.page}
                    pageCount={roles.totalPages}
                  >
                    {connector => {
                      return <Pager {...connector} />;
                    }}
                  </PagerNavigationConnector>
                </PagerWrapper>
              </React.Fragment>
            ) : (
              <Table
                onConfigClicked={() => setManagingColumns(true)}
                onSort={() => setPageParams({ ...pageParams, sortAscending: !pageParams.sortAscending })}
                currentSortDirection={pageParams.sortAscending ? 'Ascending' : 'Descending'}
                currentSortBy="role"
                footerContent={
                  <PagerNavigationConnector
                    onPageChange={page => {
                      const newParams = { ...pageParams, page };
                      initPage(true, newParams);
                      setPageParams(newParams);
                    }}
                    defaultPageNumber={pageParams.page}
                    pageCount={roles.totalPages}
                  >
                    {connector => {
                      return <Pager {...connector} />;
                    }}
                  </PagerNavigationConnector>
                }
              >
                {roles.entities.map((jobTitle, index) => {
                  return (
                    <TableRow key={jobTitle.slug} id={jobTitle.slug} configCell={() => <KebabMenu index={index} data={jobTitle} items={menuItems} />}>
                      <TableColumn sortable onClick={() => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRole.replace(':roleSlug', jobTitle.slug).replace(':companySlug', companyVariables.slug!)}`)} label="Job Role" id={'role'}>
                        <RolesPageJobTitle>{jobTitle.name.trim()}</RolesPageJobTitle>
                      </TableColumn>

                      <TableColumn label="Description" id={'description'}>
                        {jobTitle.description}
                      </TableColumn>
                    </TableRow>
                  );
                })}
              </Table>
            )
          ) : (
            <EmptyState title={!!pageParams.search ? `No results for "${pageParams.search}"` : `No Job Roles found`} />
          )}
        </CMSColumn>
      </RolesPageTableRow>

      <FocusPanel open={managingColumns} onClose={() => setManagingColumns(false)}>
        <ColumnConfiguratorWidget
          onReset={() => console.log('Reset columns')}
          onCancel={() => setManagingColumns(false)}
          onSave={() => setManagingColumns(false)}
          columns={[
            { label: 'Job Role', key: 'role', required: true },
            { label: 'Description', key: 'description', required: true },
          ]}
        />
      </FocusPanel>
    </React.Fragment>
  );
}
