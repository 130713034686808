import React from 'react';
import { SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components';

export function ViewRoleSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoader height="145px" />
      </SkeletonLoaderRow>
      <SkeletonLoaderRow>
        <SkeletonLoader height="105px" />
      </SkeletonLoaderRow>
      <SkeletonLoaderRow>
        <SkeletonLoader height="calc(100vh - 340px)" />
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
