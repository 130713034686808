import React, { useState } from 'react';
import { useAppActions } from '../../../../../overmind';
import { useParams } from 'react-router-dom';
import { FetchType } from '../../../../../enums/fetch';
import { ResponseStatusType } from '../../../../../enums/response-status';
import { BulkUploadHeader } from './bulk-upload-people-focus-panel.styles';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { UserBulkSaveDto, UserBulkValidationDto } from '../../../../../models/user-bulk-save';
import { ActionCard, Anchor, Button, FormGroup, Upload } from '@keplerco/core';

enum BulkUploadErrorType {
  Upload = 0,
  Formatting = 1,
  EmptyFile = 2,
}

export function BulkUploadPeopleFocusPanel(props: { path: PagePath.administrationPeople | PagePath.administrationDepartmentPeople | PagePath.administrationTeamPeople; onClick: () => void }): JSX.Element {
  const params = useParams<any>();

  const actions = useAppActions();

  const [dirty, setDirty] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [data, setData] = useState<Array<UserBulkValidationDto>>();
  const [errorFile, setErrorFile] = useState<string>();
  const [errorType, setErrorType] = useState<BulkUploadErrorType>();
  const [peopleUploaded, setPeopleUploaded] = useState<number>(0);

  function extractAndFormatErrors(validationResults: UserBulkValidationDto[] | undefined) {
    return validationResults
      ?.flatMap((result: any) => result.usersBulkSaveResultDto.filter((error: any) => error.responseStatusType === ResponseStatusType.Error).map((error: any) => `${result.userBulkSaveDto.email}, Line: ${error.lineNumber}, Field: ${error.fieldName}, Error: ${error.validationMessage}`))
      .join('\n');
  }

  async function handleFileValidation(validationResult: UserBulkValidationDto[] | undefined) {
    if (!file) return void setDirty(true);

    const hasErrors = validationResult?.some((result: any) => result.usersBulkSaveResultDto.some((error: any) => error.responseStatusType === ResponseStatusType.Error));

    const result = (await file!.text()).split('\n').filter(line => line.length > 5);

    if (result.length < 2) {
      actions.stopLoader(props.path);
      setErrorType(BulkUploadErrorType.EmptyFile);
      return void setErrorFile('No data found in the file');
    }

    if (hasErrors) {
      const errorString = extractAndFormatErrors(validationResult);
      setErrorType(BulkUploadErrorType.Formatting);
      setErrorFile(errorString);
    } else {
      const dataToUpload = validationResult?.map((vr: any) => vr.userBulkSaveDto);
      const transformedDataToUpload = transformToUserBulkSaveDto(dataToUpload!);
      try {
        const response = await actions.uploadEmployeesData({ companySlug: params.companySlug!, employeesData: transformedDataToUpload });

        setData(response!);
      } catch (error) {
        setErrorType(BulkUploadErrorType.Upload);
      }
    }
  }

  async function onChangeUploadHandler(files: File[]) {
    setErrorType(void 0);
    setData(void 0);
    setErrorFile(void 0);

    if (files.length < 1) {
      actions.stopLoader(PagePath.administrationPeople);
      return void 0;
    }

    const selectedFile = files.reverse()[0];
    setFile(selectedFile);

    actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Sending });

    const validationResult = await actions.validateUsersUploadCsv({ file: selectedFile, companySlug: params.companySlug!, addToBenchmark: false });
    handleFileValidation(validationResult);

    const result = (await selectedFile.text()).split('\n').filter(line => line.length > 5);
    if (result.length < 1) {
      setErrorType(BulkUploadErrorType.EmptyFile);
      setErrorFile('No data found in the file');
    }

    const errorLine = [result[0]];

    setPeopleUploaded(result.length - errorLine.length);

    actions.stopLoader(PagePath.administrationPeople);
    return void 0;
  }

  function transformToUserBulkSaveDto(data: UserBulkSaveDto[]): UserBulkSaveDto[] {
    return data.map(item => ({
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      jobTitle: item.jobTitle,
      country: item.country,
      team: item.team,
      department: item.department,
      languages: item.languages,
      userName: item.userName || '',
      idNumber: item.idNumber || '',
      tags: item.tags || [],
      useUserName: item.useUserName || false,
      externalLearningPlatformUserIds: item.externalLearningPlatformUserIds || {},
    }));
  }

  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <BulkUploadHeader className="dialogHeaderLayout">
        <h2 className="heading2">Bulk Upload People</h2>
        <h6 className="subtitle">
          <a href="/UsersUploadCSVTemplate.csv" download="UsersUploadCSVTemplate.csv" target="_blank">
            Download
          </a>{' '}
          this template to get started with a bulk upload.
        </h6>
      </BulkUploadHeader>

      <div className="dialogBodyLayout">
        <FormGroup label="" error={{ dirty: dirty, hasError: !file, errorMsg: 'Please select a file to upload' }}>
          <Upload accept={['text/csv']} onChange={files => onChangeUploadHandler(files)} description={'Drop your file here or <strong>Browse</strong>'} />
        </FormGroup>

        {typeof errorType !== 'undefined' && (
          <React.Fragment>
            {errorType === BulkUploadErrorType.Upload ? (
              <ActionCard
                icon="/icons/ico_error.svg"
                title="Failed to upload"
                description="We tried to upload your file, but it seems to have not worked. This could be due to a network error or because of something on our side"
                actionText="Try again"
                onClick={() => onChangeUploadHandler([file!])}
              />
            ) : errorType === BulkUploadErrorType.EmptyFile ? (
              <div className="card glass" style={{ padding: '80px 10px', border: '1px solid var(--berry)' }}>
                <h4 className="heading4" style={{ textAlign: 'center' }}>
                  The file you've uploaded is empty. Please upload a valid CSV file.
                </h4>
              </div>
            ) : (
              <ActionCard
                icon="/icons/ico_error.svg"
                title="Bulk upload errors"
                description="We've picked up a few errors in the file you've just uploaded and have saved them all in this CSV file. Please download this CSV containing all the lines that need to be fixed and re-upload."
                actionText="Download csv"
                onClick={() => {
                  if (!!errorFile) {
                    const blob = new Blob([errorFile], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);

                    const anchor = window.document.createElement('a');
                    anchor.setAttribute('href', url);
                    anchor.setAttribute('download', 'fix-error.csv');
                    setTimeout(() => anchor.click());
                  }
                }}
              />
            )}
          </React.Fragment>
        )}

        {!!data && !errorFile && (
          <div className="card glass" style={{ padding: '80px 0', border: '1px solid var(--apple)' }}>
            <h3 className="heading3" style={{ textAlign: 'center' }}>
              {peopleUploaded} {peopleUploaded > 1 ? 'people' : 'person'} uploaded successfully
            </h3>
          </div>
        )}
      </div>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <Anchor onClick={props.onClick}>Cancel</Anchor>

        {typeof data === 'undefined' || typeof errorType !== 'undefined' ? (
          <Button theme={!file || !!errorFile ? 'grayscale' : 'normal'} type="button" onClick={() => onChangeUploadHandler([file!])} disabled={!file || !!errorFile}>
            Upload
          </Button>
        ) : (
          <Button type="button" onClick={props.onClick}>
            Got it, thanks!
          </Button>
        )}
      </footer>
    </div>
  );
}
