import { KeplerPointType } from '../../../enums';
import { getSubdomain } from '../../../lib/get-subdomain';
import { QueueItem, QueueItemType } from '../notification-hub.models';

/**
 * Returns whether a modal should be suppressed based on the type and current subdomain.
 * @param {QueueItem} item - The item to check suppression conditions for.
 * @returns {boolean} True if the modal should be suppressed, otherwise false.
 */
export function suppressModal(item: QueueItem) {
  const subdomain = getSubdomain();
  const isLPI = subdomain.includes('lpi');
  const isRoche = subdomain.includes('roche');

  const typesToSuppress: KeplerPointType[] = [
    KeplerPointType.SkillsGapReport,
    KeplerPointType.CategoryCompleted,
    KeplerPointType.JourneyCompleted,
    KeplerPointType.LoginStreak
  ];

  if (item.keplerPointType === undefined) {
    return false;
  }

  if (item.type === QueueItemType.MissionBriefing || item.keplerPointType === KeplerPointType.LevelAchieved) {
    return true;
  }

  if (isLPI) {
    return true;
  }

  if (isRoche) {
    return (
      item.keplerPointType !== KeplerPointType.SkillScorePostAssessmentUpdated &&
      item.keplerPointType !== KeplerPointType.SkillScorePreAssessmentUpdated &&
      item.keplerPointType !== KeplerPointType.VariationQuestionReady
    );
  }

  return typesToSuppress.includes(item.keplerPointType);
}
