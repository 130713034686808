import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { ActivationStatus, FetchStatus, FetchType } from '../../../enums';
import { UploadTwoToneIcon } from './assets/upload-two-tone.icon';
import { PeopleHeaderMobileActionsRow, PeopleHeaderActionsRow } from './people.cms.styles';
import { IPeopleHeaderActionsCMSLayoutProps } from './people.cms.models';
import { BulkActionsMenu } from './bulk-actions-menu/bulk-actions-menu';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { filterableColumns, getSelectedItemValue } from './people.cms.helpers';
import { CMSColumn } from '../../../theme/layout.styles';
import { AddTwoToneIcon, Button, Checkbox, DropdownItem, DropdownSelect, Filters, IconButton, Searchfield, Tooltip, useMediaQuery } from '@keplerco/core';
import { updateItems, clearItems, generateItems } from '../../../lib/filter.helpers';

export function PeopleHeaderActionsCMSLayout(props: IPeopleHeaderActionsCMSLayoutProps): JSX.Element {
  const { fetchState } = useAppState();
  const actions = useAppActions();

  const showIconButton = useMediaQuery('screen and (max-width: 1360px)');

  const [departmentItems, setDepartmentItems] = useState<DropdownItem[]>([]);
  const [teamItems, setTeamItems] = useState<DropdownItem[]>([]);
  const [countryItems, setCountryItems] = useState<DropdownItem[]>([]);
  const [statusItems, setStatusItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function generateFilterByColumn(column: ColumnConfiguration) {
      if (!column.key || !column.label || !filterableColumns.includes(column.key)) return;

      switch (column.key) {
        case 'Department': {
          const items = generateItems(props.departments, (value: string) => setDepartmentItems(currentState => updateItems(currentState, value)));
          setDepartmentItems(items);
          break;
        }

        case 'Team': {
          const items = generateItems(props.teams, (value: string) => setTeamItems(currentState => updateItems(currentState, value)));
          setTeamItems(items);
          break;
        }

        case 'Country': {
          const items = generateItems(props.countries, (value: string) => setCountryItems(currentState => updateItems(currentState, value)));
          setCountryItems(items);
          break;
        }

        case 'Status': {
          const items = generateItems(
            [
              { slug: ActivationStatus.Active.toString(), name: 'Account active' },
              { slug: ActivationStatus.Archived.toString(), name: 'Archived' },
              { slug: ActivationStatus.Inactive.toString(), name: 'Activation required' },
              { slug: ActivationStatus.NotInvited.toString(), name: 'Invite pending' },
            ],
            (value: string) => setStatusItems(currentState => updateItems(currentState, value))
          );
          setStatusItems(items);
          break;
        }

        default: {
          break;
        }
      }
    }

    props.columnConfiguration.forEach(generateFilterByColumn);
  }, [props.columnConfiguration, props.departments, props.teams, props.countries]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: props.path, type: FetchType.Custom });
    props.setRequest({ ...props.request, search: value, page: 1 });
  }

  function clearFilters() {
    const nextRequest = structuredClone(props.request);
    nextRequest.page = 1;
    nextRequest.departmentSlug = undefined;
    nextRequest.teamSlug = undefined;
    nextRequest.countryId = undefined;
    nextRequest.activationStatus = undefined;
    props.setRequest(nextRequest);

    setDepartmentItems(currentState => clearItems(currentState));
    setTeamItems(currentState => clearItems(currentState));
    setCountryItems(currentState => clearItems(currentState));
    setStatusItems(currentState => clearItems(currentState));
  }

  function applyFilters() {
    const nextRequest = structuredClone(props.request);
    nextRequest.page = 1;
    nextRequest.departmentSlug = getSelectedItemValue(departmentItems);
    nextRequest.teamSlug = getSelectedItemValue(teamItems);

    const countryValue = getSelectedItemValue(countryItems);
    nextRequest.countryId = !countryValue ? undefined : parseInt(countryValue);

    const statusValue = getSelectedItemValue(statusItems);
    nextRequest.activationStatus = !statusValue ? undefined : parseInt(statusValue);

    props.setRequest(nextRequest);
  }

  if (props.isMobile)
    return (
      <React.Fragment>
        <PeopleHeaderActionsRow>
          <CMSColumn>
            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect label="Department" items={departmentItems} />

              <DropdownSelect label="Team" items={teamItems} />

              <DropdownSelect label="Country" items={countryItems} />

              <DropdownSelect label="Status" items={statusItems} />
            </Filters>
          </CMSColumn>

          <CMSColumn>
            <Tooltip content="Bulk Upload">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setBulkUpload(true)}>
                <UploadTwoToneIcon />
              </IconButton>
            </Tooltip>
          </CMSColumn>

          <CMSColumn>
            <Tooltip content="Add A Person">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setPersonToManage({ show: true })}>
                <AddTwoToneIcon />
              </IconButton>
            </Tooltip>
          </CMSColumn>

          <CMSColumn style={{ width: '100%' }}>
            <Searchfield loading={fetchState[props.path].status === FetchStatus.Active && fetchState[props.path].type === FetchType.Custom} responsive onInput={onInputHandler} />
          </CMSColumn>
        </PeopleHeaderActionsRow>

        <PeopleHeaderMobileActionsRow>
          <CMSColumn>
            <Checkbox
              checked={props.selectedRows.length === props.data?.employees.length}
              id="selectAll"
              onChange={() => {
                if (!props.data?.employees) return;
                const ids: any[] = props.data.employees.map(person => ({ id: person.id! }));
                props.setSelectedRows(props.selectedRows.length === props.data?.employees.length ? [] : ids);
              }}
            >
              Select all
            </Checkbox>
          </CMSColumn>
          {props.selectedRows.length > 0 && (
            <CMSColumn style={{ textAlign: 'right' }}>
              <BulkActionsMenu path={props.path} selectedRows={props.selectedRows} setSelectedRows={props.setSelectedRows} data={props.data} onClickSendActivationInvitation={props.onClickSendActivationInvitation} onClickSendActivationReminder={props.onClickSendActivationReminder} />
            </CMSColumn>
          )}
        </PeopleHeaderMobileActionsRow>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <PeopleHeaderActionsRow>
        <CMSColumn>
          <Searchfield loading={fetchState[props.path].status === FetchStatus.Active && fetchState[props.path].type === FetchType.Custom} onInput={onInputHandler} />
        </CMSColumn>

        <CMSColumn>
          <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
            <DropdownSelect label="Department" items={departmentItems} />

            <DropdownSelect label="Team" items={teamItems} />

            <DropdownSelect label="Country" items={countryItems} />

            <DropdownSelect label="Status" items={statusItems} />
          </Filters>
        </CMSColumn>

        <CMSColumn>
          {showIconButton ? (
            <Tooltip content="Bulk Upload">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setBulkUpload(true)}>
                <UploadTwoToneIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button type="button" square theme="dark" onClick={() => props.setBulkUpload(true)}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <UploadTwoToneIcon />
                Bulk Upload
              </div>
            </Button>
          )}
        </CMSColumn>

        <CMSColumn>
          {showIconButton ? (
            <Tooltip content="Add A Person">
              <IconButton iconType="fill" borderColour="borders" backgroundColour="cards" borderRadius="10px" borderWidth={1} onClick={() => props.setPersonToManage({ show: true })}>
                <AddTwoToneIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button type="button" square theme="dark" onClick={() => props.setPersonToManage({ show: true })}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Add A Person
              </div>
            </Button>
          )}
        </CMSColumn>

        <CMSColumn>
          <BulkActionsMenu path={props.path} selectedRows={props.selectedRows} setSelectedRows={props.setSelectedRows} data={props.data} onClickSendActivationInvitation={props.onClickSendActivationInvitation} onClickSendActivationReminder={props.onClickSendActivationReminder} />
        </CMSColumn>
      </PeopleHeaderActionsRow>
    </React.Fragment>
  );
}

