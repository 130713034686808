import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { LearnerSkillAssessment } from '../../../models/skill-assessment-configuration';
import { Button, DropdownSelect, DropdownSelectItem, Filters, IFilterButtonDataMapEntryData, Modal, PageHeader, Searchfield, SkillLevel, SkillPercentage, ragColourType } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { AssessmentCard, AssessmentCardSubtitle, AssessmentTitleWrapper, SkillGradingWrapper } from './completed-assessments.styles';
import { AppPageWrapper } from '../../../theme';
import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';
import { FetchStatus, FetchType } from '../../../enums';
import { assessmentTypeItems, clearFilterItems, setFilterItems } from './completed-assessments.helper';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { ConfirmationModalLayout } from '../../../widgets/layouts';

export function getBorderColor(score: any) {
  const rag = ragColourType(score);
  switch (rag) {
    case 'r':
      return 'red';
    case 'a':
      return 'orange';
    case 'g':
      return 'green';
    default:
      return 'grey';
  }
}

function formatAssessmentTypeLabel(label: string): string {
  return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export default function CompletedAssessmentsPage() {
  const actions = useAppActions();
  const { companyVariables, fetchState, settings } = useAppState();

  const [learnerSkillAssessments, setLearnerSkillAssessments] = useState<LearnerSkillAssessment[]>();
  const [assessmentTypeDropdownItems, setAssessmentDropdownItems] = useState<DropdownSelectItem[]>(assessmentTypeItems?.map(item => ({ ...item, onClick: () => onClickDropdownItem(item) })) ?? []);
  const [reassessment, setReassessment] = useState<LearnerSkillAssessment>();
  const [request, setRequest] = useState<Partial<AssessmentsSearchRequest & { filters: Map<string, IFilterButtonDataMapEntryData> }>>({ companySlug: companyVariables.slug, sortAscending: true, pageSize: 20, page: 1 });

  function onClickDropdownItem(item: DropdownSelectItem) {
    setAssessmentDropdownItems(currentState => setFilterItems(currentState, item.value));
  }

  async function getData() {
    actions.startLoader({ path: PagePath.learningCompletedAssessments, type: FetchType.PageFetching });

    let assessmentType = request.filters?.get('AssessmentType')?.value;

    if (assessmentType !== undefined) assessmentType = AssessmentType[assessmentType as any];

    const requestParsed: AssessmentsSearchRequest = {
      ...request,
    };

    if (assessmentType !== undefined) {
      requestParsed.assessmentType = parseInt(assessmentType);
    }
    delete (requestParsed as any)['filters'];

    const result = await actions.getLearnerAssessments({ ...requestParsed });
    setLearnerSkillAssessments(result);

    actions.stopLoader(PagePath.learningCompletedAssessments);
  }

  useEffect(() => {
    getData();
  }, [request, request.filters]);


  function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.skills, type: FetchType.Custom });
    setRequest(current => ({ ...current, search: value, page: 1 }));
  }

  function clearFilters(): void {
    setAssessmentDropdownItems(currentState => clearFilterItems(currentState));
    setRequest(current => ({ ...current, assessmentType: undefined }));
  }

  function applyFilters() {
    const assessmentSelectedItem = assessmentTypeDropdownItems.find(item => item.selected)?.value;
    if (assessmentSelectedItem) {
      setRequest(current => ({ ...current, assessmentType: parseInt(assessmentSelectedItem) }));
    }
  }

  return (
    <React.Fragment>
      <AppPageWrapper className="pageWrapper wrapper">
        <PageHeader title="Assessments" breadcrumbs={[{ name: 'Assessments', url: PagePath.learningCompletedAssessments }]} />

        <div className="column">
          <div className="row">
            <Searchfield label="Search" loading={fetchState[PagePath.learningCompletedAssessments].status === FetchStatus.Active && fetchState[PagePath.learningCompletedAssessments].type === FetchType.Custom} onInput={onInputHandler} />

            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect id="status" name="Status" label="Status" items={assessmentTypeDropdownItems} />
            </Filters>
          </div>
        </div>

        {!!learnerSkillAssessments?.length ? (
          learnerSkillAssessments?.map(assessment => (
            <AssessmentCard key={assessment.assessmentName} score={assessment.score?.percentage ?? 0} className="card">
              <AssessmentTitleWrapper>
                <AssessmentCardSubtitle>{formatAssessmentTypeLabel(AssessmentType[assessment.assessmentType])}</AssessmentCardSubtitle>
                {assessment.assessmentName}
              </AssessmentTitleWrapper>

              <SkillGradingWrapper>
                {companyVariables.useLevels ? (
                  <SkillLevel
                    level={assessment.score?.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    notAssessed={!assessment.score}
                  />
                ) : (
                  <SkillPercentage
                    percentage={assessment.score?.percentage ?? 0}
                    notAssessed={!assessment.score}
                  />
                )}

                {assessment.allowReassessment && (
                  <Button type="button" onClick={() => setReassessment(assessment)}>
                    Reassess
                  </Button>
                )}
              </SkillGradingWrapper>
            </AssessmentCard>
          ))
        ) : (
          <EmptyState badgeIconWidth={450} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} title="No assessments have been completed yet" />
        )}
      </AppPageWrapper>

      <Modal type="small" open={!!reassessment} onClose={() => setReassessment(undefined)}>
        {!!reassessment && (
          <ConfirmationModalLayout
            title="Confirm Reassessment"
            subtitle={`Are you sure you want to redo ${reassessment?.assessmentName}? If you proceed, your previous score will be overwritten with your new score...`}
            submitButtonText="Confirm"
            onClickSubmit={async () => {
              if (!settings) {
                return actions.addNotification({
                  active: true,
                  id: crypto.randomUUID(),
                  type: QueueItemType.Error,
                  priority: QueueItemPriority.Toast,
                  title: 'There was an error triggering reassessment',
                  message: 'The system was unable to retrieve your email address. Please refresh the page and try again.',
                });
              }

              actions.startLoader({ path: PagePath.learningCompletedAssessments, type: FetchType.Sending });
              await actions.resetBenchmarkAssessment({ benchmarkSlug: reassessment.assessmentSlug, userIds: [settings.email] });
              actions.getLearnerSkillAssessmentResultsReady();
              actions.stopLoader(PagePath.learningCompletedAssessments);
            }}
            onClickCancel={() => setReassessment(undefined)}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}

