import React, { useEffect, useState } from 'react';
import styles from './assign-role.module.css';
import { Anchor, DropdownItem, DropdownSearch, ListItemLayout, ListLayout, Modal, SpinnerIcon } from '@keplerco/core';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../../../overmind';
import { Employee } from '../../../../../models';
import { IAssignPersonPanelProps } from './assign-role.models';
import { OrganizationLevelType } from '../../../../../enums';
import { ConfirmationModalLayout } from '../../../../../widgets/layouts';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { EmptyState } from '../../../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import { EntityAssigneeListItemResponse } from '../../../../../models/overmind/entities';

function toRoleAssignee(employee: Employee): EntityAssigneeListItemResponse {
  return {
    slug: employee.learnerSlug,
    name: `${employee.firstName} ${employee.lastName}`,
    department: employee.departmentName,
    team: employee.teamName,
    email: employee.email,
  };
}

export function AssignPersonPanel(props: IAssignPersonPanelProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const keplerNavigate = useKeplerNavigate();

  const [currentEmployees, setCurrentEmployees] = useState<EntityAssigneeListItemResponse[]>([]);
  const [loadingSlugs, setLoadingSlugs] = useState<string[]>([]);
  const [personToRemove, setPersonToRemove] = useState<EntityAssigneeListItemResponse>();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    if (props.roleSlug) {
      fetchEmployees();
      fetchRoleAssignees();
    }
  }, [props.roleSlug]);


  
  async function fetchRoleAssignees() {
    // TODO https://keplerandco.atlassian.net/browse/KPRDEV-1872
    const assignees = await actions.getRoleAssignees({
      companySlug: companyVariables.slug!,
      roleSlug: props.roleSlug!,
      page: 1,
      pageSize: 9999,
      search: '',
    });
    if (assignees) {
      setCurrentEmployees(assignees.assignees || []);
      syncDropdownItemsWithAssignees(assignees.assignees || []);
    }
  }

  async function fetchEmployees() {
    const employees = await actions.getEmployeesByCompanySlugAndActivationStatus({
      activationStatus: 0,
      companySlug: companyVariables.slug!,
    });

    if (employees) {
      const items: DropdownItem[] = employees.map(employee => ({
        value: employee.learnerSlug!,
        label: `${employee.firstName} ${employee.lastName}`,
        onClick: () => onClickMultiSelectItemHandler(toRoleAssignee(employee)),
        selected: false,
      }));
      setDropdownItems(items);
      syncDropdownItemsWithAssignees(currentEmployees); // sync with current employees
    }
  }

  function syncDropdownItemsWithAssignees(assignees: EntityAssigneeListItemResponse[]) {
    setDropdownItems(currentItems =>
      currentItems.map(item => ({
        ...item,
        selected: assignees.some(assignee => assignee.slug === item.value),
      }))
    );
  }

  function onClickMultiSelectItemHandler(employee: EntityAssigneeListItemResponse) {
    setDropdownItems(currentState => {
      const nextState = currentState.map(item => ({ ...item }));
      const item = nextState.find(temp => temp.value === employee.slug);
      if (item) {
        item.selected ? handleRemovePerson(employee) : handleAddPerson(employee);
        item.selected = !item.selected;
      }
      return nextState;
    });
  }

  async function handleAddPerson(person: EntityAssigneeListItemResponse) {
    setLoadingSlugs(current => [...current, person.slug!]);

    const result = await actions.assignRole({
      companySlug: companyVariables.slug!,
      entitySlug: props.roleSlug!,
      assignees: [
        {
          organizationLevel: OrganizationLevelType.Learner,
          entitySlug: person.slug!,
          name: person.name,
        },
      ],
    });

    if (!result) {
      setDropdownItems(currentState =>
        currentState.map(item => ({
          ...item,
          selected: false,
        }))
      );
      setLoadingSlugs(current => current.filter(a => a !== person.slug));
      return;
    }

    setCurrentEmployees(currentState => {
      if (!currentState.some(item => item.slug === person.slug)) {
        return [...currentState, person];
      }
      return currentState;
    });
    setLoadingSlugs(current => current.filter(a => a !== person.slug));
  }

  async function handleRemovePerson(person: EntityAssigneeListItemResponse) {
    setPersonToRemove(void 0);
    setLoadingSlugs(current => [...current, person.slug!]);
    const result = await actions.removePersonFromRole({
      companySlug: companyVariables.slug!,
      assignees: [
        {
          entitySlug: person.slug!,
          organizationLevel: OrganizationLevelType.Learner,
        },
      ],
      entitySlug: props.roleSlug!,
    });

    if (!result) {
      setDropdownItems(currentState =>
        currentState.map(item => ({
          ...item,
          selected: true,
        }))
      );
      setLoadingSlugs(current => current.filter(a => a !== person.slug));
      return;
    }

    setCurrentEmployees(currentState => currentState.filter(item => item.slug !== person.slug));
    setLoadingSlugs(current => current.filter(a => a !== person.slug));
  }

  function closeConfirmationModal() {
    setPersonToRemove(undefined);
  }

  function navigateToPerson(employee: EntityAssigneeListItemResponse) {
    window.scrollTo(0, 0);
    keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsPerson.replace(':companySlug', companyVariables.slug!).replace(':personSlug', employee.slug!)}`);
  }

  return (
    <React.Fragment>
      <div className={classNames(styles.assignPeopleSearch)}>
        <div className={styles.dropDownSearch}>
          <DropdownSearch responsive multiple items={dropdownItems} label="Search and assign people" />
        </div>

        <div className={styles.scrollContainer}>
          <ListLayout>
            {currentEmployees.map(employee => (
              <ListItemLayout key={employee.slug}>
                <div className={classNames('card', styles.addedCardWrapper, styles.cardWrapper)}>
                  <div className={styles.cardContent}>
                    <span className="caption1" onClick={() => navigateToPerson(employee)}>
                      {employee.name}
                    </span>
                    <span className="caption2">{employee.email}</span>
                  </div>
                  <div className={styles.cardAction}>{loadingSlugs.includes(employee.slug!) ? <SpinnerIcon /> : <Anchor onClick={() => onClickMultiSelectItemHandler(employee)}>Remove</Anchor>}</div>
                </div>
              </ListItemLayout>
            ))}
          </ListLayout>

          {currentEmployees.length === 0 && <EmptyState centerText={true} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} badgeIconWidth={'full-width'} subtitle="No people assigned to this role" />}
        </div>
      </div>

      <Modal type="small" open={!!personToRemove} onClose={closeConfirmationModal}>
        {!!personToRemove && (
          <ConfirmationModalLayout
            title="Remove person"
            titleSize="standard"
            submitButtonText="Yes, remove"
            onClickSubmit={() => {
              handleRemovePerson(personToRemove);
              closeConfirmationModal();
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
            textAlign="left"
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove <strong>{personToRemove?.name}</strong> from this role?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </React.Fragment>
  );
}
