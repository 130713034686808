import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StarRating } from '../../../../components';
import { Datepicker, FuzzySearch, FuzzySearchParams, FuzzySearchResultsContainer } from '../../../../components/form-elements';
import { FetchStatus, FetchType } from '../../../../enums';
import { UniqueEntity } from '../../../../models/view/unique-entity';
import { useAppActions, useAppState } from '../../../../overmind';
import { AdditionalLearningViewModel, additionalLearningTypeIcons, AdditionalLearningTypeEnum } from '../additional-learning.models';
import { IAdditionalLearningFocusPanelLayoutProps } from './additional-learning-focus-panel.models';
import * as additionalLearningFocusPanelStyles from './additional-learning-focus-panel.styles';
import { AdditionalLearningFocusPanelSkeleton } from './additional-learning-focus-panel.skeleton';
import { FocusPanelLoaderLayer } from '../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { AddIcon, Anchor, Button, FormControl, FormGroup, ListItemLayout, ListLayout, PillButton, RadioButton, RadioButtonCard, RequiredValidator, Textfield, WebAddressValidator, XIcon } from '@keplerco/core';

export function AdditionalLearningFocusPanelLayout(props: IAdditionalLearningFocusPanelLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { user, fetchState } = useAppState();

  const { control, handleSubmit, setValue, getValues, watch } = useForm({ mode: 'onBlur', defaultValues: { dateStarted: new Date(), dateCompleted: new Date() } as any });

  const [skills, setSkills] = useState<UniqueEntity[]>();
  const [selectedSkills, setSelectedSkills] = useState<UniqueEntity[]>([]);
  const [skillsDirty, setSkillsDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<AdditionalLearningViewModel & { doReflection: boolean }>();
  const skillsRef = useRef<HTMLDivElement>(null);

  const [selectedType, setSelectedType] = useState<AdditionalLearningTypeEnum>();
  const [typeDirty, setTypeDirty] = useState<boolean>(false);
  const typeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    watch(() => {
      const values = getValues() as any;
      setFormValues(values);
    });

    if (!formValues) setFormValues(getValues() as any);
  }, []);

  useEffect(() => {
    async function getData() {
      if (!props.slug) return;

      actions.startLoader({ path: props.path, type: FetchType.DialogFetching });

      const data = await actions.getAdditionalLearning(props.slug);

      actions.stopLoader(props.path);

      if (!data) return;

      setValue('name', data.name);
      setValue('webAddress', data.webAddress);
      setValue('rating', data.rating);

      setSelectedType(data.type);
      setSelectedSkills(data.skills);
    }

    getData();
  }, [props.slug]);

  useEffect(() => {
    async function getData() {
      if (!props.skillSlug || (!!selectedSkills && selectedSkills.length > 0)) return;

      actions.startLoader({ path: props.path, type: FetchType.DialogFetching });

      const data = await actions.searchLearnerSkills('');
      setSelectedSkills(data?.filter(skill => skill.slug === props.skillSlug) ?? []);

      actions.stopLoader(props.path);
    }

    getData();
  }, [props.skillSlug]);

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setTypeDirty(true);
    setSelectedType(parseInt(event.target.value));
  }

  async function onFuzzySearchHandler(searchParams: FuzzySearchParams) {
    if (!searchParams.query) return setSkills([]);

    actions.startLoader({ path: props.path, type: FetchType.PageFetching });
    const data = await actions.searchLearnerSkills(searchParams.query);
    setSkills(data);
    actions.stopLoader(props.path);
  }

  function onAddPillActionedHandler(skill: UniqueEntity) {
    setSelectedSkills([...selectedSkills, skill]);
    setSkillsDirty(false);
  }

  function onRemovePillActionedHandler(skillId: number) {
    const nextState = [...selectedSkills.filter(temp => temp.id !== skillId)];
    setSelectedSkills(nextState);
    setSkillsDirty(nextState.length === 0);
  }

  function onClickHandler() {
    setTypeDirty(true);
    setSkillsDirty(true);

    if (selectedType === undefined) return setTimeout(() => typeRef.current?.scrollIntoView());
    if (!selectedSkills || selectedSkills.length === 0) return setTimeout(() => skillsRef.current?.scrollIntoView());
  }

  async function onSubmitHandler(value: any) {
    if (selectedType === undefined || !selectedSkills || selectedSkills.length === 0) return;

    const doReflection = value.doReflection === 'yes';
    delete value.doReflection;

    const model = {
      ...value,
      slug: props.slug,
      type: selectedType,
      skills: selectedSkills,
    } as AdditionalLearningViewModel;

    props.onSubmit(model, doReflection);
  }

  return (
    <FocusPanelLoaderLayer path={props.path} skeletonLoader={<AdditionalLearningFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <additionalLearningFocusPanelStyles.AdditionalLearningHeader className="dialogHeaderLayout">
          <h2 className="heading2">Track additional learning</h2>
        </additionalLearningFocusPanelStyles.AdditionalLearningHeader>

        <additionalLearningFocusPanelStyles.AdditionalLearningForm id="additionalLearning" onSubmit={handleSubmit(value => onSubmitHandler(value))}>
          <div className="dialogBodyLayout" ref={typeRef}>
            <FormGroup label="What would you like to track?">
              {typeDirty && selectedType === undefined && (
                <div className="formErrorMessage" style={{ marginBottom: 15 }}>
                  Please select a type
                </div>
              )}

              <additionalLearningFocusPanelStyles.AdditionalLearningGridCardLayout>
                {additionalLearningTypeIcons.map(typeIcon => (
                  <additionalLearningFocusPanelStyles.AdditionalLearningGridCard key={typeIcon.type}>
                    <RadioButtonCard id={typeIcon.name} name={typeIcon.name} value={typeIcon.type} checked={selectedType === typeIcon.type} onChange={onChangeHandler} checkicon>
                      <div className="card">
                        <div className="toggleCardVerticalContentLayout">
                          <div className="toggleCardIcon">
                            <img src={typeIcon.iconUrl} aria-hidden />
                          </div>

                          <label className="toggleCardLabel" style={{ textAlign: 'center' }} htmlFor={typeIcon.name}>
                            {typeIcon.description}
                          </label>
                        </div>
                      </div>
                    </RadioButtonCard>
                  </additionalLearningFocusPanelStyles.AdditionalLearningGridCard>
                ))}
              </additionalLearningFocusPanelStyles.AdditionalLearningGridCardLayout>

              <br />
            </FormGroup>

            <FormGroup label="When did you complete it?">
              <additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayout>
                <additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayoutColumn>
                  <FormControl
                    control={control}
                    rules={new RequiredValidator('Enter a start date')}
                    name="dateStarted"
                    render={({ field, fieldState }) => {
                      const defaultDate = !!field.value ? new Date(field.value) : new Date();
                      const maxDate = formValues?.dateCompleted instanceof Date ? formValues.dateCompleted : new Date();

                      return (
                        <React.Fragment>
                          <Datepicker autoHeight defaultDate={defaultDate} haserror={!!fieldState.error} maxDate={maxDate} label="Date started" onDateSelected={value => field.onChange(value)} />
                        </React.Fragment>
                      );
                    }}
                  />
                </additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayoutColumn>

                <additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayoutColumn>
                  <FormControl
                    control={control}
                    rules={new RequiredValidator('Enter a completion date')}
                    name="dateCompleted"
                    render={({ field, fieldState }) => {
                      const defaultDate = !!field.value ? new Date(field.value) : new Date();
                      const maxDate = new Date();

                      let minDate: Date | undefined = void 0;
                      if (!!formValues?.dateStarted) {
                        minDate = new Date(formValues?.dateStarted);
                        minDate.setDate(minDate.getDate() - 1);
                      }

                      return (
                        <React.Fragment>
                          <Datepicker autoHeight defaultDate={defaultDate} haserror={!!fieldState.error} minDate={minDate} maxDate={maxDate} label="Date completed" onDateSelected={value => field.onChange(value)} />
                        </React.Fragment>
                      );
                    }}
                  />
                </additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayoutColumn>
              </additionalLearningFocusPanelStyles.AdditionalLearningDatepickerLayout>
            </FormGroup>

            <FormGroup label="What was it about?">
              <FormControl
                control={control}
                rules={new RequiredValidator('Please enter a name')}
                name="name"
                render={({ field, fieldState }) => {
                  return <Textfield {...field} haserror={!!fieldState.error} label="Name" />;
                }}
              />

              <FormControl
                control={control}
                name="webAddress"
                rules={new WebAddressValidator(false, 'Please enter a valid web address')}
                render={({ field, fieldState }) => {
                  return <Textfield {...field} haserror={!!fieldState.error} label="Web address" />;
                }}
              />
            </FormGroup>

            <FormGroup label="What skills were you developing?" error={{ dirty: skillsDirty, hasError: selectedSkills?.length === 0, errorMsg: 'Please select at least one skill' }}>
              <additionalLearningFocusPanelStyles.AdditionalLearningFuzzySearchWrapper ref={skillsRef}>
                <FuzzySearch boxedResults disableEmptyState label="Start typing..." loading={fetchState[props.path].type === FetchType.PageFetching && fetchState[props.path].status === FetchStatus.Active} onFuzzySearch={onFuzzySearchHandler} fullWidthSearch={true}>
                  {!!skills &&
                    skills.length > 0 &&
                    skills
                      .filter(skill => !selectedSkills?.some(selectedSkill => selectedSkill.id === skill.id))
                      .map(skill => {
                        return <PillButton key={skill.id} id={skill.id} label={skill.name} icon={<AddIcon />} active backgroundColour="light" activeTextColour="black" onClick={() => onAddPillActionedHandler(skill)} />;
                      })}
                </FuzzySearch>

                {/* added skills */}
                {!!selectedSkills && selectedSkills.length > 0 && (
                  <FuzzySearchResultsContainer>
                    {selectedSkills.map(selectedSkill => {
                      return <PillButton key={selectedSkill.id} id={selectedSkill.id} label={selectedSkill.name} icon={<XIcon />} backgroundColour="baby-blue" onClick={() => onRemovePillActionedHandler(selectedSkill.id!)} />;
                    })}
                  </FuzzySearchResultsContainer>
                )}
              </additionalLearningFocusPanelStyles.AdditionalLearningFuzzySearchWrapper>
            </FormGroup>

            <FormGroup label="Your rating:">
              <FormControl
                control={control}
                rules={new RequiredValidator('How useful was this learning?')}
                name="rating"
                render={({ field }) => {
                  return <StarRating {...field} id="rating" />;
                }}
              />
            </FormGroup>

            {user?.allowReflections && (
              <FormGroup label="Would you like to reflect on your learning now?">
                <FormControl
                  control={control}
                  rules={new RequiredValidator('Do you want to reflect on your learning?')}
                  name="doReflection"
                  render={({ field }) => {
                    return (
                      <ListLayout wrap gap={20}>
                        <ListItemLayout>
                          <RadioButton id="yes" {...field} value="yes" checked={field.value === 'yes'}>
                            Yes
                          </RadioButton>
                        </ListItemLayout>

                        <ListItemLayout>
                          <RadioButton id="no" {...field} value="no" checked={field.value === 'no'}>
                            No, I will do this later
                          </RadioButton>
                        </ListItemLayout>
                      </ListLayout>
                    );
                  }}
                />
              </FormGroup>
            )}
          </div>

          <additionalLearningFocusPanelStyles.AdditionalLearningFocusPanelFooter className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
            <Button type="button" onClick={onClickHandler}>
              Submit
            </Button>

            {!!props.onCancel && <Anchor onClick={props.onCancel}>Cancel</Anchor>}
          </additionalLearningFocusPanelStyles.AdditionalLearningFocusPanelFooter>
        </additionalLearningFocusPanelStyles.AdditionalLearningForm>
      </div>
    </FocusPanelLoaderLayer>
  );
}

