import { ReducerAction } from '@keplerco/core';
import { Skill } from '../../../../../models';
import { EntityListResponse } from '../../../../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';

export interface ViewRoleAssignedSkillState {
  pageData?: EntityListResponse;
  hasPopulated: boolean;
  request: CompanyEntitySearchParams | undefined;
  assigningSkill: boolean;
  skillToRemove?: Skill;
  openConfirmationModal: boolean;
}

export enum ViewRoleAssignedSkillActionTypes {
  SetSkillToManage,
  SetData,
  SetSkillToRemove,
  SetRequest,
}

type ViewRoleAssignedSkillsActions =
  | ReducerAction<ViewRoleAssignedSkillActionTypes.SetSkillToManage, { payload: { open: boolean } }>
  | ReducerAction<ViewRoleAssignedSkillActionTypes.SetRequest, { payload: CompanyEntitySearchParams }>
  | ReducerAction<ViewRoleAssignedSkillActionTypes.SetSkillToRemove, { payload: { open: boolean; skill?: any } }>
  | ReducerAction<ViewRoleAssignedSkillActionTypes.SetData, { payload: EntityListResponse | undefined }>;

export const initialState: ViewRoleAssignedSkillState = {
  request: undefined,

  hasPopulated: false,
  assigningSkill: false,
  openConfirmationModal: false,
};
export function reducer(state: ViewRoleAssignedSkillState, action: ViewRoleAssignedSkillsActions) {
  if (action.type === ViewRoleAssignedSkillActionTypes.SetData) {
    return { ...state, pageData: action.payload, hasPopulated: true };
  }
  if (action.type === ViewRoleAssignedSkillActionTypes.SetSkillToManage) {
    return { ...state, assigningSkill: action.payload.open };
  }
  if (action.type === ViewRoleAssignedSkillActionTypes.SetSkillToRemove) {
    return { ...state, openConfirmationModal: action.payload.open, skillToRemove: action.payload.skill };
  }
  if (action.type === ViewRoleAssignedSkillActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  return state;
}
