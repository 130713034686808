import { IPillButton } from '@keplerco/core';
import { LeaderboardResponse } from '../../../models/overmind/analytics';

interface Paging {
  pageNumber: number;
  pageCount: number;
  pageData: LeaderboardResponse[];
}

export type LeaderboardType = 'Kepler Points' | 'Skill Level';

interface PeopleLeaderboardState {
  loading: boolean;
  pillButtons: IPillButton[];
  leaderboardType?: LeaderboardType;
  keplerPointsLeaderboard: LeaderboardResponse[];
  skillLevelLeaderboard: LeaderboardResponse[];
  paging: Paging;
}

export const initialState: PeopleLeaderboardState = {
  loading: true,
  pillButtons: [],
  keplerPointsLeaderboard: [],
  skillLevelLeaderboard: [],
  paging: { pageNumber: 1, pageCount: 0, pageData: [] },
};

export enum PeopleLeaderboardActionTypes {
  SetLoading,
  SetLeaderboardType,
  SetKeplerPointsLeaderboard,
  SetSkillLevelLeaderboard,
  SetPaging,
}

type PeopleLeaderboardActions =
  | { type: PeopleLeaderboardActionTypes.SetLoading; payload: boolean }
  | { type: PeopleLeaderboardActionTypes.SetLeaderboardType; payload: LeaderboardType }
  | { type: PeopleLeaderboardActionTypes.SetKeplerPointsLeaderboard; payload: LeaderboardResponse[] }
  | { type: PeopleLeaderboardActionTypes.SetSkillLevelLeaderboard; payload: LeaderboardResponse[] }
  | { type: PeopleLeaderboardActionTypes.SetPaging; payload: Paging };

export function reducer(state: PeopleLeaderboardState, action: PeopleLeaderboardActions): PeopleLeaderboardState {
  switch (action.type) {
    case PeopleLeaderboardActionTypes.SetLoading: {
      return { ...state, loading: action.payload };
    }

    case PeopleLeaderboardActionTypes.SetLeaderboardType: {
      const nextState = { ...state, leaderboardType: action.payload };
      nextState.pillButtons.forEach(pillButton => (pillButton.active = pillButton.label === action.payload));
      return nextState;
    }

    case PeopleLeaderboardActionTypes.SetKeplerPointsLeaderboard: {
      const nextState = { ...state, keplerPointsLeaderboard: action.payload };
      if (!nextState.leaderboardType) nextState.leaderboardType = 'Kepler Points';
      if (!nextState.pillButtons.some(pillButton => pillButton.label === 'Kepler Points')) nextState.pillButtons.push({ label: 'Kepler Points', active: nextState.leaderboardType === 'Kepler Points' });
      return nextState;
    }

    case PeopleLeaderboardActionTypes.SetSkillLevelLeaderboard: {
      const nextState = { ...state, skillLevelLeaderboard: action.payload };
      if (!nextState.leaderboardType) nextState.leaderboardType = 'Skill Level';
      if (!nextState.pillButtons.some(pillButton => pillButton.label === 'Skill Level')) nextState.pillButtons.push({ label: 'Skill Level', active: nextState.leaderboardType === 'Skill Level' });
      return nextState;
    }

    case PeopleLeaderboardActionTypes.SetPaging: {
      return { ...state, paging: action.payload };
    }

    default: {
      return state;
    }
  }
}

