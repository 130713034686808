import React, { useState, useEffect } from 'react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CzechFlag, EnglishFlag, FrenchFlag, GermanFlag, ItalianFlag, NetherlandsFlag, PortugalFlag, SouthKoreanFlag, SpanishFlag, VietnameseFlag } from './weglot-switcher.svgs';
import { IWeglotLanguageSwitcherProps, LanguageAbbreviations, LanguageOptionProps, LanguageOptionType } from './weglot-switcher.models';
import { Button, Container, LanguageOptionElement, Modal, ModalHeading } from './weglot-switcher.styles';

export default function WeglotLanguageSwitcher({ position = 'bottomLeft' }: IWeglotLanguageSwitcherProps) {
  const [currentLang, setCurrentLang] = useState<keyof LanguageAbbreviations>('en');
  const [showModal, setShowModal] = useState(false);

  const languageOptions: LanguageOptionType[] = [
    { code: 'en', flag: <EnglishFlag />, name: 'English', abbreviation: 'ENG' },
    { code: 'fr', flag: <FrenchFlag />, name: 'Français', abbreviation: 'FRA' },
    { code: 'es', flag: <SpanishFlag />, name: 'Español', abbreviation: 'SPA' },
    { code: 'it', flag: <ItalianFlag />, name: 'Italian', abbreviation: 'ITA' },
    { code: 'vi', flag: <VietnameseFlag />, name: 'Vietnamese', abbreviation: 'VIE' },
    { code: 'pt', flag: <PortugalFlag />, name: 'Portuguese', abbreviation: 'POR' },
    { code: 'de', flag: <GermanFlag />, name: 'German', abbreviation: 'GER' },
    { code: 'ko', flag: <SouthKoreanFlag />, name: 'Korean', abbreviation: 'KOR' },
    { code: 'nl', flag: <NetherlandsFlag />, name: 'Dutch', abbreviation: 'DUT' },
    { code: 'cs', flag: <CzechFlag />, name: 'Czech', abbreviation: 'CZE' },
  ];

  useEffect(() => {
    if ((window as any).Weglot) {
      setCurrentLang((window as any).Weglot.getCurrentLang());
    }
  }, []);
  function handleLanguageChange(lang: keyof LanguageAbbreviations) {
    if ((window as any).Weglot) {
      (window as any).Weglot.switchTo(lang);
      setCurrentLang(lang);
      setShowModal(false);
    }
  }

  function LanguageOption({ langCode, children }: LanguageOptionProps) {
    return (
      <LanguageOptionElement onClick={() => handleLanguageChange(langCode)} style={{}}>
        {children}
        {currentLang === langCode && <CheckIcon />}
      </LanguageOptionElement>
    );
  }

  return (
    <Container position={position}>
      <Button onClick={() => setShowModal(!showModal)}>
        {languageOptions.find(option => option.code === currentLang)?.flag}
        {languageOptions.find(option => option.code === currentLang)?.abbreviation}
        {showModal ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
      {showModal && (
        <Modal position={position}>
          <ModalHeading className="heading5">Language</ModalHeading>
          {languageOptions.map(({ code, flag, name }: LanguageOptionType) => (
            <LanguageOption key={code} langCode={code} onClick={() => handleLanguageChange(code)}>
              {flag} {name}
            </LanguageOption>
          ))}
        </Modal>
      )}
    </Container>
  );
}



