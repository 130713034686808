import React from 'react';
import { AnalyticsGridSection } from '../analytics.styles';
import { Daterange } from '../../../components';
import { useAppActions, useAppState } from '../../../overmind';
import { EntitySkeleton } from './entity.skeleton';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { IEntityLayoutProps } from './entity.models';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { DailyActivityWidget, SkillScoreOverTimeWidget } from '../../../widgets';
import { OrganizationLevelType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { EntityAverageWidget } from '../overview/entity-average/entity-average.widget';
import { SkillsBreakdownWidget } from '../../../widgets/analytics/skills-breakdown/skills-breakdown.widget';
import { PageHeader, capitalize } from '@keplerco/core';

export function EntityLayout(props: IEntityLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  return (
    <PageLoaderLayer path={props.path} skeletonLoader={<EntitySkeleton />}>
      <div className="pageWrapper wrapper">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: `${PagePath.analyticsBase}`,
            },
            {
              name: `${capitalize(props.type)}s`,
              url: `${PagePath.analyticsBase}${props.parentPath.replace(':companySlug', companyVariables.slug!)}`,
            },
            {
              name: props.name,
              url: `${PagePath.analyticsBase}${props.path.replace(':companySlug', companyVariables.slug!)}/${props.slug}`,
            },
          ]}
          title={props.name}
          type="actions"
        >
          <div className="headerActions">
            <Daterange defaultValue={dateRangeOutput} onDaterangeChange={actions.setGlobalDateRange} />
          </div>
        </PageHeader>

        <AnalyticsGridSection>
          <div className="start-1">
            <EntityAverageWidget
              entity={{
                organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
                entity: {
                  slug: props.slug,
                  name: props.name,
                },
              }}
            />
          </div>

          <div className="end-2">
            <SkillsBreakdownWidget
              entity={{
                organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
                entity: {
                  slug: props.slug,
                  name: props.name,
                },
              }}
              path={props.path}
            />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection>
          <div className="full-span">
            <CourseEngagementWidget
              entity={{
                organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
                entity: {
                  slug: props.slug,
                  name: props.name,
                },
              }}
              isLearner={false}
            />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection>
          <div className="full-span">
            <SkillScoreOverTimeWidget
              entity={{
                organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
                entity: {
                  slug: props.slug,
                  name: props.name,
                },
              }}
            />
          </div>
        </AnalyticsGridSection>

        <AnalyticsGridSection>
          <div className="full-span">
            <DailyActivityWidget
              entity={{
                organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
                entity: {
                  slug: props.slug,
                  name: props.name,
                },
              }}
            />
          </div>
        </AnalyticsGridSection>
      </div>
    </PageLoaderLayer>
  );
}

