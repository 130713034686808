import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { ReducerAction } from '../../../models/reducer-state';
import { EmployeeSearchResponse, PersonListItem } from '../../../models/view/employee-search-response';
import { PeopleCMSLayoutData, PeopleHeaderContentCMSLayoutData } from './people.cms.models';
import { createDepartmentLink, createTeamLink } from './people.cms.helpers';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagePath } from '../../../navigation/navigation.enums';
import { UniqueEntity } from '../../../models';
import { SortField } from '../../../enums';
import { FocusPanelViewData } from '../../../lib/focus-panel.models';
import { Breadcrumb } from '@keplerco/core';

interface PeopleCMSState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  request: BaseSearchRequest;
  data: EmployeeSearchResponse | undefined;

  // dialogs
  showBulkUpload?: boolean;
  pendingInvites?: PersonListItem[];
  personToArchive?: PersonListItem;
  personToManage?: FocusPanelViewData<string | undefined>;

  // columns
  managingColumns?: boolean;
  columnConfiguration: ColumnConfiguration[];

  // filters
  departments: UniqueEntity[] | undefined;
  teams: UniqueEntity[] | undefined;
  countries: UniqueEntity[] | undefined;
}

export const initialState: PeopleCMSState = {
  pageTitle: 'People',
  crumbs: [],
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 15 },
  data: undefined,

  // columns
  columnConfiguration: [
    { label: 'Name', key: 'Name', required: true },
    { label: 'Department', key: 'Department', required: false, selected: true },
    { label: 'Team', key: 'Team', required: false, selected: true },
    { label: 'Email', key: 'Email', required: false, selected: true },
    { label: 'Country', key: 'Country', required: false, selected: true },
    { label: 'Status', key: 'Status', required: false, selected: true },
    { label: 'Date Created', key: 'DateCreated', required: false, selected: true },
  ],

  // filters
  departments: undefined,
  teams: undefined,
  countries: undefined,
};

export enum PeopleCMSActionTypes {
  SetHeader = 0,
  SetRequest = 1,
  SetData = 2,

  // dialogs
  SetBulkUpload = 3,
  SetPendingInvites = 4,
  SetPersonToArchive = 5,
  SetPersonToManage = 6,

  // columns
  ManageColumns = 7,
  EditColumnConfiguration = 8,

  // filters
  SetDepartments = 9,
  SetTeams = 10,
  SetCountries = 11,
}

type PeopleCMSActions =
  | ReducerAction<PeopleCMSActionTypes.SetHeader, { payload: PeopleHeaderContentCMSLayoutData }>
  | ReducerAction<PeopleCMSActionTypes.SetRequest, { payload: BaseSearchRequest }>
  | ReducerAction<PeopleCMSActionTypes.SetData, { payload: PeopleCMSLayoutData }>

  // dialogs
  | ReducerAction<PeopleCMSActionTypes.SetBulkUpload, { payload: boolean }>
  | ReducerAction<PeopleCMSActionTypes.SetPendingInvites, { payload: PersonListItem[] | undefined }>
  | ReducerAction<PeopleCMSActionTypes.SetPersonToArchive, { payload: PersonListItem | undefined }>
  | ReducerAction<PeopleCMSActionTypes.SetPersonToManage, { payload: FocusPanelViewData<string | undefined> }>

  // columns
  | ReducerAction<PeopleCMSActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<PeopleCMSActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>

  // filters
  | ReducerAction<PeopleCMSActionTypes.SetDepartments, { payload: UniqueEntity[] | undefined }>
  | ReducerAction<PeopleCMSActionTypes.SetTeams, { payload: UniqueEntity[] | undefined }>
  | ReducerAction<PeopleCMSActionTypes.SetCountries, { payload: UniqueEntity[] | undefined }>;

export function reducer(state: PeopleCMSState, action: PeopleCMSActions): PeopleCMSState {
  if (action.type === PeopleCMSActionTypes.SetHeader) {
    let crumbs: Breadcrumb[] = [
      { name: 'Companies', url: PagePath.companies },
      { name: !!action.payload.entityName ? `People in ${action.payload.entityName}` : 'People', url: `${PagePath.administrationBase}${PagePath.administrationPeople.replace(':companySlug', action.payload.companySlug)}` },
    ];

    if (!!action.payload.teamSlug) {
      crumbs = [
        { name: 'Companies', url: PagePath.companies },
        { name: 'Teams', url: `${PagePath.administrationBase}${PagePath.administrationTeams.replace(':companySlug', action.payload.companySlug)}` },
        { name: !!action.payload.entityName ? `People in ${action.payload.entityName}` : 'People', url: createTeamLink(action.payload.companySlug, action.payload.teamSlug!) },
      ];
    } else if (!!action.payload.departmentSlug) {
      crumbs = [
        { name: 'Companies', url: PagePath.companies },
        { name: 'Departments', url: `${PagePath.administrationBase}${PagePath.administrationDepartments.replace(':companySlug', action.payload.companySlug)}` },
        { name: !!action.payload.entityName ? `People in ${action.payload.entityName}` : 'People', url: createDepartmentLink(action.payload.companySlug, action.payload.departmentSlug!) },
      ];
    }

    return {
      ...state,
      pageTitle: !!action.payload.entityName ? `People in ${action.payload.entityName}` : 'People',
      crumbs: crumbs,
    };
  }

  if (action.type === PeopleCMSActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetData) {
    return {
      ...state,
      data: action.payload.data,
    };
  }

  // dialogs
  if (action.type === PeopleCMSActionTypes.SetBulkUpload) {
    return { ...state, showBulkUpload: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetPendingInvites) {
    return { ...state, pendingInvites: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetPersonToArchive) {
    return { ...state, personToArchive: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetPersonToManage) {
    return { ...state, personToManage: action.payload };
  }

  // columns
  if (action.type === PeopleCMSActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === PeopleCMSActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  // filters
  if (action.type === PeopleCMSActionTypes.SetDepartments) {
    return { ...state, departments: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetTeams) {
    return { ...state, teams: action.payload };
  }

  if (action.type === PeopleCMSActionTypes.SetCountries) {
    return { ...state, countries: action.payload };
  }

  return state;
}

