import React from 'react';
import { IRoutingMapProps } from '../../../models/routing-map';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppState } from '../../../overmind';
import { Navigate } from 'react-router-dom';
import { AssessmentType } from '../../../enums/assessment-type';

export function SelfAssessmentRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`;
  const previousPage = `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function TechnicalPeerEndorsementInterstitialRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const previousPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`;
  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function TechnicalPeerEndorsementRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const previousPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`;
  const nextPage = PagePath.analysisBase;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function TechnicalPeerEndorsementRateRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const previousPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`;
  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function CareerPathsLandingRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.FieldsOfPractice) return <Navigate to={PagePath.analysisBase} />;

  return <React.Fragment>{props.children({ next: PagePath.analysisBase, previous: PagePath.analysisBase })}</React.Fragment>;
}

export function CareerPathRatingRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.FieldsOfPractice) return <Navigate to={PagePath.analysisBase} />;

  const previousPage = `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`;
  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

