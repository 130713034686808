import React, { useEffect, useRef, useState } from 'react';
import { Anchor, Button, PageHeader } from '@keplerco/core';
import styles from './manage-role.module.css';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../../overmind';
import { CMSColumn, CMSRow } from '../../../../theme/layout.styles';
import { useParams } from 'react-router-dom';
import { PagePath } from '../../../../navigation/navigation.enums';
import { FetchType } from '../../../../enums';
import { AssignSkillPanel } from './assign-skill/assign-skill';
import { AssignPersonPanel } from './assign-role/assign-role';
import RoleDetails from './role-details/role-details';
import { useSearchParams } from 'react-router-dom';
import { EntityResponse } from '../../../../models/overmind/entities';

export function ManageRolePage(): JSX.Element {
  const [role, setRole] = useState<EntityResponse>();
  const topAnchorRef = useRef<HTMLDivElement>(null);
  const roleDetailsRef = useRef<{ submitForm: () => void }>(null);

  const actions = useAppActions();
  const { companySlug, roleSlug } = useParams();
  const [searchParams] = useSearchParams();
  const isImport = searchParams.get('isImport') === 'true';

  const isCreate = !role?.slug;

  const { companyVariables } = useAppState();

  async function fetchRole() {
    actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });
    const roleDescription = await actions.getRole({ companySlug: companySlug!, roleSlug: roleSlug! });
    if (roleDescription) setRole(roleDescription);
    actions.stopLoader(PagePath.administrationSelectedRoleEdit);
  }

  useEffect(() => {
    fetchRole();
  }, [roleSlug]);

  return (
    <div className="pageWrapper wrapper">
      <CMSRow>
        <CMSColumn className={styles.viewRoleHeaderColumn} style={{ width: '100%' }}>
          <PageHeader
            breadcrumbs={[
              { name: `${companyVariables.name}`, url: PagePath.companies },
              { name: 'Roles', url: `${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companyVariables.slug!)}` },
              { name: `${role?.name}`, url: `${PagePath.administrationBase}${PagePath.administrationSelectedRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role?.slug ?? '')}` },
              { name: isImport ? 'Import' : isCreate ? 'Create new role' : 'Edit', url: window.location.pathname },
            ].filter(i => i.name.toLowerCase() !== 'undefined' && !!i.name)}
            title={isCreate ? `Create new role` : `Edit ${role?.name}`}
          />
        </CMSColumn>
      </CMSRow>

      <div className={styles.formCardsWrapper}>
        <div className={classNames('card', styles.formCard)}>
          <RoleDetails
            ref={roleDetailsRef}
            role={role}
            onRoleCreated={role => {
              setRole(role);
              window.history.replaceState(null, '', `${PagePath.administrationBase}${PagePath.administrationSelectedRoleEdit.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug!)}`);
              setTimeout(() => topAnchorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }));
            }}
          />
          <div ref={topAnchorRef} />
        </div>

        {/* TODO: add when related roles is ready */}
        {/* {!isImport && (
          <div className={classNames('card', styles.viewRoleHeaderCard)}>
            <div className={styles.cardContent}>
              <h5 className="heading5">{role?.name}</h5>
              <EmptyState subtitle="No related roles assigned" badgeIconWidth={'full-width'} badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} />
            </div>
          </div>
        )} */}
      </div>

      <div className={classNames('card', styles.formCard, { [styles.disabledCard]: !role })}>
        <div className="cardHeader">
          <h5 className="heading5">Skills</h5>
          <p className="caption1">Assign and confirm skills allocated to {role?.name ?? 'this'} role</p>
        </div>

        <AssignSkillPanel roleSlug={role?.slug} />
      </div>

      <div className={classNames('card', styles.formCard, { [styles.disabledCard]: !role })}>
        <div className="cardHeader">
          <h5 className="heading5">People</h5>
          <p className="caption1">Assign and confirm skills allocated to {role?.name ?? 'this'} role</p>
        </div>
        <AssignPersonPanel roleSlug={role?.slug} />
      </div>

      <footer className={classNames('dialogFooterLayout', 'modalFooterLayout', styles.footerStyling)} style={{ flex: 1, justifyContent: 'space-between' }}>
        <Anchor>cancel</Anchor>

        <Button
          type="button"
          onClick={() => {
            if (roleDetailsRef.current) {
              roleDetailsRef.current.submitForm();
            }
          }}
        >
          Submit
        </Button>
      </footer>
    </div>
  );
}
