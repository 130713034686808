import React, { useEffect, useState } from 'react';
import { ColumnConfiguratorWidgetFooter, ColumnConfiguratorWidgetHeader, ColumnConfiguratorWidgetListCard, ColumnConfiguratorWidgetListCardLayout, ColumnConfiguratorWidgetListWrapper } from './column-configurator.widget.styles';
import { noop } from 'overmind';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { Anchor, Button, Checkbox, ListItemLayout, ListLayout, colourString, safeCallback } from '@keplerco/core';

interface IColumnConfiguratorWidgetProps {
  columns: ColumnConfiguration[];
  defaultSelectionColumns?: ColumnConfiguration[];
  onCancel?: () => void;
  onSave?: (selection: ColumnConfiguration[]) => void;
  onReset?: () => void;
}

export function ColumnConfiguratorWidget(props: IColumnConfiguratorWidgetProps): JSX.Element {
  const [selectionColumns, setSelectionColumns] = useState(props.defaultSelectionColumns);

  useEffect(() => {
    setSelectionColumns([...props.columns.filter(col => col.required || col.selected)]);
  }, [props.columns]);

  return (
    <div className="dialogContentLayout.focusPanelContentLayout">
      <ColumnConfiguratorWidgetHeader>
        <h3 className="heading3">Configure fields</h3>
        <span className="caption1">Select which fields you want to view</span>
        <Anchor onClick={props.onReset} type="span">
          Reset visible columns
        </Anchor>
      </ColumnConfiguratorWidgetHeader>

      <ColumnConfiguratorWidgetListWrapper>
        <ListLayout>
          {props.columns?.map((col, index) => {
            const checked: boolean = !!col.required || !!selectionColumns?.find(i => i.key === col.key && col.label === col.label);

            return (
              <ListItemLayout key={col.key ?? col.label}>
                <ColumnConfiguratorWidgetListCard
                  className="card"
                  onClick={() => {
                    if (col.required) return;

                    if (checked && !!selectionColumns) {
                      setSelectionColumns([...selectionColumns.filter(i => !(i.key === col.key && col.label === col.label))]);
                    } else setSelectionColumns([...(selectionColumns ?? []), col]);
                  }}
                >
                  <>{col.label ?? col.key}</>

                  <ColumnConfiguratorWidgetListCardLayout>
                    {!!col.required && (
                      <span className="caption2" style={{ color: colourString('text_1') }}>
                        This column cannot be hidden
                      </span>
                    )}
                    {!col.required && (
                      <React.Fragment>
                        {checked ? (
                          <span className="caption2" style={{ color: colourString('text_1') }}>
                            This column is visible
                          </span>
                        ) : (
                          <span className="caption2" style={{ color: colourString('text_1') }}>
                            This column is hidden
                          </span>
                        )}
                      </React.Fragment>
                    )}

                    <Checkbox disabled={!!col.required} checked={checked} onChange={() => noop()} id={col.key ?? index.toString()} />
                  </ColumnConfiguratorWidgetListCardLayout>
                </ColumnConfiguratorWidgetListCard>
              </ListItemLayout>
            );
          })}
        </ListLayout>
      </ColumnConfiguratorWidgetListWrapper>

      <ColumnConfiguratorWidgetFooter className="dialogFooterLayout focusPanelFooterLayout">
        <Anchor
          onClick={() => {
            setSelectionColumns([...props.columns.filter(col => col.required || col.selected)]);
            safeCallback(props.onCancel);
          }}
          type="span"
        >
          Discard
        </Anchor>

        <Button
          onClick={() =>
            safeCallback(props.onSave, [
              ...props.columns.map(col => {
                const isSelected = !!selectionColumns?.find(s => s.key === col.key && s.label === col.label);
                return {
                  ...col,
                  selected: isSelected,
                };
              }),
            ])
          }
          type="button"
          filled
        >
          Save Changes
        </Button>
      </ColumnConfiguratorWidgetFooter>
    </div>
  );
}

