import { OrganizationLevelType } from '../enums';
import { extractHighestOrganizationLevel } from '../lib/permissions.helpers';
import { themedAssetUrl } from '../lib/theme-asset-url';
import { CourseSmallViewModel } from '../models/overmind/learner-skill-with-courses';
import { OrganizationLevel, Permissions } from '../models/overmind/permissions';
import { SkillAssessmentConfiguration } from '../models/skill-assessment-configuration';
import { User } from '../models/user';
import { Mode, PagePath } from './navigation.enums';
import { ISideNavChildRoute, ISideNavRoute } from './side-nav/side-nav.models';

const learnerRoutes: ISideNavRoute[] = [
  {
    icon: themedAssetUrl('icons/sidebar/home.icon.svg'),
    path: PagePath.dashboard,
    title: 'Dashboard',
  },

  {
    icon: themedAssetUrl('icons/sidebar/skills-gaps.icon.svg'),
    path: PagePath.yourSkillsBase,
    title: 'Your Skills',
  },

  {
    icon: themedAssetUrl('icons/sidebar/learning-journey.icon.svg'),
    path: PagePath.learningJourneyBase,
    title: 'Learning Journey',
    children: [],
  },
  {
    icon: themedAssetUrl('icons/sidebar/assessments.icon.svg'),
    path: PagePath.learningCompletedAssessments,
    title: 'Assessments',
  },
];

const adminRoute: ISideNavRoute = {
  icon: themedAssetUrl('icons/sidebar/company.icon.svg'),
  path: PagePath.administrationBase,
  title: 'Administration',
  children: [
    {
      path: `${PagePath.administrationBase}${PagePath.administrationDepartments}`,
      title: 'Departments',
      exactMatch: true,
    },
    {
      path: `${PagePath.administrationBase}${PagePath.administrationTeams}`,
      title: 'Teams',
      exactMatch: true,
    },
    {
      path: `${PagePath.administrationBase}${PagePath.administrationPeople}`,
      title: 'People',
      exactMatch: true,
    },
    // TODO: Hide if roles permissions don't get sorted in time
    {
      path: `${PagePath.administrationBase}${PagePath.administrationRoles}`,
      title: 'Roles',
      exactMatch: true,
    },
    {
      path: `${PagePath.administrationBase}${PagePath.administrationTags}`,
      title: 'Tags',
      exactMatch: true,
    },
  ],
};

const analyticsRoute: ISideNavRoute = {
  icon: themedAssetUrl('icons/sidebar/analytics.icon.svg'),
  path: PagePath.analyticsBase,
  title: 'Analytics',
  children: [
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsOverview}`,
      title: 'Overview',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsEngagement}`,
      title: 'Engagement',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsDepartments}`,
      title: 'Departments',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsTeams}`,
      title: 'Teams',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsPeople}`,
      title: 'People',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsSkills}`,
      title: 'Skills',
    },
    {
      path: `${PagePath.analyticsBase}${PagePath.analyticsReportTemplates}`,
      title: 'Report templates',
    },
  ],
};

const assessmentsRoute: ISideNavRoute[] = [
  {
    icon: themedAssetUrl('icons/sidebar/assessments.icon.svg'),
    path: PagePath.assessmentsBase,
    title: 'Assessments',
  },
];

const learningManagementRoute: ISideNavRoute = {
  icon: themedAssetUrl('icons/sidebar/learning-management.icon.svg'),
  path: PagePath.learningManagementBase,
  title: 'Learning Management',
  children: [
    {
      path: `${PagePath.learningManagementBase}${PagePath.learningManagementCourseMapping}`,
      title: 'Course mapping',
    },
    //TODO: add back when Mapped Courses page is ready
    // {
    //   path: `${PagePath.learningManagementBase}${PagePath.learningManagementMappedCourses}`,
    //   title: 'Mapped Courses',
    // },
  ],
};

const reportManagementRoute: ISideNavRoute = {
  icon: themedAssetUrl('icons/sidebar/report-management.icon.svg'),
  path: PagePath.reportManagement,
  title: 'Report management',
};

const skillsRoute: ISideNavRoute[] = [
  {
    icon: themedAssetUrl('icons/sidebar/skills-gaps.icon.svg'),
    path: PagePath.skillsBase,
    title: 'Skills',
  },
];

export function generateLearnerRoutes(user: User | undefined, skillAssessmentConfiguration: SkillAssessmentConfiguration | undefined, yearCourses: CourseSmallViewModel[], assignedCourses: CourseSmallViewModel[]): ISideNavRoute[] {
  if (!user?.completedOnboarding || !skillAssessmentConfiguration?.benchmarkResultReady) return [];

  const routes: ISideNavRoute[] = structuredClone(learnerRoutes);

  if (!!user.jobRoles.length) {
    routes.push({
      icon: themedAssetUrl('icons/sidebar/career-pathways.icon.svg'),
      title: 'My Career',
      path: PagePath.myCareer,
    });
  }

  const learningJourneySideNavParentRoute = routes.find(route => route.path === PagePath.learningJourneyBase);
  if (!learningJourneySideNavParentRoute) return routes;

  if (yearCourses.length > 0 || assignedCourses.length > 0 || user?.allowOffPlatformLearning || user?.allowReflections || user?.allowCertificateDownload) {
    learningJourneySideNavParentRoute.children?.push({
      path: PagePath.learningJourneyBase,
      title: 'Learning',
      exactMatch: true,
    });
  }

  if (yearCourses.length > 0) {
    yearCourses.forEach(yearCourse => {
      if (learningJourneySideNavParentRoute.children?.some(child => child.title === yearCourse.displayName)) return;

      learningJourneySideNavParentRoute.children?.push({
        path: `${PagePath.learningJourneyBase}${PagePath.learningJourneyYearSkill.replace(':skillSlug', yearCourse.slug)}`,
        title: yearCourse.displayName,
        exactMatch: true,
      });
    });
  }

  if (assignedCourses.length > 0) {
    assignedCourses.forEach(assignedCourse => {
      if (learningJourneySideNavParentRoute.children?.some(child => child.title === assignedCourse.displayName)) return;

      learningJourneySideNavParentRoute.children?.push({
        path: `${PagePath.learningJourneyBase}${PagePath.learningJourneyAssignedSkill.replace(':skillSlug', assignedCourse.slug)}`,
        title: assignedCourse.displayName,
        exactMatch: true,
      });
    });
  }

  if (user?.allowOffPlatformLearning) {
    learningJourneySideNavParentRoute.children?.push({
      path: `${PagePath.learningJourneyBase}${PagePath.learningJourneyAdditionalLearning}`,
      title: 'Additional learning',
      exactMatch: true,
    });
  }

  if (user?.allowReflections) {
    learningJourneySideNavParentRoute.children?.push({
      path: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCourseReflections}`,
      title: 'Course reflections',
      exactMatch: true,
    });
  }

  if (user?.allowCertificateDownload) {
    learningJourneySideNavParentRoute.children?.push({
      path: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCertificates}`,
      title: 'Certificates',
      exactMatch: true,
    });
  }

  return routes;
}

function populateCompanySlugs(currentRoutes: ISideNavRoute[], companySlug: string) {
  const nextRoutes: ISideNavRoute[] = structuredClone(currentRoutes);
  nextRoutes.forEach(parentRoute => {
    parentRoute.path = parentRoute.path.replace(`:companySlug`, companySlug);
    parentRoute.children?.forEach(childRoute => {
      childRoute.path = childRoute.path.replace(`:companySlug`, companySlug);
    });
  });
  return nextRoutes;
}

function removeChildRoute(currentChildren: ISideNavChildRoute[] | undefined, title: string): ISideNavChildRoute[] {
  if (!currentChildren) return [];
  const nextChildren = structuredClone(currentChildren);
  const childIndex = currentChildren.findIndex(child => child.title === title);
  nextChildren.splice(childIndex, 1);
  return nextChildren;
}

function generateAdminRoute(organizationLevel: OrganizationLevel | undefined): ISideNavRoute | undefined {
  if (!organizationLevel || organizationLevel.organizationLevel === OrganizationLevelType.Learner) return undefined;

  const route: ISideNavRoute = structuredClone(adminRoute);
  route.children = removeChildRoute(route.children, 'Report Management');

  switch (organizationLevel.organizationLevel) {
    case OrganizationLevelType.Company: {
      return route;
    }

    case OrganizationLevelType.Department: {
      route.children = removeChildRoute(route.children, 'Tags');

      if (organizationLevel.entities.length > 1) return route;

      route.children = removeChildRoute(route.children, 'Departments');
      return route;
    }

    case OrganizationLevelType.Team: {
      route.children = removeChildRoute(route.children, 'Departments');
      route.children = removeChildRoute(route.children, 'Tags');

      if (organizationLevel.entities.length > 1) return route;

      route.children = removeChildRoute(route.children, 'Teams');
      return route;
    }

    default: {
      return undefined;
    }
  }
}

function generateAnalyticsRoutes(organizationLevel: OrganizationLevel | undefined): ISideNavRoute | undefined {
  if (!organizationLevel || organizationLevel.organizationLevel === OrganizationLevelType.Learner) return undefined;

  const route: ISideNavRoute = structuredClone(analyticsRoute);

  switch (organizationLevel.organizationLevel) {
    case OrganizationLevelType.Company: {
      return route;
    }

    case OrganizationLevelType.Department: {
      if (organizationLevel.entities.length > 1) return route;

      route.children = removeChildRoute(route.children, 'Departments');
      return route;
    }

    case OrganizationLevelType.Team: {
      route.children = removeChildRoute(route.children, 'Departments');

      if (organizationLevel.entities.length > 1) return route;

      route.children = removeChildRoute(route.children, 'Teams');
      return route;
    }

    default: {
      return undefined;
    }
  }
}

function generateLearningManagementRoutes(organizationLevel: OrganizationLevel | undefined): ISideNavRoute | undefined {
  if (!organizationLevel || organizationLevel.organizationLevel === OrganizationLevelType.Learner) return undefined;

  const route: ISideNavRoute = structuredClone(learningManagementRoute);
  return route;
}

function generateAssessmentManagementRoutes(organizationLevel: OrganizationLevel | undefined): ISideNavRoute[] | undefined {
  if (!organizationLevel || organizationLevel.organizationLevel === OrganizationLevelType.Learner) return undefined;

  const route: ISideNavRoute[] = structuredClone(assessmentsRoute);
  return route;
}

export function generateCompanyRoutes(user: User | undefined, skillAssessmentConfiguration: SkillAssessmentConfiguration | undefined, activeCompanySlug: string | undefined, mode: Mode, permissions: Permissions | undefined): ISideNavRoute[] {
  const companySlug = activeCompanySlug ?? user?.companySlug;
  let routes: ISideNavRoute[] = [];
  if (!user?.completedOnboarding || !skillAssessmentConfiguration?.benchmarkResultReady || !companySlug || (user?.isSystemAdmin && mode === Mode.LearningDashboard)) return routes;

  if (user?.isSystemAdmin && mode === Mode.PlatformManagement) {
    routes = routes.concat(adminRoute, analyticsRoute, assessmentsRoute, learningManagementRoute, reportManagementRoute, skillsRoute);
    return populateCompanySlugs(routes, companySlug);
  }

  if (!!permissions?.administration) {
    const organizationLevel = extractHighestOrganizationLevel(permissions.administration.organizationLevels);
    const route = generateAdminRoute(organizationLevel);
    if (!!route) routes = routes.concat(route);
  }

  if (!!permissions?.analytics) {
    const organizationLevel = extractHighestOrganizationLevel(permissions.analytics.organizationLevels);
    const route = generateAnalyticsRoutes(organizationLevel);
    if (!!route) routes = routes.concat(route);
  }

  if (!!permissions?.learningManagement) {
    const organizationLevel = extractHighestOrganizationLevel(permissions.learningManagement.organizationLevels);
    const route = generateLearningManagementRoutes(organizationLevel);
    if (!!route) routes = routes.concat(route);
  }

  if (!!permissions?.assessments) {
    const organizationLevel = extractHighestOrganizationLevel(permissions.assessments.organizationLevels);
    const route = generateAssessmentManagementRoutes(organizationLevel);
    if (!!route) routes = routes.concat(route);
  }

  // TODO: add when Skills APIs updated for Permissions

  return populateCompanySlugs(routes, companySlug);
}

export function keplerActive(exactMatch: boolean, path: string, pathname: string): boolean {
  if (exactMatch && pathname === path) return true;
  if (!exactMatch && pathname.startsWith(path)) return true;
  return false;
}
