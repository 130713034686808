import React, { useEffect, useState } from 'react';
import { OrganizationLevelType } from '../../../enums';
import {
  SkillsBreakdownWidgetHeader,
  SkillsBreakdownWidgetNavlink,
  SkillsBreakdownWidgetColumn,
  SkillsBreakdownWidgetBody,
  SkillsBreakdownWidgetColumnDataCell,
  SkillsBreakdownWidgetColumnLabelCell,
  SkillsBreakdownWidgetContainer,
  SkillsBreakdownWidgetOrderButton,
  SkillsBreakdownWidgetColumnSkillNameCell,
} from './skills-breakdown.styles';
import { ISkillsBreakdownWidgetProps, SkillsBreakdownSkill } from './skills-breakdown.models';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { SkillScoresRequest, SkillScoresSkillTypeResponse } from '../../../models/overmind/analytics';
import { ResponseGroup } from '../../../enums/analytics';
import { SkeletonLoader } from '../../../components';
import { ExplainerFocusPanelLayout } from '../../layouts';
import { FocusPanel, HelpIcon, IPillButton, IconButton, OrderButton, OrderType, Pager, PillButton, SkillLevel, SkillPercentage, ragColourType } from '@keplerco/core';
import { skillLevelRAGColour } from '../../../lib/skill-level-rag-colour.helper';

const sortTypes: Map<OrderType, string> = new Map<OrderType, string>([
  [OrderType.Ascending, 'Low to High'],
  [OrderType.Descending, 'High to Low'],
  [OrderType.Organic, 'Alphabetical'],
]);
const pageSize = 10;

export function SkillsBreakdownWidget({ entity, path }: ISkillsBreakdownWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, dateRange } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [skillTypes, setSkillTypes] = useState<SkillScoresSkillTypeResponse[]>([]);

  const [filters, setFilters] = useState<IPillButton[]>([]);
  const [activeFilter, setActiveFilter] = useState<IPillButton>();
  const [filteredSkills, setFilteredSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [activeSortType, setActiveSortType] = useState<OrderType>(OrderType.Organic);
  const [sortedSkills, setSortedSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [pagedSkills, setPagedSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      setLoading(true);

      const searchParams: SkillScoresSearchParams = {
        startDate: path === PagePath.dashboard ? undefined : dateRange?.from?.toJSON(),
        endDate: path === PagePath.dashboard ? undefined : dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        learnerSlug: undefined,
        teamSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: true,
        skillLevelComparison: companyVariables.skillScoreComparison,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;

      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams: searchParams,
      };

      const tempData = await actions.analyticsGetSkillScores(request);

      const tempSkillTypes: SkillScoresSkillTypeResponse[] = !tempData || !tempData[0] || !tempData[0].skillTypes ? [] : tempData[0].skillTypes;
      tempSkillTypes.forEach(skillType => skillType.skillSubTypes?.forEach(skillSubType => skillSubType.skills?.sort((a, b) => a.name.localeCompare(b.name))));
      setSkillTypes(tempSkillTypes);

      const tempFilters: IPillButton[] = [];
      tempSkillTypes.forEach(skillType => {
        tempFilters.push({ id: skillType.type, label: `${skillType.name} skills` });
      });
      setFilters(tempFilters);

      setLoading(false);
    }

    getData();
  }, [companyVariables.slug, dateRange, entity]);

  function filterSkills(tempActiveFilter: IPillButton) {
    if (!skillTypes) return setFilteredSkills([]);

    const tempSkillType = skillTypes.find(skillType => skillType.type === tempActiveFilter.id);
    const tempSkills: SkillsBreakdownSkill[] = [];
    tempSkillType?.skillSubTypes?.forEach(skillSubType => {
      skillSubType.skills?.forEach(skill =>
        tempSkills.push({
          name: skill.name,
          slug: skill.slug,
          score: skill.score,
          peopleCount: skill.peopleCount,
          skillSubTypeSlug: skillSubType.slug,
        })
      );
    });
    setFilteredSkills(tempSkills);
  }

  // filter
  useEffect(() => {
    setActiveFilter(filters[0]);
    filterSkills(filters[0]);
  }, [skillTypes]);

  function sortSkills(tempActiveSortType: OrderType) {
    if (!filteredSkills) return setSortedSkills([]);

    const tempSkills: SkillsBreakdownSkill[] = structuredClone(filteredSkills);
    tempSkills.sort((a, b) => {
      if (tempActiveSortType === OrderType.Organic) return a.name.localeCompare(b.name);
      if (tempActiveSortType === OrderType.Ascending) return (a.score?.percentage ?? 0) - (b.score?.percentage ?? 0);
      if (tempActiveSortType === OrderType.Descending) return (b.score?.percentage ?? 0) - (a.score?.percentage ?? 0);
      return 0;
    });
    setSortedSkills(tempSkills);
  }

  // sort
  useEffect(() => {
    sortSkills(OrderType.Organic);
  }, [filteredSkills]);

  function pageSkills(tempStart: number, tempEnd: number) {
    if (!sortedSkills) return setPagedSkills([]);

    let tempSkills: SkillsBreakdownSkill[] = structuredClone(sortedSkills);
    tempSkills = tempSkills.slice(tempStart, tempEnd);
    setPagedSkills(tempSkills);
  }

  // page
  useEffect(() => {
    setActivePageNumber(1);
    pageSkills(0, pageSize);
  }, [sortedSkills]);

  function onFilterChangeHandler(filter: IPillButton) {
    setActiveFilter(filter);
    filterSkills(filter);
  }

  function onSortChangeHandler(sortType: OrderType) {
    setActiveSortType(sortType);
    sortSkills(sortType);
  }

  function onPageChangeHandler(pageNumber: number) {
    setActivePageNumber(pageNumber);
    const end = pageNumber * pageSize;
    const start = end - pageSize;
    pageSkills(start, end);
  }

  if (loading) return <SkeletonLoader height="425px" />;

  return (
    <div className="card glass">
      {!pagedSkills.length ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} title="No skills found" />
      ) : (
        <React.Fragment>
          <SkillsBreakdownWidgetContainer>
            <SkillsBreakdownWidgetHeader>
              <h3 className="heading3">
                Skills breakdown
                {path !== PagePath.dashboard && (
                  <IconButton iconType="fill" onClick={() => setOpen(true)}>
                    <HelpIcon />
                  </IconButton>
                )}
              </h3>

              <SkillsBreakdownWidgetOrderButton>
                <OrderButton labelMap={sortTypes} defaultOrderType={activeSortType} onChange={onSortChangeHandler} />
              </SkillsBreakdownWidgetOrderButton>
            </SkillsBreakdownWidgetHeader>

            {filters.length > 1 && (
              <div className="pillButtonsContainer">
                {filters.map(filter => (
                  <PillButton key={filter.id} label={filter.label} active={activeFilter?.id === filter.id} backgroundColour="borders" square onClick={() => onFilterChangeHandler(filter)} />
                ))}
              </div>
            )}

            <SkillsBreakdownWidgetBody>
              <SkillsBreakdownWidgetColumn>
                <SkillsBreakdownWidgetColumnLabelCell>Skill name</SkillsBreakdownWidgetColumnLabelCell>

                {pagedSkills.map(skill => {
                  const partialPath = !!skill.skillSubTypeSlug
                    ? PagePath.analyticsSkillSubType.replace(':companySlug', companyVariables.slug!).replace(':skillSubTypeSlug', skill.skillSubTypeSlug).replace(':skillSlug', skill.slug)
                    : PagePath.analyticsSkill.replace(':companySlug', companyVariables.slug!).replace(':skillSlug', skill.slug);

                  return (
                    <SkillsBreakdownWidgetColumnSkillNameCell key={`${skill.skillSubTypeSlug}-${skill.slug}`} title={skill.name}>
                      {path !== PagePath.dashboard ? <SkillsBreakdownWidgetNavlink to={`${PagePath.analyticsBase}${partialPath}`}>{skill.name}</SkillsBreakdownWidgetNavlink> : <React.Fragment>{skill.name}</React.Fragment>}
                    </SkillsBreakdownWidgetColumnSkillNameCell>
                  );
                })}
              </SkillsBreakdownWidgetColumn>

              {companyVariables.useLevels ? (
                <SkillsBreakdownWidgetColumn>
                  <SkillsBreakdownWidgetColumnLabelCell>Skill level</SkillsBreakdownWidgetColumnLabelCell>

                  {pagedSkills.map(skill => (
                    <SkillsBreakdownWidgetColumnDataCell key={`${skill.skillSubTypeSlug}-${skill.slug}`}>
                      <SkillLevel
                        level={skill.score?.level ?? companyVariables.minLevel}
                        minLevel={companyVariables.minLevel}
                        maxLevel={companyVariables.maxLevel}
                        notAssessed={!skill.score}
                        dotColour={path !== PagePath.dashboard ? skillLevelRAGColour(skill.score?.level ?? 0, companyVariables.maxLevel) : 'baby-blue'}
                      />
                    </SkillsBreakdownWidgetColumnDataCell>
                  ))}
                </SkillsBreakdownWidgetColumn>
              ) : (
                <SkillsBreakdownWidgetColumn>
                  <SkillsBreakdownWidgetColumnLabelCell>Skill percentage</SkillsBreakdownWidgetColumnLabelCell>

                  {pagedSkills.map(skill => (
                    <SkillsBreakdownWidgetColumnDataCell key={`${skill.skillSubTypeSlug}-${skill.slug}`}>
                      <SkillPercentage
                        percentage={skill.score?.percentage ?? 0}
                        notAssessed={!skill.score}
                        barColour={path !== PagePath.dashboard ? ragColourType(skill.score?.percentage ?? 0) : 'baby-blue'}
                      />
                    </SkillsBreakdownWidgetColumnDataCell>
                  ))}
                </SkillsBreakdownWidgetColumn>
              )}

              {/* TODO: Will add back when needed
              {!isMobile && (
                <SkillsBreakdownWidgetColumn>
                  <SkillsBreakdownWidgetColumnLabelCell style={{ justifyContent: 'flex-end' }}>People</SkillsBreakdownWidgetColumnLabelCell>

                  {pagedSkills.map(skill => (
                    <SkillsBreakdownWidgetColumnDataCell key={`${skill.skillSubTypeSlug}-${skill.slug}`} style={{ justifyContent: 'flex-end' }}>
                      {skill.peopleCount ?? 0}
                    </SkillsBreakdownWidgetColumnDataCell>
                  ))}
                </SkillsBreakdownWidgetColumn>
              )} */}
            </SkillsBreakdownWidgetBody>

            {sortedSkills.length > pageSize && <Pager activePageNumber={activePageNumber} pageCount={Math.ceil(sortedSkills.length / pageSize)} onPageChange={onPageChangeHandler} />}
          </SkillsBreakdownWidgetContainer>

          {path !== PagePath.dashboard && (
            <FocusPanel open={open} onClose={() => setOpen(false)}>
              <ExplainerFocusPanelLayout
                title="Skills breakdown"
                description="The information on the breakdown of skills - firstly, into role-based skills and behavioural skills, and secondly, into individual skills - indicates to you which skills gaps are closest to being finished, how many people currently use that skill, and where these people work. It highlights areas that are struggling, but also emphasises the stronger skills in your company, and allows you to use those skills in a wider range of projects."
                onClose={() => setOpen(false)}
              />
            </FocusPanel>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

