import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { SkeletonLoader } from '../../../components';
import { Questionnaire } from '../../../models/questionnaire';
import { useAppActions } from '../../../overmind';
import { AppPageWrapper } from '../../../theme/layout.styles';
import {
  QuestionnairesLandingPageCards,
  QuestionnairesLandingPageCardsExplainer,
  QuestionnairesLandingPageCardsList,
  QuestionnairesLandingPageCardsListItem,
  QuestionnairesLandingPageCardsListItemLink,
  QuestionnairesLandingPageCardsListLoader,
  QuestionnairesLandingPageHeader,
  QuestionnairesLandingPageKeplerPointsTally,
} from './behavioral.styles';
import { useNavigate } from 'react-router-dom';
import { FetchType } from '../../../enums';
import { KeplerExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/kepler-explainer-focus-panel/kepler-explainer-focus-panel.layout';
import { LottieOutput } from '../../../components/lottie-icon/lottie-icon';
import fireworks from './fireworks.json';
import { QuestionnaireCard } from './questionnaire.card';
import { QuestionnairesLandingRoutingMap } from './questionnaire-routing.map';
import { RoutingMapResult } from '../../../models/routing-map';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, Chip, FocusPanel, HelpIcon, IconButton, KeplerPoints, LoaderIcon, Modal, PageHeader, useMediaQuery } from '@keplerco/core';

export function QuestionnairesPage(): JSX.Element {
  const actions = useAppActions();

  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const scrollCardRef = useRef<HTMLElement>(null);
  const scrollCardListRef = useRef<HTMLUListElement>(null);
  const isTablet = useMediaQuery('screen and (max-width: 930px)');
  const isMobile = useMediaQuery('screen and (max-width: 580px)');

  useEffect(() => {
    if (!questionnaires)
      actions.getAllQuestionnaires().then(data => {
        setQuestionnaires(data);
      });
  });

  const hasQuestionnaires = !!questionnaires && questionnaires.length > 0;
  const hasCompleted = hasQuestionnaires && questionnaires.filter(d => !d.isComplete).length === 0;
  const hasAnyComplete = hasQuestionnaires && !!questionnaires.some(d => d.totalAnsweredQuestions >= 1);

  useEffect(() => {
    if (!hasAnyComplete) {
      setIsMobileOpen(true);
    }
  }, [isTablet, isMobile]);

  async function continueAction(renderProps: RoutingMapResult) {
    actions.startLoader({ path: PagePath.analysisBehavioural, type: FetchType.Sending });
    await actions.getLearnerSkillAssessmentResultsReady();

    if (renderProps.next) navigate(renderProps.next);

    window.scrollTo(0, 0);
    actions.stopLoader(PagePath.analysisBehavioural);
  }

  function ExplainerCard(renderProps: RoutingMapResult) {
    return (
      <React.Fragment>
        <Chip label={isMobile ? `Skill analysis` : `Behavioural skills analysis`} variant="tiny" backgroundColour="primary" colour="contrast-text" />

        {hasCompleted ? (
          <React.Fragment>
            <h3 className="heading3">Thank you for completing your behavioural skills assessment.</h3>

            <QuestionnairesLandingPageKeplerPointsTally>
              <LottieOutput autoplay loop data={fireworks} />

              <h5 className="heading5">You've earned a total of</h5>

              <h2 className="heading2" style={{ marginTop: 5 }}>
                <KeplerPoints trimWhiteSpace points={questionnaires.reduce((a, b) => a + b.keplerPointsEarned, 0)} />

                <IconButton iconType="fill" onClick={() => setIsModalOpen(true)}>
                  <HelpIcon tone="link-text" />
                </IconButton>
              </h2>
            </QuestionnairesLandingPageKeplerPointsTally>

            <div className="button always-visible">
              <p className="body1">Click the link below to view your results.</p>

              <Button className="center" type="button" filled arrow={false} onClick={() => continueAction(renderProps)}>
                Continue
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div style={{ height: 5 }} />

            <h3 className="heading3">Unlock and develop your behavioural strengths</h3>

            <ul ref={scrollCardListRef}>
              <li>
                <span className="caption1">
                  Read each question carefully and choose the answer that <strong>best describes how you may respond in the situation.</strong>
                </span>
              </li>

              <li>
                <span className="caption1">
                  Select the answer that is the <strong>most accurate</strong>, even if it doesn't perfectly describe how you would react.
                </span>
              </li>

              <li className="no-dot">
                <span className="caption1">
                  <strong>Please note:</strong> This analysis is not used for official performance evaluation. It is a way to determine where the start of your Learning Journey will be, so that your learning experience is efficient and useful.
                </span>
              </li>
            </ul>

            {!!hasQuestionnaires ? (
              <div className="button">
                <Button
                  filled
                  type="button"
                  onClick={() => {
                    setIsCollapsed(true);
                    if (isTablet) setIsMobileOpen(false);
                  }}
                  arrow={false}
                >
                  Got it, thanks
                </Button>
              </div>
            ) : (
              <LoaderIcon size={50} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <QuestionnairesLandingRoutingMap>
      {renderProps => {
        return (
          <React.Fragment>
            <AppPageWrapper className={classNames('wrapper', { padBottom: hasCompleted })}>
              <QuestionnairesLandingPageHeader>
                <PageHeader
                  breadcrumbs={[
                    { name: 'Analysis', url: PagePath.analysisBase },
                    { name: 'Questionnaires', url: `${PagePath.analysisBase}${PagePath.analysisBehavioural}` },
                  ]}
                  title="Behavioural Skills"
                  subtitle="These skills determine how well you interact with others and manage your work and social connections."
                />
              </QuestionnairesLandingPageHeader>

              <QuestionnairesLandingPageCards className={classNames({ hasCompleted, isCollapsed: (isCollapsed || hasAnyComplete) && !hasCompleted })} style={{ overflowY: 'auto' }}>
                {!isTablet && (
                  <QuestionnairesLandingPageCardsExplainer id="behavioural-page-explainer-card">
                    <div className="card" ref={scrollCardRef as any}>
                      <ExplainerCard {...renderProps} />
                    </div>
                  </QuestionnairesLandingPageCardsExplainer>
                )}

                {isTablet && (
                  <Modal
                    onClose={() => {
                      setIsMobileOpen(false);
                      if (hasCompleted) continueAction(renderProps);
                    }}
                    open={isMobileOpen}
                  >
                    <QuestionnairesLandingPageCardsExplainer id="behavioural-page-explainer-card" style={{ position: 'static' }}>
                      <ExplainerCard {...renderProps} />
                    </QuestionnairesLandingPageCardsExplainer>
                  </Modal>
                )}

                {!hasCompleted && (
                  <React.Fragment>
                    {!!questionnaires ? (
                      <QuestionnairesLandingPageCardsList>
                        {questionnaires.map((questionnaire, index) => {
                          const isComplete = questionnaire.totalAnsweredQuestions >= questionnaire.totalQuestions;

                          if (isComplete) {
                            return (
                              <QuestionnairesLandingPageCardsListItem key={questionnaire.slug}>
                                <QuestionnaireCard {...questionnaire} index={index} />
                              </QuestionnairesLandingPageCardsListItem>
                            );
                          } else {
                            return (
                              <QuestionnairesLandingPageCardsListItemLink to={`${PagePath.analysisBase}${PagePath.analysisBehaviouralQuestionnaire.replace(':questionnaireSlug', questionnaire.slug)}`} key={questionnaire.slug}>
                                <QuestionnaireCard {...questionnaire} index={index} />
                              </QuestionnairesLandingPageCardsListItemLink>
                            );
                          }
                        })}
                      </QuestionnairesLandingPageCardsList>
                    ) : (
                      <QuestionnairesLandingPageCardsList>
                        <QuestionnairesLandingPageCardsListLoader>
                          <SkeletonLoader height="210" />
                          <SkeletonLoader height="210" />
                        </QuestionnairesLandingPageCardsListLoader>
                      </QuestionnairesLandingPageCardsList>
                    )}
                  </React.Fragment>
                )}
              </QuestionnairesLandingPageCards>
            </AppPageWrapper>

            <FocusPanel open={isModalOpen} zIndex={110} onClose={() => setIsModalOpen(false)}>
              <KeplerExplainerFocusPanelLayout onClick={() => setIsModalOpen(false)} />
            </FocusPanel>
          </React.Fragment>
        );
      }}
    </QuestionnairesLandingRoutingMap>
  );
}

