import React, { useMemo, useState } from 'react';
import { randomNumber } from '@keplerco/core';
import { ICoreSkillsMapProps, Planet } from './core-skills-map.models';
import { CoreSkillsMapPlanet } from './core-skills-map-planet/core-skills-map-planet';
import { CoreSkillsMapPlanetsContainer, CoreSkillsMapSunContainer } from './core-skills-map.styles';
import sun from './sun.graphic.svg';

export function CoreSkillsMap({ skills }: ICoreSkillsMapProps): JSX.Element {
  const [activeSlug, setActiveSlug] = useState<string>();

  const planets: Planet[] = useMemo(() => {
    return skills.map((skill, index) => {
      const imageIndex = index % 60;
      const isEven = index % 2 === 0;

      return {
        ...skill,
        src: `/planets/planet_${(imageIndex + 1).toString().padStart(2, '0')}.svg`,
        width: randomNumber(120, 190),
        offset: isEven ? randomNumber(15, 38) : -randomNumber(15, 38),
      };
    });
  }, [skills.length]);

  function onClickHandler(result: { slug: string; target: HTMLElement | undefined }): void {
    if (result.slug === activeSlug) return;

    setActiveSlug(result.slug);
    result.target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  return (
    <React.Fragment>
      <CoreSkillsMapSunContainer>
        <object data={sun} type="image/svg+xml">
          <img src={sun} alt="The Sun" />
        </object>
      </CoreSkillsMapSunContainer>

      <CoreSkillsMapPlanetsContainer>
        {planets.map(planet => (
          <CoreSkillsMapPlanet key={planet.slug} planet={planet} onClick={onClickHandler} />
        ))}
      </CoreSkillsMapPlanetsContainer>
    </React.Fragment>
  );
}

