import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Rater } from '../../../components';
import { FetchType } from '../../../enums';
import { RatingFocusPanelContentLayout, RatingPageHeader, RatingPageLayout, RatingPageMobileContent, RatingPageScaleFooter, RatingPageScaleInputs, RatingPageScaleKey, RatingPageScaleKeyInner, RatingPageScaleLayout, RatingScaleMarker } from './rating.styles';
import { IRatingsProps } from './rating.models';
import { RatingScale } from '../../../models/rating-scale';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate, useParams } from 'react-router-dom';
import * as st from './rating.state';
import { ReviewResource } from '../../../models/review-resource';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/explainer-focus-panel/explainer-focus-panel.layout';
import { filteredColours } from '../../../lib/filtered-colours';
import { CareerPathRatingRoutingMap, SelfAssessmentRoutingMap, TechnicalPeerEndorsementRateRoutingMap } from './rating-routing.map';
import { RatingSkeleton } from './rating.skeleton';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, FocusPanel, PageHeader, UnorderedList, UnorderedListItem, capitalize } from '@keplerco/core';

function RatingKeyPartial(state: { ratingScale: RatingScale }): JSX.Element {
  return (
    <RatingPageScaleKeyInner>
      <h6 className="subtitle">Rating key</h6>

      <UnorderedList>
        {state.ratingScale.map(rating => {
          return (
            <UnorderedListItem key={rating.label}>
              <span className="caption1">{rating.label}</span>
              <span className="caption2">{rating.description}</span>
            </UnorderedListItem>
          );
        })}
      </UnorderedList>
    </RatingPageScaleKeyInner>
  );
}

export function RatingLayout(props: IRatingsProps): JSX.Element {
  const ratingKey = useRef<HTMLDivElement>(null);
  const scrollMarker = useRef<HTMLDivElement>(null);

  const actions = useAppActions();
  const { skillAssessmentConfiguration } = useAppState();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(st.reducer, st.initialState);
  const { ratingSlug } = useParams<any>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<ReviewResource[]>();

  const [queriedSkill, setQueriedSkill] = useState<{ name: string; description: string }>();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: props.path, type: FetchType.PageFetching });

      if (props.type === 'peer') {
        const [ratingScale, teamMembers] = await Promise.all([actions.getCompanyRatingScales(), actions.getTeamReviews()]);
        const teamMember = teamMembers?.find(tempTeamMember => tempTeamMember.userSlug === ratingSlug);
        dispatch({ type: 'populate-peer', payload: { ratingScale, teamMember, title: props.title, includeManagerSkillAdd: false } });
      } else if (props.type === 'self') {
        const [ratingScale, learnerSkills] = await Promise.all([actions.getCompanyRatingScales(), actions.getLearnerSkills()]);
        dispatch({ type: 'populate-self', payload: { ratingScale, learnerSkills, title: props.title, includeLearnerSkillFrequency: true } });
      } else {
        const [ratingScale, reviews] = await Promise.all([actions.getCompanyRatingScales(), actions.getLearnerCareerPathSkillSetReviews(ratingSlug!)]);
        dispatch({ type: 'populate-career-paths', payload: { ratingScale, careerPathSkills: reviews, title: props.title } });
      }

      actions.stopLoader(props.path);
    }

    getData();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const marker = scrollMarker.current;
      const key = ratingKey.current;

      if (!!key && marker) {
        const { top, left } = marker.getBoundingClientRect();

        if (top <= 90) {
          key.style.position = 'fixed';
          key.style.top = `90px`;
          key.style.left = `${left}px`;
        } else {
          key.style.position = '';
          key.style.top = '';
          key.style.left = '';
        }
      }
    });
  }, []);

  function onRatingPeerHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-team-member-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    if (rating === undefined) {
      return;
    }

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingCareerPathHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-career-path-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingSelfHandler(rating: number, skill: ReviewResource) {
    dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);
  }

  return (
    <PageLoaderLayer path={props.path} skeletonLoader={<RatingSkeleton />}>
      <RatingPageLayout className="wrapper">
        <RatingPageHeader>
          <PageHeader breadcrumbs={state.crumbs} title={state.title ?? props.title} subtitle={state.subtitle ?? props.subtitle} />

          <RatingPageMobileContent className="rating-key-anchor">
            <Anchor onClick={() => setIsModalOpen(true)}>view rating key</Anchor>
          </RatingPageMobileContent>
        </RatingPageHeader>

        <RatingPageScaleLayout>
          <RatingPageScaleInputs>
            {state.type === 'peer' && (
              <TechnicalPeerEndorsementRateRoutingMap>
                {renderProps => {
                  return (
                    <React.Fragment>
                      {!!state.teamMember && (
                        <React.Fragment>
                          {state.teamMember.reviews.map((skill, index, parent) => {
                            const colour = filteredColours[index % filteredColours.length];

                            return (
                              <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                                {!!state.ratingScale && (
                                  <Rater
                                    scale={state.ratingScale}
                                    data={{
                                      id: skill.companySkillId,
                                      label: skill.skillName,
                                      description: skill.skillDescription,
                                      rating: skill.skillLevel,
                                    }}
                                    colour={colour}
                                    fadeOnComplete
                                    extraRating={{ label: `Don't know` }}
                                    onChange={rating => onRatingPeerHandler(rating, skill, index, parent)}
                                  />
                                )}

                                {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                                  <div style={{ padding: '0 0' }}>
                                    <div className="formErrorMessage">Rating cannot be blank</div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </React.Fragment>
                      )}

                      <RatingPageScaleFooter>
                        <Anchor arrow reverse block onClick={() => navigate(renderProps.previous!)}>
                          Back
                        </Anchor>

                        <Button
                          type={'button'}
                          onClick={async () => {
                            const emptyReviews = state.teamMember?.reviews.filter(r => typeof r.skillLevel === 'undefined' || r.skillLevel === null) ?? [];

                            if (emptyReviews?.length > 0) {
                              const first = emptyReviews[0];
                              const element = document.getElementById(`rater-${first.companySkillId}`);
                              element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                              setErrorFields(emptyReviews);
                            } else {
                              actions.startLoader({ path: props.path, type: FetchType.Sending });

                              if (!!state.teamMember) {
                                await actions.submitTeamMemberReview({ ...state.teamMember, hasCompletedReview: true });
                              }

                              actions.resetAllLoaders();

                              if (renderProps.next) navigate(renderProps.next);
                            }
                          }}
                        >
                          Submit Rating
                        </Button>
                      </RatingPageScaleFooter>
                    </React.Fragment>
                  );
                }}
              </TechnicalPeerEndorsementRateRoutingMap>
            )}

            {state.type === 'career-path' && (
              <CareerPathRatingRoutingMap>
                {renderProps => {
                  return (
                    <React.Fragment>
                      {!!state.careerPathSkills && (
                        <React.Fragment>
                          {state.careerPathSkills.reviews
                            .sort((a, b) => a.skillName.localeCompare(b.skillName))
                            .map((skill, index, parent) => {
                              const colour = filteredColours[index % filteredColours.length];

                              return (
                                <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                                  {!!state.ratingScale && (
                                    <Rater
                                      scale={state.ratingScale}
                                      data={{
                                        id: skill.companySkillId,
                                        label: skill.skillName,
                                        description: skill.skillDescription,
                                        rating: skill.skillLevel,
                                      }}
                                      colour={colour}
                                      fadeOnComplete
                                      onChange={rating => onRatingCareerPathHandler(rating, skill, index, parent)}
                                    />
                                  )}

                                  {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                                    <div style={{ padding: '0 0' }}>
                                      <div className="formErrorMessage">Rating cannot be blank</div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </React.Fragment>
                      )}

                      <RatingPageScaleFooter>
                        <Anchor arrow reverse block onClick={() => navigate(renderProps.previous!)}>
                          Back
                        </Anchor>

                        <Button
                          type={'button'}
                          onClick={async () => {
                            if (!!state.careerPathSkills && !!skillAssessmentConfiguration) {
                              const emptySkills = state.careerPathSkills.reviews.filter(skill => typeof skill.skillLevel === 'undefined' || skill.skillLevel === null) ?? [];

                              if (emptySkills.length > 0) {
                                const first = emptySkills[0];
                                const element = document.getElementById(`rater-${first.companySkillId}`);
                                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                setErrorFields(emptySkills);
                              } else {
                                actions.startLoader({ path: props.path, type: FetchType.Sending });
                                await actions.saveLearnerCareerPathSkillSetReviews(state.careerPathSkills);
                                await actions.getLearnerSkillAssessmentResultsReady();

                                actions.resetAllLoaders();

                                if (renderProps.next) navigate(renderProps.next);
                              }
                            }
                          }}
                        >
                          Submit Ratings
                        </Button>
                      </RatingPageScaleFooter>
                    </React.Fragment>
                  );
                }}
              </CareerPathRatingRoutingMap>
            )}

            {state.type === 'self' && (
              <SelfAssessmentRoutingMap>
                {renderProps => {
                  return (
                    <React.Fragment>
                      {!!state.learnerSkills && (
                        <React.Fragment>
                          {state.learnerSkills.map((skill, index) => {
                            const colour = filteredColours[index % filteredColours.length];

                            return (
                              <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                                {!!state.ratingScale && (
                                  <Rater
                                    scale={state.ratingScale}
                                    data={{
                                      id: skill.companySkillId,
                                      label: skill.skillName,
                                      description: skill.skillDescription,
                                      rating: skill.skillLevel,
                                    }}
                                    colour={colour}
                                    extraRating={{
                                      count: skill.yearsSpent,
                                      label: 'Number of years using this skill',
                                      maxValue: 99,
                                      onChange: years => {
                                        dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { yearsSpent: years } } });
                                      },
                                    }}
                                    onChange={(rating: any) => onRatingSelfHandler(rating, skill)}
                                  />
                                )}

                                {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                                  <div style={{ padding: '0 0' }}>
                                    <div className="formErrorMessage">Rating cannot be blank</div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </React.Fragment>
                      )}

                      <RatingPageScaleFooter>
                        <Anchor arrow reverse block onClick={() => navigate(renderProps.previous!)}>
                          Back
                        </Anchor>

                        <Button
                          type={'button'}
                          onClick={() => {
                            if (!!state.learnerSkills && !!skillAssessmentConfiguration) {
                              const emptySkills = state.learnerSkills.filter(skill => {
                                return typeof skill.skillLevel === 'undefined' || skill.skillLevel === null || typeof skill.yearsSpent === 'undefined';
                              });

                              if (emptySkills.length > 0) {
                                const first = emptySkills[0];
                                const element = document.getElementById(`rater-${first.companySkillId}`);
                                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                setErrorFields(emptySkills);
                              } else {
                                actions.startLoader({ path: props.path, type: FetchType.Sending });

                                actions.updateLearnerSkills(state.learnerSkills).then(async () => {
                                  await actions.completeSkillsGaps();
                                  await actions.getLearnerSkillAssessmentResultsReady();

                                  if (renderProps.next) navigate(renderProps.next);

                                  actions.stopLoader(props.path);
                                });
                              }
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </RatingPageScaleFooter>
                    </React.Fragment>
                  );
                }}
              </SelfAssessmentRoutingMap>
            )}
          </RatingPageScaleInputs>

          <RatingScaleMarker ref={scrollMarker} />

          <RatingPageScaleKey ref={ratingKey}>{!!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} />}</RatingPageScaleKey>
        </RatingPageScaleLayout>
      </RatingPageLayout>

      <FocusPanel open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <RatingFocusPanelContentLayout className="dialogContentLayout focusPanelContentLayout">
          <div className="dialogBodyLayout">{!!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} />}</div>

          <Button filled type={'button'} onClick={() => setIsModalOpen(false)}>
            Got it, Thanks!
          </Button>
        </RatingFocusPanelContentLayout>
      </FocusPanel>

      <FocusPanel open={!!queriedSkill} onClose={() => setQueriedSkill(void 0)}>
        {!!queriedSkill && <ExplainerFocusPanelLayout title={capitalize(queriedSkill.name)} description={queriedSkill.description} onClose={() => setQueriedSkill(void 0)} />}
      </FocusPanel>
    </PageLoaderLayer>
  );
}

