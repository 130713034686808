import { LearnerSkillResource } from '../../../models/learner-skill-resource';
import { RatingScale } from '../../../models/rating-scale';
import { ReducerAction } from '../../../models/reducer-state';
import { ReviewResource } from '../../../models/review-resource';
import { TeamMemberReviewResource } from '../../../models/team-member-review-resource';
import { LearnerCareerPathSkillSet } from '../../../models/view/learner-career-path-skill-set';
import { RatingPageTypes } from './rating.models';
import { PagePath } from '../../../navigation/navigation.enums';
import { pluralise } from '@keplerco/core';

export interface Breadcrumb {
  name: string;
  url: string;
}

export type RatingState = {
  ratingScale?: RatingScale;

  teamMember?: TeamMemberReviewResource;
  learnerSkills?: LearnerSkillResource[];
  careerPathSkills?: LearnerCareerPathSkillSet;

  type?: RatingPageTypes;

  crumbs: Breadcrumb[];

  title?: string;
  subtitle?: string;
};

export const initialState: RatingState = {
  crumbs: [],
};

export function reducer(state: RatingState, action: ReducerAction<string, { payload?: any }>): RatingState {
  if (action.type === 'populate-peer') {
    const member: TeamMemberReviewResource = action.payload.teamMember;
    return {
      ...state,
      ratingScale: action.payload.ratingScale,
      teamMember: action.payload.teamMember,
      title: `${member?.firstName} ${member?.lastName}`,
      subtitle: `Rate ${pluralise(member?.firstName ?? '')} level of expertise in each of the skills below. `,
      type: 'peer',
      crumbs: [
        {
          name: 'Endorse your team',
          url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`,
        },
        { name: `${member?.firstName} ${member?.lastName}`, url: window.location.pathname },
      ],
    };
  }

  if (action.type === 'populate-self') {
    let crumbs = [
      { name: 'Skills analysis', url: PagePath.analysisBase },
      { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
      { name: action.payload.title, url: window.location.pathname },
    ];

    if (!action.payload.includeLearnerSkillFrequency) {
      crumbs = [crumbs[0], crumbs[2]];
    }

    return {
      ...state,
      ratingScale: action.payload.ratingScale,
      learnerSkills: action.payload.learnerSkills,
      title: action.payload.title,
      subtitle: `Rate your level of expertise in each of the skills below.`,
      type: 'self',
      crumbs,
    };
  }

  if (action.type === 'populate-career-paths') {
    const path: LearnerCareerPathSkillSet = action.payload.careerPathSkills;

    return {
      ...state,
      ratingScale: action.payload.ratingScale,
      careerPathSkills: path,
      title: `${path.skillSetName}`,
      subtitle: `Rate your level of expertise in each of the skills below`,
      type: 'career-path',
      crumbs: [
        { name: 'Analysis', url: PagePath.analysisBase },
        { name: 'Career Paths', url: `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}` },
        { name: `${path.skillSetName}`, url: window.location.pathname },
      ],
    };
  }

  if (action.type === 'patch-learner-skill') {
    const learnerSkills = state.learnerSkills?.map(skill => {
      if (skill.companySkillId === action.payload.skillId) {
        return { ...skill, ...action.payload.skillUpdates };
      } else return { ...skill };
    });

    return { ...state, learnerSkills };
  }

  if (action.type === 'patch-team-member-skill') {
    if (!!state.teamMember) {
      const reviews: ReviewResource[] = state.teamMember.reviews.map(review => {
        if (review.companySkillId === action.payload.skillId) {
          return { ...review, ...action.payload.skillUpdates };
        }

        return { ...review };
      });

      const teamMember: TeamMemberReviewResource = { ...state.teamMember, reviews };

      return { ...state, teamMember };
    }

    return { ...state };
  }

  if (action.type === 'patch-career-path-skill') {
    if (!!state.careerPathSkills) {
      const reviews: ReviewResource[] = state.careerPathSkills.reviews.map(review => {
        if (review.companySkillId === action.payload.skillId) {
          return { ...review, ...action.payload.skillUpdates };
        }

        return { ...review };
      });

      const careerPathSkills: LearnerCareerPathSkillSet = { ...state.careerPathSkills, reviews };

      return { ...state, careerPathSkills };
    }

    return { ...state };
  }

  return state;
}
