import React, { useEffect, useState } from 'react';
import { MultiselectContent, MultiselectActions, MultiselectTabs } from './multiselect.styles';
import { IMultiselectCategories, IMultiselectItem, IMultiselectProps } from './multiselect.models';
import { Checkbox, CheckboxCard, GridItemLayout, GridLayout, Searchfield, ToggleTabGroup } from '@keplerco/core';

export function Multiselect(props: IMultiselectProps): JSX.Element {
  const [activeCategoryKey, setActiveCategoryKey] = useState<string>(Object.keys(props.categories)[0]);
  const [currentCategories, setCurrentCategories] = useState<IMultiselectCategories>(structuredClone(props.categories));

  function search(categories: IMultiselectCategories) {
    const nextCategories: IMultiselectCategories = structuredClone(categories);
    if (!nextCategories[activeCategoryKey].query) {
      return nextCategories;
    }

    const category = nextCategories[activeCategoryKey];
    if (!category) {
      return nextCategories;
    }

    category.items = category.items.filter(item => item.name.toLowerCase().includes(nextCategories[activeCategoryKey].query!));
    return nextCategories;
  }

  useEffect(() => {
    setCurrentCategories(search(props.categories));
  }, [props.categories]);

  function onClickSelectAllHandler() {
    const nextCategories: IMultiselectCategories = structuredClone(props.categories);
    nextCategories[activeCategoryKey].selectAll = !nextCategories[activeCategoryKey].selectAll;
    nextCategories[activeCategoryKey].items.forEach(item => (item.active = nextCategories[activeCategoryKey].selectAll));
    props.onClick(nextCategories);
  }

  function onInputQueryHandler(query: string) {
    const nextCategories: IMultiselectCategories = structuredClone(props.categories);
    nextCategories[activeCategoryKey].query = query;
    props.onClick(nextCategories);
  }

  function onClickSelectItemHandler(selectedItem: IMultiselectItem) {
    const nextCategories: IMultiselectCategories = structuredClone(props.categories);
    const item = nextCategories[activeCategoryKey].items.find(temp => temp.slug === selectedItem.slug);

    if (!item) {
      return nextCategories;
    }

    item.active = !item.active;

    if (nextCategories[activeCategoryKey].selectAll && nextCategories[activeCategoryKey].items.some(temp => !temp.active)) {
      nextCategories[activeCategoryKey].selectAll = false;
    }

    if (nextCategories[activeCategoryKey].items.every(temp => temp.active)) {
      nextCategories[activeCategoryKey].selectAll = true;
    }

    props.onClick(nextCategories);
  }

  return (
    <React.Fragment>
      {Object.keys(currentCategories).length > 1 && (
        <MultiselectTabs>
          <ToggleTabGroup tabs={Object.keys(props.categories).map(key => ({ label: key, disabled: props.categories[key].disabled }))} defaultLabel={Object.keys(props.categories)[0]} onClick={setActiveCategoryKey} />
        </MultiselectTabs>
      )}

      <MultiselectActions>
        <Checkbox id="toggle" checked={currentCategories[activeCategoryKey].selectAll} clickablearea="label" onChange={onClickSelectAllHandler}>
          Select all {currentCategories[activeCategoryKey].selectAll && currentCategories[activeCategoryKey].selectAllMessage && `(${currentCategories[activeCategoryKey].selectAllMessage})`}
        </Checkbox>

        <Searchfield value={currentCategories[activeCategoryKey].query} onInput={onInputQueryHandler} />
      </MultiselectActions>

      <MultiselectContent>
        <GridLayout>
          {currentCategories[activeCategoryKey].items.map(item => (
            <GridItemLayout key={item.slug}>
              <CheckboxCard id={item.slug} checked={item.active} onChange={() => onClickSelectItemHandler(item)}>
                <div className="card glass">
                  <label style={{ width: 'fit-content', display: 'inline-block' }} htmlFor={item.slug}>
                    {item.name}
                  </label>
                </div>
              </CheckboxCard>
            </GridItemLayout>
          ))}
        </GridLayout>
      </MultiselectContent>
    </React.Fragment>
  );
}

