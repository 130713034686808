import React, { useEffect, useState } from 'react';
import { AnalysisButtonContainer, AnalysisTeamAvatarIcon, AnalysisTeamMemberListContainer, AnalysisTeamMemberNameContainer } from '../../analysis.styles';
import { AvatarIcon, Button, PageHeader, ThemeColours, randomColours, useMediaQuery } from '@keplerco/core';
import { CheckTeamPageRoutingMap } from '../peer-endorsements.map';
import { CheckTeamSkeleton } from './check-team.skeleton';
import { FetchType } from '../../../../enums';
import { FocusAreasResource } from '../../../../models/view/focus-areas-resource';
import { PageLoaderLayer } from '../../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../navigation/navigation.enums';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { useAppActions } from '../../../../overmind';
import { useNavigate } from 'react-router-dom';
import * as onboardingStyles from '../../../onboarding/onboarding.styles';
import classNames from 'classnames';

export function CheckTeamPage(): JSX.Element {
  const isTablet = useMediaQuery('screen and (max-width: 930px)');
  const [focusAreasResource, setFocusAreasResource] = useState<FocusAreasResource>();
  const actions = useAppActions();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisTechnicalCheckTeam, type: FetchType.PageFetching });
      const areas = await actions.getTeamFocusAreas();
      setFocusAreasResource(areas);
      actions.stopLoader(PagePath.analysisTechnicalCheckTeam);
    }

    getData();
  }, []);

  function getRandomColour(): ThemeColours {
    return randomColours.filter(colour => !['black', 'white', 'lime', 'yellow'].includes(colour))[Math.floor(Math.random() * randomColours.length)];
  }

  return (
    <CheckTeamPageRoutingMap>
      {renderProps => {
        return (
          <React.Fragment>
            <PageLoaderLayer path={PagePath.analysisTechnicalCheckTeam} skeletonLoader={<CheckTeamSkeleton />}>
              <div className="wrapper stack">
                <onboardingStyles.PageWrapper>
                  <onboardingStyles.ConfirmTeamContent>
                    <PageHeader
                      breadcrumbs={[
                        {
                          name: 'Skills analysis',
                          url: `${PagePath.analysisBase}${PagePath.analysisTechnical}`,
                        },
                        {
                          name: 'Check your team',
                          url: `${PagePath.analysisBase}${PagePath.analysisTechnicalCheckTeam}`,
                        },
                      ]}
                    />

                    <h1 className="heading1">Check your team</h1>

                    <h6 className="subtitle">Your team's already been added to the platform. All you need to do is double-check that the team members are listed correctly.</h6>

                    <h6 className="subtitle">Can't find a team member? Please contact your administrator. </h6>

                    <AnalysisButtonContainer>
                      <Button fullWidthMobile type="button" onClick={() => navigate(renderProps.next!)}>
                        Assign Skills
                      </Button>
                    </AnalysisButtonContainer>
                  </onboardingStyles.ConfirmTeamContent>
                  {!isTablet && (
                    <object data={themedAssetUrl('graphics/vertical-pattern.graphic.svg')} type="image/svg+xml">
                      <img src={themedAssetUrl('graphics/vertical-pattern.graphic.svg')} alt="Fade Pattern" />
                    </object>
                  )}

                  <onboardingStyles.ConfirmTeamListWrapper>
                    <h4 className="heading4" style={{ fontWeight: 400 }}>
                      Your team
                    </h4>

                    <span className="caption1">
                      {focusAreasResource?.learnersInTeam?.length} Member{focusAreasResource?.learnersInTeam?.length === 1 ? '' : 's'}
                    </span>

                    <AnalysisTeamMemberListContainer className={classNames('scrollbar')}>
                      {focusAreasResource?.learnersInTeam?.map(learner => {
                        return (
                          <AnalysisTeamMemberNameContainer key={learner.id}>
                            <AnalysisTeamAvatarIcon>
                              <AvatarIcon backgroundColour={getRandomColour()} name={learner} />
                            </AnalysisTeamAvatarIcon>
                            <h5 className="heading5" style={{ fontWeight: 400, fontSize: '0.8rem' }}>
                              {learner.firstName} {learner.lastName}
                            </h5>
                          </AnalysisTeamMemberNameContainer>
                        );
                      })}
                    </AnalysisTeamMemberListContainer>
                  </onboardingStyles.ConfirmTeamListWrapper>
                </onboardingStyles.PageWrapper>

                <div style={{ height: 100 }} />
              </div>
            </PageLoaderLayer>
          </React.Fragment>
        );
      }}
    </CheckTeamPageRoutingMap>
  );
}

