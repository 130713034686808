import styled from 'styled-components';

export const PersonDetailsAvatar = styled.figure`
  margin: 0px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  overflow: hidden;

  @media screen and (max-width: 580px) {
    grid-area: icon;
    width: 100px;
    height: 100px;
  }
`;

export const PersonDetailsContent = styled.div`
  flex: 1;

  @media screen and (max-width: 580px) {
    grid-area: title;
    margin-top: 20px;
  }
`;

export const PersonDetailsKeplerPoints = styled.div`
  @media screen and (max-width: 580px) {
    grid-area: points-card;
  }
`;

export const PersonDetailsContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  gap: 30px;

  @media screen and (max-width: 580px) {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'icon points-card' 'title title';
  }
`;

