import { Anchor, useMediaQuery } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { IScrollSectionProps } from './scroll-section.models';
import styles from './scroll-section.module.css';
import classNames from 'classnames';

export function ScrollSection<T>({ maxDesktopHeight, items, visibleItemsIncrement, children }: IScrollSectionProps<T>): JSX.Element {
  const isMobile = useMediaQuery('screen and (max-width: 580px)');

  const [visibleItems, setVisibleItems] = useState<T[]>([]);

  useEffect(() => {
    setVisibleItems([]);
  }, []);

  useEffect(() => {
    setVisibleItems(isMobile ? items.slice(0, visibleItems.length + visibleItemsIncrement) : items);
  }, [items]);

  return (
    <div className={classNames('kplr_scrollSection', styles.scrollSectionContainer)}>
      <div className={styles.scrollSection} style={{ maxHeight: isMobile ? 'auto' : maxDesktopHeight }}>
        {children({ visibleItems })}
      </div>

      {isMobile && visibleItems.length < items.length && (
        <Anchor onClick={() => setVisibleItems(items.slice(0, visibleItems.length + visibleItemsIncrement))}>View More</Anchor>
      )}
    </div>
  );
}