import React from 'react';
import { SkeletonLoader } from '../../../../../components';

export function ViewRoleAssigneesSkeleton(): JSX.Element {
  const height = '90px';

  return (
    <div>
      <div className="row">
        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>

        <div className="column">
          <SkeletonLoader height={height} />
        </div>
      </div>
    </div>
  );
}

