import { BaseRagColours } from '@keplerco/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SkillsBreakdownWidgetOrderButton = styled.div`
  height: fit-content;
  width: fit-content;
`;

export const SkillsBreakdownWidgetHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
`;

export const SkillsBreakdownWidgetNavlink = styled(NavLink)`
  text-decoration: none;
  color: var(--link-text);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SkillsBreakdownWidgetAverage = styled.span<{ colour: BaseRagColours }>`
  display: block;
  color: ${props => `var(--${props.colour})`};
  text-align: right;
`;

const SkillsBreakdownWidgetColumnCell = css`
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;

  @media screen and (max-width: 580px) {
    align-items: start;

    &.alignRight {
      justify-content: flex-end;
    }
  }
`;

export const SkillsBreakdownWidgetColumnDataCell = styled.div`
  ${SkillsBreakdownWidgetColumnCell}
`;

export const SkillsBreakdownWidgetColumnSkillNameCell = styled(SkillsBreakdownWidgetColumnDataCell)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SkillsBreakdownWidgetColumnLabelCell = styled.label`
  ${SkillsBreakdownWidgetColumnCell}
  font-weight: 700;
  font-family: var(--heading);

  &.alignRight {
    justify-content: flex-end;
  }
`;

export const SkillsBreakdownWidgetColumn = styled.div``;

export const SkillsBreakdownWidgetBody = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 1fr) 115px;
  gap: 20px;
`;

export const SkillsBreakdownWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
