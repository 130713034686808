import React, { useState } from 'react';
import { Anchor, ListItemLayout, ListLayout } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { FuzzySearch } from '../../../components';
import styles from './global-roles.module.css';
import classNames from 'classnames';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { EntityListResponse } from '../../../models/overmind/entities';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { OrganizationLevelType, SortField } from '../../../enums';

export function GlobalRolesView(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const [globalRoles, setGlobalRoles] = useState<EntityListResponse>();

  async function searchForGlobalRoles(search: string) {
    if (!search) {
      setGlobalRoles(undefined);
      return;
    }

    const organizationLevel = extractHighestOrganizationLevel(permissions?.skills?.organizationLevels);
    const request: CompanyEntitySearchParams = {
      search,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 15,
      page: 1,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: true,
    };
    const rolesData = await actions.getRoles(request);
    setGlobalRoles(rolesData);
  }

  return (
    <div style={{ width: '100%' }}>
      <FuzzySearch
        emptyState={{ title: 'Search global roles', badgeUrl: themedAssetUrl('graphics/empty-state-clouds.graphic.svg'), badgeIconWidth: 'full-width' }}
        fullWidthSearch
        noBorders
        hideSubtitle
        onFuzzySearch={params => {
          searchForGlobalRoles(params.query);
        }}
      >
        {!!globalRoles?.entities.length && (
          <ListLayout>
            {globalRoles.entities.map((item, index) => {
              return (
                <ListItemLayout key={`${item.slug}${index}`}>
                  <div className={classNames(styles.roleCard, 'card')}>
                    <div className={styles.roleContent}>
                      <h5 className="heading5">{item.name || 'Untitled role'}</h5>
                    </div>

                    {item.slug === null ? (
                      <div className={styles.roleAction}>
                        <p className="caption2">Role cannot be view</p>
                      </div>
                    ) : (
                      <div className={styles.roleAction}>
                        <Anchor onClick={() => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRoleImport.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', item.slug)}`)}>View Role</Anchor>
                      </div>
                    )}
                  </div>
                </ListItemLayout>
              );
            })}
          </ListLayout>
        )}
      </FuzzySearch>
    </div>
  );
}

