import React, { useEffect, useReducer, useState } from 'react';
import { FetchStatus, FetchType, OrganizationLevelType, SortField } from '../../enums';
import { useAppActions, useAppState } from '../../overmind';
import * as store from './company-skill.cms.store';
import { HoverCard } from '../../components/cards/hover-card/hover-card';
import { SkillPoolWrapper } from './company-skill.cms.styles';
import { SkillPoolCMSSkeleton } from './company-skill.cms.skeleton';
import { PagerNavigationConnector } from '../../components/pager-connector/pager-connector';
import { SearchSkillFocusPanelCMSLayout } from './manage-skill-cascading-focus-panels/search-skill-focus-panel.layout';
import { ManageSkillFocusPanelCMSLayout } from './manage-skill-cascading-focus-panels/manage-skill-focus-panel.layout';
import { PagePath } from '../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { EmptyState } from '../../components/empty-state/empty-state';
import { ColumnConfiguratorWidget } from '../../widgets/layouts';
import { ColumnConfiguration } from '../../models/column-configuration';
import { CMSColumn, CMSHeaderRow, CMSRow } from '../../theme/layout.styles';
import { AddTwoToneIcon, CascadingFocusPanels, FocusPanel, GridIcon, IconButton, KebabMenu, ListIcon, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow, ToggleButtonGroup, Tooltip, sentenceCase, useCascadingPanelConnector, useMediaQuery } from '@keplerco/core';
import { EntityListItemResponse } from '../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../lib/permissions.helpers';

export function CompanySkillCMSPage(): JSX.Element {
  const isMobile = useMediaQuery('(max-width: 580px)');

  const { fetchState, companyVariables, permissions } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [activeViewType, setActiveViewType] = useState<string>('Grid');

  const { connector, closeAll, next } = useCascadingPanelConnector();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.skills?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 9,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false, // TODO: check if this should be true or false
  };

  async function updateData(request: CompanyEntitySearchParams | undefined) {
    if (!request) {
      actions.stopLoader(PagePath.skills);
      return;
    }

    const data = await actions.getSkills(request);
    dispatch({ type: store.SkillPoolCMSActionTypes.SetData, payload: data });
    actions.stopLoader(PagePath.skills);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.skills, type: FetchType.PageFetching });

      dispatch({ type: store.SkillPoolCMSActionTypes.SetHeader, payload: { entityName: companyVariables.name, entitySlug: companyVariables.slug! } });

      dispatch({ type: store.SkillPoolCMSActionTypes.SetRequest, payload: defaultRequest });

      updateData(defaultRequest);
    }

    initPage();
  }, []);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.skills, type: FetchType.Custom });
    dispatch({ type: store.SkillPoolCMSActionTypes.SetRequest, payload: { ...(state.request ?? defaultRequest), search: value, page: 1 } });
  }

  async function onPageChangeHandler(page: number) {
    if (page === state.request?.page) return;

    actions.startLoader({ path: PagePath.skills, type: FetchType.PageFetching });
    dispatch({ type: store.SkillPoolCMSActionTypes.SetRequest, payload: { ...(state.request ?? defaultRequest), page: page } });
  }

  function onClickCreateHandler() {
    next('search-skill');
  }

  function onClickManageHandler(skillPoolListItem: EntityListItemResponse) {
    dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: { skillPoolListItem: skillPoolListItem, type: 'update' } });
    next('manage-skill');
  }

  function getSkillDescription(description: string | undefined): any {
    if (!description) return '';
    if (isMobile && description.length > 156) return description.substring(0, 156) + '...';
    return isMobile ? description : <div style={{ textOverflow: 'ellipsis', width: '500px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{description}</div>;
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.SkillPoolCMSActionTypes.SetManageColumns, payload: { open: false, reset: false } });
  }

  function onClickResetManagingColumns() {
    dispatch({ type: store.SkillPoolCMSActionTypes.SetManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: store.SkillPoolCMSActionTypes.SetColumnConfiguration, payload: selection });
    dispatch({ type: store.SkillPoolCMSActionTypes.SetManageColumns, payload: { open: false } });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.skills} skeletonLoader={<SkillPoolCMSSkeleton />}>
        <div className="wrapper stack">
          <CMSRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <CMSColumn>
              <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} subtitle="The skills pool is based on your company profile and industry field, with the allocated departments based on internal creation and global standards." />
            </CMSColumn>
          </CMSRow>

          <CMSHeaderRow style={{ gridTemplateColumns: 'auto auto' }}>
            <CMSColumn style={{ display: 'flex', gap: 10 }}>
              <Searchfield loading={fetchState[PagePath.skills].status === FetchStatus.Active && fetchState[PagePath.skills].type === FetchType.Custom} onInput={onInputHandler} />
            </CMSColumn>

            <CMSColumn style={{ marginLeft: 'auto' }}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <Tooltip content="Add skill">
                  <IconButton iconType="fill" borderColour="borders" borderRadius="10px" borderWidth={2} onClick={onClickCreateHandler}>
                    <AddTwoToneIcon />
                  </IconButton>
                </Tooltip>

                <ToggleButtonGroup
                  buttons={[
                    { label: 'Grid', icon: <GridIcon /> },
                    { label: 'List', icon: <ListIcon /> },
                  ]}
                  defaultButtonLabel={activeViewType}
                  onClick={setActiveViewType}
                />
              </div>
            </CMSColumn>
          </CMSHeaderRow>

          <CMSRow>
            <CMSColumn style={{ width: '100%' }}>
              {!state.data?.entities ? (
                <EmptyState title="There are no skills yet" buttons={[{ text: 'Create skill', onClick: onClickCreateHandler }]} />
              ) : (
                <React.Fragment>
                  {activeViewType === 'Grid' ? (
                    <React.Fragment>
                      <SkillPoolWrapper>
                        {state.data?.entities.map((skill: EntityListItemResponse, index: number) => (
                          <HoverCard
                            key={index}
                            chip=""
                            actions={[
                              {
                                label: 'Manage skill',
                                icon: <AddTwoToneIcon />,
                                onClick: () => onClickManageHandler(skill),
                              },
                              // TODO
                              // { label: 'Assign Skill', onClick: () => alert('Assign Skill'), icon: <assets.AddPersonTwoToneIcon /> },
                              // { label: 'View Skill', onClick: () => alert('View Skill'), icon: <assets.ExpandTwoToneIcon /> },
                            ]}
                            title={skill.name ?? ''}
                            description={skill.description ?? ''}
                          />
                        ))}
                      </SkillPoolWrapper>
                      {state.data?.totalPages > 1 && (
                        <div style={{ marginTop: 20 }}>
                          <PagerNavigationConnector
                            pageCount={state.data?.totalPages}
                            defaultPageNumber={state.request?.page}
                            onPageChange={(pageNumber: number) => {
                              if (pageNumber !== state.request?.page && typeof pageNumber !== 'undefined' && !isNaN(pageNumber)) {
                                onPageChangeHandler(pageNumber);
                                window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
                              }
                            }}
                          >
                            {connector => {
                              return <Pager {...connector} />;
                            }}
                          </PagerNavigationConnector>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <Table
                      emptyState={<EmptyState title="There are no skills yet" buttons={[{ text: 'Create skill', onClick: onClickCreateHandler }]} />}
                      onConfigClicked={() => dispatch({ type: store.SkillPoolCMSActionTypes.SetManageColumns, payload: { open: true } })}
                      footerContent={
                        <PagerNavigationConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.request?.page ?? 1} pageCount={state.data?.totalPages ?? 1}>
                          {connector => {
                            return <Pager {...connector} />;
                          }}
                        </PagerNavigationConnector>
                      }
                    >
                      {state.data?.entities.map((skill: any) => {
                        return (
                          <TableRow
                            id={skill.id}
                            key={skill.id}
                            configCell={() => (
                              <KebabMenu
                                items={[
                                  {
                                    text: 'Manage Skill',
                                    onClick: () => onClickManageHandler(skill),
                                  },
                                ]}
                              />
                            )}
                          >
                            <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'skillName')?.selected} id="skillName" label="Skill Name">
                              {sentenceCase(skill.name)}
                            </TableColumn>

                            <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'skillDescription')?.selected} id="skillDescription" label="Description">
                              <Tooltip content={skill.description ?? ''}>
                                {getSkillDescription(skill.description)}
                              </Tooltip>
                            </TableColumn>

                            <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'skillType')?.selected} id="skillType" label="Skill Type">
                              {skill.category}
                            </TableColumn>
                          </TableRow>
                        );
                      })}
                    </Table>
                  )}
                </React.Fragment>
              )}
            </CMSColumn>
          </CMSRow>
        </div>
      </PageLoaderLayer>

      <FocusPanel open={state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} columns={state.columnConfiguration} />
      </FocusPanel>

      <CascadingFocusPanels
        openIds={connector}
        onClosePanelById={() => {
          closeAll();
          dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: undefined });
        }}
        onCloseAllPanels={() => {
          closeAll();
          dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: undefined });
        }}
      >
        <FocusPanel id="search-skill" onClose={closeAll}>
          <SearchSkillFocusPanelCMSLayout
            onClickBack={closeAll}
            onClickCreate={() => next('manage-skill')}
            onClickImport={skillPoolListItem => {
              dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: { skillPoolListItem: skillPoolListItem, type: 'create' } });
              next('manage-skill');
            }}
          />
        </FocusPanel>

        <FocusPanel
          id="manage-skill"
          onClose={() => {
            closeAll();
            dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: undefined });
          }}
        >
          <ManageSkillFocusPanelCMSLayout
            type={state.type}
            skillPoolListItem={state.skillPoolListItem}
            onClickCancel={() => {
              closeAll();
              dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: undefined });
            }}
            onClickSave={() => {
              actions.startLoader({ path: PagePath.skills, type: FetchType.PageFetching });
              updateData(state.request);
              closeAll();
              dispatch({ type: store.SkillPoolCMSActionTypes.SetSkillPoolListItem, payload: undefined });
            }}
          />
        </FocusPanel>
      </CascadingFocusPanels>
    </React.Fragment>
  );
}

