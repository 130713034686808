import React, { useEffect, useReducer } from 'react';
import { ILeaderboardProps as ILeaderboardProps } from './leaderboard.models';
import { skillScoreRounding } from '../../../lib/skill-score-rounding';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { useAppActions, useAppState } from '../../../overmind';
import { SkeletonLoader } from '../../../components';
import { OrganizationLevelType } from '../../../enums';
import { FullAnalyticsSearchParams } from '../../../models/overmind/search-params';
import * as store from './leaderboard.store';
import { LeaderboardWidgetContainer } from './leaderboard.styles';
import { Pager, PillButton, SkillLevel, SkillPercentage, Table, TableColumn, TableRow, ragColourType } from '@keplerco/core';
import { skillLevelRAGColour } from '../../../lib/skill-level-rag-colour.helper';

const pageSize = 10;

export function LeaderboardWidget({ entity }: ILeaderboardProps): JSX.Element {
  const { companyVariables, dateRange } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      dispatch({ type: store.PeopleLeaderboardActionTypes.SetLoading, payload: true });

      const searchParams: FullAnalyticsSearchParams = {
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;

      const tempSkillScoresLeaderboard = await actions.analyticsGetSkillScoresLeaderboard(searchParams);
      dispatch({ type: store.PeopleLeaderboardActionTypes.SetSkillLevelLeaderboard, payload: tempSkillScoresLeaderboard ?? [] });

      const tempKeplerPointsLeaderboard = await actions.analyticsGetKeplerPointsLeaderboard(searchParams);
      dispatch({ type: store.PeopleLeaderboardActionTypes.SetKeplerPointsLeaderboard, payload: tempKeplerPointsLeaderboard?.sort((a, b) => b.amount - a.amount) ?? [] });

      dispatch({ type: store.PeopleLeaderboardActionTypes.SetLoading, payload: false });
    }

    getData();
  }, [dateRange, entity]);

  useEffect(() => {
    if (!state.leaderboardType) return;
    onPageChangeHandler(1);
  }, [state.leaderboardType]);

  function onPageChangeHandler(pageNumber: number) {
    const start = (pageNumber - 1) * pageSize;
    const end = start + pageSize;
    const leaderboard = (state.leaderboardType === 'Kepler Points' ? state.keplerPointsLeaderboard : state.skillLevelLeaderboard) ?? [];

    const pageData = leaderboard.slice(start, end);

    dispatch({
      type: store.PeopleLeaderboardActionTypes.SetPaging,
      payload: {
        pageNumber,
        pageCount: Math.ceil(leaderboard.length / pageSize),
        pageData,
      },
    });
  }

  if (state.loading) return <SkeletonLoader height="550" />;

  return (
    <div className="card glass">
      {!state.keplerPointsLeaderboard.length && !state.skillLevelLeaderboard.length ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} title="No leaderboard results" />
      ) : (
        <LeaderboardWidgetContainer>
          <h3 className="heading3">Leaderboard</h3>

          {!!state.pillButtons && (
            <div className="pillButtonsContainer">
              {state.pillButtons.map(pillButton => (
                <PillButton
                  key={pillButton.label}
                  label={pillButton.label}
                  active={state.leaderboardType === pillButton.label}
                  backgroundColour="borders"
                  square
                  onClick={() => dispatch({ type: store.PeopleLeaderboardActionTypes.SetLeaderboardType, payload: pillButton.label as store.LeaderboardType })}
                />
              ))}
            </div>
          )}

          {!state.paging.pageData.length ? (
            <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} title="No leaderboard results" />
          ) : (
            <Table>
              {state.paging.pageData.map((value, index) => (
                <TableRow id={value.slug} key={value.slug}>
                  <TableColumn id="Rank" label="Rank">
                    {(index + 1).toString().padStart(4, '0')}
                  </TableColumn>

                  <TableColumn id="Name" label="Name">
                    {value.name}
                  </TableColumn>

                  <TableColumn
                    id={state.leaderboardType === 'Kepler Points' ? 'Points' : companyVariables.useLevels ? 'Level' : 'Percentage'}
                    label={state.leaderboardType === 'Kepler Points' ? 'Points' : companyVariables.useLevels ? 'Level' : 'Percentage'}
                  >
                    {state.leaderboardType === 'Kepler Points' ? (
                      skillScoreRounding(value.amount)
                    ) : (
                      <React.Fragment>
                        {companyVariables.useLevels ? (
                          <SkillLevel
                            level={value.score?.level ?? companyVariables.minLevel}
                            minLevel={companyVariables.minLevel}
                            maxLevel={companyVariables.maxLevel}
                            notAssessed={!value.score}
                            dotColour={skillLevelRAGColour(value.score?.level ?? 0, companyVariables.maxLevel)}
                          />
                        ) : (
                          <SkillPercentage
                            percentage={value.score?.percentage ?? 0}
                            notAssessed={!value.score}
                            barColour={ragColourType(value.score?.percentage ?? 0)}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </TableColumn>
                </TableRow>
              ))}
            </Table>
          )}

          {state.paging.pageCount > 1 && <Pager activePageNumber={state.paging.pageNumber} pageCount={state.paging.pageCount} onPageChange={onPageChangeHandler} />}
        </LeaderboardWidgetContainer>
      )}
    </div>
  );
}

