import styled from 'styled-components';

export const ReflectionCardListItemImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  min-width: 200px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-right: 1px solid var(--borders);
  }

  @supports (aspect-ratio: 16 / 9) {
    aspect-ratio: 16 / 9;
  }
`;

export const ReflectionCardListItemBodyLayout = styled.div`
  position: relative;

  align-items: flex-start;
  justify-content: center;
  gap: 0;

  min-height: 54px;

  text-align: left;
`;

export const ReflectionsFocusPanelFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse nowrap;
`;

export const ReflectionsForm = styled.form`
  display: block;

  @media screen and (max-width: 850px) {
    padding-bottom: 220px;
  }
`;

