import { AssessmentsSearchRequest } from '../../models/overmind/search-request';
import { ReducerAction } from '../../models/reducer-state';
import { SkillAssessmentsSearchResponse } from '../../models/view/skill-assessments-search-response';
import { CascadingPanelConfig, SkillAssessmentsCMSLayoutHeader } from './skill-assessments.models';
import { SortField } from '../../enums';
import { Employee } from '../../models/view/employee';
import { ColumnConfiguration } from '../../models/column-configuration';
import { PagePath } from '../../navigation/navigation.enums';
import { FocusPanelViewData } from '../../lib/focus-panel.models';
import { Breadcrumb } from '@keplerco/core';
import { SkillAssessment } from '../../models';

interface SkillAssessmentsCMSState {
  pageTitle: string;
  crumbs: Breadcrumb[];
  request: AssessmentsSearchRequest;
  columnConfiguration: ColumnConfiguration[];

  data?: SkillAssessmentsSearchResponse;
  cascadingPanelsConfig?: CascadingPanelConfig;
  managingColumns?: boolean;
  isArchiveModalOpen?: boolean;
  isCloseModalOpen?: boolean;
  archiveSkillAssessmentData?: SkillAssessment
  closeSkillAssessmentData?: SkillAssessment
}

export const initialState: SkillAssessmentsCMSState = {
  pageTitle: 'Skill Assessments',
  crumbs: [],
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 15 },
  columnConfiguration: [
    { label: 'Skill Assessment Name', key: 'name', required: true },
    { label: 'Date Created', key: 'dateCreated', required: false, selected: true },
    { label: 'Status', key: 'Status', required: false, selected: true },
    { label: 'Assessment Type', key: 'assessmentType', required: false, selected: true },
  ],
};

export enum SkillAssessmentsCMSActionTypes {
  SetHeader,
  SetRequest,
  SetData,
  SetSkillAssessmentToManage,
  SetCascadingPanelsConfig,
  ManageColumns,
  EditColumnConfiguration,
  SetArchiveConfirmationModalOpen,
  SetCloseConfirmationModalOpen
}

type SkillAssessmentsCMSActions =
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetHeader, { payload: SkillAssessmentsCMSLayoutHeader }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetRequest, { payload: AssessmentsSearchRequest }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetData, { payload: SkillAssessmentsSearchResponse | undefined }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetSkillAssessmentToManage, { payload: FocusPanelViewData<Employee> }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetCascadingPanelsConfig, { payload: CascadingPanelConfig }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetArchiveConfirmationModalOpen, { payload: { open: boolean, data?:SkillAssessment;} }>
  | ReducerAction<SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen, { payload: { open: boolean, data?:SkillAssessment;} }>;

export function reducer(state: SkillAssessmentsCMSState, action: SkillAssessmentsCMSActions): SkillAssessmentsCMSState {
  if (action.type === SkillAssessmentsCMSActionTypes.SetHeader) {
    let crumbs: Breadcrumb[];

    if (action.payload.entityName === 'Create Assessment') {
      crumbs = [
        { name: 'Assessments', url: `${PagePath.assessmentsBase}${PagePath.assessments.replace(':companySlug', state.request.companySlug!)}` },
        { name: 'Create Assessment', url: '' },
      ];
    } else {
      crumbs = [
        { name: 'Companies', url: PagePath.companies },
        { name: !!action.payload.entityName ? `Skill Assessments in ${action.payload.entityName}` : 'Skill Assessments', url: `${PagePath.assessmentsBase}${PagePath.assessments.replace(':companySlug', state.request.companySlug!)}` },
      ];
    }

    return {
      ...state,
      pageTitle: 'Skill Assessments',
      crumbs: crumbs,
    };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.SetCascadingPanelsConfig) {
    return { ...state, cascadingPanelsConfig: action.payload };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  if (action.type === SkillAssessmentsCMSActionTypes.SetArchiveConfirmationModalOpen) {
    return { ...state, isArchiveModalOpen: action.payload.open, archiveSkillAssessmentData: action.payload.data };
  }


  if (action.type === SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen) {
    return { ...state, isCloseModalOpen: action.payload.open, closeSkillAssessmentData: action.payload.data };
  }
  return state;
}

