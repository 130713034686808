import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import { LoginStreakDays } from './login-streak-modal.styles';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { LoginStreak } from '../../../../components';
import { Days } from '../../../../components/login-streak/login-streak';
import { Button, KeplerPoints } from '@keplerco/core';

export function LoginStreakModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const days: Days[] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];

  return (
    <>
      <div className="dialogBackgroundLayout">
        <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
        </object>
      </div>

      <div className="dialogContentLayout modalContentLayout">
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data="/badges/badge_Streak.svg" type="">
              <img src="/badges/badge_Streak.svg" alt="Flame" />
            </object>
          </div>

          <LoginStreakDays>
            <LoginStreak days={days} />
          </LoginStreakDays>

          <h2 className="heading2">5 days in a row!</h2>

          <h6 className="subtitle">
            You've earned a total of
            <br />
            <h3 className="heading3">
              <KeplerPoints forceFontWeight="bold" points={props.amount} />
            </h3>
            <br />
            for your five day learning streak.
          </h6>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button chubby type={'button'} filled arrow={false} onClick={props.onAction}>
            WooHoo!
          </Button>
        </footer>
      </div>
    </>
  );
}

