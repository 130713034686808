import React, { useEffect, useReducer } from 'react';
import { AssessmentsSearchRequest, BaseSearchRequest } from '../../models/overmind/search-request';
import { CMSButtonRow, CMSDropdownColumn, SkillAssessmentCreateButtonInner } from './skill-assessments.cms.styles';
import { ColumnConfiguration } from '../../models/column-configuration';
import { ColumnConfiguratorWidget, ConfirmationModalLayout } from '../../widgets/layouts';
import { CompletionStatus, FetchStatus, FetchType } from '../../enums';
import { EmptyState } from '../../components/empty-state/empty-state';
import { PageLoaderLayer } from '../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../navigation/navigation.enums';
import { SkillAssessment } from '../../models/view/skill-assessment';
import { SkillAssessmentsCMSSkeleton } from './skill-assessments.cms.skeleton';
import { useAppActions, useAppState } from '../../overmind';
import { useNavigate, useParams } from 'react-router-dom';
import * as store from './skill-assessments.store';
import classNames from 'classnames';
import { CMSColumn, CMSRow } from '../../theme/layout.styles';
import { AddTwoToneIcon, Button, FocusPanel, MenuItem, PageHeader, Searchfield, Tabs, Tab, Modal } from '@keplerco/core';
import { SkillAssessmentView } from './skill-assessment-view/skill-assessment-view.page';

export function SkillAssessmentsCMSPage(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();

  const actions = useAppActions();
  const { fetchState } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [loadingSkillAssessments, setLoadingSkillAssessments] = React.useState<string[]>([]);

  const viewType = params.viewType ?? 'all';

  async function updateData(request: BaseSearchRequest) {
    const data = await actions.getSkillAssessments(request);
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetData, payload: data });

    actions.stopLoader(PagePath.assessments);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.assessments, type: FetchType.PageFetching });

      if (params.companySlug) {
        const company = await actions.getCompany(params.companySlug);
        const entityName = company?.companyName;

        const filterArgs: Partial<AssessmentsSearchRequest> = {};
        if (viewType === 'all') {
          filterArgs.completionStatus = void 0;
          filterArgs.isArchived = false;
        } else if (viewType === 'archived') {
          filterArgs.completionStatus = void 0;
          filterArgs.isArchived = true;
        } else {
          filterArgs.completionStatus = parseInt(viewType);
          filterArgs.isArchived = false;
        }

        dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetHeader, payload: { companySlug: params.companySlug, entityName: entityName } });
        const request: AssessmentsSearchRequest = { ...state.request, companySlug: params.companySlug!, isScheduled: false, ...filterArgs };
        dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetRequest, payload: request });

        await updateData(request);
      }
    }

    initPage();
  }, [viewType]);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.assessments, type: FetchType.Custom });
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  function onClickCreateHandler() {
    navigate(`${PagePath.assessmentsBase}${PagePath.assessmentsCreate.replace(':companySlug', params.companySlug!)}`);
    1;
  }

  async function onChangeHandler(value: string) {
    if (value === 'all') {
      navigate(`${PagePath.assessmentsBase}${PagePath.assessments.replace(':companySlug', state.request.companySlug!)}`);
    } else {
      navigate(`${PagePath.assessmentsBase}${PagePath.assessmentsFilter.replace(':companySlug', state.request.companySlug!).replace(':viewType', value)}`);
    }
  }

  async function onPageChangeHandler(page: number) {
    if (!!page && page === state.request?.page) return;

    actions.startLoader({ path: PagePath.assessments, type: FetchType.PageFetching });
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  const kebabMenuItems: MenuItem<SkillAssessment>[] = [
    {
      text: 'Activate',
      onClick: onClickActivateHandler,
    },
    {
      text: 'Archive Assessment',
      onClick: onClickArchiveDraftAssessmentHandler,
    },
    {
      text: 'Edit Assessment',
      onClick: onClickManageAssessmentDraftHandler,
    },
    {
      text: 'Close',
      onClick: onClickCompleteHandler,
    },
    {
      text: 'View Assignees',
      onClick: onClickViewAssigneesHandler,
    },
  ];

  async function onClickManageAssessmentDraftHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.slug === 'undefined') return;

    navigate(`${PagePath.assessmentsBase}${PagePath.assessmentsUpdate.replace(':companySlug', params.companySlug!).replace(':assessmentSlug', data.slug)}`);
  }

  async function onClickActivateHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.id === 'undefined') return;

    setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(data.id)]);

    await actions.activateSkillAssessment({ companySlug: params.companySlug!, skillAssessmentSlug: data.slug! });
    updateData(state.request);
    setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(data.id) !== sid)]);
  }

  async function onClickArchiveDraftAssessmentHandler(data: SkillAssessment | undefined) {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetArchiveConfirmationModalOpen, payload: { open: true, data: data } });
  }

  async function onClickCompleteHandler(data: SkillAssessment | undefined) {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen, payload: { open: true, data: data } });
  }

  async function onClickViewAssigneesHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.slug === 'undefined') return;

    navigate(`${PagePath.assessmentsBase}${PagePath.assessmentAssignees.replace(':companySlug', params.companySlug!).replace(':assessmentSlug', data.slug)}`);
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.ManageColumns, payload: { open: false, reset: false } });
  }

  function onClickCancelArchiveModal() {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetArchiveConfirmationModalOpen, payload: { open: false } });
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen, payload: { open: false } });
  }

  function onClickCancelCloseModal() {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen, payload: { open: false } });
  }
  function onClickResetManagingColumns() {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.ManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.EditColumnConfiguration, payload: selection });
    dispatch({ type: store.SkillAssessmentsCMSActionTypes.ManageColumns, payload: { open: false } });
  }

  async function onSortHandler(column: any) {
    const isCurrentlyAscending = state.request?.sortAscending;
    const currentSortField = state.request?.sortField;

    const newSortDirection = currentSortField === column ? !isCurrentlyAscending : true;

    dispatch({
      type: store.SkillAssessmentsCMSActionTypes.SetRequest,
      payload: {
        ...state.request,
        sortField: column,
        sortAscending: newSortDirection,
      },
    });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.assessments} skeletonLoader={<SkillAssessmentsCMSSkeleton />}>
        <div className="pageWrapper stack wrapper">
          <CMSRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <CMSColumn>
              <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle}>
              </PageHeader>
            </CMSColumn>
          </CMSRow>

          <CMSRow style={{ display: 'block' }}>
            <CMSColumn>
              <Tabs static>
                <Tab id="all" name="All" active={viewType === 'all'} onClick={() => onChangeHandler('all')} />
                <Tab id={CompletionStatus.InProgress.toString()} name="Active" active={viewType === CompletionStatus.InProgress.toString()} onClick={() => onChangeHandler(CompletionStatus.InProgress.toString())} />
                <Tab id={CompletionStatus.NotStarted.toString()} name="Draft" active={viewType === CompletionStatus.NotStarted.toString()} onClick={() => onChangeHandler(CompletionStatus.NotStarted.toString())} />
                <Tab id={CompletionStatus.Completed.toString()} name="Closed" active={viewType === CompletionStatus.Completed.toString()} onClick={() => onChangeHandler(CompletionStatus.Completed.toString())} />
                <Tab id="archived" name="Archived" active={viewType === 'archived'} onClick={() => onChangeHandler('archived')} />
              </Tabs>
            </CMSColumn>
          </CMSRow>

          <CMSButtonRow>
            <CMSColumn>
              <Searchfield loading={fetchState[PagePath.assessments].status === FetchStatus.Active && fetchState[PagePath.assessments].type === FetchType.Custom} onInput={onInputHandler} />
            </CMSColumn>

            <CMSDropdownColumn className={classNames({ hidden: state.data?.benchmarks.length === 0 })} style={{ marginLeft: 'auto' }}>
              <Button type="button" square theme="dark" onClick={onClickCreateHandler}>
                <SkillAssessmentCreateButtonInner>
                  <AddTwoToneIcon />
                  Create Skill Assessment
                </SkillAssessmentCreateButtonInner>
              </Button>
            </CMSDropdownColumn>
          </CMSButtonRow>

          <CMSRow>
            <CMSColumn style={{ width: '100%' }}>
              {state.data?.benchmarks && state.data.benchmarks.length > 0 ? (
                <SkillAssessmentView
                  totalPages={state.data?.totalPages ?? 1}
                  loadingSkillAssessments={loadingSkillAssessments}
                  skillAssessments={state.data?.benchmarks}
                  onConfigClicked={() => dispatch({ type: store.SkillAssessmentsCMSActionTypes.ManageColumns, payload: { open: true } })}
                  request={state.request}
                  onSortHandler={onSortHandler}
                  onClickCreateHandler={onClickCreateHandler}
                  onPageChangeHandler={onPageChangeHandler}
                  columnConfiguration={state.columnConfiguration}
                  kebabMenuItems={kebabMenuItems}
                />
              ) : (
                <EmptyState title="There are no skill assessments yet" buttons={[{ text: 'Create One', onClick: () => onClickCreateHandler() }]} />
              )}
            </CMSColumn>
          </CMSRow>
        </div>
      </PageLoaderLayer>

      <FocusPanel open={state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} columns={state.columnConfiguration} />
      </FocusPanel>

      <Modal type="small" open={!!state.isArchiveModalOpen} onClose={onClickCancelArchiveModal}>
        <ConfirmationModalLayout
          titleSize="medium"
          onClickCancel={onClickCancelArchiveModal}
          onClickSubmit={async () => {
            if (state.archiveSkillAssessmentData?.slug) {
              setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(state.archiveSkillAssessmentData?.id)]);
              await actions.archiveAssessment({ benchmarkSlug: state.archiveSkillAssessmentData?.slug });
              dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetArchiveConfirmationModalOpen, payload: { open: false, data: undefined } });
              updateData(state.request);
              setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(state.archiveSkillAssessmentData?.id) !== sid)]);
            }
          }}
          title="Are you sure you want to archive this assessment?"
          cancelButtonText="Cancel"
          submitButtonText="Yes, archive"
        />
      </Modal>

      <Modal type="small" open={!!state.isCloseModalOpen} onClose={onClickCancelCloseModal}>
        <ConfirmationModalLayout
          onClickCancel={onClickCancelCloseModal}
          onClickSubmit={async () => {
            if (state.closeSkillAssessmentData?.slug) {
              setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(state.closeSkillAssessmentData?.id)]);
              actions.startLoader({ path: PagePath.assessments, type: FetchType.Sending });
              await actions.completeSkillAssessment({ companySlug: params.companySlug!, skillAssessmentSlug: state.closeSkillAssessmentData?.slug });
              dispatch({ type: store.SkillAssessmentsCMSActionTypes.SetCloseConfirmationModalOpen, payload: { open: false, data: undefined } });
              updateData(state.request);
              setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(state.closeSkillAssessmentData?.id) !== sid)]);
            }
          }}
          title="Are you sure you want to close this assessment?"
          subtitle='This will close the active assessment. People who have not completed the assessment will no longer be able to complete it. Scores will not be affected.'
          cancelButtonText="Cancel"
          submitButtonText="Yes, close"
        />
      </Modal>
    </React.Fragment>
  );
}

