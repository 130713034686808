import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { FetchStatus, FetchType, OrganizationLevelType, SortField } from '../../../enums';
import { ISearchSkillFocusPanelCMSLayoutProps } from './manage-skill-cascading-focus-panels.models';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { SearchSkillFocusPanelSkeleton } from './search-skill-focus-panel.skeleton';
import { PagePath } from '../../../navigation/navigation.enums';
import { FocusPanelLoaderLayer } from '../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { ActionCardListItem, AddTwoToneIcon, Anchor, Button, FocusPanelHeader, ListItemLayout, ListLayout, Pager, Searchfield, colourString } from '@keplerco/core';
import { EntityListResponse } from '../../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';

export function SearchSkillFocusPanelCMSLayout(props: ISearchSkillFocusPanelCMSLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { fetchState, companyVariables, permissions } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.skills?.organizationLevels);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 4,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: true, // TODO: check if this should be true or false
  });

  const [data, setData] = useState<EntityListResponse>();

  async function getData() {
    const temp = await actions.getSkills(request);
    setData(temp);
    actions.stopLoader(PagePath.skills);
  }

  useEffect(() => {
    actions.startLoader({ path: PagePath.skills, type: FetchType.DialogFetching });
    getData();
  }, []);

  useEffect(() => {
    if (!data) return;

    getData();
  }, [request]);

  function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.skills, type: FetchType.Custom });
    setRequest(current => ({ ...current, search: value, page: 1 }));
  }

  function onPageChangeHandler(page: number) {
    if (page === request.page) return;
    actions.startLoader({ path: PagePath.skills, type: FetchType.DialogFetching });
    setRequest(current => ({ ...current, page: page }));
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.skills} skeletonLoader={<SearchSkillFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ borderBottom: '1px solid var(--borders)', marginBottom: 30, paddingBottom: 30 }}>
          <FocusPanelHeader title="Create skill" subtitle="To create a skill you must search for a skill that is the closest to what you are looking for." type="actions">
            <div className="headerActions">
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Searchfield loading={fetchState[PagePath.skills].status === FetchStatus.Active && fetchState[PagePath.skills].type === FetchType.Custom} onInput={onInputHandler} />
              </div>
            </div>
          </FocusPanelHeader>
        </header>

        <div className="dialogBodyLayout">
          <h4 className="heading4" style={{ marginBottom: 30, color: colourString('secondary') }}>
            Search results
          </h4>

          <ListLayout>
            {data?.entities.map(skill => (
              <ListItemLayout key={skill.slug}>
                <ActionCardListItem
                  title={skill.name}
                  description={!skill.description ? '' : skill.description.length > 156 ? skill.description.substring(0, 156) + '...' : skill.description}
                  action={{
                    icon: <AddTwoToneIcon />,
                    iconType: 'fill',
                    onClick: () => props.onClickImport(skill),
                  }}
                ></ActionCardListItem>
              </ListItemLayout>
            ))}
          </ListLayout>

          <div className="row">
            <div className="column">
              {!!data && data.totalPages > 0 && (
                <PagerNavigationConnector pageCount={data.totalPages} onPageChange={onPageChangeHandler} defaultPageNumber={request.page}>
                  {connector => {
                    return <Pager {...connector} />;
                  }}
                </PagerNavigationConnector>
              )}
            </div>
          </div>

          <div className="row" style={{ marginTop: 30, justifyContent: 'space-between', alignItems: 'center' }}>
            <p className="body2" style={{ color: colourString('accent-3') }}>
              Can't find a skill, create one!
            </p>

            <Button type="button" square theme="dark" onClick={props.onClickCreate}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Create New Skill
              </div>
            </Button>
          </div>
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'start' }}>
          <Anchor onClick={props.onClickBack}>Back</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

