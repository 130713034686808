import React, { useEffect, useState } from 'react';
import { Anchor, AvatarIcon, Button, Chip, ChipWrapper, FilterByMenu, GridItemLayout, GridLayout, Modal, PageHeader, capitalize, useMediaQuery } from '@keplerco/core';
import { ConfirmationEndorsementsModalLayout } from '../rating-technical-peer-endorsement-modal/rating-confirm-endorsements-modal.layout';
import { EmptyState } from '../../../../../components/empty-state/empty-state';
import { EndorsementsTitleWrapper, RatingTechnicalPeerEndorsementCard, RatingTechnicalPeerEndorsementPageAvatar, RatingTechnicalPeerEndorsementPageHeader } from './rating-technical-peer-endorsement-landing.styles';
import { FetchType } from '../../../../../enums';
import { filteredColours } from '../../../../../lib/filtered-colours';
import { NavLink, useNavigate } from 'react-router-dom';
import { PageLoaderLayer } from '../../../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { RatingLandingCardsWrapper, RatingPageLayout } from '../../rating.styles';
import { RatingTechnicalPeerEndorsementLandingSkeleton } from './rating-technical-peer-endorsement-landing.skeleton';
import { RoutingMapResult } from '../../../../../models/routing-map';
import { TeamMemberReviewResource } from '../../../../../models/team-member-review-resource';
import { TechnicalPeerEndorsementRoutingMap } from '../../rating-routing.map';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import { useAppActions } from '../../../../../overmind';

export function RatingTechnicalPeerEndorsementLandingPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();

  const [team, setTeam] = useState<TeamMemberReviewResource[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.PageFetching });
      const data = await actions.getTeamReviews();
      setTeam(data);
      actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
    }
    getData();
  }, []);

  const isMobile = useMediaQuery('screen and (max-width: 580px)');
  const isTablet = useMediaQuery('screen and (max-width: 930px)');
  let columns = 3;
  if (isMobile) columns = 1;
  else if (isTablet) columns = 2;

  const teamMembers = team?.sort((a, b) => a.firstName.localeCompare(b.firstName));

  async function onClickHandler(renderProps: RoutingMapResult) {
    actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.Sending });

    await actions.submitTeamReviews();
    await actions.getLearnerSkillAssessmentResultsReady();

    if (renderProps.next) navigate(renderProps.next);
    actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
  }

  const handleShowAll = () => {
    setFilterStatus('all');
    setShowModal(false);
  };

  const handleShowCompleted = () => {
    setFilterStatus('completed');
    setShowModal(false);
  };

  const handleShowNotCompleted = () => {
    setFilterStatus('notCompleted');
    setShowModal(false);
  };

  const filteredTeamMembers = teamMembers?.filter(member => {
    if (filterStatus === 'all') return true;
    if (filterStatus === 'completed') return member.hasCompletedReview;
    if (filterStatus === 'notCompleted') return !member.hasCompletedReview;
    return false;
  });

  return (
    <TechnicalPeerEndorsementRoutingMap>
      {renderProps => {
        return (
          <React.Fragment>
            <PageLoaderLayer path={PagePath.analysisRatingTechnicalPeerEndorsements} skeletonLoader={<RatingTechnicalPeerEndorsementLandingSkeleton />}>
              <RatingPageLayout className="pageWrapper wrapper">
                <RatingTechnicalPeerEndorsementPageHeader>
                  <PageHeader
                    breadcrumbs={[
                      { name: 'Skills analysis', url: PagePath.analysisBase },
                      { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
                      { name: 'Role-based skills analysis', url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}` },
                      {
                        name: 'Endorse your team',
                        url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
                      },
                    ]}
                    title="Endorse your team"
                    subtitle="Reviewing your team's work fosters collaboration and continuous improvement, helping them grow and building a stronger team."
                    type="actions"
                  >
                    <div className="headerActions">
                      <Button type="button" arrow onClick={() => setShowModal(!showModal)}>
                        Submit
                      </Button>
                    </div>
                  </PageHeader>
                </RatingTechnicalPeerEndorsementPageHeader>

                {!!team && (
                  <RatingLandingCardsWrapper>
                    <EndorsementsTitleWrapper>
                      <h4 className="heading4" style={{ marginRight: 20, marginTop: 10 }}>
                        Endorsements:
                      </h4>

                      <FilterByMenu
                        defaultItem="All"
                        items={[
                          { onClick: handleShowCompleted, text: 'Completed' },
                          { onClick: handleShowNotCompleted, text: 'Not Started' },
                          { onClick: handleShowAll, text: 'All' },
                        ]}
                      />
                    </EndorsementsTitleWrapper>

                    {!!filteredTeamMembers?.length ? (
                      <GridLayout columnCount={columns}>
                        {filteredTeamMembers.map((member, index) => {
                          return (
                            <GridItemLayout key={member.userId}>
                              <NavLink to={`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsement.replace(':ratingSlug', member.userSlug)}`} style={{ textDecoration: 'none' }}>
                                <RatingTechnicalPeerEndorsementCard className="card">
                                  {member.hasCompletedReview ? (
                                    <ChipWrapper className="ratingChipWrapper">
                                      <Chip label="Completed" variant="tiny" backgroundColour="g" colour="background" />
                                    </ChipWrapper>
                                  ) : (
                                    <ChipWrapper className="ratingChipWrapper">
                                      <Chip label="Not Started" variant="tiny" backgroundColour="light" />
                                    </ChipWrapper>
                                  )}

                                  <RatingTechnicalPeerEndorsementPageAvatar>
                                    <React.Fragment>{!!member.profileImageUrl ? <img src={member.profileImageUrl} /> : <AvatarIcon name={member} imageUrl={member.profileImageUrl} backgroundColour={filteredColours[index % filteredColours.length]} fontColour={'grc_14'} />}</React.Fragment>
                                  </RatingTechnicalPeerEndorsementPageAvatar>

                                  <h6 className="subtitle">
                                    {member.firstName} {member.lastName}
                                  </h6>

                                  <span className="caption2">&nbsp;{capitalize(member.jobTitle)}&nbsp;</span>

                                  {member.hasCompletedReview ? <Anchor>Change Endorsement</Anchor> : <Anchor>Start Endorsement</Anchor>}
                                </RatingTechnicalPeerEndorsementCard>
                              </NavLink>
                            </GridItemLayout>
                          );
                        })}
                      </GridLayout>
                    ) : (
                      <div style={{ padding: '30px 0 0 0' }}>
                        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={450} subtitle="No team members" />
                      </div>
                    )}
                  </RatingLandingCardsWrapper>
                )}
              </RatingPageLayout>
            </PageLoaderLayer>

            <Modal open={showModal} onClose={() => setShowModal(false)}>
              <ConfirmationEndorsementsModalLayout title={`Have you endorsed everyone that you work closely with?`} team={team} handleShowCompleted={handleShowCompleted} handleShowNotCompleted={handleShowNotCompleted} onClickSubmit={() => onClickHandler(renderProps)} />
            </Modal>
          </React.Fragment>
        );
      }}
    </TechnicalPeerEndorsementRoutingMap>
  );
}


