import React from 'react';
import { IPersonDetailsProps } from './person-details.models';
import { PersonDetailsAvatar, PersonDetailsContent, PersonDetailsKeplerPoints, PersonDetailsContainer } from './person-details.styles';
import { AvatarIcon, KeplerPoints } from '@keplerco/core';

export function PersonDetails({ firstName, lastName, keplerPoints }: IPersonDetailsProps): JSX.Element {
  return (
    <PersonDetailsContainer id="employee-detail-section">
      <PersonDetailsAvatar>
        <AvatarIcon name={{ firstName: firstName, lastName: lastName }} />
      </PersonDetailsAvatar>

      <PersonDetailsContent>
        <h1 className="heading1">{`${firstName} ${lastName}`}</h1>
      </PersonDetailsContent>

      <PersonDetailsKeplerPoints>
        <div className="card glass">
          <h5 className="heading5">KEPLER POINTS:</h5>

          <h2 className="heading2">
            <KeplerPoints trimWhiteSpace points={keplerPoints} />
          </h2>
        </div>
      </PersonDetailsKeplerPoints>
    </PersonDetailsContainer>
  );
}

