/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import TagInput from '../../../../../../components/form-elements/tags/tag-input';
import { FocusPanelLoaderLayer } from '../../../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { FetchType } from '../../../../../../enums';
import { TagType } from '../../../../../../enums/tag';
import { BaseSearchRequest } from '../../../../../../models';
import { TagList, TagAssigneeRequest, Assignee } from '../../../../../../models/tag';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { ManagePersonTagsFocusPanelSkeleton } from './manage-person-tags-focus-panel.skeleton';
import { IManagePersonTagsFocusPanelProps } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { Anchor } from '@keplerco/core';

export function ManagePersonTagsFocusPanel(props: IManagePersonTagsFocusPanelProps): JSX.Element {
  const actions = useAppActions();
  const [tags, setTags] = useState<TagList[]>([]);
  const { companyVariables } = useAppState();
  const [search, setSearch] = useState('');

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.personSlug,
        tagType: TagType.User,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }
  function getTagAssignees(): Assignee[] {
    return [
      {
        entitySlug: props.personSlug,
        tagType: TagType.User,
      },
    ];
  }

  async function getData() {
    actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
    const result = await actions.getAssigneeTags({ entitySlug: props.personSlug, tagType: TagType.User, companySlug: companyVariables.slug! });
    setTags(result ?? []);
    actions.stopLoader(PagePath.administrationPeople);
  }

  useEffect(() => {
    getData();
  }, []);

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;

      assignees = [
        {
          entitySlug: props.personSlug,
          tagType: TagType.User,
        },
      ];
    }

    await actions.addAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  function onTagChange(tags: TagList[]) {
    setTags(tags);
  }

  async function searchUserTags(query: string): Promise<TagList[]> {
    setSearch(query);

    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: companyVariables.slug!,
    };

    const paginatedResult = await actions.searchTags(searchTagParams);

    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }

    return [];
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople} skeletonLoader={<ManagePersonTagsFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout">
          <h2 className="heading2">Tag Person</h2>
        </header>

        <div className="dialogBodyLayout">
          <TagInput getAssignees={getTagAssignees} onRemoveTag={onClickRemoveTagHandler} onCreateTag={onClickCreateTagHandler} initialTags={tags} onTagChange={onTagChange} searchTags={searchUserTags} />
        </div>

        <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
          <Anchor onClick={props.onCancel}>Cancel</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

