import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import confettiLottie from '../../../../assets/confetti.lottie.json';
import { LottieOutput } from '../../../../components/lottie-icon/lottie-icon';
import { Button, KeplerPoints } from '@keplerco/core';

export function JourneyCompletedModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const totalCoursesKP = (props.awardBreakdowns ?? []).reduce((a, b) => a + b.amount, 0);
  const totalEarnedKP = (props.amount ?? 0) + totalCoursesKP;

  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={confettiLottie} />
      </div>

      <div className="dialogContentLayout modalContentLayout">
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data={'/badges/badge_KP.svg'} type="image/svg+xml">
              <img src={'/badges/badge_KP.svg'} alt="KP" />
            </object>
          </div>

          <h2 className="heading2" style={{ paddingBottom: 30 }}>
            WooHoo!
          </h2>

          <h6 className="subtitle">
            You've earned a total of
            <br />
            <h2 className="heading2">
              <KeplerPoints trimWhiteSpace forceFontWeight="bold" points={totalEarnedKP} />
            </h2>
            <br />
            for the completion of <strong style={{ color: 'var(--text)' }}>{props.title}</strong>
          </h6>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button type={'button'} chubby filled arrow={false} onClick={props.onAction}>
            Great, thanks!
          </Button>
        </footer>
      </div>
    </>
  );
}

