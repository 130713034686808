import { Context } from '../..';
import * as base from '../base';
import { CareerPath } from '../../../models/view/career-path';
import { LearnerCareerPathSkillSet } from '../../../models/view/learner-career-path-skill-set';
import { LearnerCareerPath } from '../../../models/view/learner-career-path';

export async function getSkillAssessmentCareerPaths(): Promise<CareerPath[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `get-benchmark-career-paths`), authenticated: true };
  const response: base.IResponse<CareerPath[]> = await base.request(request);

  return response.data;
}

export async function saveLearnerCareerPaths(context: Context, learnerCareerPaths: LearnerCareerPath[]): Promise<LearnerCareerPath[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `save-learner-career-paths`), authenticated: true, method: 'POST', body: JSON.stringify(learnerCareerPaths) };
  const response: base.IResponse<LearnerCareerPath[]> = await base.request(request);

  return response.data;
}

export async function getLearnerCareerPaths(): Promise<LearnerCareerPath[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `get-learner-career-paths`), authenticated: true };
  const response: base.IResponse<LearnerCareerPath[]> = await base.request(request);

  return response.data;
}

export async function getLearnerCareerPathSkillSets(): Promise<LearnerCareerPathSkillSet[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `get-learner-career-path-skill-sets`), authenticated: true };
  const response: base.IResponse<LearnerCareerPathSkillSet[]> = await base.request(request);

  return response.data;
}

export async function saveLearnerCareerPathSkillSetReviews(context: Context, careerPathSkillSet: LearnerCareerPathSkillSet): Promise<LearnerCareerPathSkillSet | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `save-learner-career-path-skill-set-reviews`), authenticated: true, method: 'POST', body: JSON.stringify(careerPathSkillSet) };
  const response: base.IResponse<LearnerCareerPathSkillSet> = await base.request(request);

  return response.data;
}

export async function getLearnerCareerPathSkillSetReviews(context: Context, careerPathSkillSetSlug: string): Promise<LearnerCareerPathSkillSet | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `get-learner-career-path-skill-set-reviews`, careerPathSkillSetSlug), authenticated: true };
  const response: base.IResponse<LearnerCareerPathSkillSet> = await base.request(request);

  return response.data;
}

export async function getCompanyCareerPaths(context: Context, companySlug: string): Promise<CareerPath[] | undefined> {
  const request: base.IRequest = { url: base.url(`careerpath`, `get-carrier-paths`, companySlug), authenticated: true };
  const response: base.IResponse<CareerPath[]> = await base.request(request);

  return response.data;
}
