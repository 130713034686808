import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';
import { ListLayout, ListItemLayout } from '@keplerco/core';

export function SearchSkillFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ borderBottom: '1px solid var(--borders)', marginBottom: 30, paddingBottom: 30 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="55px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SkeletonLoader height="45px" width="300px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="30px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <ListLayout>
            <ListItemLayout>
              <SkeletonLoader height="90px" />
            </ListItemLayout>

            <ListItemLayout>
              <SkeletonLoader height="90px" />
            </ListItemLayout>

            <ListItemLayout>
              <SkeletonLoader height="90px" />
            </ListItemLayout>

            <ListItemLayout>
              <SkeletonLoader height="90px" />
            </ListItemLayout>
          </ListLayout>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={1} />

        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="30px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="30px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />

        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'start' }}>
        <SkeletonLoader height="25px" width="150px" />
      </footer>
    </div>
  );
}

