import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components';

export function ManageSkillFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="dialogContentLayout focusPanelContentLayout">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="55px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="285px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="450px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
        <SkeletonLoader height="25px" width="150px" />
        <SkeletonLoader height="45px" width="150px" />
      </footer>
    </div>
  );
}

