import React, { useEffect, useReducer, useState } from 'react';
import { CompletionStatus } from '../../../enums/completion-status';
import { FetchType, SyncStatus } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { SkillsSkeleton } from './skills.skeleton';
import { EmptyState } from '../../../components/empty-state/empty-state';
import * as store from './skills.store';
import { filterData } from './skills.helpers';
import { SyncContentCard } from '../../../components';
import { useDataSyncReaction } from '../../../lib/data-sync.hooks';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { FilterByMenu, ListIcon, ListLayout, MapIcon, MenuItem, OrderButton, OrderType, PageHeader, Searchfield, ToggleButtonGroup, XIcon, useMediaQuery } from '@keplerco/core';
import { SkillListItem } from './skill-list-item/skill-list-item';
import classNames from 'classnames';
import styles from './skills.module.css';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { LearningJourneyViewType } from '../../../enums/learning-journey-view-type';

const labelMap = new Map([
  [OrderType.Ascending, 'Low to High'],
  [OrderType.Descending, 'High to Low'],
  [OrderType.Organic, 'Default'],
]);

export function SkillsPage(): JSX.Element {
  const actions = useAppActions();
  const { lastSynced, courseSyncStatus } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const navigate = useKeplerNavigate();

  const [reset, setReset] = useState(Date.now());

  const isMobile = useMediaQuery('screen and (max-width: 580px)');

  async function getData(useLoader: boolean = true) {
    if (useLoader) actions.startLoader({ path: PagePath.learningJourneyBase, type: FetchType.PageFetching });
    const data = await actions.getCoursesLearnerSkills();
    dispatch({ type: store.SkillsActionTypes.SetData, payload: data });
    if (useLoader) actions.stopLoader(PagePath.learningJourneyBase);
  }

  useDataSyncReaction(async () => {
    getData(false);
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const payload = filterData(state.data, state.filters);
    dispatch({ type: store.SkillsActionTypes.SetFilteredData, payload });
  }, [state.data, state.filters]);

  function onInputSearch(query: string) {
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, query } });
  }

  function onChangeSort(type: OrderType) {
    const sortAscending = type === OrderType.Organic ? undefined : type === OrderType.Ascending;
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, sortAscending } });
  }

  function onClickCompletionStatus(completionStatus: CompletionStatus | undefined) {
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, completionStatus } });
  }

  const menuItems: MenuItem<any>[] = [
    {
      text: 'All',
      onClick: () => onClickCompletionStatus(undefined),
    },
    {
      text: 'Not Started',
      onClick: () => onClickCompletionStatus(CompletionStatus.NotStarted),
    },
    {
      text: 'In Progress',
      onClick: () => onClickCompletionStatus(CompletionStatus.InProgress),
    },
    {
      text: 'Completed',
      onClick: () => onClickCompletionStatus(CompletionStatus.Completed),
    },
  ];

  function onClickClearFilters() {
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { query: undefined, sortAscending: true, completionStatus: undefined } });
    setReset(Date.now());
  }

  async function onClickViewType(viewType: LearningJourneyViewType) {
    actions.startLoader({ path: PagePath.learningJourneyBase, type: FetchType.PageFetching });
    actions.setLearningJourneyView(viewType);
    navigate(viewType === LearningJourneyViewType.Map ? `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}` : PagePath.learningJourneyBase);
    actions.stopLoader(PagePath.learningJourneyBase);
  }

  return (
    <PageLoaderLayer path={PagePath.learningJourneyBase} skeletonLoader={<SkillsSkeleton />}>
      <div className={classNames('wrapper stack', [styles.skillsWrapper])}>
        <PageHeader
          breadcrumbs={[
            {
              name: 'Learning Journey',
              url: PagePath.learningJourneyBase,
            },
            {
              name: 'Learning',
              url: PagePath.learningJourneyBase,
            },
          ]}
          title="Learning"
        />

        <div className={styles.skillsSyncToggleActions}>
          <SyncContentCard
            isSyncing={courseSyncStatus === SyncStatus.Syncing}
            variant="compact-reverse"
            lastSyncDate={lastSynced}
            onClick={async () => {
              await actions.syncCourseContent();
              actions.getTotalLearnerKeplerPoints();
            }}
          />

          {!isMobile && (
            <ToggleButtonGroup
              buttons={[
                { label: LearningJourneyViewType.Map, icon: <MapIcon /> },
                { label: LearningJourneyViewType.List, icon: <ListIcon /> },
              ]}
              defaultButtonLabel={LearningJourneyViewType.List}
              onClick={buttonLabel => onClickViewType(buttonLabel as LearningJourneyViewType)}
            />
          )}
        </div>

        <div key={reset} className={styles.skillsFilters}>
          <Searchfield value={state.filters.query} responsive={isMobile} onInput={onInputSearch} />

          <OrderButton defaultOrderType={OrderType.Ascending} labelMap={labelMap} variant="normal" onChange={onChangeSort} />

          <FilterByMenu items={menuItems} defaultItem="All" />

          <button className={styles.skillsClearFiltersButton} style={{ visibility: !state.filters.query && state.filters.sortAscending === true && state.filters.completionStatus === undefined ? 'hidden' : 'visible' }} type="button" onClick={onClickClearFilters}>
            Clear Filters
            <XIcon tone="secondary" />
          </button>
        </div>

        {!state.filteredData.length ? (
          <EmptyState title="No results" badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} />
        ) : (
          <ListLayout>
            {state.filteredData.map(skill => (
              <SkillListItem key={skill.slug} {...skill} path={`${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(`:skillSlug`, skill.slug)}`} />
            ))}
          </ListLayout>
        )}
      </div>
    </PageLoaderLayer>
  );
}

