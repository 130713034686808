import React, { PropsWithChildren } from 'react';
import { IConfirmationLayoutProps } from './confirmation-modal.models';
import { Button, colourString } from '@keplerco/core';
import classNames from 'classnames';
import styles from './confirmation-modal.module.css';

export function ConfirmationModalLayout(props: PropsWithChildren<IConfirmationLayoutProps>): JSX.Element {
  const submitButtonText = props.submitButtonText ?? 'Submit';
  const cancelButtonText = props.cancelButtonText ?? 'Cancel';
  const textAlign = props.textAlign ?? 'center';

  return (
    <div className="dialogContentLayout modalContentLayout" style={{ textAlign }}>
      <header className={classNames('dialogHeaderLayout modalHeaderLayout', styles.confirmationModalHeader)} style={{ paddingBottom: 15, textAlign: props.textAlign }}>
        {props.titleSize === 'small' ? (
          <h6 className="subtitle" style={{ textAlign: props.textAlign }}>
            {props.title}
          </h6>
        ) : props.titleSize === 'medium' ? (
          <h4 className="heading4" style={{ textAlign: props.textAlign }}>
            {props.title}
          </h4>
        ) : (
          <h2 className="heading2" style={{ textAlign: props.textAlign }}>
            {props.title}
          </h2>
        )}
      </header>

      {!!props.subtitle && !props.children && (
        <div className="dialogBodyLayout">
          {props.subtitleSize === 'large' ? (
            <h3 className="heading3" style={{ color: colourString('accent-2') }}>
              {props.subtitle}
            </h3>
          ) : (
            <h6 className="subtitle">{props.subtitle}</h6>
          )}
        </div>
      )}

      {!!props.children && <div className="dialogBodyLayout">{props.children}</div>}

      <footer className={classNames('dialogFooterLayout', 'modalFooterLayout', styles.footerStyling)} style={{ flex: 1, gap: 15 }}>
        <Button grow square theme="dark" type="button" filled onClick={props.onClickCancel}>
          {cancelButtonText}
        </Button>

        <Button grow square type="button" filled onClick={props.onClickSubmit}>
          {submitButtonText}
        </Button>

        {/* <Anchor onClick={props.onClickCancel}>{cancelButtonText}</Anchor> */}
      </footer>
    </div>
  );
}
