export enum PagePath {
  // account
  accountConfig = '/account/*',
  accountBase = '/account',
  accountLogin = '/login',
  accountRegister = '/register',
  accountSetPassword = '/set-password',
  accountForgotPassword = '/forgot-password',
  accountResetPassword = '/reset-password',
  accountVerifyEmail = '/verify-email',
  accountActivate = '/activate',

  // analysis
  analysisConfig = '/analysis/*',
  analysisBase = '/analysis',

  analysisInterimDownload = '/report',

  analysisTechnical = '/technical', // Champion
  analysisTechnicalCheckTeam = '/technical/check-team', // Champion
  analysisTechnicalAssignSkills = '/technical/assign-skills', // Champion
  analysisTechnicalSkillFrequency = '/technical/skill-frequency', // Learner

  analysisCareerPaths = '/career-paths',

  analysisRatingTechnical = '/rating/technical',
  analysisRatingTechnicalSelfAssessment = '/rating/technical/self-assessment', // Learner
  analysisRatingTechnicalPeerEndorsements = '/rating/technical/peer-endorsements',
  analysisRatingTechnicalPeerEndorsement = '/rating/technical/peer-endorsements/:ratingSlug',
  analysisRatingTechnicalCompleted = '/rating/technical/completed', // Champion

  analysisRatingCareerPaths = '/rating/career-paths',
  analysisRatingCareerPath = '/rating/career-paths/:ratingSlug',

  analysisBehavioural = '/behavioural',
  analysisBehaviouralQuestionnaire = '/behavioural/:questionnaireSlug',

  // your skills
  yourSkillsConfig = '/your-skills/*',
  yourSkillsBase = '/your-skills',
  yourSkillsSkill = '/skill/:skillSlug',

  // learning journey
  learningJourneyConfig = '/learning-journey/*',
  learningJourneyBase = '/learning-journey',
  learningJourneyCoreSkillsMap = '/core-skills-map',
  learningJourneySkill = '/skill/:skillSlug',
  learningJourneyYearSkill = '/year-skill/:skillSlug',
  learningJourneyAssignedSkill = '/assigned-skill/:skillSlug',
  learningJourneyCourseReflections = '/course-reflections',
  learningJourneyAdditionalLearning = '/additional-learning',
  learningJourneyCertificates = '/certificates',
  learningCompletedAssessments = '/completed-assessments',

  // administration
  administrationConfig = '/administration/*',
  administrationBase = '/administration',
  administrationDepartments = '/:companySlug/departments',
  administrationDepartmentTeams = '/:companySlug/departments/:departmentSlug/teams',
  administrationDepartmentPeople = '/:companySlug/departments/:departmentSlug/people',
  administrationTeams = '/:companySlug/teams',
  administrationTeamPeople = '/:companySlug/teams/:teamSlug/people',
  administrationPeople = '/:companySlug/people',
  administrationTags = '/:companySlug/tags',

  administrationRoles = '/:companySlug/roles',
  administrationRolesConfig = '/:companySlug/roles/*',
  administrationRolesGlobal = '/:companySlug/roles/view/global',

  administrationRolesCreate = '/:companySlug/roles/manage/create',
  administrationSelectedRole = '/:companySlug/roles/manage/:roleSlug',
  administrationSelectedRoleEdit = '/:companySlug/roles/manage/:roleSlug/edit',
  administrationSelectedRoleImport = '/:companySlug/roles/manage/:roleSlug/import',

  administrationReportManagement = '/:companySlug/report-management',
  administrationSettings = '/:companySlug/settings',

  // analytics
  analyticsConfig = '/analytics/*',
  analyticsBase = '/analytics',
  analyticsOverview = '/:companySlug/overview',
  analyticsDepartments = '/:companySlug/departments',
  analyticsDepartment = '/:companySlug/departments/:departmentSlug',
  analyticsTeams = '/:companySlug/teams',
  analyticsTeam = '/:companySlug/teams/:teamSlug',
  analyticsSkills = '/:companySlug/skills',
  analyticsSkill = '/:companySlug/skills/:skillSlug',
  analyticsSkillSubType = '/:companySlug/skills/:skillSubTypeSlug/:skillSlug',
  analyticsEngagement = '/:companySlug/engagement',
  analyticsPeople = '/:companySlug/people',
  analyticsPerson = '/:companySlug/people/:personSlug',
  analyticsReportTemplates = '/:companySlug/report-templates',
  analyticsReportTemplate = '/:companySlug/report-templates/:reportSlug',
  analyticsReportTemplateName = '/:companySlug/report-templates/:reportSlug/:reportName',

  // assessments
  assessmentsConfig = '/assessments/*',
  assessmentsBase = '/assessments',
  assessments = '/:companySlug',
  assessmentsFilter = '/:companySlug/view/:viewType',

  assessmentsCreate = '/:companySlug/create',
  assessmentsUpdate = '/:companySlug/create/:assessmentSlug',
  assessmentAssignees = '/:companySlug/assignees/:assessmentSlug',

  // learning management
  learningManagementConfig = '/learning-management/*',
  learningManagementBase = '/learning-management',
  learningManagementCourseMapping = '/:companySlug/course-mapping',
  learningManagementMappedCourses = '/:companySlug/mapped-courses',
  learningManagementCourseMappingCourse = '/:companySlug/course-mapping/:courseSlug',

  // report management
  reportManagement = '/report-management',

  // skills
  skillsConfig = '/skills/*',
  skillsBase = '/skills',
  skills = '/:companySlug',

  // other
  onboarding = '/onboarding',
  profile = '/profile',
  companies = '/companies',
  dashboard = '/dashboard',
  myCareer = '/my-career',
  version = '/version',

  // error
  error = '/error/:type/:code',
  errorRouting404 = '/error/routing/404',

  // root
  root = '/',
  rootWildcard = '/*',
}

export enum Mode {
  PlatformManagement = 'PlatformManagement',
  LearningDashboard = 'LearningDashboard',
}
