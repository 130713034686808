import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { FetchStatus, FetchType } from '../../../../enums';
import { KeplerState } from '../../../../models/kepler-state';
import { WidgetSkeleton } from '../widget.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, ToggleCard } from '@keplerco/core';
import styles from './preferences.module.css';
import classNames from 'classnames';
import { SkillAssessment } from '../../../../models';

export function PreferencesWidget({ assessmentSlug, onClick }: { assessmentSlug: string, onClick: () => void }): JSX.Element {
  const actions = useAppActions();
  const { fetchState, companyVariables } = useAppState<KeplerState>();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 580);
  const [isDirty, setIsDirty] = useState<boolean>(!!assessmentSlug);
  const [allowReassessment, setAllowReassessment] = useState<boolean>(false);
  const [assessment, setAssessment] = useState<SkillAssessment>();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.assessmentsCreate, type: FetchType.Custom });
      const tempAssessment = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, skillAssessmentSlug: assessmentSlug });
      setAllowReassessment(!!tempAssessment?.allowReassessment);
      setAssessment(tempAssessment);
      actions.stopLoader(PagePath.assessmentsCreate);
    }

    getData();
  }, [assessmentSlug]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 580);
  }, [window.innerWidth]);

  return fetchState[PagePath.assessmentsCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentsCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <React.Fragment>
      <div className={classNames('heading5', styles.label)}>Reassessment</div>

      <ToggleCard
        id="allowReassessment"
        value={allowReassessment}
        onChange={(_, value) => {
          setAllowReassessment(value);
          setIsDirty(true);
        }}
      >
        <div>
          <h6 className="subtitle">Allow reassessment</h6>
          {!isMobile && (
            <p className="caption1">Allow the learners to reset and retake the assessment by themselves. </p>
          )}
        </div>
      </ToggleCard>

      <div className={styles.submitContainer}>
        {isDirty && (
          <Anchor
            arrow
            onClick={() => {
              if (!assessment) return;
              actions.updateSkillAssessment({
                ...assessment,
                companySlug: companyVariables.slug!,
                allowReassessment,
              });
              onClick();
            }}>
            Next
          </Anchor>
        )}
      </div>
    </React.Fragment>
  );
}

