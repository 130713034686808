import React, { useEffect, useState } from 'react';
import { AnalyticsGridSection, AnalyticsPageHeaderContainer } from '../analytics.styles';
import { Daterange } from '../../../components';
import { DailyActivityWidget, LeaderboardWidget as LeaderboardWidget, SkillsBreakdownWidget } from '../../../widgets';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { ActiveAssessmentFocusPanelLayout } from './active-assessment-focus-panels/active-assessment-focus-panel.layout';
import { ActiveAssessmentListFocusPanelLayout } from './active-assessment-focus-panels/active-assessment-list-focus-panel.layout';
import { EntityAverageWidget } from './entity-average/entity-average.widget';
import { UniqueEntity } from '../../../models';
import { ActionCard, CascadingFocusPanels, FocusPanel, PageHeader, useCascadingPanelConnector } from '@keplerco/core';

export function AnalyticsOverviewPage(): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const { connector, closeById, closeAll, next } = useCascadingPanelConnector();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [activeAssessmentList, setActiveSkillAssessmentList] = useState<UniqueEntity[]>();
  const [activeAssessmentSlug, setActiveAssessmentSlug] = useState<string>();

  useEffect(() => {
    async function getData() {
      const tempActiveAssessmentList = await actions.getActiveSkillAssessmentList(companyVariables.slug!);
      setActiveSkillAssessmentList(tempActiveAssessmentList);
    }

    getData();
  }, []);

  function onClickHandler() {
    if (!activeAssessmentList?.length) return;

    if (activeAssessmentList.length === 1) {
      setActiveAssessmentSlug(activeAssessmentList[0].slug);
      next('active-assessment');
      return;
    }

    next('active-assessment-list');
  }

  return (
    <div className="pageWrapper wrapper">
      <AnalyticsPageHeaderContainer>
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: `${PagePath.analyticsBase}${PagePath.analyticsOverview}`,
            },
          ]}
          title="Overview"
          type="actions"
        >
          <div className="headerActions" style={{ flexWrap: 'wrap' }}>
            <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

            <Daterange
              defaultValue={dateRangeOutput}
              onDaterangeChange={result => {
                actions.setGlobalDateRange(result);
              }}
            />
          </div>
        </PageHeader>
      </AnalyticsPageHeaderContainer>

      {!!activeAssessmentList && activeAssessmentList.length > 0 && (
        <React.Fragment>
          <ActionCard icon="/assessments-pending.svg" title="Assessments in progress" description="Your team has outstanding assessments" actionText="View Progress" onClick={onClickHandler} />

          <CascadingFocusPanels
            openIds={connector}
            onClosePanelById={id => {
              if (id === 'active-assessment') setActiveAssessmentSlug(undefined);
              closeById(id);
            }}
            onCloseAllPanels={() => {
              setActiveAssessmentSlug(undefined);
              closeAll();
            }}
          >
            <FocusPanel id="active-assessment-list">
              <ActiveAssessmentListFocusPanelLayout
                activeAssessmentList={activeAssessmentList}
                onClickAssessment={activeAssessment => {
                  setActiveAssessmentSlug(activeAssessment);
                  next('active-assessment');
                }}
                onClickBack={() => closeById('active-assessment-list')}
              />
            </FocusPanel>

            <FocusPanel id="active-assessment">
              <ActiveAssessmentFocusPanelLayout activeAssessmentSlug={activeAssessmentSlug} onClick={() => closeById('active-assessment')} />
            </FocusPanel>
          </CascadingFocusPanels>
        </React.Fragment>
      )}

      <AnalyticsGridSection>
        <div className="start-1">
          <EntityAverageWidget entity={entity} />
        </div>

        <div className="end-2">
          <SkillsBreakdownWidget entity={entity} path={PagePath.analyticsOverview} />
        </div>
      </AnalyticsGridSection>

      <AnalyticsGridSection>
        <div className="full-span">
          <CourseEngagementWidget entity={entity} isLearner={false} />
        </div>
      </AnalyticsGridSection>

      <AnalyticsGridSection>
        <div className="full-span">
          <DailyActivityWidget entity={entity} />
        </div>
      </AnalyticsGridSection>

      <AnalyticsGridSection>
        <div className="start-2">
          <LeaderboardWidget entity={entity} />
        </div>
      </AnalyticsGridSection>
    </div>
  );
}

