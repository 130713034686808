import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TechnicalPeerEndorsementInterstitialRoutingMap } from '../../rating-routing.map';
import { themedAssetUrl } from '../../../../../lib/theme-asset-url';
import {
  TechnicalPeerEndorsementInterstitialBody,
  TechnicalPeerEndorsementInterstitialHeroImageContainer,
  TechnicalPeerEndorsementInterstitialHeroImage,
  TechnicalPeerEndorsementInterstitialContent,
  TechnicalPeerEndorsementInterstitialPageWrapper,
  TechnicalPeerEndorsementInterstitialListContainer,
} from './rating-technical-peer-endorsement-interstitial.styles';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Button, PageHeader, UnorderedList, UnorderedListItem, useMediaQuery } from '@keplerco/core';

export function RatingTechnicalPeerEndorsementInterstitialPage(): JSX.Element {
  const navigate = useNavigate();

  const isMobile = useMediaQuery('screen and (max-width: 930px)');

  const heroImage = <img src={themedAssetUrl('graphics/endorse-team.graphic.png')} alt="" />;

  return (
    <TechnicalPeerEndorsementInterstitialRoutingMap>
      {renderProps => {
        return (
          <PageLoaderLayer path={PagePath.analysisRatingTechnical}>
            <TechnicalPeerEndorsementInterstitialPageWrapper className="pageWrapper wrapper">
              <PageHeader
                breadcrumbs={[
                  { name: 'Skills analysis', url: PagePath.analysisBase },
                  { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
                  { name: 'Role-based skills analysis', url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}` },
                  {
                    name: 'Endorse your team',
                    url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
                  },
                ]}
              />

              <TechnicalPeerEndorsementInterstitialContent>
                <TechnicalPeerEndorsementInterstitialHeroImageContainer>
                  <TechnicalPeerEndorsementInterstitialHeroImage>{heroImage}</TechnicalPeerEndorsementInterstitialHeroImage>
                </TechnicalPeerEndorsementInterstitialHeroImageContainer>

                <TechnicalPeerEndorsementInterstitialBody>
                  <h1 className="heading1">Endorse your team's skills</h1>
                  <h6 className="subtitle" style={{ paddingBottom: 0 }}>
                    Show your appreciation for your colleagues' skills and strengths by endorsing them. Here's how it works:
                  </h6>

                  <TechnicalPeerEndorsementInterstitialListContainer>
                    <UnorderedList>
                      <UnorderedListItem>Choose which team members you’d like to endorse. You don’t need to complete endorsements for every colleague.</UnorderedListItem>

                      <UnorderedListItem>Give your honest, objective feedback.</UnorderedListItem>

                      <UnorderedListItem>Submit your endorsements anonymously.</UnorderedListItem>
                    </UnorderedList>
                  </TechnicalPeerEndorsementInterstitialListContainer>

                  <Button type="button" onClick={() => navigate(renderProps.next!)}>
                    Endorse your team
                  </Button>

                  {isMobile && <TechnicalPeerEndorsementInterstitialHeroImage>{heroImage}</TechnicalPeerEndorsementInterstitialHeroImage>}
                </TechnicalPeerEndorsementInterstitialBody>
              </TechnicalPeerEndorsementInterstitialContent>
            </TechnicalPeerEndorsementInterstitialPageWrapper>
          </PageLoaderLayer>
        );
      }}
    </TechnicalPeerEndorsementInterstitialRoutingMap>
  );
}

