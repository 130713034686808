import React, { useEffect, useState } from 'react';
import { IManagePersonCascadingPanelsProps, ManagePersonCascadingPanelsEnum } from './manage-person-panels.models';
import { FetchType } from '../../../../../enums';
import { Employee } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ManagePersonTagsFocusPanel } from './manage-person-tags-panels/manage-person-tags-focus-panel';
import { Permissions } from '../../../../../models/overmind/permissions';
import { TagType } from '../../../../../enums/tag';
import { TagList } from '../../../../../models/tag';
import { ManagePersonPermissionsCascadingPanels } from './manage-person-permissions-panels/manage-person-permissions-cascading-panels';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { ManagePersonFocusPanel } from './manage-person-focus-panel/manage-person-focus-panel';
import { CascadingFocusPanels, FocusPanel, useCascadingPanelConnector } from '@keplerco/core';

export function ManagePersonCascadingPanels(props: IManagePersonCascadingPanelsProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const cascade = useCascadingPanelConnector();

  const [person, setPerson] = useState<Employee>();
  const [permissions, setPermissions] = useState<Permissions>();
  const [tags, setTags] = useState<TagList[]>();

  async function getPerson(personId: string): Promise<Employee | undefined> {
    const data = await actions.getPerson(personId);
    setPerson(data);
    return data;
  }

  async function getPermissions(personId: string) {
    const data = await actions.getPermissions(personId);
    setPermissions(data);
  }

  async function getTags(personSlug: string | undefined) {
    if (!companyVariables.slug || !personSlug) return;
    const data = await actions.getAssigneeTags({ companySlug: companyVariables.slug, entitySlug: personSlug, tagType: TagType.User });
    setTags(data);
  }

  useEffect(() => {
    cascade.next(ManagePersonCascadingPanelsEnum.Sections);
  }, []);

  useEffect(() => {
    async function getData(): Promise<void> {
      if (!props.personId) return;

      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });

      const personData = await getPerson(props.personId);
      await getPermissions(props.personId);
      await getTags(personData?.id);

      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, [props.personId]);

  function onClosePanelByIdHandler(id: string) {
    cascade.closeById(id);
    if (id === ManagePersonCascadingPanelsEnum.Sections || cascade.connector.length <= 1) props.onCancel();
  }

  return (
    <CascadingFocusPanels openIds={cascade.connector} onClosePanelById={onClosePanelByIdHandler}>
      <FocusPanel id={ManagePersonCascadingPanelsEnum.Sections}>
        <ManagePersonFocusPanel
          person={person}
          permissions={permissions}
          tags={tags}
          onClickSection={section => cascade.next(section)}
          onCancel={props.onCancel}
          onSave={async slug => {
            actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
            await getPerson(slug);
            await getPermissions(slug);
            actions.stopLoader(PagePath.administrationPeople);
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonCascadingPanelsEnum.Permissions}>
        {!!person && !!permissions && (
          <ManagePersonPermissionsCascadingPanels
            person={person}
            permissions={permissions}
            onSave={async () => {
              actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
              await getPermissions(person.id!);
              actions.stopLoader(PagePath.administrationPeople);
            }}
            onCancel={() => cascade.previous()}
          />
        )}
      </FocusPanel>

      <FocusPanel id={ManagePersonCascadingPanelsEnum.Tags}>
        {!!person && !!person.learnerSlug && (
          <ManagePersonTagsFocusPanel
            personSlug={person.learnerSlug}
            onCancel={async () => {
              actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
              await getTags(person?.id);
              cascade.previous();
              actions.stopLoader(PagePath.administrationPeople);
            }}
          />
        )}
      </FocusPanel>
    </CascadingFocusPanels>
  );
}

