import React from 'react';
import { Chip } from '@keplerco/core';
import { CompletionStatus } from '../../../../enums/completion-status';
import { LandingPageContentCard, LandingPageContentCardsWrapper } from '../landing.page.styles';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PeerEndorsementsLandingPageRoutingMap } from '../../peer-endorsements/peer-endorsements.map';
import { TechnicalLandingPageContent, TechnicalLandingPageImage, TechnicalLandingPageWrapper } from './technical-landing.styles';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { useAppState } from '../../../../overmind';
import classNames from 'classnames';

export function TechnicalLandingPage(): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  const teamPeerReviewCompletionStatus = skillAssessmentConfiguration?.teamPeerReviewCompletionStatus;
  const skillAssignmentCompletionStatus = skillAssessmentConfiguration?.skillAssignmentCompletionStatus;

  function getTeamPeerReviewCompletionStatus(): string {
    if (teamPeerReviewCompletionStatus === CompletionStatus.Completed) {
      return 'Completed';
    }

    if (teamPeerReviewCompletionStatus === CompletionStatus.InProgress) {
      return 'In progress';
    }

    return 'Not started';
  }

  function getSkillAssignmentCompletionStatus(): string {
    if (skillAssignmentCompletionStatus === CompletionStatus.Completed) {
      return 'Completed';
    }

    if (skillAssignmentCompletionStatus === CompletionStatus.InProgress) {
      return 'In progress';
    }

    return 'Not started';
  }

  return (
    <PeerEndorsementsLandingPageRoutingMap>
      {() => {
        return (
          <TechnicalLandingPageWrapper>
            <TechnicalLandingPageImage>
              <img src={themedAssetUrl('graphics/technical-landing.graphic.png')} alt="graph" />
            </TechnicalLandingPageImage>

            <TechnicalLandingPageContent>
              <h1 className="heading1">Team Skills Analysis</h1>
              <h6 className="subtitle">To find the most accurate results for your team, you can separate them into specific job roles, assign skills pertinent to that role, and then get a balanced view of their skills by getting a team rating for individuals.</h6>

              <LandingPageContentCardsWrapper>
                <LandingPageContentCard style={{ minHeight: 110 }} to={`${PagePath.analysisBase}${PagePath.analysisTechnicalCheckTeam}`} borderColour={'baby-blue'}>
                  <h3 className="heading3">Check your team</h3>
                  <span className="caption2">Confirm your team structure has been set up correctly.</span>
                </LandingPageContentCard>

                <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisTechnicalAssignSkills}`} borderColour={'blue'} className={classNames({ done: skillAssignmentCompletionStatus === CompletionStatus.Completed })}>
                  <Chip
                    label={getSkillAssignmentCompletionStatus()}
                    variant="tiny"
                    backgroundColour={skillAssignmentCompletionStatus === CompletionStatus.Completed ? 'g' : 'default'}
                    colour={skillAssignmentCompletionStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
                  />

                  <h3 className="heading3">Skill assignment</h3>
                  <span className="caption2">Allocate the skills required for each role within the team.</span>
                </LandingPageContentCard>

                <LandingPageContentCard to={`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`} borderColour={'grape'} className={classNames({ done: teamPeerReviewCompletionStatus === CompletionStatus.Completed })}>
                  <Chip
                    label={getTeamPeerReviewCompletionStatus()}
                    variant="tiny"
                    backgroundColour={teamPeerReviewCompletionStatus === CompletionStatus.Completed ? 'g' : 'default'}
                    colour={teamPeerReviewCompletionStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
                  />

                  <h3 className="heading3">Team endorsements</h3>
                  <span className="caption2">Endorse your team members on each skill.</span>
                </LandingPageContentCard>
              </LandingPageContentCardsWrapper>
            </TechnicalLandingPageContent>
          </TechnicalLandingPageWrapper>
        );
      }}
    </PeerEndorsementsLandingPageRoutingMap>
  );
}

