import { SkillScoreComparison } from '../../enums';

export interface CompanyVariables {
  slug: string | undefined;
  name: string | undefined;
  useLevels: boolean;
  minLevel: number;
  maxLevel: number;
  skillScoreComparison: SkillScoreComparison;
  displayJobInformationForm: boolean;
  allowOffPlatformLearning: boolean;
  allowReflections: boolean;
  showCertificates: boolean;
  downloadLearningProgressPdf: boolean;
}

export const defaultCompanyVariables: CompanyVariables = {
  slug: undefined,
  name: undefined,
  minLevel: 1,
  maxLevel: 7,
  useLevels: true,
  displayJobInformationForm: true,
  allowOffPlatformLearning: false,
  allowReflections: false,
  showCertificates: false,
  downloadLearningProgressPdf: true,
  skillScoreComparison: SkillScoreComparison.None,
};
