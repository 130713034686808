import React from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { ISideNavRoute } from '../side-nav.models';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { Mode, PagePath } from '../../navigation.enums';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ChevronIcon } from '../../../components';
import applicationPackage from '../../../../package.json';
import { SideNavPanelRouteList } from './side-nav-panel-route-list/side-nav-panel-route-list';
import { DoubleChevronIcon } from '../../../components/assets/chevron.icons';
import navStyles from '../../navigation.module.css';
import sideNavStyles from '../side-nav.module.css';
import sideNavPanelStyles from './side-nav-panel.module.css';
import sideNavPanelRouteListStyles from './side-nav-panel-route-list/side-nav-panel-route-list.module.css';
import KeplerNavlink from '../../guards/kepler-navlink';
import { Tooltip } from '@keplerco/core';

export function SideNavPanel(props: { learnerRoutes: ISideNavRoute[]; companyRoutes: ISideNavRoute[]; onOpenSettings: () => void }): JSX.Element {
  const { learnerRoutes, companyRoutes, onOpenSettings } = props;

  const { pathname } = useLocation();

  const actions = useAppActions();
  const { companyVariables, mode, mayViewSideNav, sideNavVisible, sideNavCollapsed } = useAppState();

  return (
    <nav
      className={classNames(
        sideNavPanelStyles.sideNavPanelContainer,
        {
          [sideNavPanelStyles.visible]: mayViewSideNav && sideNavVisible,
          [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed,
        },
      )}
    >
      <a className={sideNavPanelStyles.sideNavPanelLogoContainer} href={PagePath.root}>
        <img
          className={classNames(
            sideNavPanelStyles.sideNavPanelLogo,
            sideNavPanelStyles.text,
            { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed }
          )}
          src={themedAssetUrl('logos/logo.svg')}
          alt="Logo"
        />

        <img
          className={classNames(
            sideNavPanelStyles.sideNavPanelLogo,
            sideNavPanelStyles.icon,
            { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed })}
          src={themedAssetUrl('logos/logo.icon.svg')}
          alt="Logo Icon"
        />
      </a>

      <button
        className={classNames(sideNavPanelStyles.sideNavPanelToggleButton, { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed })}
        onClick={() => actions.setSideNavCollapsed(!sideNavCollapsed)}
      >
        <ChevronIcon tone="secondary" size={15} rotation={sideNavCollapsed ? 1 : 3} />
      </button>

      <div className={sideNavPanelStyles.sideNavPanelRoutesContainer}>
        {mode === Mode.PlatformManagement ? (
          <div className={sideNavStyles.sideNavAllCompaniesContainer}>
            <KeplerNavlink
              to={PagePath.companies}
              className={classNames(
                sideNavStyles.sideNavAllCompanies,
                sideNavPanelStyles.sideNavPanelAllCompaniesExpanded,
                { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed }
              )}
            >
              <DoubleChevronIcon tone="primary" />
              All Companies
            </KeplerNavlink>

            <KeplerNavlink
              to={PagePath.companies}
              className={classNames(
                sideNavStyles.sideNavAllCompanies,
                sideNavPanelStyles.sideNavPanelAllCompaniesCollapsed,
                { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed })}
            >
              <DoubleChevronIcon tone="primary" />
            </KeplerNavlink>
          </div>
        ) : (
          <div className={sideNavPanelStyles.sideNavPanelLearnerRouteListContainer}>
            <SideNavPanelRouteList sideNavParentRoutes={learnerRoutes} sideNavCollapsed={sideNavCollapsed} pathname={pathname} />
          </div>
        )}

        {companyRoutes.length > 0 && (
          <React.Fragment>
            <span className={classNames(navStyles.navCaption, sideNavPanelStyles.sideNavPanelCompany, { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed })}>
              Company
            </span>
            <SideNavPanelRouteList sideNavParentRoutes={companyRoutes} sideNavCollapsed={sideNavCollapsed} pathname={pathname} />
          </React.Fragment>
        )}

        {/* TODO: remove when Settings Page added */}
        {!!companyVariables.slug && mode === Mode.PlatformManagement && (
          <React.Fragment>
            {sideNavCollapsed ? (
              <div className={classNames(sideNavPanelRouteListStyles.sideNavPanelParentRouteContainer, sideNavPanelRouteListStyles.collapsed)}>
                <Tooltip
                  content="Settings"
                  backgroundColour="primary"
                  borderColour="primary"
                  fontWeight="700"
                  textTransform="uppercase"
                >
                  <button className={sideNavPanelRouteListStyles.sideNavPanelParentRouteButton} onClick={onOpenSettings}>
                    <img
                      className={classNames(navStyles.navIcon, sideNavPanelRouteListStyles.sideNavPanelParentRouteIcon, sideNavPanelRouteListStyles.collapsed)}
                      src={themedAssetUrl('icons/sidebar/settings.icon.svg')}
                      alt=""
                    />
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div className={classNames(sideNavPanelRouteListStyles.sideNavPanelParentRouteContainer, sideNavPanelRouteListStyles.expanded)}>
                <button className={sideNavPanelRouteListStyles.sideNavPanelParentRouteButton} onClick={onOpenSettings}>
                  <img
                    className={classNames(navStyles.navIcon, sideNavPanelRouteListStyles.sideNavPanelParentRouteIcon, sideNavPanelRouteListStyles.expanded)}
                    src={themedAssetUrl('icons/sidebar/settings.icon.svg')}
                    alt=""
                  />
                  <div
                    className={classNames(
                      sideNavStyles.sideNavParentRouteTitle,
                      sideNavPanelRouteListStyles.sideNavPanelParentRouteTitle,
                      sideNavPanelRouteListStyles.expanded,
                    )}
                  >
                    Settings
                  </div>
                </button>
              </div>
            )}
          </React.Fragment>
        )}
      </div>

      <KeplerNavlink to={PagePath.version} className={classNames(sideNavPanelStyles.sideNavPanelVersion, { [sideNavPanelStyles.sideNavCollapsed]: sideNavCollapsed })}>
        Version: {applicationPackage.version}
      </KeplerNavlink>
    </nav>
  );
}
