import React, { Dispatch, SetStateAction } from 'react';
import { IAssessmentCreationStepsProps } from '../create-skill-assessment.models';
import EndorsementSelectionWidget from '../../../../widgets/forms/skill-assessment/endorsement-selection/endorsement-selection.widget';
import { SkillAssessmentAssignees } from '../../../../models';
import { SelectSkillsWidget } from '../../../../widgets';
import { SkillAssessmentTagsWidget } from '../../../../widgets/forms';
import { QuestionnaireStepHeader } from '../create-skill-assessment.helpers';
import { SmartSelectSection } from '@keplerco/core';

interface PeerEndorsementStepsProps {
  handleEndorsementSelectionChange: (updatedAssessmentAssignees: SkillAssessmentAssignees) => void;
  assessmentAssignees: SkillAssessmentAssignees | null;
  skillAssessmentSlug: string;
  isDraft?: boolean;
  setHasUserWithNoSkills: Dispatch<SetStateAction<boolean>>;
  stepRefs: React.RefObject<HTMLDivElement>[];
}

export function PeerEndorsementSteps(props: IAssessmentCreationStepsProps & PeerEndorsementStepsProps): JSX.Element[] {
  return [
    <SmartSelectSection key="endorsementSelection" headerContent={<QuestionnaireStepHeader title="Select who to endorse:" descriptions="Choose which team members you would like to take part in the endorsement, and their manager." />}>
      <div ref={props.stepRefs[1]} key="endorsementSelection">
        <EndorsementSelectionWidget onEndorsementSelectionChange={props.handleEndorsementSelectionChange} selectedAssignees={props.assessmentAssignees} benchmarkSlug={props.skillAssessmentSlug} onStepComplete={() => props.handleStepCompletion(1)} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="selectSkills" headerContent={<QuestionnaireStepHeader title="Select skills:" descriptions="Select the skills you would like the team to endorse each other on." />}>
      <div ref={props.stepRefs[2]} key="selectSkills">
        <SelectSkillsWidget setHasUserWithNoSkills={props.setHasUserWithNoSkills} isDraft={!!props.isDraft} selectedAssigneeSkills={props.assessmentAssignees} onStepComplete={() => props.handleStepCompletion(2)} assessmentId={props.skillAssessmentSlug} />
      </div>
    </SmartSelectSection>,
    <SmartSelectSection key="skillTags" headerContent={<QuestionnaireStepHeader title="Tag:" descriptions="Assign a tag to an assessment for analytics." />}>
      <div ref={props.stepRefs[3]} key="skillTags">
        <SkillAssessmentTagsWidget
          onComplete={() => {
            props.onFinalStepCompletion();
          }}
          assessmentSlug={props.skillAssessmentSlug}
        />
      </div>
    </SmartSelectSection>,
  ].filter((_, index) => index + 1 <= props.index);
}

