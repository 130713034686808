import { DeveloperConsole } from '@keplerco/core';
import { track } from '../../lib/track';
import { User } from '../../models/user';

export const BASE_ENDPOINT_URL = (process.env.REACT_APP_KEPLER_API_ENDPOINT as string).trim();

/**
 *
 * @param paths A list of strings to join as a path
 * @returns A fully formed api URL path with the base URL and configs populated
 */
export function url(...paths: string[]): string {
  const mappedPaths = paths.map(path => {
    if (!path) return '';
    let trimmedPath = path.trim();
    trimmedPath = trimmedPath.startsWith('/') ? trimmedPath.substring(1) : trimmedPath;
    trimmedPath = trimmedPath.endsWith('/') ? trimmedPath.substring(0, trimmedPath.length - 1) : trimmedPath;
    return trimmedPath;
  });

  const baseUrl = BASE_ENDPOINT_URL.endsWith('/') ? BASE_ENDPOINT_URL.substring(0, BASE_ENDPOINT_URL.length - 1) : BASE_ENDPOINT_URL;
  return `${baseUrl}/api/${mappedPaths.join('/')}`;
}

export function params(currentObject: {}): string {
  const nextObject = Object.entries(currentObject)
    .filter(entry => entry[1] !== undefined)
    .reduce((tempObject, [key, value]) => ({ ...tempObject, [key]: value }), {});
  const urlSearchParams = new URLSearchParams(nextObject).toString();
  return !!urlSearchParams ? `?${urlSearchParams}` : '';
}

export class DefaultRequestParameters implements RequestInit {
  public headers = { 'Content-Type': 'application/json' };
  public method = 'GET';

  constructor(init?: RequestInit) {
    Object.assign(this, init);
  }

  public toObject(): RequestInit {
    return this as any;
  }
}

export class DefaultAuthenticatedParameters extends DefaultRequestParameters {
  public credentials = 'include';
}

export interface IRequest {
  url: string;

  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
  authenticated?: boolean;
  body?: any;
  headers?: any;
}

export interface IError {
  requestURL: string;
  requestMethod: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
  statusCode: number;
  statusText: string;
  type: 'API' | 'JSON' | 'OTHER';
  detail?: string;
}

export interface IResponse<T> {
  data?: T;
  error?: IError;
}

export async function request<T = any>(request: IRequest): Promise<IResponse<T>> {
  try {
    const {
      url,
      authenticated = true,

      method = 'GET',
      body = typeof request.body === 'object' ? JSON.stringify(request.body) : request.body,
      headers = { 'Content-Type': 'application/json' },
    } = request;

    const params = authenticated ? new DefaultAuthenticatedParameters({ method, body, headers }) : new DefaultRequestParameters({ method, body, headers });

    const response = await fetch(url, params.toObject());

    if (!response.ok || response.status === 500) {
      const apiError: IError = { requestURL: url, requestMethod: method, statusCode: response.status, statusText: response.statusText, type: 'API' };

      try {
        const message = await response.json();

        apiError.detail = message.detail;

        if (response.status === 401 && message.RedirectToLogin === true) {
          apiError.statusText = apiError.statusText || 'Unknown error';
          window.location.reload();
          throw apiError;
        }

        const errorMessage = message.detail ?? message.errors[0];
        apiError.statusText = apiError.statusText || errorMessage || 'Unknown error';
      } catch (error) {
        apiError.statusText = apiError.statusText || `Unknown error`;
      }

      throw apiError;
    }

    if (response.headers.get('content-type') === null) return {};

    if (!response.headers.get('content-type')?.includes('json')) {
      try {
        const data = await response.text();
        return { data: data as T };
      } catch (error: any) {
        const otherError: IError = { requestURL: url, requestMethod: method, statusCode: response.status, statusText: 'Unexpected error', type: 'OTHER' };
        track('KPLR-ERROR-EVENT', otherError);
        new DeveloperConsole('OTHER', ['#E5145F', '#FFFFFF']).error(otherError);
        return { error: otherError };
      }
    }

    try {
      const data = await response.json();
      return { data };
    } catch (error: any) {
      const jsonError: IError = { requestURL: url, requestMethod: method, statusCode: response.status, statusText: 'Unexpected end of JSON input', type: 'JSON' };
      track('KPLR-ERROR-EVENT', jsonError);
      new DeveloperConsole('JSON', ['#E5145F', '#FFFFFF']).error(jsonError);
      return { error: jsonError };
    }
  } catch (apiError: any) {
    track('KPLR-ERROR-EVENT', apiError);
    new DeveloperConsole('API', ['#E5145F', '#FFFFFF']).error(apiError);
    return { error: apiError };
  }
}

export function generateLocalStorageKey(user: User): string {
  if (!!user.learnerSlug) {
    return `${user.learnerSlug}-savedActiveRole`;
  }

  return `${user.companySlug}-${user.firstName}-${user.lastName}-savedActiveRole`;
}
