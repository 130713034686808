import React from 'react';
import { SkeletonLoader } from '../../../../components';
import { SkeletonLoaderContainer, SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

export function CourseMappingCourseSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="20px" />
        </SkeletonLoaderColumn>
        <SkeletonLoaderColumn flex={2} />
      </SkeletonLoaderRow>
      <SkeletonLoaderRow>

        <SkeletonLoaderColumn>
          <SkeletonLoader height="80px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 20 }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="150" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="150px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="460px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 20 }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={3} />

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="50px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

