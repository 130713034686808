import { Context } from '../..';
import { Learner, LearnerSearch } from '../../../models/view/learner';
import { LearnerSkillResource } from '../../../models/learner-skill-resource';
import { PersonalDetails } from '../../../models/view/personal-details';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { LearnerCertificateSearchResponse } from '../../../models/view/learner-certificate-sesearch-response';
import * as base from '../base';
import { User } from '../../../models/user';

export async function updateLearnerSkills(_: Context, learnerSkillResources: Array<LearnerSkillResource>): Promise<void> {
  const request: base.IRequest = { url: base.url(`learnerskill`, `update-learner-skills`), authenticated: true, method: 'POST', body: JSON.stringify(learnerSkillResources) };
  base.request(request);
}

export async function completeSkillsGaps(): Promise<void> {
  const request: base.IRequest = { url: base.url(`learnerskill`, `complete-learner-skills`), authenticated: true, method: 'POST' };
  base.request(request);
}

export async function searchLearners(context: Context, SearchRequest: BaseSearchRequest): Promise<LearnerSearch | undefined> {
  const queryParams = new URLSearchParams(SearchRequest as any).toString();

  const request: base.IRequest = { url: base.url(`learner`, `search-learners?${queryParams}`), authenticated: true };
  const response: base.IResponse<LearnerSearch> = await base.request(request);

  return response.data;
}

export async function getLearnerSkills(): Promise<LearnerSkillResource[] | undefined> {
  const request: base.IRequest = { url: base.url(`learnerskill`, `get-learner-skills`), authenticated: true };
  const response: base.IResponse<LearnerSkillResource[]> = await base.request(request);

  return response.data?.filter((item, index, data) => index === data.findIndex(tempIndex => item.companySkillId === tempIndex.companySkillId));
}

export async function submitPersonalDetails({ state }: Context, personalDetails: PersonalDetails): Promise<void> {
  const request: base.IRequest = { url: base.url(`learner`, `submit-personal-details`), authenticated: true, method: 'POST', body: JSON.stringify(personalDetails) };
  const response: base.IResponse<User> = await base.request(request);

  state.user = response.data;
}

export async function getLearnerCertificateList(context: Context, SearchRequest: BaseSearchRequest): Promise<LearnerCertificateSearchResponse | undefined> {
  const queryParams = new URLSearchParams({ ...SearchRequest } as any).toString();

  const request: base.IRequest = { url: base.url(`learnercertificate`, `search-certificates?${queryParams}`), authenticated: true };
  const response: base.IResponse<LearnerCertificateSearchResponse> = await base.request(request);

  return response.data;
}

export async function getLearnerBySlug(context: Context, learnerSlug: string): Promise<Learner | undefined> {
  const request: base.IRequest = {
    url: base.url(`learner`, `${learnerSlug}`),
    authenticated: true
  };

  const response: base.IResponse<Learner> = await base.request(request);

  return response.data;
}
