import React, { useEffect, useRef, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { ActivationStatus, FetchType, OrganizationLevelType } from '../../../enums';
import { useForm } from 'react-hook-form';
import { Multiselect } from '../../../components/multiselect/multiselect';
import { IMultiselectCategories, IMultiselectItem } from '../../../components/multiselect/multiselect.models';
import { filterCategory } from './manage-skill-focus-panel.helpers';
import { useParams } from 'react-router-dom';
import { IManageSkillFocusPanelCMSLayoutProps } from './manage-skill-cascading-focus-panels.models';
import { ManageSkillFocusPanelSkeleton } from './manage-skill-focus-panel.skeleton';
import { FocusPanelLoaderLayer } from '../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { Anchor, Button, Chip, ChipWrapper, FormControl, PageHeader, RequiredValidator, Textarea, Textfield, capitalize, colourString } from '@keplerco/core';
import classNames from 'classnames';
import { AssignEntityRequest, EntityAssignee, EntityResponse, CreateEntityRequest } from '../../../models/overmind/entities';

interface IFormValues {
  name: string;
  description: string;
}

export function ManageSkillFocusPanelCMSLayout(props: IManageSkillFocusPanelCMSLayoutProps): JSX.Element {
  const params = useParams();

  const actions = useAppActions();

  const { handleSubmit, control, setValue } = useForm<any>({ mode: 'onChange' });

  const errorRef = useRef<HTMLDivElement>(null);

  const [initialCategories, setInitialCategories] = useState<IMultiselectCategories>();
  const [currentCategories, setCurrentCategories] = useState<IMultiselectCategories>();
  const [invalid, setInvalid] = useState<boolean>(true);
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    async function generateInitialCategories() {
      const departments = await actions.getAdministrationDepartments(params.companySlug!);
      const departmentItems = departments?.map(department => ({ slug: department.slug, name: department.name } as IMultiselectItem)) ?? [];

      const teams = await actions.getAdministrationTeams(params.companySlug!);
      const teamItems = teams?.map(team => ({ slug: team.slug, name: team.teamName, parentSlug: team.departmentSlug } as IMultiselectItem)) ?? [];

      const individuals = await actions.getEmployeesByCompanySlugAndActivationStatus({ companySlug: params.companySlug!, activationStatus: ActivationStatus.Active });
      const individualItems = individuals?.map(individual => ({ slug: individual.learnerSlug, name: `${individual.firstName} ${individual.lastName}`, parentSlug: individual.teamSlug } as IMultiselectItem)) ?? [];

      let skillPoolItem: EntityResponse | undefined;
      if (props.type === 'update' && !!props.skillPoolListItem) {
        skillPoolItem = await actions.getCompanySkill({ companySlug: params.companySlug!, skillSlug: props.skillPoolListItem.slug });

        skillPoolItem?.assignees.forEach(assignee => {
          switch (assignee.organizationLevel) {
            case OrganizationLevelType.Learner: {
              const individualItem = individualItems.find(item => item.slug === assignee.entitySlug);
              if (!!individualItem) {
                individualItem.active = true;
              }
              break;
            }

            case OrganizationLevelType.Team: {
              const teamItem = teamItems.find(item => item.slug === assignee.entitySlug);
              if (!!teamItem) {
                teamItem.active = true;
              }
              break;
            }

            case OrganizationLevelType.Department: {
              const departmentItem = departmentItems.find(item => item.slug === assignee.entitySlug);
              if (!!departmentItem) {
                departmentItem.active = true;
              }
              break;
            }
          }
        });
      }

      setInitialCategories(() => {
        const next = {
          Departments: {
            selectAllMessage: 'All departments in the company have been selected',
            items: departmentItems,
          },
          Teams: {
            items: teamItems,
          },
          Individuals: {
            items: individualItems,
          },
        };

        return next;
      });
    }

    async function getData() {
      actions.startLoader({ path: PagePath.skills, type: FetchType.DialogFetching });

      if (props.type === 'update') {
        setValue('name', props.skillPoolListItem?.name);
      }
      setValue('description', props.skillPoolListItem?.description);

      await generateInitialCategories();

      actions.stopLoader(PagePath.skills);
    }

    getData();
  }, []);

  useEffect(() => {
    setCurrentCategories(() => {
      if (!initialCategories) return;

      const nextCategories: IMultiselectCategories | undefined = structuredClone(initialCategories);

      if (!nextCategories) return;

      nextCategories['Teams'] = filterCategory(initialCategories, nextCategories, 'Departments', 'Teams');
      nextCategories['Individuals'] = filterCategory(initialCategories, nextCategories, 'Teams', 'Individuals');

      return nextCategories;
    });
  }, [initialCategories]);

  useEffect(() => {
    if (!currentCategories || props.hideAssignedDetails) return;
    setInvalid(!Object.values(currentCategories).some(value => value.items.some(item => item.active)));
  }, [currentCategories]);

  function onClickHandler(previousCategories: IMultiselectCategories) {
    if (!initialCategories) return;

    const nextCategories: IMultiselectCategories = structuredClone(previousCategories);

    nextCategories['Teams'] = filterCategory(initialCategories, nextCategories, 'Departments', 'Teams');
    nextCategories['Individuals'] = filterCategory(initialCategories, nextCategories, 'Teams', 'Individuals');

    setCurrentCategories(nextCategories);
  }

  function generateSkillPoolType(key: string) {
    switch (key) {
      case 'Individuals':
        return OrganizationLevelType.Learner;

      case 'Teams':
        return OrganizationLevelType.Team;

      case 'Departments':
        return OrganizationLevelType.Department;

      default:
        return OrganizationLevelType.Department;
    }
  }

  async function onSubmitHandler(values: IFormValues) {
    if (!params.companySlug || !currentCategories || !initialCategories) return;

    if (invalid && !props.hideAssignedDetails) return errorRef.current?.scrollIntoView();

    actions.startLoader({ path: PagePath.skills, type: FetchType.Sending });

    const skillPoolItemRequest = {
      name: values.name,
      description: values.description,
    } as CreateEntityRequest;

    let skillPoolItemSlug = props.skillPoolListItem?.slug;
    if (props.type === 'update' && !!props.skillPoolListItem) {
      await actions.updateCompanySkill({ companySlug: params.companySlug, skillSlug: props.skillPoolListItem.slug, data: skillPoolItemRequest });
    } else {
      skillPoolItemSlug = await actions.createCompanySkill({ companySlug: params.companySlug, data: skillPoolItemRequest });
    }

    if (!!skillPoolItemSlug && !props.hideAssignedDetails) {
      const skillPoolAssignees: EntityAssignee[] = [];
      for (const [currentCategoryKey, currentCategoryValue] of Object.entries(currentCategories)) {
        for (const item of currentCategoryValue.items) {
          if (item.active) {
            skillPoolAssignees.push({
              entitySlug: item.slug,
              name: item.name,
              organizationLevel: generateSkillPoolType(currentCategoryKey),
            } as EntityAssignee);
          }
        }

        if (currentCategoryValue.selectAll) {
          switch (currentCategoryKey) {
            case 'Departments': {
              for (const [initialCategoryKey, initialCategoryValue] of Object.entries(initialCategories)) {
                if (initialCategoryKey !== 'Departments') {
                  for (const item of initialCategoryValue.items) {
                    skillPoolAssignees.push({
                      entitySlug: item.slug,
                      name: item.name,
                      organizationLevel: generateSkillPoolType(initialCategoryKey),
                    } as EntityAssignee);
                  }
                }
              }
              break;
            }

            case 'Teams': {
              for (const [initialCategoryKey, initialCategoryValue] of Object.entries(initialCategories)) {
                if (initialCategoryKey !== 'Departments' && initialCategoryKey !== 'Teams') {
                  for (const item of initialCategoryValue.items) {
                    skillPoolAssignees.push({
                      entitySlug: item.slug,
                      name: item.name,
                      organizationLevel: generateSkillPoolType(initialCategoryKey),
                    } as EntityAssignee);
                  }
                }
              }
              break;
            }
          }
        }
      }

      const skillPoolAssigneeRequest = {
        entitySlug: skillPoolItemSlug,
        assignees: skillPoolAssignees,
      } as AssignEntityRequest;

      await actions.assignSkill({ companySlug: params.companySlug!, data: skillPoolAssigneeRequest });
    }

    actions.stopLoader(PagePath.skills);

    props.onClickSave();
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.skills} skeletonLoader={<ManageSkillFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ paddingBottom: 30 }}>
          <PageHeader title={`${capitalize(props.type ?? '')} skill`} />
        </header>

        <form id="save-skill" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="dialogBodyLayout">
            <div className="card" style={{ position: 'relative', marginBottom: 30 }}>
              <ChipWrapper style={{ position: 'absolute', top: 0, transform: 'translateY(-50%)' }}>
                <Chip label="Skill Details" backgroundColour="g" variant="tiny" />
              </ChipWrapper>

              <div className="row" style={{ alignItems: 'start', display: 'flex', gap: 10 }}>
                {props.type !== 'update' && !!props.skillPoolListItem && (
                  <div className="column">
                    <span className="caption2" style={{ color: colourString('accent-3'), fontSize: 11, fontWeight: 'bold' }}>
                      Original name
                    </span>
                    <p className="body1">{props.skillPoolListItem.name}</p>
                  </div>
                )}

                <div className="column">
                  <FormControl
                    control={control}
                    rules={new RequiredValidator('Enter a name')}
                    name="name"
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} label={props.type !== 'update' && !!props.skillPoolListItem ? 'New name' : 'Name'} type="text" responsive />;
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <FormControl
                    control={control}
                    rules={new RequiredValidator('Enter a description')}
                    name="description"
                    render={({ field, fieldState }) => {
                      return <Textarea {...field} haserror={!!fieldState.error} label="Description" responsive />;
                    }}
                  />
                </div>
              </div>
            </div>

            {!!currentCategories && !props.hideAssignedDetails && (
              <div className={classNames('card')} style={{ position: 'relative' }}>
                <ChipWrapper style={{ position: 'absolute', top: 0, transform: 'translateY(-50%)' }}>
                  <Chip label="Skill Assigned Details" variant="tiny" backgroundColour="g" />
                </ChipWrapper>

                <h6 className="subtitle" style={{ margin: '15px 0px' }}>
                  Assign skill to a everyone in the company or department/s, team/s, job role/s and individual/s.{' '}
                </h6>

                <Multiselect categories={currentCategories} onClick={onClickHandler} />

                <div className="fieldErrorMessage" ref={errorRef} style={{ visibility: invalid && dirty ? 'visible' : 'hidden', marginTop: 10 }}>
                  Select at least one department, team, job role or individual to assign this skill to.
                </div>
              </div>
            )}
          </div>

          <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
            <Anchor onClick={props.onClickCancel}>Cancel</Anchor>

            <Button type="button" onClick={() => setDirty(true)}>
              Submit
            </Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}


