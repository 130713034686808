import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { CompanySkillCMSPage } from '../../pages/skills/company-skill.cms.page';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../lib/use-company-slug-param-change';

export function SkillsRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.skillsBase);

  return (
    <Routes>
      <Route path={PagePath.skills} element={<CompanySkillCMSPage />} />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.skillsBase}${PagePath.skills}`} />} />
    </Routes>
  );
}