import React from 'react';
import { useAppState } from '../../../overmind';
import { Navigate } from 'react-router-dom';
import { IRoutingMapProps } from '../../../models/routing-map';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';

// Learner
export function PeerEndorsementsPageRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`;
  const previousPage = PagePath.analysisBase;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

// Manager
export function CheckTeamPageRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const nextPage = `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`;
  const previousPage = `${PagePath.analysisBase}${PagePath.analysisTechnical}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function AssignSkillsPageRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const nextPage = PagePath.analysisBase;
  const previousPage = `${PagePath.analysisBase}${PagePath.analysisTechnicalCheckTeam}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

export function PeerEndorsementsLandingPageRoutingMap(props: IRoutingMapProps): JSX.Element {
  const { skillAssessmentConfiguration } = useAppState();

  if (skillAssessmentConfiguration?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const nextPage = `${PagePath.analysisBase}${PagePath.analysisTechnicalCheckTeam}`;
  const previousPage = `${PagePath.analysisBase}${PagePath.analysisTechnical}`;

  return <React.Fragment>{props.children({ next: nextPage, previous: previousPage })}</React.Fragment>;
}

