import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { FormControl, RequiredValidator, Textfield, Textarea, safeCallback } from '@keplerco/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import styles from './role-details.module.css';
import { useAppActions, useAppState } from '../../../../../overmind';
import { IManageRoleFormValue } from '../manage-role.models';
import { RoleDetailsSkeleton } from './role-details.skeleton';
import { EntityResponse } from '../../../../../models/overmind/entities';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../../../navigation/navigation.enums';

interface IRoleDetailsProps {
  role?: EntityResponse;
  onRoleCreated?: (role: EntityResponse) => void;
}

const RoleDetails = forwardRef((props: IRoleDetailsProps, ref): JSX.Element => {
  const { control, handleSubmit, setValue } = useForm<any>({ mode: 'onBlur' });

  const [apiError, setApiError] = useState<string>();
  const [panelLoader, setPanelLoader] = useState<boolean>(false);
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const keplerNavigate = useKeplerNavigate();

  useEffect(() => {
    if (props.role) {
      setValue('name', props.role.name);
      setValue('description', props.role.description);
    }
  }, [props.role?.slug]);

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit((values: IManageRoleFormValue) => handleFormSubmit(values))
  }));

  async function handleFormSubmit(values: IManageRoleFormValue): Promise<void> {
    setApiError(void 0);
    setPanelLoader(true);

    let result;
    if (!props.role) {
      result = await actions.createRole({ companySlug: companyVariables.slug!, ...values });
    } else result = await actions.updateRole({ companySlug: companyVariables.slug!, slug: props.role.slug, ...values });

    if (result !== 'error') {
      const role = await actions.getRole({ roleSlug: result, companySlug: companyVariables.slug! });
      safeCallback(props.onRoleCreated, role);
    }

    keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companyVariables.slug!)}`);

    setPanelLoader(false);
  }

  return (
    <form onSubmit={handleSubmit((values: IManageRoleFormValue) => handleFormSubmit(values))}>
      <div className="cardHeader">
        <h5 className="heading5">Role details</h5>
        <p className="caption1">Courses assigned through skills will only appear once an assessment has been completed.</p>
      </div>

      <div className={classNames(styles.manageRoleFormWrapper)}>
        {!panelLoader ? (
          <React.Fragment>
            <div className="row">
              <div className="column">
                <FormControl
                  name={'name'}
                  control={control}
                  rules={new RequiredValidator('Please enter a role name')}
                  render={({ field, fieldState }) => {
                    return <Textfield {...field} label="Role name" haserror={!!fieldState.error} type="text" />;
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  name={'description'}
                  control={control}
                  rules={new RequiredValidator('Please enter a role description')}
                  render={({ field, fieldState }) => {
                    return <Textarea {...field} label="Role Description" haserror={!!fieldState.error} responsive />;
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <RoleDetailsSkeleton />
        )}
      </div>

      {apiError && <div className="formErrorMessage">{apiError}</div>}
    </form>
  );
});

RoleDetails.displayName = 'RoleDetails';

export default RoleDetails;
