import { ColumnConfiguration } from '../../../models/column-configuration';
import { ReducerAction } from '../../../models/reducer-state';
import { SkillAssessmentAssigneeStatusResponse } from '../../../models/skill-assessment-configuration';

export interface AssessmentAssigneesState {
  managingColumns?: boolean;
  columnConfiguration: ColumnConfiguration[];
  pageData?: SkillAssessmentAssigneeStatusResponse;
  hasPopulated: boolean;
}

export enum AssessmentAssigneesActionTypes {
  ManageColumns,
  EditColumnConfiguration,
  PopulatePageData,
}

type DepartmentCMSActions =
  | ReducerAction<AssessmentAssigneesActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<AssessmentAssigneesActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>
  | ReducerAction<AssessmentAssigneesActionTypes.PopulatePageData, { payload: SkillAssessmentAssigneeStatusResponse | undefined }>;

export const initialState: AssessmentAssigneesState = {
  columnConfiguration: [
    { label: 'Name', key: 'name', required: true, selected: true },
    { label: 'Job title', key: 'jobTitle', required: false, selected: true },
    { label: 'Status', key: 'status', required: false, selected: true },
    { label: 'Email', key: 'email', required: false, selected: true },
    { label: 'Department', key: 'department', required: false, selected: true },
    { label: 'Team', key: 'team', required: false, selected: true },
    { label: 'Skill score', key: 'skillScore', required: false, selected: true },
  ],
  hasPopulated: false,
};

export function reducer(state: AssessmentAssigneesState = initialState, action: DepartmentCMSActions): AssessmentAssigneesState {
  if (action.type === AssessmentAssigneesActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === AssessmentAssigneesActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  if (action.type === AssessmentAssigneesActionTypes.PopulatePageData) {
    return { ...state, pageData: action.payload, hasPopulated: true };
  }

  return state;
}

