import styled from 'styled-components';
import { CMSRow } from '../../../theme/layout.styles';

export const RolesPageJobTitle = styled.strong`
  color: var(--link-text);
`;

export const RolesPageFilteringRow = styled(CMSRow)`
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

export const JobPoolWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
    padding-top: 10px;
  }

  @media screen and (min-width: 581px) and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const RolesPageTableRow = styled(CMSRow)`
  display: block;
`;

export const RolesPageFocusPanelContentLayout = styled.div`
  width: 100%;
`;

export const RolesPageFocusPanelHeaderLayout = styled.header`
  gap: 10px 25px;
  border-bottom: solid 1px var(--borders);
  margin-bottom: 30px;
  display: block;
`;

export const RolesPageFocusPanelHeaderLayoutTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 25px;
  padding-bottom: 10px;
`;

export const RolesPageFocusPanelBodyLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;

  .heading5 {
    color: var(--accent-2);
  }
`;

export const RolesPageFocusPanelCardHeader = styled.header`
  display: flex;
  gap: 15px;

  .body1 {
    color: var(--accent-3);
  }
`;

export const PagerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`;

