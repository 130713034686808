import { DeveloperConsole } from '@keplerco/core';
import { Context } from '../..';
import { BaseSearchRequest } from '../../../models';
import { ExpectedSkillLevelResponse, SkillExpectedLevel } from '../../../models/overmind/roles';
import * as base from '../base';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { AssignEntityRequest, EntityAssigneeListResponse, EntityResponse, EntityListResponse, RelateEntityRequest, CreateEntityRequest } from '../../../models/overmind/entities';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
const dconsole = new DeveloperConsole('Role Pool', ['#E5145F', '#FFFFFF']);

export async function createRole(context: Context, body: { companySlug: string } & CreateEntityRequest): Promise<string> {
  const url: string = base.url('companyrole', 'create-role', body.companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(body) };
  const response: base.IResponse<string> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error creating this role',
      message: `There was an error creating this role`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `${Date.now() + Math.random()}`,
      active: true,
    });
    return response.error.statusText ?? 'error';
  }

  return response.data!;
}

export async function updateRole(context: Context, body: { companySlug: string; slug: string } & CreateEntityRequest): Promise<string> {
  const url: string = base.url('companyrole', 'update-role', body.companySlug, body.slug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(body) };
  const response: base.IResponse<string> = await base.request(request);

  if (response.error) return response.error.statusText ?? 'error';

  return body.slug;
}

export async function assignRole(context: Context, body: { companySlug: string } & AssignEntityRequest): Promise<boolean> {
  const url: string = base.url('companyrole', 'assign-role', body.companySlug);
  const pulledRoleAssignment: AssignEntityRequest = { entitySlug: body.entitySlug, assignees: body.assignees };
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(pulledRoleAssignment) };
  const response = await base.request(request);

  if (response.data !== undefined || Object.keys(response).length === 0) {
    return true;
  } else if (response.error) {
    context.actions.addNotification({
      title: 'There was an error assigning this user to the role',
      message: `There was a problem assigning this user to the role.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `${Date.now() + Math.random()}`,
      active: true,
    });

    dconsole.error('Could not assign roles', response.error);
    return false;
  }

  dconsole.error('Unexpected result from assigning roles', response);
  return false;
}

export async function removePersonFromRole(context: Context, body: { companySlug: string } & AssignEntityRequest): Promise<{ success: boolean; message?: string }> {
  const url: string = base.url('companyrole', 'unassign-role', body.companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(body) };
  const response = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error removing this user from the role',
      message: `There was an error removing this user from the role`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `${Date.now() + Math.random()}`,
      active: true,
    });

    return { success: false, message: 'Error removing this user from the role.' };
  }

  return { success: true, message: 'Person removed from role successfully.' };
}

// get-role START
/**
 * Request a Role as a User from within the User's Company.
 * If the Role is not saved to the User's Company this request will result in a 404
 */
export async function getUserRole(context: Context, payload: { roleSlug: string }): Promise<EntityResponse | undefined> {
  const url: string = base.url('companyrole', 'get-role', payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityResponse> = await base.request(request);
  return response.data;
}

/**
 * Request a Role as a User with Permissions or a System Admin from within a specific Company.
 */
export async function getRole(context: Context, payload: { companySlug: string; roleSlug: string }): Promise<EntityResponse | undefined> {
  const url: string = base.url('companyrole', 'get-role', payload.companySlug, payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityResponse> = await base.request(request);
  return response.data;
}
// get-role END

// get-roles START
/**
 * Search for Roles as a User with the User's Company.
 */
export async function getUserRoles(context: Context, payload: CompanyEntitySearchParams): Promise<EntityListResponse | undefined> {
  const url: string = base.url(`companyrole`, `get-roles${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Roles as a User with Permissions or a System Admin.
 */
export async function getRoles(context: Context, payload: CompanyEntitySearchParams): Promise<EntityListResponse | undefined> {
  const url: string = base.url(`companyrole`, `get-roles`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityListResponse> = await base.request(request);
  return response.data;
}
// get-roles END

// get-role-assignees START
/**
 * Search for Roles as a User with the User's Company.
 */
export async function getUserRoleAssignees(context: Context, payload: { roleSlug: string } & BaseSearchRequest): Promise<EntityAssigneeListResponse | undefined> {
  const url: string = base.url(`companyrole`, `get-role-assignees`, `${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityAssigneeListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Role Assignees as a User with Permissions or a System Admin.
 */
export async function getRoleAssignees(context: Context, payload: { companySlug: string; roleSlug: string } & BaseSearchRequest): Promise<EntityAssigneeListResponse | undefined> {
  const url: string = base.url(`companyrole`, `get-role-assignees`, `${payload.companySlug}/${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<EntityAssigneeListResponse> = await base.request(request);
  return response.data;
}
// get-role-assignees END

export async function relateEntityToRole(context: Context, body: { companySlug: string } & RelateEntityRequest): Promise<boolean> {
  const url: string = base.url('companyrole', 'relate', `${body.companySlug}`);
  delete (body as any).companySlug;
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(body) };
  const response = await base.request(request);

  if (response.data !== undefined || Object.keys(response).length === 0) {
    return true;
  } else if (response.error) {
    dconsole.error('Failed to update role', response.error);
    context.actions.addNotification({
      title: 'There was an error assigning the skill from the role',
      message: `There was an error assigning the skill to the role.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `${Date.now() + Math.random()}`,
      active: true,
    });
    return false;
  }

  return false;
}
export async function unrelateEntityFromRole(context: Context, body: { companySlug: string } & RelateEntityRequest): Promise<{ success: boolean; message?: string }> {
  const url: string = base.url('companyrole', 'un-relate', body.companySlug);

  const requestBody = { ...body };
  delete (body as any).companySlug;

  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(requestBody) };
  const response = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error removing the skill from the role',
      message: `There was an error removing the skill from the role.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `${Date.now() + Math.random()}`,
      active: true,
    });

    return { success: false, message: 'Error unrelating skill from role.' };
  }

  return { success: true, message: 'Skill unrelated from role successfully.' };
}

export async function setSkillLevel(context: Context, level: SkillExpectedLevel): Promise<ExpectedSkillLevelResponse> {
  const slug = context.state.companyVariables.slug!;
  const url: string = base.url('companyrole', 'relate', 'expected-level', slug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(level) };
  const response = await base.request(request);

  if (response.error) {
    return {
      isFailure: true,
      isSuccess: false,
    };
  }

  return {
    isFailure: false,
    isSuccess: true,
  };
}
