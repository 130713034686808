import React from 'react';
import { RatingConfirmationModalCardWrapper, RatingConfirmationModalCards } from './rating-confirm-endorsements-modal.styles';
import { TeamMemberReviewResource } from '../../../../../models/team-member-review-resource';
import { Anchor, Button, Chip, ThemeColours, colourString } from '@keplerco/core';

interface ConfirmationEndorsementsModalLayout {
  team: TeamMemberReviewResource[] | undefined;
  title: string;
  handleShowCompleted: () => void;
  handleShowNotCompleted: () => void;
  onClickSubmit: () => Promise<void>;
}

interface RenderCardProps {
  label: string;
  chipBackgroundColor: ThemeColours | undefined;
  count: number;
  buttonText: string;
  handleClick: () => void;
}

export function ConfirmationEndorsementsModalLayout({ team, title, handleShowCompleted, handleShowNotCompleted, onClickSubmit }: ConfirmationEndorsementsModalLayout): JSX.Element {
  const usersNotCompletedReview = team?.filter((i: any) => !i.hasCompletedReview).length ?? 0;
  const usersCompletedReview = team?.filter((i: any) => i.hasCompletedReview).length ?? 0;
  const singleCard = usersNotCompletedReview === 0;

  function RenderCard({ label, chipBackgroundColor, count, buttonText, handleClick }: RenderCardProps) {
    return (
      <div className="card">
        <Chip label={label} variant="tiny" backgroundColour={chipBackgroundColor} />

        <h1 className="heading1" style={{ paddingTop: 20, paddingBottom: 10 }}>
          {count}
        </h1>
        <span className="caption1" style={{ paddingBottom: 20, color: colourString('accent-2') }}>
          {count === 1 ? `Team Member` : `Team members`}
        </span>

        <Anchor onClick={handleClick}>{buttonText}</Anchor>
      </div>
    );
  }

  return (
    <div className="dialogContentLayout modalContentLayout">
      <header className="dialogHeaderLayout modalHeaderLayout" style={{ paddingBottom: 15 }}>
        <h4 className="heading4">{title}</h4>
      </header>

      <RatingConfirmationModalCardWrapper className="dialogBodyLayout" style={singleCard ? { alignItems: 'center' } : {}}>
        {team && !singleCard && (
          <RatingConfirmationModalCards>
            <RenderCard label="Not Started" chipBackgroundColor="light" count={usersNotCompletedReview} buttonText={usersNotCompletedReview === 1 ? `Finish Endorsement` : `Finish Endorsements`} handleClick={handleShowNotCompleted} />
          </RatingConfirmationModalCards>
        )}
        <RatingConfirmationModalCards>
          <RenderCard label="Completed" chipBackgroundColor="apple" count={usersCompletedReview} buttonText={usersCompletedReview === 1 ? `Change Endorsement` : `Change Endorsements`} handleClick={handleShowCompleted} />
        </RatingConfirmationModalCards>
      </RatingConfirmationModalCardWrapper>

      <footer className="dialogFooterLayout modalFooterLayout">
        <Button type="button" filled onClick={onClickSubmit}>
          Yes, I'm done
        </Button>
      </footer>
    </div>
  );
}


