import React, { useEffect, useState } from 'react';
import { FetchType } from '../../../../../../enums';
import { UniqueEntity } from '../../../../../../models';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { ManagePersonPermissionFocusPanel } from './manage-person-permission-focus-panel';
import { IManagePersonPermissionsCascadingPanelsProps, ManagePersonPermissionsCascadingPanelsEnum } from '../manage-person-panels.models';
import { ManagePersonPermissionsFocusPanel } from './manage-person-permissions-focus-panel/manage-person-permissions-focus-panel';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { CascadingFocusPanels, FocusPanel, useCascadingPanelConnector } from '@keplerco/core';

export function ManagePersonPermissionsCascadingPanels(props: IManagePersonPermissionsCascadingPanelsProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const cascade = useCascadingPanelConnector();

  const [company, setCompany] = useState<UniqueEntity>();

  useEffect(() => {
    cascade.next(ManagePersonPermissionsCascadingPanelsEnum.Sections);

    async function getData() {
      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
      const data = await actions.getCompany(companyVariables.slug!);
      if (!!data) setCompany({ name: data.companyName, slug: data.companySlug });
      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, []);

  function onClosePanelByIdHandler(id: string) {
    if (id !== 'sections') return cascade.closeTo('sections');
    cascade.closeById(id);
    props.onCancel();
  }

  return (
    <CascadingFocusPanels openIds={cascade.connector} onClosePanelById={onClosePanelByIdHandler}>
      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Sections}>
        <ManagePersonPermissionsFocusPanel
          person={props.person}
          permissions={props.permissions}
          onClickSection={section => cascade.next(section)}
          onCancel={() => {
            cascade.closeById('sections');
            props.onCancel();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Analytics}>
        <ManagePersonPermissionFocusPanel
          name="Analytics"
          company={company}
          person={props.person}
          permission={props.permissions.analytics}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Administration}>
        <ManagePersonPermissionFocusPanel
          name="Administration"
          company={company}
          person={props.person}
          permission={props.permissions.administration}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Assessments}>
        <ManagePersonPermissionFocusPanel
          name="Assessment Management"
          company={company}
          person={props.person}
          permission={props.permissions.assessments}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.LearningManagement}>
        <ManagePersonPermissionFocusPanel
          name="Learning Management"
          company={company}
          person={props.person}
          permission={props.permissions.learningManagement}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Skills}>
        <ManagePersonPermissionFocusPanel
          name="Skills"
          company={company}
          person={props.person}
          permission={props.permissions.skills}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>

      <FocusPanel id={ManagePersonPermissionsCascadingPanelsEnum.Roles}>
        <ManagePersonPermissionFocusPanel
          name="Roles"
          company={company}
          person={props.person}
          permission={props.permissions.roleManagement}
          onCancel={() => cascade.previous()}
          onSave={() => {
            props.onSave();
            cascade.previous();
          }}
        />
      </FocusPanel>
    </CascadingFocusPanels>
  );
}

