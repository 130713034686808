import React from 'react';
import { TopNavContainer, TopNavContent, TopNavLogo, TopNavLogoContainer } from './top-nav.styles';
import classNames from 'classnames';
import { useAppState } from '../../overmind';
import { TopNavMenu } from './top-nav-menu/top-nav-menu';
import { themedAssetUrl } from '../../lib/theme-asset-url';
import { PagePath } from '../navigation.enums';
import { KeplerPoints, useHasScrolled } from '@keplerco/core';

export function TopNav(props: { isMobile: boolean; isTablet: boolean; onOpenSideNavDialog: () => void }): JSX.Element {
  const { isMobile, isTablet, onOpenSideNavDialog } = props;

  const { user, keplerPoints, skillAssessmentConfiguration, mayViewSideNav, sideNavVisible } = useAppState();

  const hasScrolled = useHasScrolled(5);

  return (
    <TopNavContainer className={classNames({ hasScrolled })}>
      <TopNavLogoContainer href={PagePath.root}>
        {(isMobile || isTablet || !mayViewSideNav || !sideNavVisible) && <React.Fragment>{isMobile ? <TopNavLogo src={themedAssetUrl('logos/logo.icon.svg')} alt="Logo Icon" /> : <TopNavLogo src={themedAssetUrl('logos/logo.svg')} alt="Logo" />}</React.Fragment>}
      </TopNavLogoContainer>

      <TopNavContent>
        {!isMobile && !isTablet && !user?.isSystemAdmin && skillAssessmentConfiguration?.benchmarkResultReady && !!keplerPoints && (
          <h5 className="heading5">
            <KeplerPoints trimWhiteSpace points={keplerPoints} />
          </h5>
        )}

        <TopNavMenu isMobile={isMobile} isTablet={isTablet} onOpenSideNavDialog={onOpenSideNavDialog} />
      </TopNavContent>

      <div id="hubspot-bot" />
    </TopNavContainer>
  );
}

