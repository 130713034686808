import styled from 'styled-components';

export const BulkUploadHeader = styled.header`
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  a {
    color: var(--link-text);
    text-decoration: none;
    cursor: pointer;
  }
`;

