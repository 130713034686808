import React, { useEffect, useState } from 'react';
import { Button, DropdownSelect, DropdownSelectItem, EditIcon, PageHeader } from '@keplerco/core';
import { FetchType } from '../../../../enums';
import { PageLoaderLayer } from '../../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../overmind';
import { useLocation, useParams } from 'react-router-dom';
import { ViewRoleSkeleton } from './view-role.skeleton';
import styles from './view-role.module.css';
import ViewRoleAssignedPeople from './view-role-assigned-people/view-role-assigned-people.page';
import ViewRoleAssignedSkills from './view-role-assigned-skills/view-role-assigned-skills.page';
import classNames from 'classnames';
import { EntityResponse } from '../../../../models/overmind/entities';
import { useKeplerNavigate } from '../../../../navigation/guards/use-kepler-navigate';

export default function ViewRolePage() {
  const { roleSlug } = useParams();
  const { pathname } = useLocation();
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { user, companyVariables } = useAppState();

  const [role, setRole] = useState<EntityResponse>();
  const [selectedRoleSlug, setSelectedRoleSlug] = useState<string | undefined>(user?.jobRoles[0]?.slug);

  async function fetchRole(companySlug?: string, roleSlug?: string) {
    if (!companySlug || !roleSlug) {
      console.error('Company slug and role slug are required for fetching role details.');
      return;
    }

    const roleDescription = pathname !== PagePath.myCareer
      ? await actions.getRole({ companySlug, roleSlug })
      : await actions.getUserRole({ roleSlug });
    if (roleDescription) setRole(roleDescription);
    actions.stopLoader(PagePath.administrationSelectedRole);
  }

  useEffect(() => {
    actions.startLoader({ path: PagePath.administrationSelectedRole, type: FetchType.PageFetching });
    fetchRole(companyVariables.slug!, pathname !== PagePath.myCareer ? roleSlug : selectedRoleSlug);
  }, [roleSlug, selectedRoleSlug]);

  const isImport = window.location.pathname.includes('import');

  function mapJobRolesSelection(): DropdownSelectItem[] {
    return (user?.jobRoles ?? []).map(role => {
      const item: DropdownSelectItem = {
        label: role.name,
        value: role.slug,
        onClick: () => {
          setSelectedRoleSlug(role.slug);
        },
        selected: role.slug === selectedRoleSlug || role.slug === roleSlug,
      };

      return item;
    });
  }

  return (
    <div className="pageWrapper wrapper">
      <PageLoaderLayer path={PagePath.administrationSelectedRole} skeletonLoader={<ViewRoleSkeleton />}>
        {!!role ? (
          <PageHeader
            title={role.name}
            breadcrumbs={pathname !== PagePath.myCareer ? [
              { name: `Administration`, url: PagePath.administrationBase },
              { name: `Roles`, url: `${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companyVariables.slug!)}` },
              { name: `${role?.name}`, url: window.location.pathname },
            ] : ([
              { name: `My Career`, url: window.location.pathname },
            ])}
            type="actions"
          >
            <React.Fragment>
              {pathname !== PagePath.myCareer ? (
                <div className={styles.buttonContainer}>
                  <Button
                    fullWidthMobile
                    type="button"
                    square
                    theme="dark"
                    onClick={async () => {
                      if (isImport) {
                        await actions.updateRole({ companySlug: companyVariables.slug!, ...role });
                        keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRoleEdit.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}?isImport=true`);
                        return;
                      }

                      keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationSelectedRoleEdit.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}`);
                    }}
                  >
                    <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                      <EditIcon />
                      {isImport ? 'Import' : 'Edit'} Role
                    </div>
                  </Button>
                </div>
              ) : (
                <React.Fragment>
                  {!!user?.jobRoles && user.jobRoles.length > 1 && <DropdownSelect label="Select Role" items={mapJobRolesSelection()} />}
                </React.Fragment>
              )}
            </React.Fragment>
          </PageHeader>
        ) : (
          <PageHeader
            title="Role"
            breadcrumbs={pathname !== PagePath.myCareer ? [
              { name: `Administration`, url: PagePath.administrationBase },
              { name: `Roles`, url: `${PagePath.administrationBase}${PagePath.administrationRoles.replace(':companySlug', companyVariables.slug!)}` },
              { name: `Role`, url: window.location.pathname },
            ] : ([
              { name: `My Career`, url: window.location.pathname },
            ])}
          />
        )}

        <div className={classNames(styles.viewRoleHeaderCards, { [styles.viewRoleHeaderCardsSingleColumn]: isImport })}>
          <div className={classNames('card', styles.viewRoleHeaderCard)}>
            <h5 className="heading5">{role?.name}</h5>
            <p className="body1">{role?.description}</p>
          </div>

          {/* TODO: add when related roles is ready */}
          {/* {!isImport && (
            <div className={classNames('card', styles.viewRoleHeaderCard)}>
              <h5 className="heading5">{role?.name}</h5>

              <EmptyState subtitle="No related roles assigned" badgeIconWidth={'full-width'} badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} />

              <ChipWrapper className={styles.viewRoleHeaderCardChips}>
                <Chip whiteSpace="nowrap" label="Developer" backgroundColour="baby-blue" isOutline fontColour="text" variant="large" borderRadius="60px" />
                <Chip whiteSpace="nowrap" label="A really long title that must wrap" backgroundColour="baby-blue" isOutline fontColour="text" variant="large" borderRadius="60px" />
              </ChipWrapper>
            </div>
          )} */}
        </div>

        <div className={styles.viewRoleCards}>
          {!!role && <ViewRoleAssignedSkills role={role} />}
          {(!!role && !isImport) && <ViewRoleAssignedPeople role={role} />}
        </div>
      </PageLoaderLayer>
    </div>
  );
}
